const AUTH_LOADER = 'AUTH_LOADER'
const REGISTER_REQUEST = 'REGISTER_REQUEST'
const SEND_OTP = 'SEND_OTP'
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
const RESET = 'RESET'
const ROUTE_DATA_VERIFICATION = "ROUTE_DATA_VERIFICATION"
const ROUTE_DATA_RESET_PWD = "ROUTE_DATA_RESET_PWD"
const SET_HEADER_TAB = "SET_HEADER_TAB"
const DRAWER_STATE = "DRAWER_STATE"
const GET_STATE_DATA = "GET_STATE_DATA"

export { 
  AUTH_LOADER,
  SET_HEADER_TAB, 
  REGISTER_REQUEST, 
  SEND_OTP, 
  SET_AUTH_TOKEN, 
  RESET, 
  ROUTE_DATA_VERIFICATION, 
  ROUTE_DATA_RESET_PWD, 
  DRAWER_STATE, 
  GET_STATE_DATA 
}