import React, { useEffect, useState } from "react";
import Map from "./Map";
import moment from "moment";
import DriverMap from "./DriverMap";
import { OrderStatuses } from "../../constants/orderStatus";

import backArrowImg from "assets/images/back-arrow-mobile.svg";
import swipeDownArrowImg from "assets/images/swipe-down-arrow.svg";
import { useSwipeable } from "react-swipeable";
import alertCross from "assets/images/alert-cross.svg";
import CancelOrderMobile from "./CancelOrderMobile";
import dropdownIcon from "assets/images/dropdown-arrow.svg";
import { useDispatch } from "react-redux";
import FeesAndTax from "components/FeesAndTax";
import tooltipIcon from "assets/images/tooltip-icon.svg";

const MobileView = (props) => {
	const {
		calcArray,
		orderInfo,
		products,
		notes,
		isDelivery,
		giftCard,
		driverStatus,
		orderStatus,
		getSingleOrder,
		handleReorder,
		paymentMode,
	} = props;
	const fullMapHeight = "375px";
	const [fullViewMapHeight, setFullViewMapHeight] = useState(null);

	const [orderProducts, setOrderProducts] = useState([]);
	const [showDropdown, setShowDropdown] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (products && products.length) {
			if (orderInfo[0].group_order_id) {
				let unique_member_ids = products
					.map((it) => it.member_id)
					.filter((value, index, self) => self.indexOf(value) === index);

				let memberArr = [];

				unique_member_ids.forEach((it) => {
					let elements = products.filter((data) => data.member_id === it);
					memberArr.push(elements);
				});

				let data = memberArr.map((it) => ({
					member_name: it[0].member_name,
					items: it,
				}));

				setOrderProducts(data);
			}
		}
	}, [products]);

	const [priceObj, setPriceObj] = useState({});

	const [showDetail, setToggleDetail] = useState(false);
	const [showDriverMap, setShowDriverMap] = useState(false);
	const [currentDriverStatus, setCurrentDriverStatus] = useState();
	const [currentOrderStatus, setCurrentOrderStatus] = useState();
	const [deliveryTime, setDeliveryTime] = useState(null);
	const [readyTime, setReadyTime] = useState(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		const obj = {};
		calcArray.map((item) => {
			obj[item.title] = item;
		});

		setPriceObj(obj);
	}, [calcArray]);

	useEffect(() => {
		if (orderStatus) {
			if (+orderStatus.order_id === +orderInfo[0].orders_id) {
				for (var key in OrderStatuses) {
					if (OrderStatuses.hasOwnProperty(key)) {
						if (OrderStatuses[key].id === +orderStatus?.order_status_id) {
							setCurrentOrderStatus({ ...OrderStatuses[key], ...orderStatus });
							// below block for only delivery type
							if (isDelivery && OrderStatuses[key].order === 4) {
								setShowDriverMap(false);
								setShowDriverMap(true);
								setCurrentDriverStatus(null);
							}
							if (OrderStatuses[key].order === 4) {
								setReadyTime(null);
								setDeliveryTime(null);
							}
							// below block will gives us time for step 2 and 3
							if ([2, 3].includes(OrderStatuses[key].order)) {
								if (isDelivery) {
									setReadyTime(null);
									let mins =
										+orderInfo[0].restaurant_eta +
										+orderInfo[0].duration_to_deliver;
									mins = mins + " mins";
									setDeliveryTime(mins);
									if ([3].includes(OrderStatuses[key].order)) {
										if (orderInfo[0].admin_lat && orderInfo[0].admin_lng) {
											setShowDriverMap(true);
										}
										setCurrentDriverStatus({
											latitude:
												+orderInfo[0].admin_lat || +orderInfo[0].latitude,
											longitude:
												+orderInfo[0].admin_lng || +orderInfo[0].longitude,
										});
									}
								} else {
									setDeliveryTime(null);
									let mins = +orderInfo[0].restaurant_eta;
									mins = mins + " mins";
									setReadyTime(mins);
								}
							}
						}
					}
				}
			}
		}
	}, [orderStatus]);

	// cancel oprder map
	useEffect(() => {
		if (+orderInfo?.[0]?.orders_status_id === 6) {
			setShowDriverMap(true);
		}
	}, [orderInfo?.[0]?.orders_status_id]);

	useEffect(() => {
		if (driverStatus) {
			if (+driverStatus.order_id === +orderInfo[0].orders_id) {
				if (currentOrderStatus.order !== 4) {
					setShowDriverMap(true);
					setCurrentDriverStatus({
						latitude: +driverStatus.lat,
						longitude: +driverStatus.lng,
					});
				}
				if (currentOrderStatus.order === 4) {
					setDeliveryTime(null);
					setCurrentDriverStatus(null);
				}
			}
		}
	}, [driverStatus]);

	const isDataLoaded = Object.keys(priceObj).length;
	const [cancelModal, setCancelModal] = useState(false);

	const swipeDownHandler = () => {
		setFullViewMapHeight(fullMapHeight);
	};

	const deliveryTimeHandler = (val) => {
		if (val) {
			setDeliveryTime(val.text);
		}
	};
	const handlers = useSwipeable({
		onSwipedDown: () => swipeDownHandler(),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	const handleCancelOrder = () => {
		setCancelModal(true);
	};

	const toggleMember = (index) => {
		let copy = showDropdown.map((it) => it);
		if (copy.includes(index)) {
			let pcopy = copy.filter((it) => it !== index);
			setShowDropdown(pcopy);
		} else {
			copy.push(index);
			setShowDropdown(copy);
		}
	};

	return cancelModal ? (
		<CancelOrderMobile
			closeModal={() => setCancelModal(false)}
			getSingleOrder={getSingleOrder}
			orderInfo={orderInfo?.[0]}
			paymentMode={paymentMode}
		/>
	) : (
		<main className="mobile-inner-screen">
			<FeesAndTax
				show={show}
				setShow={setShow}
				fullscreen={true}
				centered={true}
				sf={`${parseFloat(
					+parseFloat(
						Number(priceObj["Restaurant Service Fee"]?.value),
					).toFixed(2) +
						+parseFloat(
							Number(priceObj["Phone Order Service Fee"]?.value),
						).toFixed(2),
				).toFixed(2)}`}
				lof={+parseFloat(Number(priceObj["Low Order Fee"]?.value)).toFixed(2)}
				st={+parseFloat(Number(priceObj["Sales Tax"]?.value)).toFixed(2)}
			/>
			<div className="payment__page__wrapper orderDetails__page__wrapper">
				<div className="go-back-wrapper">
					<a href className="go-back-btn" onClick={props.handleGoBack}>
						<img src={backArrowImg} alt="go back" />
					</a>
					<h2>Order Details</h2>
					{[1, 4].includes(currentOrderStatus?.order) && (
						<button
							className="btn btn-cencel-order"
							onClick={
								currentOrderStatus?.order === 4
									? handleReorder
									: handleCancelOrder
							}
						>
							{currentOrderStatus?.order === 4 ? "Reorder" : "Cancel Order"}
						</button>
					)}
				</div>
				<div className="main-wrapper">
					<section className="mobile-inner-section">
						<div className="main">
							<div
								className="map-section"
								{...handlers}
								style={{ touchAction: "pan-y" }}
							>
								<div className="map-wrapper">
									{readyTime && (
										<div className="delivery-status-time">
											<span>Ready in {readyTime}</span>
										</div>
									)}
									{deliveryTime && (
										<div className="delivery-status-time">
											<span>Delivery in {deliveryTime}</span>
										</div>
									)}
									{!showDriverMap ? (
										<Map
											cities={props.cities}
											handleOnLoad={props.handleOnLoad}
											dispatch={props.dispatch}
											polylineOptions={
												props.polylineOptions ? props.polylineOptions : false
											}
											mapProjection={props.mapProjection}
											mapZoom={props.mapZoom}
											height={fullViewMapHeight || "228px"}
											isCheckOut={isDelivery}
											mapValue={props.mapValue}
											isPickUp={
												orderInfo.length && orderInfo[0].pickup_order ? 1 : 0
											}
										/>
									) : (
										<DriverMap
											driverPosition={currentDriverStatus}
											destinationPosition={{
												latitude: +orderInfo[0].customers_lat,
												longitude: +orderInfo[0].customers_lng,
											}}
											height={fullViewMapHeight || "228px"}
											deliveryTimeHandler={deliveryTimeHandler}
										/>
									)}
									{!fullViewMapHeight && (
										<div
											role="button"
											className="swipe-down"
											onClick={swipeDownHandler}
										>
											<img
												src={swipeDownArrowImg}
												alt="icon"
												className="img-fluid"
											/>
											<span>Swipe down to view map</span>
										</div>
									)}
								</div>
							</div>
							<div className="container">
								{currentOrderStatus?.id === 6 ? (
									<div className="order-details-cancelled-alert">
										<div className="inner-wrapper">
											<div className="img-box">
												<img
													src={alertCross}
													alt="cross icon"
													className="img-fluid"
												/>
											</div>
											<div className="content-box">
												<p>
													<p>
														{paymentMode?.toLowerCase() === "cash"
															? "This order is canceled."
															: "This order is canceled. The amount has been refunded to your payment method."}
													</p>
												</p>
											</div>
										</div>
									</div>
								) : null}
								<div className="order-details-stepper">
									{currentOrderStatus?.id === 6 ? null : (
										<div className="row">
											<div className="col-xs-12 col-md-12">
												<div className="order-status">
													<span>{currentOrderStatus?.text}</span>
												</div>
											</div>
											<div className="col-xs-12 col-md-12 pb-3">
												<div className="wrapper-progressBar">
													<ul className="progressBar">
														<li
															className={
																currentOrderStatus?.order >= 1 ? "active" : null
															}
														></li>
														<li
															className={
																currentOrderStatus?.order >= 2 ? "active" : null
															}
														></li>
														<li
															className={
																currentOrderStatus?.order >= 3 ? "active" : null
															}
														></li>
														<li
															className={
																currentOrderStatus?.order >= 4 ? "active" : null
															}
														></li>
													</ul>
												</div>
											</div>
										</div>
									)}
									<div className="order-info">
										{orderInfo.length ? (
											<div className="row">
												<div className="col-12">
													<div className="order-detail">
														<div className="row">
															<div className="col-5">
																<span>Order Number</span>
															</div>
															<div className="col-7">
																<span className="font-weight-bold">
																	{+orderInfo[0].orders_id}
																</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="order-detail">
														<div className="row">
															<div className="col-5">
																<span>Restaurant Name</span>
															</div>
															<div className="col-7">
																<span className="font-weight-bold">
																	{orderInfo[0].restaurants_name}
																</span>
															</div>
														</div>
													</div>
												</div>
												{isDelivery ? (
													<>
														<div className="col-12">
															<div className="order-detail">
																<div className="row">
																	<div className="col-5">
																		<span>
																			{currentOrderStatus?.order === 4
																				? "Delivery"
																				: "Order"}{" "}
																			Time
																		</span>
																	</div>
																	<div className="col-7">
																		<span className="font-weight-bold">
																			{currentOrderStatus?.order === 4
																				? moment(
																						currentOrderStatus?.date_deliver,
																				  ).format("ddd, MM/DD/yyyy h:mm A")
																				: moment(
																						currentOrderStatus?.date_purchased,
																				  ).format("ddd, MM/DD/yyyy h:mm A")}
																			{/* {`${
                                      currentOrderStatus?.date_deliver
                                        ? moment(
                                            currentOrderStatus?.date_deliver
                                          ).format("ddd, MM/DD/yyyy h:mm A")
                                        : ""
                                    }`} */}
																		</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12">
															<div className="order-detail">
																<div className="row">
																	<div className="col-5">
																		<span>Delivery Address</span>
																	</div>
																	<div className="col-7">
																		<span className="font-weight-bold">
																			{orderInfo[0].customers_street_address}
																		</span>
																		{notes.map((item) =>
																			!item.note_type ? (
																				<p className="mb-0">
																					<label for="order-detail">
																						{item.note}
																					</label>
																				</p>
																			) : null,
																		)}
																	</div>
																</div>
															</div>
														</div>
													</>
												) : (
													<>
														<div className="col-12">
															<div className="order-detail">
																<div className="row">
																	<div className="col-5">
																		<span>Pickup Time</span>
																	</div>
																	<div className="col-7">
																		<span className="font-weight-bold">
																			{moment(orderInfo[0].date_deliver).format(
																				"ddd, MM/DD/yyyy h:mm A",
																			)}
																		</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12">
															<div className="order-detail">
																<div className="row">
																	<div className="col-5">
																		<span>Pickup Address</span>
																	</div>
																	<div className="col-7">
																		<span className="font-weight-bold">
																			{orderInfo[0].restaurant_address
																				? orderInfo[0].restaurant_address
																				: ""}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</>
												)}
											</div>
										) : null}
									</div>
								</div>

								{orderInfo[0]?.group_order_id ? (
									<>
										<div class="row">
											<div class="col-12 my-4 pt-3">
												<div class="section_heading mb-3">
													<h2>Your Group Orders</h2>
												</div>
											</div>
										</div>
										{orderProducts.map((member, index) => (
											<>
												<div class="order-details-wrapper accordian">
													<div class="row">
														<div class="col-12">
															<div class="section_heading mb-3">
																<a
																	href
																	class="btn btn-accordian"
																	role="button"
																	onClick={() => toggleMember(index)}
																>
																	<h2>
																		{member.member_name}
																		<span class="icon">
																			<img
																				src={dropdownIcon}
																				alt="accordian-arrow"
																				className={
																					showDropdown.includes(index)
																						? "rotate-180"
																						: "rotate-0"
																				}
																			/>
																		</span>
																	</h2>
																</a>
															</div>
														</div>
													</div>
													{showDropdown.includes(index) ? (
														<div class="order-details-cards-wrapper gp-order-details">
															<div class="row">
																<div class="col-12">
																	{member?.items &&
																		member?.items?.length &&
																		member?.items?.map((item, idx) => (
																			<div class="order-details-card" key={idx}>
																				<div className="d-flex justify-content-between">
																					<div class="rightside">
																						<span class="item-count">
																							{item.products_quantity}x
																						</span>
																						<div className="order-item-details">
																							<h5>{item.products_name}</h5>
																						</div>
																					</div>
																					<div class="order-price">
																						<span>
																							$
																							{parseFloat(
																								item.final_price,
																							).toFixed(2)}
																						</span>
																					</div>
																				</div>
																				<div class="order-item-details">
																					{item.options &&
																						item.options.map((value, index) => {
																							return (
																								<>
																									{value.optionValues &&
																										value.optionValues.map(
																											(attr) => (
																												<>
																													{attr.products_options_values_id !=
																													0 ? (
																														<span
																															className={
																																"comma"
																															}
																														>
																															{
																																attr.products_options_values
																															}
																														</span>
																													) : null}
																												</>
																											),
																										)}
																								</>
																							);
																						})}
																					{item.options &&
																						item.options.map((value, index) =>
																							!value.optionValues[0]
																								.products_options_values_id ? (
																								<p>
																									{
																										value.optionValues[0]
																											.products_options_values
																									}
																								</p>
																							) : null,
																						)}
																				</div>
																			</div>
																		))}
																</div>
															</div>
														</div>
													) : null}
												</div>
											</>
										))}
									</>
								) : (
									<>
										<div className="order-details-wrapper normal-order-details">
											<div className="row">
												<div className="col-12 mt-4 pt-2">
													<div className="section_heading">
														<h2>
															Summary{" "}
															<span>{`(${products.length} Item)`}</span>
														</h2>
													</div>
												</div>
												<div className="col-12">
													<div className="order-in-details">
														<a
															href
															role="button"
															aria-expanded={showDetail ? "true" : "false"}
															onClick={() => {
																setToggleDetail(!showDetail);
															}}
														>
															{showDetail ? "Hide" : "View"} Details
														</a>
													</div>
												</div>
											</div>
											{showDetail ? (
												<div className="order-details-cards-wrapper">
													<div className="row">
														<div className="col-12">
															{products.map((item, index) => {
																return (
																	<div className="order-details-card">
																		<div className="d-flex justify-content-between">
																			<div className="rightside">
																				<span className="item-count">
																					{item?.products_quantity}x
																				</span>
																				<div className="order-item-details">
																					<h5>{item.products_name}</h5>
																				</div>
																			</div>
																			<div className="order-price">
																				<span>
																					$
																					{parseFloat(item.final_price).toFixed(
																						2,
																					)}
																				</span>
																			</div>
																		</div>
																		<div className="order-item-details">
																			<div>
																				{item.options &&
																					item.options.map((value, index) => (
																						<>
																							<span className={"comma"}>
																								{value.optionValues[0]
																									.products_options_values_id
																									? value.optionValues[0]
																											.products_options_values
																									: null}
																							</span>
																						</>
																					))}
																			</div>
																			{item.options &&
																				item.options.map((value, index) =>
																					!value.optionValues[0]
																						.products_options_values_id ? (
																						<p>
																							{
																								value.optionValues[0]
																									.products_options_values
																							}
																						</p>
																					) : null,
																				)}
																		</div>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											) : null}
										</div>
									</>
								)}

								<div className="row">
									{notes.map((item) =>
										item.note_type ? (
											<div className="col-12 mt-4">
												<div className="section_heading mb-2">
													<h2>Special Instructions</h2>
												</div>
												<div className="special-instruction">
													<p className="m-0">{item.note}</p>
												</div>
											</div>
										) : null,
									)}
								</div>
							</div>
							{isDataLoaded ? (
								<div className="sidenav_right">
									<div className="inner-wrapper">
										{/* <div className="section_heading mb-2">
                      <h2>Billing Details</h2>
                    </div> */}
										<div className="calculation-details">
											<div className="subtotatals">
												<div className="row">
													<div className="col-12">
														<div className="d-flex justify-content-between">
															<span>Subtotal</span>
															<span>
																$
																{parseFloat(
																	Number(priceObj["Subtotal"].value),
																).toFixed(2)}
															</span>
														</div>
													</div>
													
													{isDelivery ? (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>Delivery Fee</span>
																<span>
																	{" "}
																	$
																	{parseFloat(
																		Number(priceObj["Delivery"]?.value),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													) : (
														<></>
													)}

													<div className="col-12">
														<div className="d-flex justify-content-between">
															<span>
																Fees and Sales Tax
																<span class="tooltip-custom">
																	<span
																		data-tip
																		data-for="registerTip"
																		onClick={() => setShow(true)}
																	>
																		<img src={tooltipIcon} alt="tooltipIcon" />
																	</span>
																</span>
															</span>
															<span>
																$
																{`${parseFloat(
																	+parseFloat(
																		Number(priceObj["Sales Tax"]?.value),
																	).toFixed(2) +
																		+parseFloat(
																			Number(priceObj["Low Order Fee"]?.value),
																		).toFixed(2) +
																		+parseFloat(
																			Number(
																				priceObj["Restaurant Service Fee"]
																					?.value,
																			),
																		).toFixed(2) +
																		+parseFloat(
																			Number(
																				priceObj["Phone Order Service Fee"]
																					?.value,
																			),
																		).toFixed(2),
																).toFixed(2)}`}
															</span>
														</div>
													</div>
													{isDelivery ? (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>Driver Tip</span>
																<span>
																	$
																	{parseFloat(
																		Number(priceObj["Gratuity"].value),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													) : null}
													{parseFloat(
														Number(priceObj["Group Pricing Discount"].value),
													).toFixed(2) == 0.0 ? null : (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>Group Discount</span>
																<span>
																	-$
																	{parseFloat(
																		Number(
																			priceObj["Group Pricing Discount"].value,
																		),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													)}
													{parseFloat(
														Number(priceObj["Coupon Discount"].value),
													).toFixed(2) == 0.0 ? null : (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>Discount Applied</span>
																<span>
																	-$
																	{parseFloat(
																		Number(priceObj["Coupon Discount"].value),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													)}
													{parseFloat(
														Number(priceObj["Wallet Amount"].value),
													).toFixed(2) == 0.0 ? null : (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>Food Dude Balance</span>
																<span>
																	-$
																	{parseFloat(
																		Number(priceObj["Wallet Amount"].value),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													)}
													{Object.keys(giftCard).map((item) => (
														<div className="col-12">
															<div className="d-flex justify-content-between">
																<span>
																	Gift Card {giftCard[item].giftcard_code}
																</span>
																<span>
																	$
																	{parseFloat(
																		Number(giftCard[item].used_amount),
																	).toFixed(2)}
																</span>
															</div>
														</div>
													))}
												</div>
											</div>
											<div className="final-amount">
												<div className="row">
													<div className="col-12">
														<div className="d-flex justify-content-between">
															<span>Total</span>
															<span>
																$
																{parseFloat(
																	Number(priceObj["Total"].value),
																).toFixed(2)}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : null}
							<div className="container">
								<div className="row">
									<div className="col-12">
										<div className="billing__details">
											<div className="paid-by">
												<div className="billing__data">
													<span>Paid By</span>
													<span>
														{orderInfo[0] && orderInfo[0].payment_method}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>
	);
};

export default MobileView;
