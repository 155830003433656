import React from 'react';
import { useSelector, useDispatch } from "react-redux";

const Loader = () => {
  const store = useSelector(store => store)
  return (
    <>
      {store.auth.loader && 
        <div>
          <section className="loading">
            <div className='sk-spinner-pulse'></div>
          </section>
        </div>
      }
    </>
  );
};

export default Loader;