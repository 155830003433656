import moment from "moment";
export const formatter = (response) => {
	const address = response.results[0].formatted_address;
	let city, state, country, postal_code, street_number, route;
	for (let i = 0; i < response.results[0].address_components.length; i++) {
		for (
			let j = 0;
			j < response.results[0].address_components[i].types.length;
			j++
		) {
			switch (response.results[0].address_components[i].types[j]) {
				case "street_number":
					street_number = response.results[0].address_components[i].long_name;
					break;
				case "route":
					route = response.results[0].address_components[i].long_name;
					break;
				case "locality":
					city = response.results[0].address_components[i].long_name;
					break;
				case "administrative_area_level_1":
					state = response.results[0].address_components[i].long_name;
					break;
				case "country":
					country = response.results[0].address_components[i].long_name;
					break;
				case "postal_code":
					postal_code = response.results[0].address_components[i].long_name;
					break;
			}
		}
	}
	console.log(
		"city",
		city,
		"state",
		state,
		"country",
		country,
		"postal_code",
		postal_code,
		"street_number",
		street_number,
		"route",
		route,
	);
	return { address, city, state, country, postal_code, street_number, route };
};

const timeGenerator = () => {
	var x = 30; //minutes interval
	var times = []; // time array
	var tt = 0; // start time
	var ap = ["AM", "PM"]; // AM-PM

	//loop to increment the time and push results in array
	for (var i = 0; tt < 24 * 60; i++) {
		var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
		var mm = tt % 60; // getting minutes of the hour in 0-55 format
		times[i] =
			("0" + (hh % 12)).slice(-2) +
			":" +
			("0" + mm).slice(-2) +
			ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
		tt = tt + x;
	}

	console.log(times);
};

export const getTimeStops = (start, end, interval) => {
	var startTime = moment(start, "HH:mm");
	var endTime = moment(end, "HH:mm");

	// if( endTime.isBefore(startTime) ){
	//   endTime.add(1, 'day');
	// }

	var timeStops = [];

	while (startTime <= endTime) {
		let x = new moment(startTime).format("HH:mm");
		timeStops.push(moment(x, "hh:mm").format("LT"));
		startTime.add(+interval, "minutes");
	}
	// console.log("timeStops ", timeStops);
	// let y = moment(end, "hh:mm").format('LT')
	// timeStops.push(moment(y, "hh:mm").format('LT'))
	// console.log("timeStops 1", timeStops);
	return timeStops;
};

export const chekIfMenuOpen = (start, end, selecteddate, selecttime) => {
	if (start || end || selecteddate || selecttime) {
		const date = selecteddate ? selecteddate : "2013-05-29";
		const currentTime = selecttime
			? moment(selecttime, ["hh:mm A"]).format("HH:mm")
			: moment(new Date()).format("HH:mm");
		var fromdt = `${date}T${currentTime}`;
		var from = new Date(fromdt);
		var todt = `${date}T${start}`;
		var to = new Date(todt);
		var enddt = `${date}T${end}`;
		var en = new Date(enddt);
		if (from >= to && from <= en) {
			return false;
		} else {
			return true;
		}
	}
	return false;
};

const timeArray = [
	"00:00AM",
	"00:30AM",
	"01:00AM",
	"01:30AM",
	"02:00AM",
	"02:30AM",
	"03:00AM",
	"03:30AM",
	"04:00AM",
	"04:30AM",
	"05:00AM",
	"05:30AM",
	"06:00AM",
	"06:30AM",
	"07:00AM",
	"07:30AM",
	"08:00AM",
	"08:30AM",
	"09:00AM",
	"09:30AM",
	"10:00AM",
	"10:30AM",
	"11:00AM",
	"11:30AM",
	"00:00PM",
	"00:30PM",
	"01:00PM",
	"01:30PM",
	"02:00PM",
	"02:30PM",
	"03:00PM",
	"03:30PM",
	"04:00PM",
	"04:30PM",
	"05:00PM",
	"05:30PM",
	"06:00PM",
	"06:30PM",
	"07:00PM",
	"07:30PM",
	"08:00PM",
	"08:30PM",
	"09:00PM",
	"09:30PM",
	"10:00PM",
	"10:30PM",
	"11:00PM",
	"11:30PM",
];

export const jobs = [
	{
		id: "183286",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - St. Cloud",
		shortcode: "11BA360323",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1584564",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1584564/candidates/new",
		shortlink: "https://apply.workable.com/j/11BA360323",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-01-26T06:09:37Z"
	},
	{
		id: "183ae0",
		title: "Food Dudes Driver Partner - Northwest Metro",
		full_title: "Food Dudes Driver Partner - Northwest Metro - Osseo",
		shortcode: "035BF71098",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1586702",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1586702/candidates/new",
		shortlink: "https://apply.workable.com/j/035BF71098",
		location: {
			location_str: "Osseo, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Osseo",
			zip_code: "55369",
			telecommuting: false
		},
		created_at: "2021-01-26T20:31:52Z"
	},
	{
		id: "183aec",
		title: "Lead Driver - Food Dudes Northwest Metro",
		full_title: "Lead Driver - Food Dudes Northwest Metro - Osseo",
		shortcode: "D89C0AEA8F",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1586714",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1586714/candidates/new",
		shortlink: "https://apply.workable.com/j/D89C0AEA8F",
		location: {
			location_str: "Osseo, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Osseo",
			zip_code: "55369",
			telecommuting: false
		},
		created_at: "2021-01-26T20:38:30Z"
	},
	{
		id: "1840a8",
		title: "Assistant Market Manager",
		full_title: "Assistant Market Manager - Osseo",
		shortcode: "98EC8BD158",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1588182",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1588182/candidates/new",
		shortlink: "https://apply.workable.com/j/98EC8BD158",
		location: {
			location_str: "Osseo, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Osseo",
			zip_code: "55369",
			telecommuting: false
		},
		created_at: "2021-01-27T19:52:28Z"
	},
	{
		id: "18d4ec",
		title: "Food Dudes Driver partner",
		full_title: "Food Dudes Driver partner - St. Cloud",
		shortcode: "6BF93289EE",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1626138",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1626138/candidates/new",
		shortlink: "https://apply.workable.com/j/6BF93289EE",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-02-24T20:10:47Z"
	},
	{
		id: "18d65a",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - St. Cloud",
		shortcode: "7237F76269",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1626504",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1626504/candidates/new",
		shortlink: "https://apply.workable.com/j/7237F76269",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-02-25T03:47:50Z"
	},
	{
		id: "18e647",
		title: "Delivery Driver",
		full_title: "Delivery Driver - St. Cloud",
		shortcode: "179E8B1F4B",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1630581",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1630581/candidates/new",
		shortlink: "https://apply.workable.com/j/179E8B1F4B",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-01T02:41:18Z"
	},
	{
		id: "18e648",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Fargo",
		shortcode: "961F1DD5C2",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1630582",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1630582/candidates/new",
		shortlink: "https://apply.workable.com/j/961F1DD5C2",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: "58103",
			telecommuting: false
		},
		created_at: "2021-03-01T02:45:28Z"
	},
	{
		id: "18eb62",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Fargo",
		shortcode: "2D1F2B5708",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1631888",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1631888/candidates/new",
		shortlink: "https://apply.workable.com/j/2D1F2B5708",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: "58103",
			telecommuting: false
		},
		created_at: "2021-03-01T18:57:08Z"
	},
	{
		id: "18fc07",
		title: "Food Dudes Driver",
		full_title: "Food Dudes Driver - Brainerd",
		shortcode: "807F4BA203",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1636149",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1636149/candidates/new",
		shortlink: "https://apply.workable.com/j/807F4BA203",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: "56401",
			telecommuting: false
		},
		created_at: "2021-03-04T01:35:47Z"
	},
	{
		id: "1903c7",
		title: "Food Delivery Driver",
		full_title: "Food Delivery Driver - Brainerd",
		shortcode: "B46AB0B651",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1638133",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1638133/candidates/new",
		shortlink: "https://apply.workable.com/j/B46AB0B651",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: "56401",
			telecommuting: false
		},
		created_at: "2021-03-05T04:33:56Z"
	},
	{
		id: "1917dd",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - St. Cloud",
		shortcode: "223BB0EB23",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1643275",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1643275/candidates/new",
		shortlink: "https://apply.workable.com/j/223BB0EB23",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-09T19:35:51Z"
	},
	{
		id: "192720",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Willmar",
		shortcode: "23A947F221",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1647182",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1647182/candidates/new",
		shortlink: "https://apply.workable.com/j/23A947F221",
		location: {
			location_str: "Willmar, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Willmar",
			zip_code: "56201",
			telecommuting: false
		},
		created_at: "2021-03-11T22:24:06Z"
	},
	{
		id: "192722",
		title: "Food Delivery - On Demand Driver",
		full_title: "Food Delivery - On Demand Driver - St. Cloud",
		shortcode: "DA687070C7",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1647184",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1647184/candidates/new",
		shortlink: "https://apply.workable.com/j/DA687070C7",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-11T22:27:46Z"
	},
	{
		id: "19398f",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Sioux Falls",
		shortcode: "F8EC7BEFAA",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1651901",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1651901/candidates/new",
		shortlink: "https://apply.workable.com/j/F8EC7BEFAA",
		location: {
			location_str: "Sioux Falls, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Sioux Falls",
			zip_code: "57104",
			telecommuting: false
		},
		created_at: "2021-03-16T04:40:46Z"
	},
	{
		id: "193993",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Duluth",
		shortcode: "34F14AB962",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1651905",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1651905/candidates/new",
		shortlink: "https://apply.workable.com/j/34F14AB962",
		location: {
			location_str: "Duluth, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Duluth",
			zip_code: "55806",
			telecommuting: false
		},
		created_at: "2021-03-16T04:44:30Z"
	},
	{
		id: "19399b",
		title: "Delivery Driver",
		full_title: "Delivery Driver - St. Cloud",
		shortcode: "204476FAAD",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1651913",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1651913/candidates/new",
		shortlink: "https://apply.workable.com/j/204476FAAD",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-16T04:48:50Z"
	},
	{
		id: "1939a1",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Fargo",
		shortcode: "E225E5B3BF",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1651919",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1651919/candidates/new",
		shortlink: "https://apply.workable.com/j/E225E5B3BF",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: "58103",
			telecommuting: false
		},
		created_at: "2021-03-16T04:58:18Z"
	},
	{
		id: "193eae",
		title: "Food Courier - Delivery Driver",
		full_title: "Food Courier - Delivery Driver - Fargo",
		shortcode: "D20DB0CC44",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1653212",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1653212/candidates/new",
		shortlink: "https://apply.workable.com/j/D20DB0CC44",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-16T18:11:59Z"
	},
	{
		id: "193ebe",
		title: "Food Courier - Delivery Driver",
		full_title: "Food Courier - Delivery Driver - Brainerd",
		shortcode: "1E758060B3",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1653228",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1653228/candidates/new",
		shortlink: "https://apply.workable.com/j/1E758060B3",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-16T18:19:39Z"
	},
	{
		id: "193ff6",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Appleton",
		shortcode: "D487A5293E",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1653540",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1653540/candidates/new",
		shortlink: "https://apply.workable.com/j/D487A5293E",
		location: {
			location_str: "Appleton, Wisconsin, United States",
			country: "United States",
			country_code: "US",
			region: "Wisconsin",
			region_code: "WI",
			city: "Appleton",
			zip_code: "54911",
			telecommuting: false
		},
		created_at: "2021-03-16T21:52:09Z"
	},
	{
		id: "193ffc",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Green Bay",
		shortcode: "9B644B7F9B",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1653546",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1653546/candidates/new",
		shortlink: "https://apply.workable.com/j/9B644B7F9B",
		location: {
			location_str: "Green Bay, Wisconsin, United States",
			country: "United States",
			country_code: "US",
			region: "Wisconsin",
			region_code: "WI",
			city: "Green Bay",
			zip_code: "54303",
			telecommuting: false
		},
		created_at: "2021-03-16T21:57:35Z"
	},
	{
		id: "194299",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Bismarck",
		shortcode: "13129FE088",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1654215",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1654215/candidates/new",
		shortlink: "https://apply.workable.com/j/13129FE088",
		location: {
			location_str: "Bismarck, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Bismarck",
			zip_code: "58501",
			telecommuting: false
		},
		created_at: "2021-03-17T12:33:15Z"
	},
	{
		id: "1942a9",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Sioux Falls",
		shortcode: "58052A9565",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1654231",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1654231/candidates/new",
		shortlink: "https://apply.workable.com/j/58052A9565",
		location: {
			location_str: "Sioux Falls, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Sioux Falls",
			zip_code: "57107",
			telecommuting: false
		},
		created_at: "2021-03-17T12:46:17Z"
	},
	{
		id: "1946a2",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Alexandria",
		shortcode: "0D6A4F1D39",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1655248",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1655248/candidates/new",
		shortlink: "https://apply.workable.com/j/0D6A4F1D39",
		location: {
			location_str: "Alexandria, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Alexandria",
			zip_code: "56308",
			telecommuting: false
		},
		created_at: "2021-03-17T20:34:58Z"
	},
	{
		id: "1946b5",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Des Moines",
		shortcode: "9659846EEE",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1655267",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1655267/candidates/new",
		shortlink: "https://apply.workable.com/j/9659846EEE",
		location: {
			location_str: "Des Moines, Iowa, United States",
			country: "United States",
			country_code: "US",
			region: "Iowa",
			region_code: "IA",
			city: "Des Moines",
			zip_code: "50309",
			telecommuting: false
		},
		created_at: "2021-03-17T20:43:21Z"
	},
	{
		id: "1946ba",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Sioux City",
		shortcode: "F0787C46A1",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1655272",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1655272/candidates/new",
		shortlink: "https://apply.workable.com/j/F0787C46A1",
		location: {
			location_str: "Sioux City, Iowa, United States",
			country: "United States",
			country_code: "US",
			region: "Iowa",
			region_code: "IA",
			city: "Sioux City",
			zip_code: "51104",
			telecommuting: false
		},
		created_at: "2021-03-17T20:46:37Z"
	},
	{
		id: "19537e",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Brainerd",
		shortcode: "973BA08B76",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1658540",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1658540/candidates/new",
		shortlink: "https://apply.workable.com/j/973BA08B76",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: "56401",
			telecommuting: false
		},
		created_at: "2021-03-19T16:19:35Z"
	},
	{
		id: "196083",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Sioux Falls",
		shortcode: "E0EDC2A529",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1661873",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1661873/candidates/new",
		shortlink: "https://apply.workable.com/j/E0EDC2A529",
		location: {
			location_str: "Sioux Falls, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Sioux Falls",
			zip_code: "57105",
			telecommuting: false
		},
		created_at: "2021-03-23T04:21:36Z"
	},
	{
		id: "196088",
		title: "Food Dudes Courier",
		full_title: "Food Dudes Courier - Sioux Falls",
		shortcode: "F73E6580E4",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1661878",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1661878/candidates/new",
		shortlink: "https://apply.workable.com/j/F73E6580E4",
		location: {
			location_str: "Sioux Falls, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Sioux Falls",
			zip_code: "57105",
			telecommuting: false
		},
		created_at: "2021-03-23T04:32:07Z"
	},
	{
		id: "19608a",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Sioux Falls",
		shortcode: "47BF14AAF7",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1661880",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1661880/candidates/new",
		shortlink: "https://apply.workable.com/j/47BF14AAF7",
		location: {
			location_str: "Sioux Falls, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Sioux Falls",
			zip_code: "57104",
			telecommuting: false
		},
		created_at: "2021-03-23T04:36:51Z"
	},
	{
		id: "19608d",
		title: "Food Dudes Delivery Driver",
		full_title: "Food Dudes Delivery Driver - Alexandria",
		shortcode: "80FD6BE6C3",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1661883",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1661883/candidates/new",
		shortlink: "https://apply.workable.com/j/80FD6BE6C3",
		location: {
			location_str: "Alexandria, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Alexandria",
			zip_code: "56308",
			telecommuting: false
		},
		created_at: "2021-03-23T04:41:20Z"
	},
	{
		id: "196fd6",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Duluth",
		shortcode: "469D4FAF15",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1665796",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1665796/candidates/new",
		shortlink: "https://apply.workable.com/j/469D4FAF15",
		location: {
			location_str: "Duluth, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Duluth",
			zip_code: "55802",
			telecommuting: false
		},
		created_at: "2021-03-25T03:33:31Z"
	},
	{
		id: "196fd8",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Appleton",
		shortcode: "8319CBB2ED",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1665798",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1665798/candidates/new",
		shortlink: "https://apply.workable.com/j/8319CBB2ED",
		location: {
			location_str: "Appleton, Wisconsin, United States",
			country: "United States",
			country_code: "US",
			region: "Wisconsin",
			region_code: "WI",
			city: "Appleton",
			zip_code: "54911",
			telecommuting: false
		},
		created_at: "2021-03-25T03:36:26Z"
	},
	{
		id: "196fde",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - St. Cloud",
		shortcode: "8A5C7F051D",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1665804",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1665804/candidates/new",
		shortlink: "https://apply.workable.com/j/8A5C7F051D",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-25T03:40:31Z"
	},
	{
		id: "196fe5",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Fargo",
		shortcode: "E58B116B9E",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1665811",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1665811/candidates/new",
		shortlink: "https://apply.workable.com/j/E58B116B9E",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: "58103",
			telecommuting: false
		},
		created_at: "2021-03-25T03:44:13Z"
	},
	{
		id: "196fe7",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Mankato",
		shortcode: "EF501E7848",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1665813",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1665813/candidates/new",
		shortlink: "https://apply.workable.com/j/EF501E7848",
		location: {
			location_str: "Mankato, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Mankato",
			zip_code: "56001",
			telecommuting: false
		},
		created_at: "2021-03-25T03:46:50Z"
	},
	{
		id: "198226",
		title: "Food Delivery Driver",
		full_title: "Food Delivery Driver - St. Cloud",
		shortcode: "17825AFFC7",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1670484",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1670484/candidates/new",
		shortlink: "https://apply.workable.com/j/17825AFFC7",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-03-26T15:26:14Z"
	},
	{
		id: "198720",
		title: "Food Delivery Driver",
		full_title: "Food Delivery Driver - Spearfish",
		shortcode: "BF97A3F7E9",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1671758",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1671758/candidates/new",
		shortlink: "https://apply.workable.com/j/BF97A3F7E9",
		location: {
			location_str: "Spearfish, South Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "South Dakota",
			region_code: "SD",
			city: "Spearfish",
			zip_code: "57783",
			telecommuting: false
		},
		created_at: "2021-03-28T03:51:16Z"
	},
	{
		id: "199b04",
		title: "Food Courier",
		full_title: "Food Courier - Alexandria",
		shortcode: "C20D973BED",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676850",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676850/candidates/new",
		shortlink: "https://apply.workable.com/j/C20D973BED",
		location: {
			location_str: "Alexandria, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Alexandria",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-31T15:32:01Z"
	},
	{
		id: "199b0c",
		title: "Food Dudes Driver Partner",
		full_title: "Food Dudes Driver Partner - Alexandria",
		shortcode: "3B9E86E3F7",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676858",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676858/candidates/new",
		shortlink: "https://apply.workable.com/j/3B9E86E3F7",
		location: {
			location_str: "Alexandria, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Alexandria",
			zip_code: "56308",
			telecommuting: false
		},
		created_at: "2021-03-31T15:35:10Z"
	},
	{
		id: "199b17",
		title: "Food Dudes Driver",
		full_title: "Food Dudes Driver - Sioux City",
		shortcode: "2D73A17115",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676869",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676869/candidates/new",
		shortlink: "https://apply.workable.com/j/2D73A17115",
		location: {
			location_str: "Sioux City, Iowa, United States",
			country: "United States",
			country_code: "US",
			region: "Iowa",
			region_code: "IA",
			city: "Sioux City",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-31T15:39:19Z"
	},
	{
		id: "199b22",
		title: "Food Dudes Driver",
		full_title: "Food Dudes Driver - Appleton",
		shortcode: "43F63AB9C1",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676880",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676880/candidates/new",
		shortlink: "https://apply.workable.com/j/43F63AB9C1",
		location: {
			location_str: "Appleton, Wisconsin, United States",
			country: "United States",
			country_code: "US",
			region: "Wisconsin",
			region_code: "WI",
			city: "Appleton",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-31T15:42:00Z"
	},
	{
		id: "199b2b",
		title: "Food Dudes Delivery Driver",
		full_title: "Food Dudes Delivery Driver - Brainerd",
		shortcode: "45FE9AAD3C",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676889",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676889/candidates/new",
		shortlink: "https://apply.workable.com/j/45FE9AAD3C",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-31T15:44:36Z"
	},
	{
		id: "199b32",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Brainerd",
		shortcode: "87EB097772",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676896",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676896/candidates/new",
		shortlink: "https://apply.workable.com/j/87EB097772",
		location: {
			location_str: "Brainerd, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Brainerd",
			zip_code: "56401",
			telecommuting: false
		},
		created_at: "2021-03-31T15:47:27Z"
	},
	{
		id: "199b50",
		title: "Food Delivery Driver - Food Dudes Delivery",
		full_title: "Food Delivery Driver - Food Dudes Delivery - St. Cloud",
		shortcode: "6578868C60",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1676926",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1676926/candidates/new",
		shortlink: "https://apply.workable.com/j/6578868C60",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: null,
			telecommuting: false
		},
		created_at: "2021-03-31T16:08:13Z"
	},
	{
		id: "19ab79",
		title: "Delivery Driver",
		full_title: "Delivery Driver - St. Cloud",
		shortcode: "AB7AA44F88",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1681063",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1681063/candidates/new",
		shortlink: "https://apply.workable.com/j/AB7AA44F88",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-04-03T04:35:06Z"
	},
	{
		id: "19ab7a",
		title: "Driver Partner",
		full_title: "Driver Partner - St. Cloud",
		shortcode: "0553BFCD02",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1681064",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1681064/candidates/new",
		shortlink: "https://apply.workable.com/j/0553BFCD02",
		location: {
			location_str: "St. Cloud, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "St. Cloud",
			zip_code: "56301",
			telecommuting: false
		},
		created_at: "2021-04-03T04:38:51Z"
	},
	{
		id: "19ab7b",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Fargo",
		shortcode: "F5FCD82936",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1681065",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1681065/candidates/new",
		shortlink: "https://apply.workable.com/j/F5FCD82936",
		location: {
			location_str: "Fargo, North Dakota, United States",
			country: "United States",
			country_code: "US",
			region: "North Dakota",
			region_code: "ND",
			city: "Fargo",
			zip_code: "58103",
			telecommuting: false
		},
		created_at: "2021-04-03T04:41:48Z"
	},
	{
		id: "19ab7d",
		title: "Delivery Driver",
		full_title: "Delivery Driver - Alexandria",
		shortcode: "980DF99FB9",
		code: null,
		state: "archived",
		sample: false,
		department: null,
		department_hierarchy: [],
		url: "https://food-dudes-delivery.workable.com/jobs/1681067",
		application_url: "https://food-dudes-delivery.workable.com/jobs/1681067/candidates/new",
		shortlink: "https://apply.workable.com/j/980DF99FB9",
		location: {
			location_str: "Alexandria, Minnesota, United States",
			country: "United States",
			country_code: "US",
			region: "Minnesota",
			region_code: "MN",
			city: "Alexandria",
			zip_code: "56308",
			telecommuting: false
		},
		created_at: "2021-04-03T04:44:50Z"
	}
]
//   paging: {
//     next: "https://food-dudes-delivery.workable.com/spi/v3/jobs?limit=50&since_id=19ab7f"
//   }
// }