/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import WebCheckOutMobile from "./WebCheckOutMobile";
import WebCheckOutDesktop from "./WebCheckOutDeskTop";
import dropin from "braintree-web-drop-in";
import DateAndTime from "../../components/Header/DateAndTime";
import { useSelector, useDispatch } from "react-redux";
import {
  getResturantsData,
  selectDate,
  selectTime,
  setPlaceOrderCal,
  getPaymentSettings,
  getClientToken,
  setSelectedPaymentMethods,
  codApi,
  walletChargeApi,
  requestCoupan,
  braintreeChargeApi,
  setCoupan,
  saveAddress,
  getUsedGiftCards,
  selectedAddress,
} from "redux-store";

import { useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import DriverTip from "./DriverTip";
import PopUp from "./PopUp";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import EditAddress from "./EditAddress";
import AddFunds from "./AddFunds";
import AddFund from "components/AddFund";
import CongratsPopup from "components/CongratsPopup";

import restaurantImg from "assets/images/icon-resturant.svg";
import addressImg from "assets/images/icon-address.svg";
import { useWindowResize } from "../../components/useWindowResize";
import WebCheckOutGroupOrder from "./WebCheckoutGroupOrder";
import ItemDetail from "components/ItemDetail";

const publicIp = require("public-ip");

const WebCheckOutNormalCart = (props) => {
  const [addFundBraintree, setAddFundBrainTree] = useState(false);
  const [congratsModal, setCongratsModal] = useState(false);
  const isMobileOnly = useWindowResize();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const [nonce, setNonce] = useState(false);
  const [mapValue, setMapValue] = useState(null);
  const [mapProjection, setMapProjection] = useState(null);
  const [mapZoom, setMapZoom] = useState(1);

  const [driverTip, setDriverTip] = useState(store.cart.tips[1]);
  const [drverTipType, setDriverTipType] = useState(false);
  const [customTipAmount, setCustomTipAmount] = useState("");

  const [pySettingsData, setPySettingsData] = useState(null);
  const [loadPayment, setLoadPaymet] = useState(false)
  const [paymentMethodsObj, setpaymentMethodsObj] = useState({});

  const [radio, setRadio] = useState(
    store.tab.radioNotes ? store.tab.radioNotes : "Meet at Door"
  );

  const [modalIsOpen, setIsOpen] = React.useState({
    status: false,
    code: "",
  });
  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);
  const [cash, setCash] = useState(false);
  const [invoice, setInvoice] = useState(false);

  console.log(
    "state check of cash",
    cash,
    "!\n",
    "state check of invoice",
    invoice
  );
  const [localStatePayMethods, setLocalStatePayMethods] = useState({});
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  const [isRedirect, setIsRedirect] = useState({
    status: false,
    data: { isNavigated: true, orderId: "" },
  });

  const [reRenderBraintree, setReRenderBraintree] = useState(false);

  const [disabledServiceType, setDisabledServiceType] = useState(null);
  const [disabledAsapAdavance, setDisabledAsapAdavance] = useState(null);
  const [invalidDates, setInValidDates] = useState([]);

  useEffect(() => {
    const serviceType =
      store.rest.selectedResturantData[store.cart.cart.restaurant_id]
        .service_type;
    const asapAdvance =
      store.rest.selectedResturantData[store.cart.cart.restaurant_id]
        .allow_asap_advance_orders;
    const blockDates =
      store.rest.selectedResturantData[store.cart.cart.restaurant_id]
        .special_day_off_dates;
    if (blockDates) {
      setInValidDates(blockDates?.split(","));
    }

    switch (serviceType) {
      case 0:
        break;
      case 1:
        setDisabledServiceType("Pickup");
        break;
      case 2:
        setDisabledServiceType("Delivery");
        break;
      default:
        break;
    }
    switch (asapAdvance) {
      case 0:
        break;
      case 1:
        setDisabledAsapAdavance("SCHEDULE");
        break;
      case 2:
        setDisabledAsapAdavance("ASAP");
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (mapValue) {
      handleOnLoad(mapValue);
    }
  }, [store.auth.headerTab, store.profile.selectedAddress]);

  useEffect(() => {
    if (pySettingsData == null && loadPayment) {
      console.log("store.cart.taxdata call", store.cart.taxdata)
      setPySettingsData(store.cart.taxdata);
      
    }
  }, [store.cart.taxdata, loadPayment]);

  useEffect(() => {
    dispatch(getClientToken(setShowBraintreeDropIn));
  }, []);

  const isExists = (arr = [], key) => {
    return true;
    // console.log("isExists", arr);
    // let xy = arr.find((item) => item.id == key);
    // if (xy) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  useEffect(() => {
    if (showBraintreeDropIn && pySettingsData && loadPayment) {
      let obj = {
        authorization: store.cart.clientToken,
        vaultManager: true,
        container: "#braintree-drop-in-div",
        preselectVaultedPaymentMethod: false,
      };

      let pp = null;
      // if (
      //   isExists(pySettingsData.allowed_paymant_methods, 1) ||
      //   !store.auth.token
      // ) {
        obj["paypal"] = {
          flow: "vault",
          singleUse: true,
        };
      // }
      let gp = null;
      // if (
      //   isExists(pySettingsData.allowed_paymant_methods, 2) ||
      //   !store.auth.token
      // ) {
        obj["googlePay"] = {
          googlePayVersion: 2,
          merchantId: "merchant-id-from-google",
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: "16.50",
            currencyCode: "USD",
          },
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
                billingAddressRequired: true,
                billingAddressParameters: {
                  format: "FULL",
                },
              },
            },
          ],
        };
      // }
      let ap = null;
      // if (
      //   isExists(pySettingsData.allowed_paymant_methods, 4) ||
      //   !store.auth.token
      // ) {
        obj["applePay"] = {
          displayName: "Food Dudes",
          paymentRequest: {
            total: {
              label: "Food Dudes",
              amount: parseFloat(store.cart.placeOrderCharges.total).toFixed(2),
            },
          },
        };
      // }
      let ve = null;
      // if (
      //   isExists(pySettingsData.allowed_paymant_methods, 3) ||
      //   !store.auth.token
      // ) {
        obj["venmo"] = {
          allowNewBrowserTab: false,
        };
      // }

      // console.log("called in tests => ", pySettingsData, "showBraintreeDropIn", showBraintreeDropIn)
      console.log("called in tests => ", obj);

      const initializeBraintree = () =>
        dropin.create(obj, function (error, instance) {
          if (error) console.error(error);
          else {
            setBraintreeInstance(instance);
          }
        });
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }

   
  }, [showBraintreeDropIn, reRenderBraintree, pySettingsData, loadPayment]);

  useEffect(() => {
    if (braintreeInstance) {
      if (
        store.cart.placeOrderCharges.total <= 0 &&
        braintreeInstance.isPaymentMethodRequestable()
      ) {
        braintreeInstance.clearSelectedPaymentMethod();
        toast.error("No more payment options needed");
      }
    }
  }, [reRenderBraintree]);

  console.log("test placeorder charges", store.cart)

  const callBackEvent = (event) => {
    console.log("event.type", event)
        let y = document.getElementsByClassName("active-cash-card");
        if (y.length) {
          y[0].className = "cash-card";
          setCash(false);
        } else {
        }
        let y1 = document.getElementsByClassName("active-cash-card-invoice");
        if (y1.length) {
          y1[0].className = "cash-card-invoice";
          setInvoice(false);
        }
        //check if saved payment method is unchecked
        var classname = document.getElementsByClassName("braintree-method");
        var unselectBTMethod = function () {
          if (this.className.match(/\bbraintree-method--active\b/)) {
            braintreeInstance.clearSelectedPaymentMethod();
          }
        };
        for (var i = 0; i < classname.length; i++) {
          classname[i].addEventListener("click", unselectBTMethod, false);
        }

        let neIm = pySettingsData.allowed_paymant_methods.find(item=>item.id == 1)
        console.log("neIm", neIm)
        // console.log("test placeorder charges from event", store.cart)
        let test = document.getElementById("test-id")?.innerText
        console.log("test placeorder charges from event", test)
        if(test == "$0.00"){
          toast.error("No more payment options needed");
          braintreeInstance.clearSelectedPaymentMethod();
          return
        }
        if(!neIm && event.type == "PayPalAccount"){
          braintreeInstance.clearSelectedPaymentMethod();

          toast.error("Payment method PayPal is not available for this restaurant. Please choose a different method")
        }
  }

  useEffect(() => {
    if (braintreeInstance) {
      braintreeInstance.on("paymentMethodRequestable",callBackEvent);
    }
  }, [braintreeInstance]);

  useEffect(()=>{
    console.log("missingElem",
    "braintreeInstance",braintreeInstance,
    "pySettingsData",pySettingsData,
    "pySettingsData.allowed_paymant_methods",pySettingsData && pySettingsData.allowed_paymant_methods,
    "store.auth.token",store.auth.token,
    
    "loadPayment",loadPayment)
    if(braintreeInstance && pySettingsData && pySettingsData.allowed_paymant_methods && loadPayment){
      const paymentOptions = pySettingsData.allowed_paymant_methods.map(itm=>itm.id)
      const missingElem = [1,2,3,4].filter((it=>{
        // console.log("missingElem",it.id)
        return !paymentOptions.includes(it)
      }))
      
      hidePaymentsOptions(missingElem)
    }
  },[braintreeInstance, reRenderBraintree, pySettingsData, loadPayment])


  const hidePaymentsOptions = (disabledKeys=[]) => {
    disabledKeys.map((item,index)=>{
      switch(item){
        case 1:
          let p = document.getElementsByClassName("braintree-option__paypal")
          if(p.length){
				    p[0].className = "d-none";
			    }
          break;
        case 2:
          let gp = document.getElementsByClassName("braintree-option__googlePay")
			    if(gp.length){
				    gp[0].className = "d-none";
			    }
          break;
        case 3:
          let ve = document.getElementsByClassName("braintree-option__venmo")
			    if(ve.length){
				    ve[0].className = "d-none";
			    }
          break;
        case 4:
          let ap = document.getElementsByClassName("braintree-option__applePay")
			    if(ap.length){
				    ap[0].className = "d-none";
			    }
          break;
      }
    })
  }

  useEffect(() => {
    // setShowBraintreeDropIn(true);

    Object.keys(store.cart.giftCard).map((item, index) => {
      dispatch(
        requestCoupan(
          { code: store.cart.giftCard[item].giftcard_code },
          () => null,
          () => null,
          true
        )
      );
    });
    if (store.auth.token) {
      dispatch(getUsedGiftCards());
      if (
        !store.profile.selectedAddress.address_book_id &&
        store.profile.selectedAddress.entry_firstname
      ) {
        dispatch(
          saveAddress(
            {
              ...store.profile.selectedAddress,
              set_default:
                store.profile.selectedAddress.address_book_id ==
                store.profile.selectedAddress.customers_default_address_id
                  ? 1
                  : 0,
              address_book_id: store.profile.selectedAddress.address_book_id,
              entry_country_id: "1",
              entry_zone_id: "1",
              address_type: "1",
            },
            () => null,
            true,
            true
          )
        );
      }
    }
  }, []);
  function percentage(percent, total, type) {
    if (type == 1) {
      return +percent;
    }
    return +((percent / 100) * total).toFixed(2);
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });
 

  useEffect(() => {
    const {
      cartTotal,
      restaurant_delivery_fee,
      service_fee,
      tax,
      min_order,
      service_fee_type = 1,
    } = store.cart.cart;

   

    if (Object.keys(store.cart.taxdata).length > 0) {
      const {
        state_tax_rate,
        local_tax_rate,
        low_order_min_amount,
        low_order_fee,
        reward_points_conversion_value,
        maximum_limit_for_cod_orders,
      } = store.cart.taxdata;
      console.log();
      let discount = store.cart.coupan.coupon_amount
        ? Number(store.cart.coupan.coupon_amount)
        : 0;
      let newServiceFeeWithType = percentage(
        +service_fee,
        +cartTotal,
        +service_fee_type
      );

      let service_fee_new =  +formatter.format(newServiceFeeWithType); 

   

      if (pySettingsData && pySettingsData.allowed_phone_order) {
        let phOrder =
          (+cartTotal * pySettingsData.phone_order_service_fee_rate) / 100;
        service_fee_new = +formatter.format(service_fee_new + phOrder);
      }
      let groupDiscount = 0;
      if (pySettingsData && pySettingsData.invoice_discount) {
        let pyGroupD = (+cartTotal * pySettingsData.invoice_discount) / 100;
        groupDiscount = +formatter.format(pyGroupD);
      }

      const calc = +cartTotal + 
          (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 ) + ( +cartTotal > +low_order_min_amount ? 0 : +low_order_fee) + +service_fee_new - discount - groupDiscount
      
      

      let state_tax = (calc * state_tax_rate) / 100;
      let local_tax = (calc * local_tax_rate) / 100;
      let sales_tax = +formatter.format(state_tax + local_tax);
      if (sales_tax < 0) {
        sales_tax = 0.0;
      }
      if (store.auth.token && store.profile.customers_tax_exempt) {
        sales_tax = 0.0;
      }

      let tip = 0;

      if (drverTipType) {
        console.log("tip of driver 1", tip)
        if (store.auth.headerTab == "Delivery") {
          tip = +driverTip;
        }
      } else {
        console.log("tip of driver 2", tip)
        if (store.auth.headerTab == "Delivery") {
          let dtCalc =
            +cartTotal +
            +restaurant_delivery_fee +
            +service_fee_new +
            (+cartTotal > +low_order_min_amount
              ? 0
              : +low_order_fee) +
            +sales_tax;

          tip = (+driverTip * dtCalc) / 100;
        }
      }
      console.log("tip of driver 4 1", tip)
      let tipErr = +formatter.format(tip)
      if(!tipErr.toString()==='NaN'){
        tip=tipErr
      }
      console.log("tip of driver 4 2", tip)
      const totalAmount =
        cartTotal +
        service_fee_new +
        sales_tax +
        tip +
        (Number(cartTotal) > Number(low_order_min_amount)
          ? 0
          : Number(low_order_fee));
      let x = discount > totalAmount + (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 ) - groupDiscount ? (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 )  + totalAmount  - groupDiscount : discount;

      let xt =
        cartTotal +
        service_fee_new +
        sales_tax +
        tip +
        ((Number(cartTotal) > Number(low_order_min_amount))
          ? 0
          : Number(low_order_fee));

      
      
      let val = {
        sub_total: cartTotal,
        service_fee: service_fee_new,
        tax: sales_tax,
        discount: x,
        total: (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 ) + xt - (x + groupDiscount),
        groupDiscount: groupDiscount,
        low_order:
          Number(cartTotal) > Number(low_order_min_amount)
            ? 0
            : Number(low_order_fee),
        tip: tip,
      };

     
      console.log("tip of driver 4", val)
      let pymt = {};
      let plorderTot = val.total;

      if (Object.keys(localStatePayMethods).length) {
        Object.keys(localStatePayMethods).map((d) => {
          const data = localStatePayMethods[d];
          let deductAmount = data.giftcard_balance
            ? data.giftcard_balance
            : data.amount;
          if (plorderTot > 0) {
            let x = deductAmount >= plorderTot ? true : false;
            let y = 0;
            if (x) {
              y = plorderTot;
              plorderTot = 0;
            } else {
              y = deductAmount;
              plorderTot = plorderTot - deductAmount;
            }
            pymt = { ...pymt, [d]: { ...data, balance_used: y } };
          } else {
            toast.error("No more payment options needed");
          }
        });
      }
      if (
        braintreeInstance &&
        plorderTot <= 0 &&
        braintreeInstance.isPaymentMethodRequestable()
      ) {
        braintreeInstance.clearSelectedPaymentMethod();
      }

      dispatch(setSelectedPaymentMethods(pymt));
      dispatch(setPlaceOrderCal({ ...val, total: plorderTot }));
    } else {
    }
    

    
  }, [
    store.cart.cart,
    store.cart.coupan,
    store.auth.headerTab,
    store.cart.taxdata,
    driverTip,
    drverTipType,
    localStatePayMethods,
    pySettingsData
  ]);

  useEffect(() => {
    // applePay: {
    //   displayName: 'Food Dudes',
    //   paymentRequest: {
    //     total: {
    //     label: 'Food Dudes',
    //     amount: parseFloat(
    //               store.cart.placeOrderCharges.total
    //             ).toFixed(2)
    //     },
    //   }
    // }

    if (braintreeInstance) {
      braintreeInstance.updateConfiguration("applePay", "paymentRequest", {
        total: {
          label: "Food Dudes",
          amount: parseFloat(store.cart.placeOrderCharges.total).toFixed(2),
        },
      });
    }
  }, [store.cart.placeOrderCharges.total]);

  useEffect(() => {
    (async () => {
      const { restaurant_id, postcode } = store.cart.cart;
      const { customers_email_address } = store.profile;
      if (
        true ||
        store.auth.headerTab === "Pickup"
      ) {
        let ip = null;
        try {
          ip = await publicIp.v4();
        } catch (error) {
          ip = null;
        }
        dispatch(
          getPaymentSettings({
            restaurant_id: restaurant_id,
            postcode:
              store.auth.headerTab === "Pickup"
                ? postcode
                : store.profile.selectedAddress.entry_postcode,
            customers_email_address,
            ip: ip,
          },()=>setLoadPaymet(true))
        );
      } else {
        store.auth.headerTab === "Delivery" &&
          setIsOpen({ status: true, code: "Address" });
        if (
          location.pathname == "/webcheckout" ||
          location.pathname == "/webcheckout/"
        ) {
          store.auth.headerTab === "Delivery" &&
            toast.warn("Please add your full address");
        }
      }
    })();
  }, [store.profile.selectedAddress, store.cart.cart.restaurant_id,store.auth.headerTab]);

  // useEffect(() => {
  //   const { restaurant_id } = store.cart.cart;
  //   if (store.profile.selectedAddress.entry_postcode) {
  //     dispatch(
  //       getPaymentSettings({
  //         restaurant_id: restaurant_id,
  //         postcode: store.profile.selectedAddress.entry_postcode,
  //       })
  //     );
  //   } else {
  //     setIsOpen({ status: true, code: "Address" });
  //     if (
  //       location.pathname == "/webcheckout" ||
  //       location.pathname == "/webcheckout/"
  //     ) {
  //       toast.warn("Please add your full address");
  //     }
  //   }
  // }, [store.profile.selectedAddress, store.cart.cart.restaurant_id]);

  if (
    (store.cart.cart.items && store.cart.cart.items.length < 1) ||
    !store.cart.cart.items ||
    Object.keys(store.cart.disabledProducts).length ||
    store.rest.selectedResturantData[store.cart.cart.restaurant_id]
      ?.rest_status != 1
  ) {
    return <Redirect to="/" />;
  }

  const { address_lat, address_lng } = store.profile.selectedAddress || {};
  const { lat, lng } = store.cart.cart.latlng || {};
  let cities = [];
  if (store.auth.headerTab == "Delivery") {
    cities = [
      {
        img: restaurantImg,
        id: 1,
        name: "rest",
        position: { lat: Number(lat), lng: Number(lng) },
      },
      {
        img: addressImg,
        id: 2,
        name: "addr",
        position: {
          lat: Number(address_lat),
          lng: Number(address_lng),
        },
      },
    ];
  } else {
    cities = [
      {
        img: restaurantImg,
        id: 1,
        name: "rest",
        position: { lat: Number(lat), lng: Number(lng) },
      },
    ];
  }

  const handleOnLoad = (map) => {
    setMapValue(map);
    const bounds = new window.google.maps.LatLngBounds();
    cities.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    window.google.maps.event.addListenerOnce(
      map,
      "projection_changed",
      function () {
        setMapProjection(map.getProjection());
        // document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
      }
    );
    window.google.maps.event.addListener(map, "zoom_changed", function () {
      setMapZoom(map.getZoom());
      // map.setZoom(16)
      // setMapProjection(map.getProjection())
      // document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
    });
  };
  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths:
      store.auth.headerTab == "Delivery"
        ? [cities[0].position, cities[1].position]
        : [cities[0].position, cities[0].position],
    zIndex: 1,
  };

  const deliverNow = () => {
    dispatch(selectDate(""));
    dispatch(selectTime(""));
    dispatch(
      getResturantsData(
        {
          lat_from: address_lat.toString(),
          lng_from: address_lng.toString(),
        },
        () => null
      )
    );
  };

  const renderUi = () => {
    switch (modalIsOpen.code) {
      case "datentime":
        return (
          <DateAndTime
            invalidDates={invalidDates ? invalidDates : [""]}
            disabledAsapAdavance={disabledAsapAdavance}
            handleDateToggle={() => setIsOpen({ status: false, type: "" })}
            ids=""
            cname=""
          />
        );
      case "tip":
        return (
          <div className="payment__page__wrapper">
            <DriverTip
              setIsOpen={setIsOpen}
              ids="driverTipModal"
              cname="modal fade deliveryAddressModal"
              drverTipType={drverTipType}
              setDriverTipType={setDriverTipType}
              setDriverTip={setDriverTip}
              customTipAmount={customTipAmount}
              setCustomTipAmount={setCustomTipAmount}
              store={store}
              cartStore={store.cart}
            />
          </div>
        );
      case "Address":
        return (
          <EditAddress
            setIsOpen={setIsOpen}
            store={store}
            cartStore={store.cart}
            dispatch={dispatch}
            ids="deliveryAddressModal"
            cname="modal fade deliveryAddressModal"
          />
        );
      case "funds":
        return (
          <AddFunds
            setIsOpen={setIsOpen}
            store={store}
            dispatch={dispatch}
            ids="addFundModal"
            cname="addFundModalLabel"
          />
        );
      default:
        return;
    }
  };

  const handleSelection = (data, key, type) => {
    let y = document.getElementsByClassName("active-cash-card");

    if (y.length) {
      y[0].className = "cash-card";
      setCash(false);
    }
    let y1 = document.getElementsByClassName("active-cash-card-invoice");
    if (y1.length) {
      y1[0].className = "cash-card-invoice";
      setInvoice(false);
    }

    let selectedPay = { ...store.tab.selectedPaymentMethods };
    if (+data.amount > 0 || +data.giftcard_balance > 0) {
      if (
        store.tab.selectedPaymentMethods[
          type == "gc" ? "GC_".concat(key) : "GV_".concat(key)
        ]
      ) {
        delete selectedPay[
          type == "gc" ? "GC_".concat(key) : "GV_".concat(key)
        ];
      } else {
        selectedPay = {
          ...selectedPay,
          [type == "gc" ? "GC_".concat(key) : "GV_".concat(key)]: data,
        };
      }
      setLocalStatePayMethods({ ...selectedPay });
    }
  };

  const onSuccess = (response) => {
    setAddFundBrainTree(false);
    setCongratsModal(response.data.message);
    setReRenderBraintree(Math.random());
  };

  const handlePlaceOrder = () => {
    if (store.profile.selectedAddress.address_book_id) {
      let addMatch = store.profile.allAddress.find(
        (add) =>
          store.profile.selectedAddress.address_book_id == add.address_book_id
      );
      if (!addMatch) {
        const copy = { ...store.profile.selectedAddress };
        delete copy["address_book_id"];
        delete copy["customers_id"];
        delete copy["customers_default_address_id"];
        dispatch(selectedAddress(copy));
        setIsOpen({ status: true, code: "Address" });
        return;
      }
    }
    const checkAddFlag = !store.auth.token
      ? store.profile.selectedAddress.entry_postcode
      : store.profile.selectedAddress.address_book_id;
    
    if (checkAddFlag || store.auth.headerTab === "Pickup") {
      if ((store.auth.headerTab == "Delivery" ?store.cart.cart.minimum_deliver_order:store.cart.cart.minimum_pickup_order ) > store.cart.cart.cartTotal) {
        toast.error(
          `Sorry! minimum order value is $${(store.auth.headerTab == "Delivery" ?store.cart.cart.minimum_deliver_order:store.cart.cart.minimum_pickup_order )}`
        );
      } else {
        dispatch({ type: "ROUTEPLACE", payload: true });
        if (!store.auth.token) {
          history.push("/signin");
          // setRedirectLogin(true);
        } else {
          const copy = [...store.cart.cart.items];

          const cart_items = copy.map(
            ({ optionValuesNew: attributes, ...rest }) => ({
              attributes,
              ...rest,
            })
          );
          let data = {
            address_id: store.profile.selectedAddress.address_book_id,
            pickup_order: store.auth.headerTab == "Delivery" ? "0" : "1",
            tip: driverTip,
            tip_in_percentage: drverTipType ? "0" : "1",
            coupon_code: store.cart.coupan.coupon_code,
            // giftcards:
            // selected_date
            cart_items: cart_items,
            restaurant_id: store.cart.cart.restaurant_id,
            // nonce
            order_note: radio,
            special_instruction: store.cart.instruction,
            order_type: "1",
          };

          if (store.profile.deliveryTime) {
            data = {
              ...data,
              selected_date: moment(
                `${store.profile.deliveryDate} ${store.profile.deliveryTime}`
              ).format("YYYY-MM-DD HH:mm"),
            };
          }
          // "2021-10-28 22:00:00"
          if (cash || invoice) {
           
            dispatch(
              codApi(
                data,
                ({ orders_id, order_type_asap }) => {
                  // dispatch(setCartItems({}))
                  dispatch(setCoupan({}));
                  setIsRedirect({
                    status: true,
                    data: { isNavigated: true, orderId: orders_id },
                  });
                  // history.push({
                  // 	pathname: `/orderdetails`,
                  // 	data:{isNavigated:true, orderId:id},

                  // });
                },
                cash ? 1 : 0
              )
            );
          } else if (nonce) {
            // dispatch(braintreeChargeApi({}))
            if (braintreeInstance) {
              if (braintreeInstance.isPaymentMethodRequestable()) {
                braintreeInstance.requestPaymentMethod((error, payload) => {
                  if (error) {
                    console.error(error);
                  } else {
                    const paymentMethodNonce = payload.nonce;

                    // TODO: use the paymentMethodNonce to
                    //  call you server and complete the payment here

                    // ...
                    // onPaymentCompleted();
                  }
                });
              } else {
                toast.error("Please choose a payment method ");
              }
            }
          } else {
            if (Object.keys(store.tab.selectedPaymentMethods).length) {
              if (store.cart.placeOrderCharges.total == 0) {
                data = {
                  ...data,
                  giftcards: store.tab.selectedPaymentMethods,
                  wallet_selected: 1,
                };
                dispatch(
                  walletChargeApi(
                    data,
                    ({ orders_id, order_type_asap }) => {
                      dispatch(setCoupan({}));

                      setIsRedirect({
                        status: true,
                        data: {
                          isNavigated: true,
                          orderId: orders_id,
                        },
                      });
                    },
                    () => setNonce(false)
                  )
                );
              } else {
                if (braintreeInstance) {
                  if (braintreeInstance.isPaymentMethodRequestable()) {
                    braintreeInstance.requestPaymentMethod((error, payload) => {
                      if (error) {
                        console.error(error);
                      } else {
                        const paymentMethodNonce = payload.nonce;
                        if (
                          Object.keys(store.tab.selectedPaymentMethods).length
                        ) {
                          data = {
                            ...data,
                            giftcards: store.tab.selectedPaymentMethods,
                            wallet_selected: 1,
                            payment_method_nonce: paymentMethodNonce,
                          };
                        } else {
                          data = {
                            ...data,
                            wallet_selected: 1,
                            payment_method_nonce: paymentMethodNonce,
                          };
                        }

                        dispatch(
                          braintreeChargeApi(
                            data,
                            (id) => {
                              dispatch(setCoupan({}));
                              setIsRedirect({
                                status: true,
                                data: {
                                  isNavigated: true,
                                  orderId: id,
                                },
                              });
                            },
                            () => setNonce(false)
                          )
                        );
                      }
                    });
                  } else {
                    toast.error("Please choose more payment methods ");
                  }
                }
              }
            } else {
              if (braintreeInstance) {
                if (braintreeInstance.isPaymentMethodRequestable()) {
                  if (store.cart.placeOrderCharges.total > 0) {
                    braintreeInstance.requestPaymentMethod((error, payload) => {
                      if (error) {
                        console.error(error);
                      } else {
                        const paymentMethodNonce = payload.nonce;
                        // TODO: use the paymentMethodNonce to
                        //  call you server and complete the payment here
                        // ...
                        data = {
                          ...data,
                          payment_method_nonce: paymentMethodNonce,
                        };

                        dispatch(
                          braintreeChargeApi(data, (id) => {
                            // dispatch(setCartItems({}))
                            dispatch(setCoupan({}));
                            setIsRedirect({
                              status: true,
                              data: {
                                isNavigated: true,
                                orderId: id,
                              },
                            });
                            // history.push({
                            // 	pathname: `/orderdetails`,
                            // 	data:{isNavigated:true, orderId:id}
                            // });
                          })
                        );
                        // alert(`Payment completed with nonce=${paymentMethodNonce}`);

                        // onPaymentCompleted();
                      }
                    });
                  } else {
                    toast.error("Order value must be greater than 0");
                  }
                } else {
                  if (store.cart.placeOrderCharges.total <= 0) {
                    data = {
                      ...data,
                      giftcards: store.tab.selectedPaymentMethods,
                      wallet_selected: 1,
                    };
                    dispatch(
                      walletChargeApi(
                        data,
                        ({ orders_id, order_type_asap }) => {
                          dispatch(setCoupan({}));

                          setIsRedirect({
                            status: true,
                            data: {
                              isNavigated: true,
                              orderId: orders_id,
                            },
                          });
                        },
                        () => setNonce(false)
                      )
                    );
                  } else {
                    toast.error("Please choose a payment method ");
                  }
                }
              } else {
                toast.error("Please choose a payment method ");
              }
            }
          }
        }
      }
    } else {
      setIsOpen({ status: true, code: "Address" });
      toast.warn("Please add the full address");
    }
  };

  if (isMobileOnly) {
    return (
      <>
        {store.tab.modalIsOpen.status ? <ItemDetail /> : null}
        {isRedirect.status && (
          <Redirect
            to={{
              pathname: `/orderdetails/${isRedirect.data.orderId}`,
              data: isRedirect.data,
            }}
          />
        )}
        <CongratsPopup
          show={congratsModal}
          onHide={() => setCongratsModal(false)}
        />

        <WebCheckOutMobile
          cities={cities}
          handleOnLoad={handleOnLoad}
          dispatch={dispatch}
          polylineOptions={options}
          store={store}
          cartStore={store.cart}
          setRadio={setRadio}
          radio={radio}
          showBraintreeDropIn={showBraintreeDropIn}
          setShowBraintreeDropIn={setShowBraintreeDropIn}
          driverTip={driverTip}
          setDriverTip={setDriverTip}
          setIsOpen={setIsOpen}
          deliverNow={deliverNow}
          handlePlaceOrder={handlePlaceOrder}
          drverTipType={drverTipType}
          handleSelection={handleSelection}
          braintreeInstance={braintreeInstance}
          setLocalStatePayMethods={setLocalStatePayMethods}
          setCash={setCash}
          cash={cash}
          mapProjection={mapProjection}
          mapZoom={mapZoom}
          mapValue={mapValue}
          disabledServiceType={disabledServiceType}
          disabledAsapAdavance={disabledAsapAdavance}
          setAddFundBrainTree={setAddFundBrainTree}
          pySettingsData={pySettingsData}
          setInvoice={setInvoice}
        />
        {addFundBraintree ? (
          <AddFund
            onSuccess={onSuccess}
            show={addFundBraintree}
            fullscreen={true}
            onHide={() => setAddFundBrainTree(false)}
          />
        ) : null}
        <Modal
          className="modal fade"
          id="congrats-modal"
          show={modalIsOpen.status}
          modalIsOpen={modalIsOpen}
          onHide={() => {
            setIsOpen({ status: false, type: "" });
          }}
          // size="lg"
          aria-labelledby="congrats-modalLabel"
          centered
        >
          {modalIsOpen.code == "Address" ? (
            <div
              className="deliveryAddressModal"
              style={{ padding: "16px", background: "white" }}
            >
              <EditAddress
                show={modalIsOpen.status}
                modalIsOpen={modalIsOpen}
                onHide={() => {
                  setIsOpen({ status: false, type: "" });
                }}
                setIsOpen={setIsOpen}
                store={store}
                cartStore={store.cart}
                dispatch={dispatch}
                ids="deliveryAddressModal"
                cname="modal fade deliveryAddressModal"
              />
            </div>
          ) : (
            <>
              {modalIsOpen.code == "tip" ? (
                <div className="payment__page__wrapper">
                  <DriverTip
                    setIsOpen={setIsOpen}
                    ids="driverTipModal"
                    cname="modal fade deliveryAddressModal"
                    drverTipType={drverTipType}
                    setDriverTipType={setDriverTipType}
                    setDriverTip={setDriverTip}
                    customTipAmount={customTipAmount}
                    setCustomTipAmount={setCustomTipAmount}
                    store={store}
                    cartStore={store.cart}
                  />
                </div>
              ) : (
                <AddFunds
                  setIsOpen={setIsOpen}
                  store={store}
                  dispatch={dispatch}
                  ids="addFundModal"
                  cname="addFundModalLabel"
                />
              )}
            </>
          )}
        </Modal>
      </>
    );
  }

  return (
    <>
      {store.tab.modalIsOpen.status ? <ItemDetail /> : null}

      {isRedirect.status && (
        <Redirect
          to={{
            pathname: `/orderdetails/${isRedirect.data.orderId}`,
            data: isRedirect.data,
          }}
        />
      )}
      <CongratsPopup
        show={congratsModal}
        onHide={() => setCongratsModal(false)}
      />
      <WebCheckOutDesktop
        cities={cities}
        cartStore={store.cart}
        handleOnLoad={handleOnLoad}
        dispatch={dispatch}
        polylineOptions={options}
        store={store}
        setRadio={setRadio}
        radio={radio}
        setIsOpen={setIsOpen}
        deliverNow={deliverNow}
        showBraintreeDropIn={showBraintreeDropIn}
        setShowBraintreeDropIn={setShowBraintreeDropIn}
        driverTip={driverTip}
        setDriverTip={setDriverTip}
        handlePlaceOrder={handlePlaceOrder}
        drverTipType={drverTipType}
        handleSelection={handleSelection}
        setLocalStatePayMethods={setLocalStatePayMethods}
        setCash={setCash}
        cash={cash}
        braintreeInstance={braintreeInstance}
        mapProjection={mapProjection}
        mapZoom={mapZoom}
        mapValue={mapValue}
        disabledServiceType={disabledServiceType}
        disabledAsapAdavance={disabledAsapAdavance}
        setAddFundBrainTree={setAddFundBrainTree}
        pySettingsData={pySettingsData}
        setInvoice={setInvoice}
      />
      {/* <Modal
        isOpen={modalIsOpen.status}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {renderUi()}

      </Modal> */}
      <PopUp
        show={modalIsOpen.status}
        modalIsOpen={modalIsOpen}
        onHide={() => {
          setIsOpen({ status: false, type: "" });
        }}
      >
        {renderUi()}
      </PopUp>
      {addFundBraintree ? (
        <AddFund
          onSuccess={onSuccess}
          show={addFundBraintree}
          fullscreen={false}
          onHide={() => setAddFundBrainTree(false)}
          setCongratsModal={setCongratsModal}
          // modalIsOpen={modalIsOpen}
          // onHide={() => {
          //   setIsOpen({ status: false, type: "" });
          // }}
        />
      ) : null}
    </>
  );
};

const WebCheckOut = (props) => {
  const { groupToken } = useParams();
  if (groupToken) {
    return <WebCheckOutGroupOrder {...props} />;
  } else {
    return <WebCheckOutNormalCart {...props} />;
  }
};

export default WebCheckOut;
