import React, { useState } from "react";
import GroupOrderForm from "./GroupOrderForm";
import Modal from "react-bootstrap/Modal";
import ImportContact from "./ImportContact";
import { useWindowResize } from "components/useWindowResize";
import MobileView from "./mobile-view";

const GroupOrderModal = ({ show, onClose, isReorder }) => {
  const [openContact, setOpenContact] = useState(false);
  const [members, setMembers] = useState([]);
  const isMobileOnly = useWindowResize();

  const openContactsHandler = (val) => {
    setOpenContact(val);
  };

  const getImportedMembers = (data) => {
    let dt = data?.map((it) => ({ ...it, id: 0 }));
    setMembers(dt);
  };

  const closeModal = () => {
    if (openContact) {
      setOpenContact(false);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        id="add-balance"
        className="deliveryAddressModal modal-min-width"
        show={show}
        onHide={closeModal}
        keyboard={false}
        fullscreen={isMobileOnly}
        centered
        dialogClassName="modal-min-width"
      >
        {isMobileOnly ? (
          <MobileView isReorder={isReorder} onClose={closeModal} />
        ) : (
          <>
            <Modal.Header className="page_main_heading">
              <h1 className="modal-title" id="driverTipModalLabel">
                {isReorder
                  ? "Reorder Group Order"
                  : openContact
                  ? "Import Contacts"
                  : "Group Order"}
              </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              {openContact ? (
                <ImportContact
                  toggleContacts={(val) => openContactsHandler(val)}
                  getMembersList={getImportedMembers}
                />
              ) : (
                <GroupOrderForm
                  members={members}
                  toggleContacts={(val) => openContactsHandler(val)}
                  onComplete={onClose}
                  isReorder={isReorder}
                />
              )}
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default GroupOrderModal;
