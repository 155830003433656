import React, { useState, useEffect } from "react";
import {
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
	Polyline,
	Polygon,
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import markerImg from "assets/images/marker.svg";
import restaurantImg from "assets/images/icon-resturant.svg";
import addressImg from "assets/images/icon-address.svg";

const defaultFunction = () => null;

const Map = (props) => {
	// Load the Google maps scripts
	const { isLoaded, loadError } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyCmrodTAFNeH1cWfFPJLxiRlRUsCf7f3XE",
		version: "3.47",
	});
	const {
		cities = [],
		activeMarker = "",
		handleOnLoad = defaultFunction,
		setActiveMarker = defaultFunction,
		handleActiveMarker = defaultFunction,
		dispatch = defaultFunction,
		selectedAddress = defaultFunction,
		polylineOptions = {},
		height,
	} = props;
	const [mapZoom, setMapZoom] = useState(props.mapZoom ? props.mapZoom : 10);
	const store = useSelector((store) => store);
	useEffect(() => {
		if (props.mapZoom) {
			if (store.auth.headerTab == "Pickup") {
		
				setMapZoom(16);
			} else {
			
				setMapZoom(props.mapZoom);
			}
		}
	}, [props.mapZoom]);

	const onUnmountHandler = (e) => {};
	console.log("props.mapProjection",props.mapProjection , "polylineOptions" && props.polylineOptions , "mapZoom", props.mapZoom  )
	if (!props.isAddress) {
		return (
			<>
				{isLoaded && cities && cities.length ? (
					<GoogleMap
						options={{
							draggable: false,
							disableDoubleClickZoom: true,
							zoomControl: false,
							mapTypeControl: false,
							scaleControl: false,
							streetViewControl: false,
							panControl: false,
							rotateControl: false,
							fullscreenControl: false,
						}}
						defaultOptions={{
							fullscreenControl: false,
						}}
						onLoad={handleOnLoad}
						onClick={() => setActiveMarker(null)}
						mapContainerStyle={{ width: "100%", height: height || "300px" }}
						zoom={mapZoom}
						// center={props.mapCenter}
					>
						{cities.map(({ id, name, position, img }) => (
							<Marker
								key={id}
								position={position}
								onClick={() => handleActiveMarker(id)}
								icon={img ? img : markerImg}
								onMouseOver={() => handleActiveMarker(id)}
								// onMouseOut={() => handleActiveMarker(null)}
								// onMouseOver={event => setMapZoom(6)}
								// onMouseOut={event => setMapZoom(3)}
							>
								{activeMarker === id ? (
									<InfoWindow
										position={position}
										onCloseClick={() => setActiveMarker(null)}
									>
										<div
											className="markar__tooltip"
											style={{ cursor: "pointer" }}
											onClick={() =>
												dispatch(
													selectedAddress({
														address_lat: position.lat,
														address_lng: position.lng,
														entry_street_address: name,
													}),
												)
											}
										>
											{name}
										</div>
									</InfoWindow>
								) : null}
							</Marker>
						))}
						{props.mapProjection && props.polylineOptions && props.mapZoom ? (
							<CurveMarker
								pos1={props.cities[0].position}
								pos2={props.cities[1].position}
								mapProjection={props.mapProjection}
								zoom={mapZoom}
								// zoom={14}
							/>
						) : null}
					</GoogleMap>
				) : null}
			</>
		);
	}
};

export default Map;

var curvature = 0.3;
// const CurveMarker = ({ pos1, pos2, mapProjection, zoom }) => {
//   if (!mapProjection) return <div />;
//   var curvature = 0.2;

//   const p1 = mapProjection.fromLatLngToPoint(pos1),
//     p2 = mapProjection.fromLatLngToPoint(pos2);

//   // Calculating the arc.
//   const e = new window.google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
//     m = new window.google.maps.Point(e.x / 2, e.y / 2), // midpoint
//     o = new window.google.maps.Point(e.y, -e.x), // orthogonal
//     c = new window.google.maps.Point(
//       m.x + curvature * o.x,
//       m.y + curvature * o.y
//     ); //curve control point

//   // const pathDef = "M 0,0 " + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;
//   const pathDef = "M 0,0" + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;
//   const scale = 1 / Math.pow(2, -zoom);

//   const symbol = {
//     path: pathDef,
//     scale: scale,
//     strokeWeight: 2,
//     strokeColor: "#000000",
//     fillColor: "red",
//     repeat: "20px",
//     strokeOpacity: 1,
//     geodesic: true,
//   };
//   return <Marker position={pos1} clickable={false} icon={symbol} zIndex={1} />;
// };
const CurveMarker = ({
	pos1,
	pos2,
	mapProjection,
	zoom,
	mapValue,
	isSingleIcon,
}) => {

	if (!mapProjection) return <div />;
	var pos1 = new window.google.maps.LatLng(pos1.lat, pos1.lng);
	var pos2 = new window.google.maps.LatLng(pos2.lat - 0.0001, pos2.lng);

	// var pos1 = new LatLng(23.634501, -102.552783);
	// var pos2 = new LatLng(17.987557, -92.929147);
	var markerP1 = new window.google.maps.Marker({
		position: pos1,
		draggable: false,
		map: mapValue,
		icon: restaurantImg,
	});
	var markerP2 = new window.google.maps.Marker({
		position: pos2,
		draggable: false,
		map: mapValue,
		icon: addressImg,
	});

	if (!mapProjection) return <div />;
	var pos1 = markerP1.getPosition(), // latlng
		pos2 = markerP2.getPosition();
	var p1 = mapProjection.fromLatLngToPoint(pos1),
		p2 = mapProjection.fromLatLngToPoint(pos2);

	// Calculating the arc.
	const e = new window.google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
		m = new window.google.maps.Point(e.x / 2, e.y / 2), // midpoint
		o = new window.google.maps.Point(e.y, -e.x), // orthogonal
		c = new window.google.maps.Point(
			m.x + curvature * o.x,
			m.y + curvature * o.y,
		); //curve control point

	// const pathDef = "M 0,0 " + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;
	const pathDef = "M 0,0" + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;
	const scale = 1 / Math.pow(2, -zoom);

	const symbol = {
		path: pathDef,
		scale: scale,
		strokeWeight: 2,
		strokeColor: "#000000",
		fillColor: "red",
		repeat: "20px",
		strokeOpacity: 1,
		geodesic: true,
	};

	var lineLength = window.google.maps.geometry.spherical.computeDistanceBetween(
		markerP1.getPosition(),
		markerP2.getPosition(),
	);
	var lineHeading = window.google.maps.geometry.spherical.computeHeading(
		markerP1.getPosition(),
		markerP2.getPosition(),
	);

	var markerA = new window.google.maps.Marker({
		position: window.google.maps.geometry.spherical.computeOffset(
			markerP1.getPosition(),
			lineLength / 2,
			lineHeading - 38,
		),
		map: mapValue,

		icon: {
			url: "ss",
			size: new window.google.maps.Size(7, 7),
			anchor: new window.google.maps.Point(3.5, 3.5),
		},
	});
	var markerB = new window.google.maps.Marker({
		position: window.google.maps.geometry.spherical.computeOffset(
			markerP2.getPosition(),
			lineLength / 12,
			-lineHeading + 76,
		),
		icon: {
			url: "ss",
			size: new window.google.maps.Size(7, 7),
			anchor: new window.google.maps.Point(3.5, 3.5),
		},
		map: mapValue,
	});

	// const symbol = {
	//   path: pathDef,
	//   scale: scale,
	//   strokeWeight: 2,
	//   strokeColor: "#000000",
	//   fillColor: "red",
	//   repeat: "20px",
	//   strokeOpacity: 1,
	//   geodesic: true,
	// };

	// var curvedLine = new GmapsCubicBezier(pos1, pos2, pos1, pos2, 0.01, mapValue);

	var curvedLine = new GmapsCubicBezier(
		markerP1.getPosition(),
		markerA.getPosition(),
		markerB.getPosition(),
		markerP2.getPosition(),
		0.01,
		mapValue,
		isSingleIcon,
	);
	return curvedLine;
	return <Marker position={pos1} clickable={false} icon={symbol} zIndex={1} />;
};

var GmapsCubicBezier = function (
	latlong1,
	latlong2,
	latlong3,
	latlong4,
	resolution,
	map,
	isSingleIcon,
) {
	var lat1 = latlong1.lat();
	var long1 = latlong1.lng();
	var lat2 = latlong2.lat();
	var long2 = latlong2.lng();
	var lat3 = latlong3.lat();
	var long3 = latlong3.lng();
	var lat4 = latlong4.lat();
	var long4 = latlong4.lng();

	var points = [];

	for (let it = 0; it <= 1; it += resolution) {
		points.push(
			this.getBezier(
				{
					x: lat1,
					y: long1,
				},
				{
					x: lat2,
					y: long2,
				},
				{
					x: lat3,
					y: long3,
				},
				{
					x: lat4,
					y: long4,
				},
				it,
			),
		);
	}
	var path = [];
	for (var i = 0; i < points.length - 1; i++) {
		path.push(new window.google.maps.LatLng(points[i].x, points[i].y));
		path.push(
			new window.google.maps.LatLng(points[i + 1].x, points[i + 1].y, false),
		);
	}
	const lineSymbol = {
		path: "M 0,-1 0,1",
		strokeOpacity: 1,
		scale: 4,
	};

	return (
		<Polyline
			path={path}
			options={{
				fillColor: "lightblue",
				fillOpacity: 1,
				// strokeColor: "red",
				strokeOpacity: 0,
				strokeWeight: 0,
				clickable: false,
				draggable: false,
				editable: false,
				geodesic: false,
				zIndex: 1,
				icons: [
					{
						icon: lineSymbol,
						offset: "1000",
						repeat: "20px",
					},
				],
			}}
			strokeOpacity={0}
		/>
	);
	// return <Polyline
	// path= {path}
	//     geodesic= {true}
	//     strokeOpacity= {0.0}
	//                 icons= {[{
	//                     icon: {
	//                         path: 'M 0,-1 0,1',
	//                         strokeOpacity: 0.5,
	//                         strokeWeight: 2,
	//                         scale: 4
	//                     },
	//                     offset: '0',
	//                     repeat: '20px'
	//                 }]}
	//      strokeColor= '#000'
	// />
	// var Line = new window.google.maps.Polyline({
	//     path: path,
	//     geodesic: true,
	//     strokeOpacity: 0.0,
	//                 icons: [{
	//                     icon: {
	//                         path: 'M 0,-1 0,1',
	//                         strokeOpacity: 0.5,
	//                         strokeWeight: 2,
	//                         scale: 4
	//                     },
	//                     offset: '0',
	//                     repeat: '20px'
	//                 }],
	//      strokeColor: '#000',
	//  });
	// Line.setMap(map);
};

GmapsCubicBezier.prototype = {
	B1: function (t) {
		return t * t * t;
	},
	B2: function (t) {
		return 3 * t * t * (1 - t);
	},
	B3: function (t) {
		return 3 * t * (1 - t) * (1 - t);
	},
	B4: function (t) {
		return (1 - t) * (1 - t) * (1 - t);
	},
	getBezier: function (C1, C2, C3, C4, percent) {
		var pos = {};
		pos.x =
			C1.x * this.B1(percent) +
			C2.x * this.B2(percent) +
			C3.x * this.B3(percent) +
			C4.x * this.B4(percent);
		pos.y =
			C1.y * this.B1(percent) +
			C2.y * this.B2(percent) +
			C3.y * this.B3(percent) +
			C4.y * this.B4(percent);
		return pos;
	},
};
