import {
  GET_RESTURANT_DATA,
  GET_CUISINES_DATA,
  GET_RESTURANT_MENU,
  SET_SELECTED_RESTURANT,
  SET_UNSELECTED_RESTURANT,
  RESTURANT_ID,
  SELECTED_MENU_ITEM,
  GET_ITEM_ADDON,
  REST_TIME_SELECTION,
  SET_GROUP_ORDER,
  SET_GROUP_ORDER_INFO,
  SET_SELECTED_GROUP_ORDER_ITEM,
} from "./restType";

const initialState = {
  resturants: [],
  cuisines: [],
  menus: {},
  selectedResturantData: {},
  unSelectedResturantData: {},
  restaurant_id: 0,
  itemsDetail: {},
  selectedMenuItem: {},
  timeArray: [],
  timeArrayNew: [],
  groupOrder: {},
  groupOrderInfo: {},
  selectedGroupOrderItem: {},
};

const restReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTURANT_DATA:
      return {
        ...state,
        resturants: action.payload,
      };
    case GET_CUISINES_DATA:
      return {
        ...state,
        cuisines: action.payload,
      };
    case GET_RESTURANT_MENU:
      return {
        ...state,
        menus: {
          ...state.menus,
          [action.payload.restaurant_id]: action.payload.value,
        },
      };
    case SET_SELECTED_RESTURANT:
      return {
        ...state,
        selectedResturantData: {
          ...state.selectedResturantData,
          [action.payload.restaurant_id]: action.payload,
        },
      };
    case SET_UNSELECTED_RESTURANT:
      return {
        ...state,
        unSelectedResturantData: {
          ...state.selectedResturantData,
          [action.payload.restaurant_id]: action.payload,
        },
      };
    case RESTURANT_ID:
      return {
        ...state,
        restaurant_id: action.payload,
      };
    case SELECTED_MENU_ITEM:
      return {
        ...state,
        selectedMenuItem: action.payload,
      };
    case GET_ITEM_ADDON:
      return {
        ...state,
        itemsDetail: {
          ...state.itemsDetail,
          [action.payload.products_id]: action.payload,
        },
      };
    case REST_TIME_SELECTION:
      return {
        ...state,
        timeArray: action.payload.timeArray,
        timeArrayNew: action.payload.timeArrayNew,
      };

    case SET_GROUP_ORDER:
      return {
        ...state,
        groupOrder: { ...action.payload },
      };

    case SET_GROUP_ORDER_INFO:
      return {
        ...state,
        groupOrderInfo: { ...action.payload },
      };

    case SET_SELECTED_GROUP_ORDER_ITEM:
      return {
        ...state,
        selectedGroupOrderItem: action.payload,
      };

    default:
      return state;
  }
};

export default restReducer;
