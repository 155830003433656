import React, { useState, useEffect } from "react";
import {
	requestSubscription,
	setActiveTabs,
	setResturants,
	selectedAddress,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import { useWindowResize } from "../useWindowResize";
import { Link, useHistory, useLocation } from "react-router-dom";
import appStoreImg from "assets/images/app-store.svg";
import googlePlayImg from "assets/images/google-play.svg";
import homeActiveImg from "assets/images/home-active.svg";
import homeImg from "assets/images/home.svg";
import searchFooterActiveImg from "assets/images/search-footer-active.svg";
import searchFooterImg from "assets/images/search-footer.svg";
import ordersActiveImg from "assets/images/orders-active.svg";
import ordersImg from "assets/images/orders.svg";
import accountActiveImg from "assets/images/account-active.svg";
import accountImg from "assets/images/account.svg";

const sty = {
	cursor: "pointer",
};

const Footer = (props) => {
	const isMobileOnly = useWindowResize();
	const store = useSelector((store) => store);
	const { activeTab } = store.tab;
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [customers_email_address, setEmailAddress] = useState("");
	const handleSubscription = (e) => {
		e.preventDefault();
		dispatch(requestSubscription({ customers_email_address }));
	};
	const handleMobileNavigation = (route, tab) => {
		// dispatch(setActiveTabs(tab))
		if (tab == "home") {
			if (
				location.pathname == "/restaurants" ||
				location.pathname == "/restaurants/"
			) {
				dispatch(selectedAddress({}));
				dispatch(setResturants([]));
			}
		}

		history.push(route);
	};

	return (
		<>
			{!isMobileOnly || location.pathname == "/" ? (
				<footer>
					<div className="footer-bg">
						<div className="container">
							<div className="row justify-content-between">
								<div className="col-12 col-sm-4 col-lg-3 mb-4 mb-lg-0">
									<h5>Contact & Links</h5>
									<ul className="footer-main-links">
										<li>
											<Link style={sty} title="Contact Us" to="/contact-us" replace={location.pathname.includes("/contact-us")?true:false}>
												Contact Us
											</Link>
										</li>
										<li>
											<Link
												style={sty}
												title="Gift Cards"
												to={
													store.auth.token
														? "/giftcardtab"
														: "/buy-gift-card?guest=true"
												}
											>
												Gift Cards
											</Link>
										</li>
										<li>
											<Link
												style={sty}
												title="Rewards"
												to="/giftcardtab/rewards"
											>
												Rewards
											</Link>
										</li>
										<li>
											<Link
												style={sty}
												title="/Terms Of Use"
												to="/terms-of-use"
											>
												Terms of Use
											</Link>
										</li>
										<li>
											<Link
												style={sty}
												title="Privacy Policy"
												to="/privacy-policy"
											>
												Privacy Policy
											</Link>
										</li>
										<li>
											<Link style={sty} title="Faqs" to="/faq">
												FAQs
											</Link>
										</li>
									</ul>
								</div>
								<div className="col-12 col-sm-4 col-lg-3">
									<h5>Partner With Us</h5>
									<ul className="footer-main-links">
										<li>
											<Link
												to="/driver-signup"
												style={sty}
												title="Become A Driver"
												replace={location.pathname.includes("/driver-signup")?true:false}
											>
												Become A Driver
											</Link>
										</li>
										<li>
											<Link
												to="/restaurant-signup"
												style={sty}
												title="List Your Restaurant"
												replace={location.pathname.includes("/restaurant-signup")?true:false}

											>
												List a Restaurant
											</Link>
										</li>
									
										<li>
											<Link to="/jobs" style={sty} title="List Your Restaurant">
												Career
											</Link>
										</li>
									</ul>
									
								</div>
								<div className="col-12 col-sm-3 col-lg-2 mb-4 mb-lg-0 lastfooter-column">
									<ul className="text-md-right download-apps-footer">
										<li className="mb-3">
											<a style={sty} title="Apple app store">
												<img
													src={appStoreImg}
													className="img-fluid"
													alt="apple store logo"
												/>
											</a>
										</li>
										<li>
											<a style={sty} title="Google play store">
												<img
													src={googlePlayImg}
													className="img-fluid"
													alt="google play store logo"
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="copyright-bg">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<div className="copyright">
										<p>© 2021 Food Dudes Delivery, LLC</p>
										<ul>
											<li className="socials">
												<a style={sty} title="Facebook">
													<span>
														<i
															className="fa fa-facebook-square"
															aria-hidden="true"
														></i>
													</span>
												</a>
												<a style={sty} title="Instagram">
													<span>
														<i
															className="fa fa-instagram"
															aria-hidden="true"
														></i>
													</span>
												</a>
												<a style={sty} title="Twitter">
													<span>
														<i className="fa fa-twitter" aria-hidden="true"></i>
													</span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			) : (
				<footer className="d-md-none" id="loggedin-mobile-footer">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<ul
									className="nav nav-pills justify-content-between"
									// id="pills-tab"
									// role="tablist"
								>
									<li
										className={
											activeTab == "home" ? "nav-item active" : "nav-item"
										}
									>
										<a
											className={
												activeTab == "home" ? "nav-link active" : "nav-link"
											}
											// id="pills-home-tab"
											// data-toggle="pill"
											// href="#pills-home"
											// role="tab"
											// aria-controls="pills-home"
											// aria-selected="true"
											// onClick={() => history.push("/")}
											onClick={() => handleMobileNavigation("/?landing_page", "home")}
										>
											<span>
												{activeTab == "home" ? (
													<img src={homeActiveImg} alt="Home" />
												) : (
													<img src={homeImg} alt="Home" />
												)}
											</span>
											Home
										</a>
									</li>
									<li
										className={
											activeTab == "search" ? "nav-item active" : "nav-item"
										}
									>
										<a
											className={
												activeTab == "search" ? "nav-link active" : "nav-link"
											}
											onClick={() =>
												handleMobileNavigation("/search", "search")
											}
											// id="pills-home-tab"
											// data-toggle="pill"
											// href="#pills-home"
											// role="tab"
											// aria-controls="pills-home"
											// aria-selected="true"
											// onClick={() => history.push("/")}
										>
											<span>
												{activeTab == "search" ? (
													<img src={searchFooterActiveImg} alt="Account" />
												) : (
													<img src={searchFooterImg} alt="Search" />
												)}
											</span>
											Search
										</a>
									</li>
									{store.auth.token?<li
										className={
											activeTab == "order" ? "nav-item active" : "nav-item"
										}
									>
										<a
											className={
												activeTab == "order" ? "nav-link active" : "nav-link"
											}
											onClick={() =>
												handleMobileNavigation("/orderlist", "order")
											}

											// id="pills-home-tab"
											// data-toggle="pill"
											// href="#pills-home"
											// role="tab"
											// aria-controls="pills-home"
											// aria-selected="true"
											// onClick={() => history.push("/")}
										>
											<span>
												{activeTab == "order" ? (
													<img src={ordersActiveImg} alt="Orders" />
												) : (
													<img src={ordersImg} alt="Orders" />
												)}
											</span>
											Orders
										</a>
									</li>:null}
									<li
										className={
											activeTab == "account" ? "nav-item active" : "nav-item"
										}
									>
										<a
											className={
												activeTab == "account" ? "nav-link active" : "nav-link"
											}
											onClick={() =>
												handleMobileNavigation("/account", "account")
											}
										>
											<span>
												{activeTab == "account" ? (
													<img src={accountActiveImg} alt="Account" />
												) : (
													<img src={accountImg} alt="Account" />
												)}
											</span>
											Account
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</footer>
			)}
		</>
	);
};

export default Footer;
