// "react-router-dom": "^5.3.0",

import React, { useEffect, useState, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getProfile,
  getStateAndCities,
  getAddress,
  validateCart,
  setSelectedRest,
  setUnSelectedRest,
  getWallet,
  setCartItems,
} from "redux-store";
import PublicRoutes from "PublicRoutes";
import PrivateRoutes from "PrivateRoute";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Loader from "components/Loader";
import { useWindowResize } from "components/useWindowResize";
// import Login from "pages/Login";
// import SignUp from"pages/SignUp";

import Geocode from "react-geocode";
import moment from "moment";
Geocode.setApiKey("AIzaSyCmrodTAFNeH1cWfFPJLxiRlRUsCf7f3XE");
Geocode.setLanguage("en");

const Verification = lazy(() => import("pages/Verification"));
const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));
const SignUp = lazy(() => import("pages/SignUp"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const SocialSignUp = lazy(() => import("pages/SocialSignUp"));
const ResturantList = lazy(() => import("pages/ResturantList"));
const Profile = lazy(() => import("pages/Profile"));
const Address = lazy(() => import("pages/Address"));
const NestedModal = lazy(() => import("pages/NestedModal"));
const Account = lazy(() => import("pages/Account"));
const MenuListing = lazy(() => import("pages/MenuListing"));
const ChooseAddressMobile = lazy(() => import("pages/ChooseAddressMobile"));
const DateAndTimeMobile = lazy(() => import("pages/DateAndTimeMobile"));
const MobileSearch = lazy(() => import("pages/MobileSearch"));
const MenuItemMobile = lazy(() => import("pages/MenuItemMobile"));
const MobileCart = lazy(() => import("pages/MobileCart"));
const WebCheckOut = lazy(() => import("pages/WebCheckOut"));
const OrderDetails = lazy(() => import("pages/OrderDetails"));
const OrderList = lazy(() => import("pages/OrderListing"));
const GiftCardTabs = lazy(() => import("pages/GiftCard"));
const PaymentMethods = lazy(() => import("pages/PaymentsMethods"));
const RedeemRewardsPoint = lazy(() => import("pages/RedeemRewardsPoint"));
const WebCheckoutGcards = lazy(() => import("pages/WebCheckoutGcards"));
const NotificationsList = lazy(() => import("pages/Notifications"));
const MasterLogin = lazy(() => import("pages/MasterLogin"));
const ValidateCoupon = lazy(() => import("pages/ValidateCoupon"));
const DriverSignUp = lazy(() => import("pages/DriverSignUp"));
const RestaurantSignUp = lazy(() => import("pages/RestaurantSignUp"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const Faq = lazy(() => import("pages/Faq"));
const Terms = lazy(() => import("pages/Terms"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const JobListing = lazy(() => import("pages/JobListing"));
const NewsLetter = lazy(() => import("pages/NewsLetter"))

const AllRoutes = () => {
  const isMobileOnly = useWindowResize();

  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  let match = useRouteMatch();
  const [currentDate, setCurrentDate] = useState(moment().format());
  

  useEffect(() => {
    if (store.auth.token) {
      dispatch(getProfile());
      dispatch(getAddress());
      dispatch(getWallet());
    }
  }, [store.auth.token]);
  useEffect(() => {
    if (!store.auth.stateAndCites.length) {
      dispatch(getStateAndCities());
    }
  }, [store.auth.token]);

  useEffect(() => {
    if (store.cart.cart.restaurant_id) {
      const updateSelectRest =
        store.rest.selectedResturantData[store.cart.cart.restaurant_id];
      const findRest = store.rest.resturants.find(
        (item) => item.restaurant_id == store.cart.cart.restaurant_id
      );

      if (findRest) {
        dispatch(setSelectedRest(findRest));
        dispatch(setUnSelectedRest(findRest));
      }
    }
  }, [store.rest.resturants]);
  useEffect(() => {
    if(store.cart.cart && store.cart.cart.date){
 
      var diff = moment(currentDate).diff(store.cart.cart.date, 'minutes');

      if (diff > 1440) { 
        /* do something */ 
        dispatch(setCartItems({}))
      }
    }
    
    
  }, []);
  const routesArray = [
    {
      path: "/",
      exact: true,
      component: () => {
        return Object.keys(store.profile.selectedAddress).length > 1 ? (
          store.tab.routePlaceOrder ? (
            <Redirect
              to={
                typeof store.tab.routePlaceOrder == "boolean"
                  ? "/webcheckout"
                  : store.tab.routePlaceOrder
              }
            />
          ) : (
            <Redirect
              // to="/restaurants"
              to={{
                pathname: `/restaurants`,
                reInitiate: true,
              }}
            />
          )
        ) : (
          <Home />
        );
      },
      restricted: false,
    },
    {
      path: `/newsletter/:token`,
      exact: true, 
      component: NewsLetter,
      restricted: false,
    },
    {
      path: `/verification`,
      exact: true,
      component: Verification,
      restricted: true,
    },
    // { path: `${match.path}/verification`, exact: true, component: Verification, restricted:true },
    { path: "/signin", exact: true, component: Login, restricted: true },
    { path: "/signup", exact: true, component: SignUp, restricted: true },
    {
      path: `/forgot-password`,
      exact: true,
      component: ForgotPassword,
      restricted: true,
    },
    {
      path: `/reset-password`,
      exact: true,
      component: ResetPassword,
      restricted: true,
    },
    {
      path: `/:${id}/social-signup`,
      component: SocialSignUp,
      restricted: true,
    },
    {
      path: `/restaurants`,
      exact: true,
      component: ResturantList,
      restricted: false,
    },
    {
      path: `/restaurants/:restId`,
      component: MenuListing,
      exact: true,
    },
    {
      path: `/restaurants/:restId/:groupToken`,
      component: MenuListing,
      exact: true,
    },
    { path: "/account", exact: true, component: Account, restricted: false },
    {
      path: "/choose-address",
      exact: true,
      component: ChooseAddressMobile,
      restricted: false,
    },
    {
      path: "/choose-date-time",
      exact: true,
      component: DateAndTimeMobile,
      restricted: false,
    },
    {
      path: "/search",
      exact: true,
      component: MobileSearch,
      restricted: false,
    },
    {
      path: "/menuitemmobile",
      exact: true,
      component: MenuItemMobile,
      restricted: false,
    },
    {
      path: "/mobilecart",
      exact: true,
      component: MobileCart,
      restricted: false,
    },
    {
      path: "/giftcardtab",
      // exact: true,
      component: GiftCardTabs,
      restricted: false,
    },

    {
      path: "/webcheckout",
      exact: true,
      component: WebCheckOut,
      restricted: false,
      data: "CART",
    },
    {
      path: "/webcheckout/giftcard",
      exact: true,
      component: WebCheckOut,
      restricted: false,
      data: "GIFTCARD",
    },
    {
      path: "/webcheckout/egiftcard",
      exact: true,
      component: WebCheckOut,
      restricted: false,
      data: "EGIFTCARD",
    },
    {
      path: "/notifications",
      exact: true,
      component: NotificationsList,
      restricted: false,
    },
    {
      path: "/validate-code/:couponCode",
      exact: true,
      component: ValidateCoupon,
      restricted: false,
    },
    {
      path: "/webcheckout_giftcards_egift",
      exact: true,
      component: WebCheckoutGcards,
      restricted: false,
    },
    {
      path: "/webcheckout_giftcards_buy_egift",
      exact: true,
      component: WebCheckoutGcards,
      restricted: false,
    },
    {
      path: "/webcheckout_giftcards_gift",
      exact: true,
      component: WebCheckoutGcards,
      restricted: false,
    },
    {
      path: "/webcheckout_giftcards_buy_gift",
      exact: true,
      component: WebCheckoutGcards,
      restricted: false,
    },
    {
      path: "/webcheckout/group/:groupToken",
      exact: true,
      component: WebCheckOut,
      restricted: false,
    },
    {
      path: "/nimda/master-login/:masterToken",
      exact: true,
      component: MasterLogin,
      restricted: false,
    },
    {
      path: "/driver-signup",
      exact: true,
      component: DriverSignUp,
      restricted: false,
    },
    {
      path: "/restaurant-signup",
      exact: true,
      component: RestaurantSignUp,
      restricted: false,
    },
    {
      path: "/contact-us",
      exact: true,
      component: ContactUs,
      restricted: false,
    },
    {
      path: "/faq",
      exact: true,
      component: Faq,
      restricted: false,
    },
    {
      path: "/terms-of-use",
      exact: true,
      component: Terms,
      restricted: false,
    },
    {
      path: "/privacy-policy",
      exact: true,
      component: PrivacyPolicy,
      restricted: false,
    },
    {
      path: "/jobs",
      exact: true,
      component: JobListing,
      restricted: false,
    },

    // {
    //   path: "/webcheckout_giftcards_rewards",
    //   exact: true,
    //   component: WebCheckoutGcards,
    //   restricted: false,
    // },
    // {
    //   path: "/webcheckout/egiftcard_gift",
    //   exact: true,
    //   component: WebCheckOut,
    //   restricted: false,
    //   checkoutRoutes:checkoutRoutes.GIFT_EGIFTCARD
    // },
    // {
    //   path: "/webcheckout/giftcard_gift",
    //   exact: true,
    //   component: WebCheckOut,
    //   restricted: false,
    //   checkoutRoutes:checkoutRoutes.GIFT_GIFTCARD
    // },
    // {
    //   path: "/webcheckout/egiftcard_reward",
    //   exact: true,
    //   component: WebCheckOut,
    //   restricted: false,
    //   checkoutRoutes:checkoutRoutes.REWARD_EGIFTCARD
    // },
    // {
    //   path: "/webcheckout/giftcard_reward",
    //   exact: true,
    //   component: WebCheckOut,
    //   restricted: false,
    //   checkoutRoutes:checkoutRoutes.REWARD_GIFTCARD
    // },
    { path: "/buy-gift-card", exact: true, component: RedeemRewardsPoint },

    // { path: "/orderdetails", exact: true, component: OrderDetails },

    { path: "*", exact: true, component: () => <Redirect to="/" /> },
  ];
  const privateRoutes = [
    {
      path: "/orderdetails/:orderNo",
      exact: true,
      component: OrderDetails,
    },
    { path: "/orderlist", exact: true, component: OrderList },
    { path: "/payment-methods", exact: true, component: PaymentMethods },

    { path: "/redeem-points", exact: true, component: RedeemRewardsPoint },
    { path: "/profile", exact: true, component: NestedModal },
  ];
  const modalRoutes = [
    { path: "/profile", exact: true, component: Profile },
    { path: "/address", exact: true, component: Address },
  ];
  const renderPublicRoutes = (route, i) => (
    <PublicRoutes key={i} token={store.auth.token} {...route} />
  );
  const renderPrivateRoutes = (route, i) => (
    <PrivateRoutes key={i} token={store.auth.token} {...route} />
  );
  const background = location.state && location.state.background;
  const renderModalRoutes = (route, i) =>
    background && (
      <Route
        {...route}
        render={(props) =>
          store.auth.token ? (
            <Redirect to="/login" />
          ) : (
            <route.component {...props} />
          )
        }
      />
    );

  const [gmapsLoaded, setGmapsLoaded] = useState(true);

  // This is how you do componentDidMount() with React hooks
  useEffect(() => {
    // window.initMap = () => setGmapsLoaded(true);
    // const gmapScriptEl = document.createElement(`script`);
    // gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCmrodTAFNeH1cWfFPJLxiRlRUsCf7f3XE&libraries=geometry,drawing,places&callback=initMap`;
    // document
    //   .querySelector(`body`)
    //   .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }, []);
  useEffect(() => {
    if (store.cart.cart.items) {
      dispatch(validateCart(location.pathname));
    }
  }, [
    store.auth.headerTab,
    store.profile.deliveryDate,
    store.profile.deliveryTime,
  ]);

  return (
    <>
      {gmapsLoaded && (
        <>
          <Switch location={background || location}>
            {privateRoutes.map(renderPrivateRoutes)}
            {routesArray.map(renderPublicRoutes)}
          </Switch>
          {/* {store.tab.modalIsOpen.status ? <ItemDetail /> : null} */}

          {(!isMobileOnly || background) && (
            <Route
              path="/profile"
              render={(props) => (
                <Suspense fallback={<Loader />}>
                  {" "}
                  <NestedModal {...props} />{" "}
                </Suspense>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default AllRoutes;
