import React, { useState, useEffect } from "react";
import SearchList from "../SearchList";
import {
  selectedMenuItem,
  setActiveTabs,
  setSelectedGroupOrderItem,
  setSelectedRest,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/Footer";
import {
  Link,
  NavLink,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import searchFooterImg from "assets/images/search-footer.svg";
import backArrowImg from "assets/images/back-arrow-mobile.svg";

const MobileSarch = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const restId = store.rest.restaurant_id;
  const selectedRestData = store.rest.selectedResturantData?.[restId];
  const menuSelected = store?.rest?.menus?.[restId];
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleMenuSearch = (e) => {
    const value = e.target?.value?.trim();
    if (value) {
      if (/^[^a-zA-Z0-9]+$/.test(value)) {
        setList([]);
        setSearchValue(e.target.value);
        return;
      }
      let menus = [];
      menuSelected.forEach((item) => {
        menus.push(
          item.submenus.map((submenu) => ({
            ...submenu,
            type: item?.menu_title,
          }))
        );
      });
      const allDishes = menus.flat()?.reduce((prev, curr) => {
        return prev?.length ? prev.concat(curr.products) : curr.products;
      }, []);
      console.log("all dishesh =>", allDishes);
      const searched = value
        .toLowerCase()
        .replace(/[&, -]/g, "|")
        .split("|");

      let searchedDished = searched
        ? searched.reduce((prev, curr, index) => {
            if (/[&, -]/g.test(curr)) {
              return prev;
            }
            if (index === 0) {
              return allDishes
                .filter((dish, index, array) => {
                  return (
                    (dish?.products_name.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) => t?.products_name === dish?.products_name
                      ) === index) ||
                    (dish?.products_description.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) =>
                          t?.products_description === dish?.products_description
                      ) === index)
                  );
                })
                .map((dish) => ({ ...dish, label: dish?.products_name }));
            } else {
              return prev
                .filter((dish, index, array) => {
                  return (
                    (dish?.products_name.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) => t?.products_name === dish?.products_name
                      ) === index) ||
                    (dish?.products_description.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) =>
                          t?.products_description === dish?.products_description
                      ) === index)
                  );
                })
                .map((dish) => ({ ...dish, label: dish?.products_name }));
            }
          }, "")
        : [];
      setList(searchedDished);
    } else {
      setList([]);
    }
    setSearchValue(e.target.value);
  };

  const handleMenuItem = (item) => {
    dispatch(
      setSelectedGroupOrderItem({
        ...item,
        products_price: Number(item.products_price),
        restaurant_name: selectedRestData.name,
        restaurant_id: selectedRestData.restaurant_id,
        latlng: {
          lat: selectedRestData.lat,
          lng: selectedRestData.lng,
        },
        service_fee: selectedRestData.service_fee,
        restaurant_delivery_fee: selectedRestData.restaurant_delivery_fee,
        min_order: selectedRestData.min_order,
        tax: selectedRestData.tax,
        isEdit: false,
        selectedProduct: {},
        editIndex: null,
        address: selectedRestData.address,
        service_type: selectedRestData.service_type,
        allow_asap_advance_orders: selectedRestData.allow_asap_advance_orders,
        minimum_pickup_order:selectedRestData.minimum_pickup_order, 
        minimum_deliver_order:selectedRestData.minimum_deliver_order
      })
    );

    dispatch(
      selectedMenuItem({
        ...item,
        products_price: Number(item.products_price),
        restaurant_name: selectedRestData.name,
        restaurant_id: selectedRestData.restaurant_id,
        latlng: {
          lat: selectedRestData.lat,
          lng: selectedRestData.lng,
        },
        // fee:selectedRestData.fee,
        service_fee: selectedRestData.service_fee,
        restaurant_delivery_fee: selectedRestData.restaurant_delivery_fee,
        min_order: selectedRestData.min_order,
        tax: selectedRestData.tax,
        isEdit: false,
        selectedProduct: {},
        editIndex: null,
        address: selectedRestData.address,
        service_type: selectedRestData.service_type,
        allow_asap_advance_orders: selectedRestData.allow_asap_advance_orders,
        minimum_pickup_order:selectedRestData.minimum_pickup_order, 
        minimum_deliver_order:selectedRestData.minimum_deliver_order,
        
      })
    );
    // history.push("/menuitemmobile", { menuSearch: true });
    dispatch({
      type: "ITEM_MODAL",
      payload: { menuSearch: true, status: true, type: "" },
    });
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const resturants = [...store.rest.resturants];
      const filteredResturants = resturants.filter((resturants) => {
        const arr = resturants.restaurant_cuisines?.split(",");
        let x = false;
        arr?.map((item) => {
          if (item.toLowerCase().includes(value.toLowerCase())) {
            x = true;
          }
        });

        return (
          resturants.name.toLowerCase().includes(value.toLowerCase()) || x

          // ||
          // resturants
          // .email
          // .toLowerCase()
          // .includes(searchField.toLowerCase())
        );
      });
      setList(filteredResturants);
    } else {
      setList([]);
    }
    setSearchValue(e.target.value);
  };
  const goToMenu = (data) => {
    dispatch(setSelectedRest(data));
    history.push({
      pathname: `/restaurants/${data.name.replace(/[^a-zA-Z0-9]+/g, "_")}_${
        data.restaurant_id
      }`,
      // pathname: `restaurants/${data.name}`,
      // search: '?query=abc',
      data: data,
    });
  };
  useEffect(() => {
    dispatch(setActiveTabs("search"));
  }, []);

  return (
    <>
      {/* <main className="mobile-inner-screen">
      <div className="container">
        <div className="row">
          <div className="col-12">
					<input 
						type="search" 
						className="form-control" 
						id="deliveryAddress"
            placeholder="Search for restaurants/cuisines" 
						value={searchValue}
						onChange={handleSearch}
					/>
          </div>
        </div>
        <div className="main-wrapper">
					<SearchList list={list} goToMenu={goToMenu} />
        </div>
      </div>
			<Footer />
    </main> */}

      <div
        className="tab-pane"
        id="pills-search"
        role="tabpanel"
        aria-labelledby="pills-search-tab"
      >
        <div className="hometab-wrapper search-tab-wrapper ">
          <div className="bg">
            {location?.state?.foodSearch ? (
              <div className="px-3 pb-2  d-flex align-items-center">
                {" "}
                <span className="icons" onClick={() => history.goBack()}>
                  <img src={backArrowImg} alt="back arrow" />
                  <span className="font-weight-bold ml-2">
                    {location?.state?.restName}
                  </span>
                </span>
              </div>
            ) : null}
            <div className="container">
              <div className="mobile-serach-restandcuisine">
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder={
                    location?.state?.foodSearch
                      ? "Search food item"
                      : "Search for restaurants/cuisines"
                  }
                  value={searchValue}
                  onChange={
                    location?.state?.foodSearch
                      ? handleMenuSearch
                      : handleSearch
                  }
                />
                <button className="search">
                  <img src={searchFooterImg} alt="search button" />
                </button>
              </div>
            </div>
          </div>

          <section id="search-tab-inner-wrapper">
            <div className="container">
              <div
                className={`cuisines-restra-search ${
                  location?.state?.foodSearch ? "pt-4" : ""
                }`}
              >
                <div className="inner-wrapper pickaddress">
                  <ul className="addressLists p-0">
                    {list.map((item, index) => {
                      return location?.state?.foodSearch ? (
                        <li key={item.id} onClick={() => handleMenuItem(item)}>
                          <div className="addressType">
                            <p>
                              {
                                item.products_name?.split(".")[
                                  item.products_name?.split(".").length - 1
                                ]
                              }{" "}
                            </p>
                            <p className="addressDetails pr-3">
                              {item?.products_description}
                            </p>
                          </div>
                        </li>
                      ) : (
                        <li key={item.id} onClick={() => goToMenu(item)}>
                          <div className="addressType">
                            <p>{item.name} </p>
                            <p className="addressDetails">
                              {item.restaurant_cuisines}
                            </p>
                          </div>
                        </li>
                      );
                    })}

                    {/* <li>
                            <div className="addressType">
                                <p>Great Italy</p>
                                <p className="addressDetails">Restaurant
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="addressType">
                                <p>Italian Pizza</p>
                                <p className="addressDetails">Restaurant
                                </p>
                            </div>
                        </li> */}
                  </ul>

                  {location?.state?.foodSearch &&
                  !list.length &&
                  searchValue?.trim() ? (
                    <p className="pt-2">No Food Item Found</p>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {location?.state?.foodSearch ? null : <Footer />}
    </>
  );
};

export default MobileSarch;
