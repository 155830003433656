import React, { useState, useEffect } from "react";
import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import ConfirmationPopup from "./ConfirmationPopup";
import {
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { giftCardRestaurantId } from 'constants/giftCard'

import {
	getSingleOrderDetails,
	setCartItems,
	selectDate,
	selectTime,
	setPlaceOrderInst,
	setPlaceOrderInstGroup,
	reOrdeProcess,
	setRadioNotes,
	headerTabSelected,
	reOrderGroupOrder,
	setGcardCartItems,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import Pusher from "pusher-js";
import { SocketConfig } from "../../constants/socketConfig";
import { OrderStatuses } from "../../constants/orderStatus";
import moment from "moment";
import restaurantImg from "assets/images/icon-resturant.svg";
import addressImg from "assets/images/icon-address.svg";
import closeImg from "assets/images/modal-close.svg";
import congratsImg from "assets/images/congrats-icon.svg";
import { useWindowResize } from "../../components/useWindowResize";
import { toast } from "react-toastify";
import MobileViewGc from "./MobileViewGc";
import DesktopViewGc from "./DesktopViewGc";
import Loader from "components/Loader";

const OrderDetails = () => {
	const isMobileOnly = useWindowResize();
	let { path } = useRouteMatch();

	const store = useSelector((store) => store);
	const dispatch = useDispatch();
	const history = useHistory();
	const { orderNo } = useParams();
	const { data = {}, hash } = useLocation();

	const { isNavigated, orderId = orderNo } = data;

	const [orderStatusData, setOrderStatusData] = useState(null);
	const [driverTrackingData, setDriverTrackingData] = useState(null);

	const [showModal, setShowModal] = useState(isNavigated ? true : false);
	const [details, setDetails] = useState(null);
	const [isDelivery, setIsDelivery] = useState(true);
	const [paymentMode, setPaymentMode] = useState(null);

	const [schedule, setSchedule] = useState(
		store.profile.deliveryDate
			? `${moment(store.profile.deliveryDate).format("ddd, MMM DD")} ${
					store.profile.timeInd
			  }`
			: false,
	);

	const getSingleOrder = () => {
		dispatch(
			getSingleOrderDetails({ order_id: orderId }, (data) => {
				setDetails(data);
				setOrderStatusData({
					order_id: +orderId,
					...data.order_datail[0],
					order_status_id: +data.order_datail[0].orders_status_id,
					order_status_name: data.order_datail[0].orders_status_name,
				});
				setIsDelivery(data.order_datail[0].pickup_order === 0 ? true : false);
				setPaymentMode(data.order_datail[0]?.payment_method);
			}),
		);
	};

	useEffect(() => {
		dispatch({ type: "ROUTEPLACE", payload: false });
		dispatch(setPlaceOrderInst(""));
		dispatch(setPlaceOrderInstGroup(""));
		dispatch(setRadioNotes(""));
		getSingleOrder();
		if (isNavigated) {
			dispatch(setCartItems({}));
			dispatch(setGcardCartItems({}));
			dispatch(selectDate(""));
			dispatch(selectTime(""));
			setTimeout(() => setShowModal(false), 30000);
		}

		startingConnection();
	}, []);

	useEffect(() => {
		if (orderStatusData) {
			if (+orderStatusData.order_status_id === +OrderStatuses.PICKEDUP.id) {
				driverTrackingHandler();
			}
		}
	}, [orderStatusData]);

	let cities = [];
	let options = {};

	if (details) {
		if (details.order_datail[0].pickup_order === 0) {
			cities = [
				{
					img: restaurantImg,
					id: 1,
					name: details.order_datail[0].restaurants_name,
					position: {
						lat: Number(details.order_datail[0].latitude),
						lng: Number(details.order_datail[0].longitude),
					},
				},
				{
					img: addressImg,
					id: 2,
					name: details.order_datail[0].customers_street_address,
					position: {
						lat: Number(details.order_datail[0].customers_lat),
						lng: Number(details.order_datail[0].customers_lng),
					},
				},
			];
		} else {
			cities = [
				{
					img: restaurantImg,
					id: 1,
					name: details.order_datail[0].restaurants_name,
					position: {
						lat: Number(details.order_datail[0].latitude),
						lng: Number(details.order_datail[0].longitude),
					},
				},
			];
		}
		options = {
			strokeColor: "#FF0000",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: "#FF0000",
			fillOpacity: 0.35,
			clickable: false,
			draggable: false,
			editable: false,
			visible: true,
			radius: 30000,
			paths:
				details.order_datail[0].pickup_order == 0
					? [cities[0].position, cities[1].position]
					: [cities[0].position, cities[0].position],
			zIndex: 1,
		};
	}

	const [mapValue, setMapValue] = useState(null);
	const [mapProjection, setMapProjection] = useState(null);
	const [mapZoom, setMapZoom] = useState(1);

	const handleOnLoad = (map) => {
		setMapValue(map);
		const bounds = new window.google.maps.LatLngBounds();

		cities.forEach(({ position }) => bounds.extend(position));
		let mapValue = map
		map.fitBounds(bounds);
				window.google.maps.event.addListenerOnce(
				mapValue,
				"projection_changed",
				function () {
					setMapProjection(mapValue.getProjection());
					// document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
				},
			);
			window.google.maps.event.addListener(
				mapValue,
				"zoom_changed",
				function () {
					setMapZoom(
						mapValue.getZoom() > 17 ? mapValue.setZoom(17) : mapValue.getZoom(),
					);

					// setMapProjection(map.getProjection())
					// document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
				},
			);
		
	};
	console.log("mapZoop", mapZoom)

	const handleGoBack = () => {
		if(hash == "#notify"){
			history.goBack()
		}else{
			history.push("/orderlist");
		}
		
	};

	const startingConnection = () => {
		const pusher = new Pusher(SocketConfig.PUSHER_KEY, SocketConfig.config);

		Pusher.log = (msg) => {
			console.log(msg);
		};

		pusher.connection.bind("error", function (err) {
			if (err.error.data.code === 4004) {
				pusher.disconnect();
			}
		});

		const channel = pusher.subscribe("order-status");

		channel.bind("pusher:subscription_succeeded", function () {
			addingListener(channel);
		});
	};

	const addingListener = (channel) => {
		channel.bind("order-status-update", (pusherData) => {
			if (pusherData) {
				let status = pusherData.data;
				setOrderStatusData(status);
			}
		});
	};

	const driverTrackingHandler = () => {
		const pusher = new Pusher(SocketConfig.PUSHER_KEY, SocketConfig.config);

		Pusher.log = (msg) => {
			console.log(msg);
		};

		pusher.connection.bind("error", function (err) {
			if (err.error.data.code === 4004) {
				pusher.disconnect();
			}
		});

		const channel = pusher.subscribe("driver-tracking");

		channel.bind("pusher:subscription_succeeded", function () {
			addingTrackingListener(channel);
		});
	};

	const addingTrackingListener = (channel) => {
		channel.bind("driver-tracking-update", (pusherData) => {
			if (pusherData) {
				let status = pusherData.data;
				setDriverTrackingData(status);
			}
		});
	};
	useEffect(() => {
		// if (mapValue) {
		// 	window.google.maps.event.addListener(
		// 		mapValue,
		// 		"projection_changed",
		// 		function () {
		// 			setMapProjection(mapValue.getProjection());
		// 			// document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
		// 		},
		// 	);
		// 	window.google.maps.event.addListener(
		// 		mapValue,
		// 		"zoom_changed",
		// 		function () {
		// 			setMapZoom(
		// 				mapValue.getZoom() > 17 ? mapValue.setZoom(17) : mapValue.getZoom(),
		// 			);

		// 			// setMapProjection(map.getProjection())
		// 			// document.getElementById('output').innerHTML = "map.getProjection()="+JSON.stringify(map.getProjection(), null, ' ');
		// 		},
		// 	);

		// 	return () => {
		// 		window.google.maps.event.clearListeners(mapValue, "zoom_changed");
		// 		window.google.maps.event.clearListeners(mapValue, "projection_changed");
		// 	};
		// }
	}, [mapValue]);

	const handleReorder = () => {
		const {
			customers_lat,
			customers_lng,
			restaurants_id,
			orders_id,
			customers_street_address,
			customers_postcode,
			customers_state,
			customers_suburb,
			latitude,
			longitude,
			customers_name = "",
			// entry_firstname,
			// entry_lastname,
			customers_company,
			customers_city,
			group_order_id,
		} = details.order_datail[0];
		const getSpecificRest = {
			lat_from: customers_lat.toString(),
			lng_from: customers_lng.toString(),
			restaurant_id: restaurants_id,
		};

		let x = customers_name.split(" ");
		const updateAddress = {
			entry_country_id: "1",
			entry_zone_id: "1",
			address_type: "1",
			set_default: 0,
			entry_street_address: customers_street_address,
			entry_postcode: customers_postcode,
			entry_state: customers_state,
			entry_suburb: customers_suburb,
			address_lat: Number(customers_lat),
			address_lng: Number(customers_lng),
			entry_firstname: x[0],
			entry_lastname: x[1],
			entry_company: customers_company,
			entry_city: customers_city,
		};

		let radio = "";
		let spinst = "";
		details.order_notes.map((note) => {
			switch (note.note_type) {
				case 0:
					radio = note.note;
					break;
				case 1:
					spinst = note.note;
			}
		});

		if (group_order_id) {
			dispatch(
				reOrderGroupOrder(
					getSpecificRest,
					orders_id,
					updateAddress,
					details.order_datail[0].pickup_order,
					(data) => {
						// dispatch(setPlaceOrderInst(spinst));
						dispatch(setPlaceOrderInstGroup(spinst));
						dispatch(setRadioNotes(radio));
						dispatch(
							headerTabSelected(
								details.order_datail[0].order_type == 1 ? "Delivery" : "Pickup",
							),
						);
						let link = `${data?.link}${data?.is_reorder ? "#reorder" : ""}`;
						window.open(link);
					},
				),
			);
		} else {
			dispatch(
				reOrdeProcess(
					getSpecificRest,
					orders_id,
					updateAddress,
					details.order_datail[0].pickup_order,
					(data) => {
						dispatch(setPlaceOrderInst(spinst));
						dispatch(setRadioNotes(radio));
						// alert(data.rest_status)

						switch (data?.rest_status) {
							case 0:
								toast.error("Restuarant is closed");
								break;
							case 1:
								break;
							case 2:
								toast.error("Currently not accepting orders");
								break;
							case 3:
								toast.error("Currently not accepting orders");
								break;
						}
						dispatch(
							headerTabSelected(
								details.order_datail[0].order_type == 1 ? "Delivery" : "Pickup",
							),
						);
						history.push({
							pathname: `/restaurants/${data.name.replace(
								/[^a-zA-Z0-9]+/g,
								"_",
							)}_${data.restaurant_id}`,
							// search: '?query=abc',
							data: { ...data, radio: radio },
						});
					},
				),
			);
		}
	};

	console.log("details ", details)

	return (
		<>
		{!details?<Loader />:
		<>
			{isMobileOnly ? (
				<>
					{details &&
					details.order_datail &&
					details.order_datail[0] &&
					details.order_datail[0].restaurants_id != giftCardRestaurantId ? (
						<MobileView
							calcArray={details ? details.financial_data : []}
							orderInfo={details ? details.order_datail : []}
							products={details ? details.order_products : []}
							notes={details ? details.order_notes : []}
							giftCard={
								details ? (details.gift_cards ? details.gift_cards : []) : []
							}
							cities={cities}
							handleOnLoad={handleOnLoad}
							dispatch={dispatch}
							mapProjection={mapProjection}
							mapZoom={mapZoom}
							polylineOptions={
								details
									? details.order_datail[0].pickup_order == 0
										? options
										: false
									: false
							}
							isDelivery={isDelivery}
							handleGoBack={handleGoBack}
							orderStatus={orderStatusData}
							driverStatus={driverTrackingData}
							getSingleOrder={getSingleOrder}
							handleReorder={handleReorder}
							paymentMode={paymentMode}
						/>
					) : (
						<MobileViewGc
							calcArray={details ? details.financial_data : []}
							orderInfo={details ? details.order_datail : []}
							products={details ? details.order_products : []}
							dispatch={dispatch}
							// isDelivery={isDelivery}
							handleGoBack={handleGoBack}
							orderStatus={orderStatusData}
							// getSingleOrder={getSingleOrder}
							// handleReorder={handleReorder}
							// paymentMode={paymentMode}
							store={store}
						/>
					)}
				</>
			) : (
				<>
					{details &&
					details.order_datail &&
					details.order_datail[0] &&
					details.order_datail[0].restaurants_id != giftCardRestaurantId ? (
						<DesktopView
							calcArray={details ? details.financial_data : []}
							orderInfo={details ? details.order_datail : []}
							products={details ? details.order_products : []}
							notes={details ? details.order_notes : []}
							giftCard={
								details ? (details.gift_cards ? details.gift_cards : []) : []
							}
							cities={cities}
							handleOnLoad={handleOnLoad}
							dispatch={dispatch}
							mapProjection={mapProjection}
							mapZoom={mapZoom}
							polylineOptions={
								details
									? details.order_datail[0].pickup_order === 0
										? options
										: false
									: false
							}
							isDelivery={isDelivery}
							handleGoBack={handleGoBack}
							orderStatus={orderStatusData}
							driverStatus={driverTrackingData}
							getSingleOrder={getSingleOrder}
							handleReorder={handleReorder}
							paymentMode={paymentMode}
						/>
					) : (
						<DesktopViewGc
							calcArray={details ? details.financial_data : []}
							orderInfo={details ? details.order_datail : []}
							products={details ? details.order_products : []}
							dispatch={dispatch}
							// isDelivery={isDelivery}
							handleGoBack={handleGoBack}
							orderStatus={orderStatusData}
							store={store}
						/>
					)}
				</>
			)}
			{details && details.order_datail && details.order_datail[0] && (
				<ConfirmationPopup
					show={showModal}
					onHide={() => {
						setShowModal(false);
					}}
				>
					<div id="#congrats-modal">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body">
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={() => setShowModal(false)}
									>
										<span aria-hidden="true">
											<img src={closeImg} alt="icon" className="img-fluid" />
										</span>
									</button>
									<div className="congrates-wrapper">
										<div className="img-box">
											<img src={congratsImg} alt="icon" className="img-fluid" />
										</div>
										{details.order_datail[0].restaurants_id != giftCardRestaurantId ? (
											<div className="content-box">
												<h1>Congrats!</h1>

												{schedule ? (
													<p>Your order {orderId} has been placed.</p>
												) : (
													<p>
														Your order #{orderId} has been placed and will be{" "}
														{isDelivery ? "delivered" : "ready"}{" "}
														{schedule ? `on ${schedule}` : "in 30 mins"}.
													</p>
												)}
											</div>
										) : (
											<div className="content-box">
												<h1>Congrats!</h1>

												<p>Your order has been successfully placed.</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</ConfirmationPopup>
			)}
			</>}
		</>
	);
};

export default OrderDetails;
