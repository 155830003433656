export const setActiveTabs = (payload) => {
  
  return {
    type: "SET_ACTIVE_TAB",
    payload: payload,
  };
};
export const setForgotField = (payload) => {
  return {
    type: "SET_FORGOT_FIELD",
    payload: payload,
  };
};

export const setSelectedPaymentMethods = (payload) => {
  return {
    type: "PAYMENT_METHOD",
    payload: payload,
  };
};

export const setRadioNotes = (payload) => {
  return {
    type: "RADIO_NOTES",
    payload: payload,
  };
};

export const setWarningModalStatus = (payload) => {
  return {
    type: "WARNING_LEAVING_MODAL",
    payload: payload,
  };
};
