import React, { useEffect,useRef } from "react";
import { useWindowResize } from "./useWindowResize"
const  onOutsideClick= () => {}
const OutsideClickHandler =(props)=> {
  // const isMobileOnly = useWindowResize()
  const wrapperRef = useRef();

  useEffect(()=>{

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  },[])

 

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      props.onOutsideClick(event);
    }
  };
  return (<div ref={wrapperRef}>{props.children}</div>);
  
}

export default OutsideClickHandler;
