import React from "react";
import cancelOrderImg from "assets/images/cancel-order-icon.svg";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import { useDispatch } from "react-redux";
import { cancelOrder } from "redux-store";

function CancelOrderMobile({
  closeModal = () => {},
  orderInfo,
  getSingleOrder,
  paymentMode,
}) {
  const dispatch = useDispatch();
  return (
    <main className="mobile-inner-screen driver-tip-mobile">
      <div className="payment__page__wrapper">
        <div className="go-back-wrapper" onClick={closeModal}>
          <a href="#" className="go-back-btn">
            <img src={backArrowMobileImg} alt="go back" />
          </a>
          <h2>Cancel Order</h2>
        </div>
        <div className="main-wrapper">
          <section className="mobile-inner-section">
            <div className="main">
              <div className="container">
                <div id="cancelOrder-modal">
                  <div className="congrates-wrapper">
                    <div className="img-box">
                      <img
                        src={cancelOrderImg}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-box">
                      <h1>Cancel Order ?</h1>
                      <p>Are you sure you want to cancel the order?</p>
                      {paymentMode?.toLowerCase() === "cash" ? null : (
                        <p>
                          The order will be canceled and refund will be
                          transferred to your respective payment method.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="meanu-listing-mobile-footer checkout-footer">
            <div className="container">
              <div className="bg">
                <div
                  className="modal-bottom-actions"
                  onClick={() => {
                    dispatch(
                      cancelOrder(
                        {
                          order_id: orderInfo?.orders_id,
                        },
                        () => {
                          closeModal();
                          getSingleOrder();
                        }
                      )
                    );
                  }}
                >
                  <div className="place-order-btn-wrapper">
                    <button type="button" className="btn btn-filled">
                      Cancel Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default CancelOrderMobile;
