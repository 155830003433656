import React from 'react'
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from "react-places-autocomplete";


const defultFunction = () => null

const SearchPlaces = (props) => {
  
  const{
    address="",
		handleChange=defultFunction,
		handleSelect=defultFunction,
  } = props
  return(
    <PlacesAutocomplete
    value={address}
    onChange={handleChange}
    onPaste={()=>alert("call")}
    onSelect={handleSelect}
    shouldFetchSuggestions={address.length > 3}
    searchOptions={{
      componentRestrictions: { country: ["us"] },
      // types: ['city']
    }}
  >
    {props.children} 
  </PlacesAutocomplete>
  )

}
export default SearchPlaces