import React, { useState, useEffect } from "react";
import { useWindowResize } from "components/useWindowResize";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEditData, setGcardCartItems } from "redux-store";
import removeCartImg from "assets/images/remove-cart-icon.svg";

const GcCart = (props) => {
  const isMobileOnly = useWindowResize();
  const { cartItems = [], cartTotal } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const location = useLocation()

  const handleEdit = (item, index) => {

    if(location.pathname.includes("orderdetails")){
      return;
    }
    // if(!store.gc.gc_cart.cart_items){
    //   return
    // }
    
    dispatch(setEditData(item));

    switch (store.gc.gc_cart.restaurant_name) {
      case "/redeem-points-/EGIFT_CARD":
        history.push({
          pathname: "/redeem-points",
          search: `?edit_id=${index}&isEgift=${true}`,
        });
        break;
      case "/redeem-points-/GIFT_CARD":
        history.push({
          pathname: "/redeem-points",
          search: `?edit_id=${index}&isEgift=${false}`,
        });
        break;
      case "/buy-gift-card-/GIFT_CARD":
        history.push({
          pathname: "/buy-gift-card",
          search: `?edit_id=${index}&isEgift=${false}`,
        });
        break;
      case "/buy-gift-card-/EGIFT_CARD":
        history.push({
          pathname: "/buy-gift-card",
          search: `?edit_id=${index}&isEgift=${true}`,
        });
        break;
    }
  };
  const handleDelete = (item, index) => {
    const cartCopy = { ...store.gc.gc_cart };
    const cart_items = [...store.gc.gc_cart.cart_items];
    if (cart_items.length > 1) {
      cart_items.splice(index, 1);

     
      dispatch(
        setGcardCartItems({
          ...cartCopy,
          cart_items,
        })
      );
    } else {
     
      dispatch(setGcardCartItems({}));
    }
  };
 
  return (
    <>
      {isMobileOnly ? (
        <>
          <div className="order-details-wrapper">
            <div className="order-details-cards-wrapper ">
              <div className="row">
                <div className="col-12">
                  {cartItems.map((item, index) => {
                    const {
                      attributes = [],
                      products_name,
                      options = [],
                      customers_basket_quantity = 1,
                      
                    } = item;

                    const newArr = [];
                    options.map((item) => {
                      item.optionValues.map((item2, index) => {
                        newArr.push({ ...item, ...item2 });
                      });
                    });

                    const data = attributes.length ? attributes : newArr;

                    const arr = data.sort((a, b) => {
                      const x = a.options_id
                        ? a.options_id
                        : a.products_options_id;
                      const y = b.options_id
                        ? b.options_id
                        : b.products_options_id;
                      if (x < y) {
                        return -1;
                      }
                      if (x > y) {
                        return 1;
                      }
                      return 0;
                    });
                    return (
                      <>
                        <div className="order-details-card"
                          
                        >
                          <div className="d-flex justify-content-between" >
                            <div className="rightside" onClick={() => handleEdit(item, index)}>
                              {props.fromGcards?
                                <span className="item-count">
                                  {item.products_quantity}x
                                </span>:
                                <span className="item-count">
                                  {customers_basket_quantity}x
                                </span>
                            
                              }
                              
                              <div className="order-item-details">
                                <h5>{products_name}</h5>
                              </div>
                            </div>
                            <div className="order-price" >
                              <span>
                              {props.isRewards?<>
                              
                              {(+item.final_price?+item.final_price:item.total)*store.cart.taxdata.reward_points_conversion_value} pts.
                              <span className="dollar-value">
                              {` ($${props.fromGcards
                                ? parseFloat(+item.final_price).toFixed(2)
                                : parseFloat(+item.total).toFixed(2)})`}
                              </span>
                            </>
:
                                `$${props.fromGcards
                                  ? parseFloat(+item.final_price).toFixed(2)
                                  : parseFloat(+item.total).toFixed(2)}`}
                              </span>
                              {props.fromGcards?null:<div
                              className="remove-item"
                              // onClick={() => handleQuantity(item, index, "delete")}
                              onClick={() =>
                                handleDelete(item, index, "delete")
                              }
                            >
                                <button>
                                  <span className="icon pl-2">
                                    <img src={removeCartImg} alt="remove" />
                                  </span>
                                </button>
                              </div>}
                            </div>
                          </div>

                          <div className="order-item-details">
                            {arr.map((attr) => {
                              const {
                                options_values_id,
                                products_options_values_text,
                                options_id,
                                products_options_id = "",
                                products_options_values = "",
                              } = attr;
                              switch (
                                options_id ? options_id : products_options_id
                              ) {
                                case 118:
                                  return (
                                    <p>
                                      <span>Recipient's Email Address:{" "}</span>
                                      {products_options_values_text
                                        ? products_options_values_text
                                        : products_options_values}
                                    </p>
                                  );
                                case 119:
                                  return (
                                    <>
                                      <h5 className="mt-3">Email Subject</h5>
                                      <p>
                                        {products_options_values_text
                                          ? products_options_values_text
                                          : products_options_values}
                                        .
                                      </p>
                                    </>
                                  );
                                case 120:
                                  return (
                                    <>
                                      <h5 className="mt-3">Message</h5>
                                      <p>
                                        {products_options_values_text
                                          ? products_options_values_text
                                          : products_options_values}
                                        .
                                      </p>
                                    </>
                                  );
                                default:
                                  return null;
                              }
                            })}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
		  <div className="points-order-cart_lists">
			  <div
          className="order-details-cards-wrapper"
          style={props.fromGcards ? { marginBottom: "0px" } : {}}
        >
          <div className="row">
            <div className="col-12">
			      {cartItems.map((item, index) => {
                const {
                  attributes = [],
                  products_name,
                  options = [],
                  total,
                  customers_basket_quantity
                } = item;

                const newArr = [];
                options.map((item) => {
                  item.optionValues.map((item2, index) => {
                    newArr.push({ ...item, ...item2 });
                  });
                });

                const data = attributes.length ? attributes : newArr;

                const arr = data.sort((a, b) => {
                  const x = a.options_id ? a.options_id : a.products_options_id;
                  const y = b.options_id ? b.options_id : b.products_options_id;
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                });

                return (
                  <>
				  <div className="payment__page__wrapper points-order">
                      <div className="order-details-cards-wrapper">
                        <div className="order-details-card" role={location.pathname.includes("orderdetails")?"":"button"}>
                          <div
                            className="rightside"
                            onClick={() => handleEdit(item, index)}
                          >
                            {props.fromGcards?
                                <span className="item-count">
                                  {item.products_quantity}x
                                </span>:
                                <span className="item-count">
                                  {customers_basket_quantity}x
                                </span>
                            
                              }
                            <div className="order-item-details">
                              <h5>{products_name}</h5>
                              {arr.map((attr) => {
                                const {
                                  options_values_id,
                                  products_options_values_text,
                                  options_id,
                                  products_options_id = "",
                                  products_options_values = "",
                                } = attr;
                                switch (
                                  options_id ? options_id : products_options_id
                                ) {
                                  case 118:
                                    return (
                                      <>
                                      <h5 className="mt-3">Recipient's Email Address</h5>
                                      <p>
                                      
                                        {products_options_values_text
                                          ? products_options_values_text
                                          : products_options_values}
                                      </p>
                                      </>
                                    );
                                  case 119:
                                    return (
                                      <>
                                        <h5 className="mt-3">Email Subject</h5>
                                        <p>
                                          {products_options_values_text
                                            ? products_options_values_text
                                            : products_options_values}
                                          .
                                        </p>
                                      </>
                                    );
                                  case 120:
                                    return (
                                      <>
                                        <h5 className="mt-3">Message</h5>
                                        <p>
                                          {products_options_values_text
                                            ? products_options_values_text
                                            : products_options_values}
                                          .
                                        </p>
                                      </>
                                    );
                                  default:
                                    return null;
                                }
                              })}
                            </div>
                          </div>
                          <div className="order-price">
                            <span>
                            {props.isRewards?<>
                              
                              {(+item.final_price?+item.final_price:item.total)*store.cart.taxdata.reward_points_conversion_value} pts.
                              <span className="dollar-value">
                              {` ($${props.fromGcards
                                ? parseFloat(+item.final_price).toFixed(2)
                                : parseFloat(+item.total).toFixed(2)})`}
                              </span>
                            </>
:
                                `$${props.fromGcards
                                  ? parseFloat(+item.final_price).toFixed(2)
                                  : parseFloat(+item.total).toFixed(2)}`}
                            </span>
                            {props.fromGcards?null:<div
                              className="remove-item"
                              // onClick={() => handleQuantity(item, index, "delete")}
                              onClick={() =>
                                handleDelete(item, index, "delete")
                              }
                            >
                              <button>
                                <span className="icon pl-2">
                                  <img src={removeCartImg} alt="remove" />
                                </span>
                              </button>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </>
                );
              })}
            </div>
          </div>
        </div>
		  </div>
        
      )}
    </>
  );
};

export default GcCart;
