import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import { useWindowResize } from "./useWindowResize";

import { Modal } from "react-bootstrap";
import $ from "jquery";
const ModalWrapper = ({ children, ...props }) => {
  const isMobileOnly = useWindowResize()
  let history = useHistory();
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      {isMobileOnly ? (
        <main className="mobile-inner-screen">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="go-back-wrapper">
                  <a className="go-back-btn" onClick={props.handleBack}>
                    <img src={backArrowMobileImg} alt="go back" />
                  </a>
                  <h2>{props.headerText}</h2>
                </div>
              </div>
            </div>
            <div className="main-wrapper">{children}</div>
          </div>
        </main>
      ) : (
        <Modal
          className="modal fade show"
          id="profileModal"
          {...props}
          // size="lg"
          // aria-labelledby="congrats-modalLabel"
          centered
          show={showModal}
          onHide={() => {
            // $('#profileModal').modal('toggle')
            history.goBack();
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profileModalLabel">
                {props.headerText}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss={props.isModal ? "modal" : "modal"}
                aria-label="Close"
                // onClick={props.custom ? () => null : props.handleBack}

                onClick={() => history.goBack()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </Modal>
        // <div
        //   className="modal fade show"
        //   id="profileModal"
        //   tabindex="-1"
        //   aria-labelledby="profileModalLabel"
        //   aria-hidden="true"
        //   // data-backdrop="static"
        //   // data-keyboard="false"
        //   ref={wrapperRef}
        //   style={{ paddingRight: "0px", display:"block" }}
        // >
        //   <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
        //     <div className="modal-content">
        //       <div className="modal-header">
        //         <h5 className="modal-title" id="profileModalLabel">
        //           {props.headerText}
        //         </h5>
        //         <button
        //           type="button"
        //           className="close"
        //           data-dismiss={props.isModal ? "modal" : "modal"}
        //           aria-label="Close"
        //           onClick={props.custom ? () => null : props.handleBack}

        //           // onClick={()=>history.goBack()}
        //         >
        //           <span aria-hidden="true">&times;</span>
        //         </button>
        //       </div>
        //       <div className="modal-body">{children}</div>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default ModalWrapper;
