import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import driverImg from "assets/images/driver.svg";
import addressImg from "assets/images/icon-address.svg";

const googleMapsApiKey = "AIzaSyCmrodTAFNeH1cWfFPJLxiRlRUsCf7f3XE";

const MapDirectionsRenderer = (props) => {
  const { places, travelMode, getDeliveryTime } = props;
  const [error, setError] = useState(null);
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    if (places) {
      const waypoints = places.map((p) => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: false,
      }));
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints,
        },
        (result, status) => {
          const time = result.routes[0].legs[0].duration;
          getDeliveryTime(time);
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            setError(result);
          }
        }
      );
    }
  }, [places]);

  return (
    <>
      {directions && (
        <DirectionsRenderer
          options={{
            directions: directions,
            polylineOptions: {
              clickable: false,
              geodesic: false,
              strokeColor: "#f0b76c",
              strokeOpacity: 1.0,
              strokeWeight: 5,
            },
            draggable: false,
            suppressMarkers: true,
            suppressPolylines: false,
            suppressInfoWindows: true,
            suppressBicyclingLayer: true,
          }}
        />
      )}
    </>
  );
};

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <>
        <GoogleMap
          options={{
            backgroundColor: "#fff",
            center: props.defaultCenter,
            clickableIcons: false,
            disableDefaultUI: false,
            fullscreenControl: false,
            gestureHandling: "none",
            keyboardShortcuts: false,
            mapTypeControl: false,
            minZoom: 1,
            maxZoom: 18,
            noClear: false,
            panControl: false,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: [
              {
                featureType: "poi",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
            ],
            zoom: props.defaultZoom,
            zoomControl: false,
          }}
        >
          {props.markers.map((marker, index) => {
            const position = {
              lat: marker.latitude,
              lng: marker.longitude,
            };
            return (
              <Marker
                key={index}
                cursor={"default"}
                clickable={false}
                icon={marker.icon}
                position={position}
                crossOnDrag={false}
                draggable={false}
                visible={true}
              />
            );
          })}
          {props.markers && props.markers.length > 1 && (
            <MapDirectionsRenderer
              places={props.markers}
              travelMode={window.google.maps.TravelMode.DRIVING}
              getDeliveryTime={props.deliveryTime}
            />
          )}
        </GoogleMap>
      </>
    );
  })
);

const DriverMap = (props) => {
  const {
    driverPosition,
    destinationPosition,
    deliveryTimeHandler,
    height = "50vh",
  } = props;

  const [markers, setMarkers] = useState([
    {
      ...destinationPosition,
      icon: addressImg,
    },
  ]);

  useEffect(() => {
    console.log(markers);
  }, [markers]);

  useEffect(() => {
    if (!driverPosition) {
      let post = [].concat([
        {
          ...destinationPosition,
          icon: addressImg,
        },
      ]);
      setMarkers(post);
      deliveryTimeHandler(null);
    } else {
      let post = [].concat([
        {
          ...driverPosition,
          icon: driverImg,
        },
        {
          ...destinationPosition,
          icon: addressImg,
        },
      ]);
      setMarkers(post);
    }
  }, [driverPosition]);

  return (
    <>
      {markers && markers.length && (
        <Map
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=" +
            googleMapsApiKey +
            "&libraries=geometry,drawing,places&v=3.47"
          }
          loadingElement={<div style={{ height: height, width: "100%" }}></div>}
          containerElement={
            <div
              style={{
                height: height,
                width: "100%",
              }}
            ></div>
          }
          markers={markers}
          mapElement={<div style={{ height: height, width: "100%" }} />}
          defaultCenter={{
            lat: markers[0].latitude,
            lng: markers[0].longitude,
          }}
          defaultZoom={10}
          deliveryTime={deliveryTimeHandler}
        />
      )}
    </>
  );
};

export default DriverMap;
