import React from "react";
import Modal from "react-bootstrap/Modal";
import backIcon from "assets/images/back-arrow-mobile.svg";
import RowAccordian from "../RowAccordian";

const MobileGroupCart = (props) => {
  const {
    show,
    isMobileCart,
    closeModal,
    groupOrderInfo,
    groupBasket,
    isItAdminLink,
    isLinkOpenedByAdmin,
    amountCalc,
    isUserRemoved = false,
    isOrderCancelled = false,
    finalAmount,
    userCart,
    onSaveOrder,
    onEditOrder,
    onCancelOrder,
    onCheckout,
  } = props;

  return (
    <>
      <Modal
        id="add-balance"
        className="deliveryAddressModal modal-min-width"
        show={show}
        onHide={closeModal}
        keyboard={false}
        fullscreen
        centered
        dialogClassName="modal-min-width"
      >
        <main class="mobile-inner-screen">
          <div class="payment__page__wrapper">
            <div class="go-back-wrapper pl-3">
              <a href class="go-back-btn" onClick={closeModal}>
                <img src={backIcon} alt="go back" />
              </a>
              <h2>
                {groupOrderInfo?.title ? groupOrderInfo?.title + " - " : ""}
                {groupOrderInfo?.restaurant_name}
              </h2>
            </div>
            <div class="main-wrapper">
              <div className="container">{props.children}</div>
              {isUserRemoved ? <div class="overlay-disabled"></div> : null}
              {isOrderCancelled ? <div class="overlay-disabled"></div> : null}

              <section
                class="mobile-inner-section"
                style={{ overflowY: "scroll", background: "#fff" }}
              >
                <div class="main">
                  <div class="container">
                    <div className="gp-order-cart-pwa">
                      {groupBasket.map((res, idx) => (
                        <RowAccordian
                          {...props}
                          member={res}
                          key={idx}
                          onMbMenuListingPage
                          isItUserCart={!isItAdminLink && isLinkOpenedByAdmin}
                          isLinkOpenedByAdmin={isLinkOpenedByAdmin}
                          isItAdminLink={isItAdminLink}
                          amountCalc={amountCalc}
                          isUserRemoved={isUserRemoved}
                          isOrderCancelled={isOrderCancelled}
                          isItMobileCart={isMobileCart}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </section>
              <section class="meanu-listing-mobile-footer checkout-footer">
                <div class="container">
                  <div class="bg">
                    {isItAdminLink ? (
                      <>
                        <div class="subtotal-wrapper">
                          <span>Subtotal</span>
                          <span>
                            {finalAmount ? "$" + finalAmount.toFixed(2) : ""}
                          </span>
                        </div>
                      </>
                    ) : null}
                    <div class="modal-bottom-actions">
                      <div class="update-order-btn-wrapper">
                        {isItAdminLink ? (
                          <>
                            <div className="row">
                              <div className="col-12 d-flex justify-content-between">
                                <button
                                  type="button"
                                  onClick={onCheckout}
                                  class="btn btn-outline p-3 m-0"
                                >
                                  Go to Checkout
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-filled p-3"
                                  onClick={closeModal}
                                >
                                  Add Item
                                </button>
                              </div>
                              <div className="col-12 text-center mt-4 pt-2">
                                <a
                                  href
                                  className="cancel_text_btn"
                                  onClick={onCancelOrder}
                                >
                                  Cancel Order
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {userCart ? (
                              <>
                                {userCart?.cart_status === 0 ? (
                                  <button
                                    type="button"
                                    class="btn btn-filled p-3 w-100 justify-content-between d-flex"
                                    onClick={onSaveOrder}
                                  >
                                    <span>Save Order</span>
                                    <span>
                                      {finalAmount
                                        ? "$" + finalAmount.toFixed(2)
                                        : ""}
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-filled p-3 w-100 justify-content-between d-flex"
                                    onClick={onEditOrder}
                                  >
                                    <span>Edit Order</span>
                                    <span>
                                      {finalAmount
                                        ? "$" + finalAmount.toFixed(2)
                                        : ""}
                                    </span>
                                  </button>
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </Modal>
    </>
  );
};

export default MobileGroupCart;
