
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useWindowResize } from "../../components/useWindowResize";

export default function useOnScreen(ref) {
  const isMobileOnly = useWindowResize()
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),{threshold: 0.9}
  )

  useEffect(() => {
    
    if(ref && !isMobileOnly){
      // console.log("type of", typeof ref, ref)
      observer.observe(ref)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }
    
  }, [ref])

  return isIntersecting
}