import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useWindowResize } from "components/useWindowResize";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import GoBackHeader from "components/GoBackHeader";
import dropin from "braintree-web-drop-in";
import { useSelector, useDispatch } from "react-redux";
import BrainTreeShow from "components/BrainTreeShow";
import { toast } from "react-toastify";
import { addFundBraintree } from "redux-store";

const AddFund = (props) => {
	const store = useSelector((store) => store);
	const dispatch = useDispatch();
	const isMobileOnly = useWindowResize();
	const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(true);
	const [braintreeInstance, setBraintreeInstance] = useState(undefined);
	const [amount, setAmount] = useState(25);
	const [customAmount, setCustomAmount] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [newBalance, setNewBalance] = useState(false);
	const [reRender, setReRender] = useState("");
	useEffect(() => {
		let nb = parseFloat(store.cart.wallet_amount.amount).toFixed(2);

		if (amount != "other") {
			nb = +amount + +nb;
		} else {
			if (isValid && customAmount) {
				nb = +customAmount + +nb;
			} else {
				nb = false;
				setIsButtonDisabled(true);
			}
		}

		setNewBalance(nb);
		if (braintreeInstance) {
			if (nb && braintreeInstance.isPaymentMethodRequestable()) {
				setIsButtonDisabled(false);
			} else {
				setIsButtonDisabled(true);
			}
		}
	}, [customAmount, amount, isValid, reRender]);
	const handleCustomOnChange = (e) => {
		const x = e.target.value.replace(/[^0-9.]/g, "");
		if (x >= 10 && x <= 1000 && /^\d+(\.\d{1,2})?$/.test(x)) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
		setCustomAmount(x);
	};

	const handleAddFoodDudeBalance = () => {
		if (amount == "other") {
			if (
				+customAmount >= 10 &&
				+customAmount <= 1000 &&
				/^\d+(\.\d{1,2})?$/.test(+customAmount)
			) {
				braintreeInstance.requestPaymentMethod((error, payload) => {
					if (error) {
						console.error(error);
					} else {
						const paymentMethodNonce = payload.nonce;

						dispatch(
							addFundBraintree(
								{
									amount: +customAmount,
									payment_method_nonce: paymentMethodNonce,
								},
								props.onSuccess,
							),
						);

						// TODO: use the paymentMethodNonce to
						//  call you server and complete the payment here

						// ...
						// onPaymentCompleted();
					}
				});
			} else {
				toast.error("please choos a valid amount between $10 to $1000");
			}
		} else {
			braintreeInstance.requestPaymentMethod((error, payload) => {
				if (error) {
					console.error(error);
				} else {
					const paymentMethodNonce = payload.nonce;

					dispatch(
						addFundBraintree(
							{
								amount: amount,
								payment_method_nonce: paymentMethodNonce,
							},
							props.onSuccess,
						),
					);

					// TODO: use the paymentMethodNonce to
					//  call you server and complete the payment here

					// ...
					// onPaymentCompleted();
				}
			});
		}
	};

	useEffect(() => {
		if (store.cart.clientToken) {
			const initializeBraintree = () =>
				dropin.create(
					{
						// authorization: "sandbox_fwhv76cc_xht69cj4j5d2j4wn", // insert your tokenization key or client token here
						authorization: store.cart.clientToken,
						vaultManager: true,
						// options:{
						//   card: {
						//     vaultManager:true
						//   }
						// },
						container: "#braintree-drop-in-div1",
						// showDefaultPaymentMethodFirst:true,
						preselectVaultedPaymentMethod: false,
						paypal: {
							flow: "vault",
							singleUse: true,
							// flow: "checkout",
							// singleUse: true,
							// amount:"100.00",
							// currency: "USD"
						},

						// applePay: {
						//   displayName: 'Merchant Name',
						//   paymentRequest: {
						//   label: 'Localized Name',
						//     total: '10.00'
						//   }
						// },

						venmo: {
							allowNewBrowserTab: false,
						},
						googlePay: {
							googlePayVersion: 2,
							merchantId: "merchant-id-from-google",
							transactionInfo: {
								totalPriceStatus: "FINAL",
								totalPrice: "16.50",
								currencyCode: "USD",
							},
							allowedPaymentMethods: [
								{
									type: "CARD",
									parameters: {
										// We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
										billingAddressRequired: true,
										billingAddressParameters: {
											format: "FULL",
										},
									},
								},
							],
						},
						// applePay: {
						// 	displayName: 'Food Dudes',
						// 	paymentRequest: {
						// 		total: {
						// 		label: 'Food Dudes',
						// 		amount: parseFloat(
						// 							store.cart.placeOrderCharges.total
						// 						).toFixed(2)
						// 		},
						// 	}
						// }
					},
					function (error, instance) {
						if (error) console.error("error of braintree", error);
						else {
							setBraintreeInstance(instance);
						}
					},
				);

			if (braintreeInstance) {
				braintreeInstance.teardown().then(() => {
					initializeBraintree();
				});
			} else {
				initializeBraintree();
			}
		}
	}, []);

	useEffect(() => {
		if (braintreeInstance) {
			braintreeInstance.on("paymentMethodRequestable", function (event) {
				var classname = document.getElementsByClassName("braintree-method");
				var unselectBTMethod = function () {
					if (this.className.match(/\bbraintree-method--active\b/)) {
						braintreeInstance.clearSelectedPaymentMethod();
					} else {
					}
				};
				for (var i = 0; i < classname.length; i++) {
					classname[i].addEventListener("click", unselectBTMethod, false);
				}
				setReRender(Math.random());
			});
			braintreeInstance.on("noPaymentMethodRequestable", function (event) {
				setReRender(Math.random());
			});
		}
	}, [braintreeInstance]);

	return (
		<>
			{isMobileOnly ? (
				<Modal {...props}>
					<main class="mobile-inner-screen addFund-mobile add-fund-fd">
						<div class="payment__page__wrapper">
							<GoBackHeader
								title="Add Money to Food Dudes Balance"
								handleBackArrow={props.onHide}
							/>

							<div class="main-wrapper ">
								<section class="mobile-inner-section">
									<div class="main">
										<div class="container">
											<div class="row">
												<div
													class="col-12 mb-4 payment__page__wrapper"
													style={{ paddingBottom: "100px" }}
												>
													<div class="add-fund-wrapper ">
														<div class="balance-slab">
															<div
																class="btn-group .btn-group-vertical"
																data-toggle="buttons"
															>
																{[25, 50, 75, 100].map((value) => (
																	<label
																		class={`btn btn-secondary ${
																			value == amount ? "active" : ""
																		}`}
																		onClick={() => setAmount(value)}
																	>
																		<input
																			type="radio"
																			name="options"
																			id="amt-1"
																			checked={value == amount ? true : false}
																		/>
																		${value}
																	</label>
																))}

																<label
																	class={`btn btn-secondary ${
																		"other" == amount ? "active" : ""
																	}`}
																	onClick={() => setAmount("other")}
																>
																	<input
																		type="radio"
																		name="options"
																		id="amt-othr"
																	/>{" "}
																	Other
																</label>
															</div>
															{amount == "other" ? (
																<div class="otherway-payment-payments w-100">
																	<div class="card-details-wrapper">
																		<input
																			type="text"
																			class="form-control w-100"
																			placeholder="$"
																			style={
																				isValid
																					? {}
																					: { borderColor: "rgb(220, 53, 69)" }
																			}
																			value={customAmount}
																			onChange={handleCustomOnChange}
																		/>
																	</div>
																</div>
															) : null}
															<p class="text-center ">
																{newBalance ? (
																	<span className="fs-24">
																		Your new balance will be $
																		{parseFloat(newBalance).toFixed(2)}
																	</span>
																) : (
																	<span className="text-danger">
																		Please enter an amount in between $10 to
																		$1000
																	</span>
																)}
															</p>
														</div>
													</div>

													<hr />
													<div class="row">
														<div class="col-12">
															<div class="section_heading mb-3">
																<h2>Payment</h2>
															</div>
															<div class="payment-cards saved-cards mt-2">
																<div className="otherway-payment-payments">
																	<div id={"braintree-drop-in-div1"} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
								<section class="meanu-listing-mobile-footer">
									<div class="container">
										<div class="bg">
											<div class="modal-bottom-actions align-items-start flex-column">
												<button
													type="submit"
													class="btn btn-theme-outlined  mb-3 w-100"
													data-dismiss="modal"
													onClick={props.onHide}
												>
													Cancel
												</button>
												<button
													type="button"
													class="btn  w-100 btn-filled w-100"
													onClick={handleAddFoodDudeBalance}
													disabled={isButtonDisabled}
												>
													Add Food Dudes Balance
												</button>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</main>

					{/* <Modal.Header >
			
			</Modal.Header>
			<Modal.Body>Modal body content</Modal.Body> */}
				</Modal>
			) : (
				<Modal
					{...props}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					className="deliveryAddressModal modal-min-width"
					centered
				>
					<Modal.Header className="modal-header page_main_heading">
						<Modal.Title id="driverTipModalLabel" className="modal-title">
							<h1>Add Money to Food Dudes Balance</h1>
						</Modal.Title>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={props.onHide}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</Modal.Header>
					<Modal.Body>
						<div class="devilery-address-modal-wrapper">
							<div lass="devilery-address-modal-wrapper-inner">
								<form action="" class="row">
									<div class="col-12 mb-4 payment__page__wrapper">
										<div class="add-fund-wrapper justify-content-center">
											{/* <div class="amount">{"      "}</div> */}
											<div class="balance-slab">
												<div class="btn-group .btn-group-vertical">
													{[25, 50, 75, 100].map((value) => (
														<label
															class={`btn btn-secondary ${
																value == amount ? "active" : ""
															}`}
															onClick={() => setAmount(value)}
														>
															<input
																type="radio"
																name="options"
																id="amt-1"
																checked={value == amount ? true : false}
															/>
															${value}
														</label>
													))}
													<label
														class={`btn btn-secondary ${
															amount == "other" ? "active" : ""
														}`}
														onClick={() => setAmount("other")}
													>
														<input type="radio" name="options" id="amt-othr" />{" "}
														Other
													</label>
													{amount == "other" ? (
														<div
															class="card-details-wrapper"
															style={{
																marginLeft: "20px",
																maxWidth: "100px",
															}}
														>
															<input
																type="text"
																class="form-control font-weight-bold w-100"
																placeholder="$"
																value={customAmount}
																style={
																	isValid
																		? {}
																		: { borderColor: "rgb(220, 53, 69)" }
																}
																onChange={handleCustomOnChange}
															/>
														</div>
													) : null}
												</div>
												{newBalance ? (
													<p
														class="text-center font-weight-bold "
														style={!isMobileOnly ? { fontSize: "24px" } : {}}
													>
														Your new balance will be $
														{parseFloat(newBalance).toFixed(2)}
													</p>
												) : (
													<p className="text-center font-weight-bold text-danger">
														Please enter an amount in between $10 to $1000
													</p>
												)}
											</div>
										</div>

										<div class="row">
											<div class="col-12">
												<div class="section_heading mb-3">
													<h2>Payment</h2>
												</div>
												<div class="payment-cards saved-cards mt-2">
													<div className="otherway-payment-payments">
														<div id={"braintree-drop-in-div1"} />
													</div>
												</div>
											</div>
										</div>

										<div class="row"></div>
									</div>
								</form>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div class="col-12 mb-1">
							<div class="modal-bottom-actions modal-btn-lg d-flex justify-content-center w-100">
								<button
									type="button"
									class="btn btn-outline  mr-3 "
									onClick={props.onHide}
								>
									{" "}
									Cancel
								</button>
								<button
									type="button"
									class="btn btn-filled "
									onClick={handleAddFoodDudeBalance}
									disabled={isButtonDisabled}
								>
									Add Food Dudes Balance
								</button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default AddFund;
