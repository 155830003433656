import GcCart from "components/GcCart";
import GoBackHeader from "components/GoBackHeader";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import  { useSelector } from "reac"

const MobileViewGc = (props) => {
	const history = useHistory();
	const [priceObj, setPriceObj] = useState({});
	const {
		orderInfo,
		products,
		dispatch,
		handleGoBack,
		orderStatus,
		calcArray,
		store
	} = props;

	useEffect(() => {
		const obj = {};
		calcArray.map((item) => {
			obj[item.title] = item;
		});
		setPriceObj(obj);
	}, [calcArray]);

	return (
		<main class="mobile-inner-screen">
			<div class="payment__page__wrapper orderDetails__page__wrapper gc-order-page">
				<GoBackHeader
					title="Order Details"
					// handleBackArrow={() => history.goBack()}
				
					handleBackArrow={props.handleGoBack}
				/>
				<div class="main-wrapper pb-0">
					<section class="mobile-inner-section">
						<div class="main">
							<div class="container">
								<div class="order-details-stepper">
									<div class="row">
										<div class="col-xs-12 col-md-12">
											<div class="order-status">
												<span>
													{(orderStatus && orderStatus.orders_status_id == 1) ||
													(orderStatus && orderStatus.orders_status_id == 14)
														? "Pending"
														: orderStatus && orderStatus.orders_status_id == 10
														? "Completed"
														: ""}
												</span>
											</div>
										</div>
										<div class="col-xs-12 col-md-12 mb-0 pb-2">
											<div class="wrapper-progressBar">
												<ul class="progressBar progressBar-single">
													<li
														className={
															(orderStatus &&
																orderStatus.orders_status_id == 1) ||
															(orderStatus &&
																orderStatus.orders_status_id == 14) ||
															(orderStatus &&
																orderStatus.orders_status_id == 10)
																? orderStatus &&
																  orderStatus.orders_status_id == 10
																	? "active "
																	: "active pending "
																: "active pending"
														}
													></li>
													<li
														className={
															orderStatus && orderStatus.orders_status_id == 10
																? "active"
																: ""
														}
													></li>
												</ul>
											</div>
										</div>
									</div>
									<div class="order-info">
										<div class="row">
											<div class="col-12">
												<div class="order-detail">
													<div class="row">
														<div class="col-5">
															<span>Order Number</span>
														</div>
														<div class="col-7">
															<span className="font-weight-bold">
																{orderInfo &&
																	orderInfo[0] &&
																	orderInfo[0].orders_id}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12">
												<div class="order-detail">
													<div class="row">
														<div class="col-5">
															<span>Gift Card</span>
														</div>
														<div class="col-7">
															<span className="font-weight-bold">
																{orderInfo &&
																	orderInfo[0] &&
																	orderInfo[0].restaurants_name}
															</span>
														</div>
													</div>
												</div>
											</div>
											{orderInfo &&
											orderInfo[0] &&
											orderInfo[0].shipping_method == "Delivery" ? (
												<div className="col-12">
													<div className="order-detail">
														<div className="row">
															<div className="col-5">
																<span>Delivery Address</span>
															</div>
															<div className="col-7">
																<span className="font-weight-bold">
																	{orderInfo[0].customers_street_address}
																</span>
															</div>
														</div>
													</div>
												</div>
											) : null}
										</div>
									</div>
									<hr />
								</div>
								<div class="row">
									<div class="col-12 mt-0 mb-2">
										<div class="section_heading mb-0">
											<h2>
												Order Details ({products && products.length}{" "}
												{products && products.length < 2 ? "Items" : "Items"})
											</h2>
										</div>
									</div>
								</div>

								<GcCart
									fromGcards
									cartItems={products}
									cartTotal={
										priceObj["Total"]
											? parseFloat(Number(priceObj["Total"].value)).toFixed(2)
											: 0
									}
									isRewards={
										orderInfo &&
										orderInfo[0] &&
										orderInfo[0].payment_method == "Reward Points"
											? true
											: false
									}
								/>
							</div>
							<div class="sidenav_right">
								<div class="inner-wrapper">
									<div class="calculation-details">
										<div class="final-amount">
											<div class="row">
												<div class="col-12">
													<div class="d-flex justify-content-between">
														<span>Total</span>
														<span>
															{orderInfo &&
															orderInfo[0] &&
															orderInfo[0].payment_method == "Reward Points" ? (
																<span>
																	{priceObj["Subtotal"] &&
																	priceObj["Subtotal"].value
																		? `${
																				parseFloat(
																					Number(priceObj["Subtotal"].value),
																				).toFixed(2) * store.cart.taxdata.reward_points_conversion_value
																		  }pts. ($${
																				priceObj["Subtotal"]
																					? parseFloat(
																							Number(
																								priceObj["Subtotal"].value,
																							),
																					  ).toFixed(2)
																					: 0
																		  })`
																		: null}
																</span>
															) : (
																<span>
																	$
																	{priceObj["Subtotal"]
																		? parseFloat(
																				Number(priceObj["Subtotal"].value),
																		  ).toFixed(2)
																		: 0}
																</span>
															)}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="billing__details">
											<div class="paid-by">
												<div class="billing__data">
													<span>Paid By</span>
													<span>
														{orderInfo &&
															orderInfo[0] &&
															orderInfo[0].payment_method}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>
	);
};

export default MobileViewGc;
