import React, { useState, useEffect } from "react";

import backArrowImg from "assets/images/back-arrow-mobile.svg";
import { useWindowResize } from "../../components/useWindowResize";

const DriverTip = (props) => {
	const isMobileOnly = useWindowResize();

	const handleSave = () => {
		if (
			props.customTipAmount > 0 &&
			/^[0-9]{1,4}(\.[0-9]{0,2})?$/.test(props.customTipAmount)
		) {
			props.setDriverTipType(drverTipTypeTwo);
			props.setDriverTip(props.customTipAmount);
			props.setIsOpen({ status: false, type: "" });
		} else {
			setIsValid(false);
		}
	};
	const [isValid, setIsValid] = useState(true);
	const [drverTipTypeTwo, setDriverTipTypeTwo] = useState("");
	function valid(e) {
		let f = e.target.value;
		if (!f) {
			// Test if text field is empty
			// f = 0; // Initialize value to 0
			// console.log("1  if", props.customTipAmount); // Let user know input is OK
			setIsValid(false);
			// setTemporaryTip("");
			// props.setCustomTipAmount("");
		} else if (!/^[0-9]{1,4}(\.[0-9]{0,2})?$/.test(f)) {
			// Test regex
			// f = ""; // Empty the text box
			console.error("1  else if", f); // Let user know input is not OK
			setIsValid(false);
		} else {
			setIsValid(true);
			props.setCustomTipAmount(Number(f));
		}
	}

	const [temporaryTip, setTemporaryTip] = useState("");
	const { store, drverTipType, cartStore } = props;
	useEffect(() => {
		if (props.customTipAmount) {
			setIsValid(true);
			if (!/^[0-9]{1,4}(\.[0-9]{0,2})?$/.test(props.customTipAmount)) {
				setIsValid(false);
				return;
			}

			const {
				cartTotal,
				restaurant_delivery_fee,
				service_fee,
				tax,
				min_order,
			} = cartStore.cart;
			const {
				state_tax_rate,
				local_tax_rate,
				low_order_min_amount,
				low_order_fee,
			} = cartStore.taxdata;

			const calc =
				Number(cartTotal) +
				(store.auth.headerTab == "Delivery"
					? Number(restaurant_delivery_fee)
					: 0) +
				(Number(cartTotal) > Number(low_order_min_amount)
					? 0
					: Number(low_order_fee)) +
				Number(service_fee);

			let state_tax = (calc * state_tax_rate) / 100;
			let local_tax = (calc * local_tax_rate) / 100;
			let sales_tax = state_tax + local_tax;

			let tip = 0;
			if (drverTipTypeTwo) {
				if (store.auth.headerTab == "Delivery") {
					tip = props.customTipAmount;
				}
			} else {
				if (store.auth.headerTab == "Delivery") {
					let dtCalc =
						Number(cartTotal) +
						restaurant_delivery_fee +
						Number(service_fee) +
						(Number(cartTotal) > Number(low_order_min_amount)
							? 0
							: Number(low_order_fee)) +
						sales_tax;

					tip = (props.customTipAmount * dtCalc) / 100;
				}
			}
			setTemporaryTip(tip);
		}
	}, [props.customTipAmount, drverTipTypeTwo]);

	useEffect(() => {
		setTemporaryTip(0);
	}, []);

	useEffect(() => {
		setDriverTipTypeTwo(props.drverTipType);
	}, [props.drverTipType]);
	const handleKeyPress = (e) => {
		if (e.key == "Enter") {
			e.preventDefault();
			handleSave();
		} else {
			return;
		}
	};

	return (
		<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div className="modal-content">
				{isMobileOnly ? (
					<div className="go-back-wrapper">
						<a
							href
							className="go-back-btn"
							onClick={() => props.setIsOpen({ status: false, type: "" })}
						>
							<img src={backArrowImg} alt="go back" />
						</a>
						<h2 style={{ paddingTop: "4px", fontSize: "18px" }}>Driver Tip</h2>
					</div>
				) : (
					<div className="modal-header page_main_heading">
						<h1 className="modal-title" id="driverTipModalLabel">
							Driver Tip
							{temporaryTip && props.customTipAmount ? (
								<span className="driver-tip-amount">
									$ {parseFloat(temporaryTip).toFixed(2)}
								</span>
							) : null}
						</h1>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => props.setIsOpen({ status: false, type: "" })}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div
					className="modal-body"
					style={
						isMobileOnly
							? {
									padding: "16px",
									paddingTop: "66px",
									background: "white",
							  }
							: {}
					}
				>
					<div className="devilery-address-modal-wrapper">
						<div lass="devilery-address-modal-wrapper-inner">
							<form
								action=""
								className="row"
								// style={isMobileOnly ? { marginTop: temporaryTip ? "44px" :"56px" } : {}}
							>
								{isMobileOnly ? (
									<div
										className="col-12 col-md-12 mb-3 text-right"
										style={{ minHeight: "35px" }}
									>
										<span className="driver-tip-amount">
											{temporaryTip && props.customTipAmount
												? `$ ${parseFloat(temporaryTip).toFixed(2)}`
												: ""}
										</span>
									</div>
								) : null}
								<div className="col-12 mb-4">
									<div className="delivery__options__tabs">
										<div
											className="btn-group btn-group-toggle w-100"
											data-toggle="buttons"
										>
											<label
												className={`btn btn-secondary w-50 ${
													drverTipTypeTwo ? "active" : ""
												} `}
												onClick={() => setDriverTipTypeTwo(true)}
											>
												<input
													type="radio"
													name="options"
													id="inCurrency"
													checked={drverTipTypeTwo ? true : false}
												/>
												$
											</label>
											<label
												className={`btn btn-secondary w-50 ${
													drverTipTypeTwo ? "" : "active"
												} `}
												onClick={() => setDriverTipTypeTwo(false)}
											>
												<input
													type="radio"
													name="options"
													id="inPercentage"
													checked={drverTipTypeTwo ? false : true}
												/>
												%
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-12 mb-4">
									<input
										className="form-control text-center driver-tip-ipf"
										name="tipvalue"
										onKeyPress={handleKeyPress}
										placeholder="Tip amount"
										value={props.customTipAmount}
										// onChange={valid}
										id="rate"
										presicion={2}
										onChange={(e) => {
											props.setCustomTipAmount(e.target.value);
											// props.setCustomTipAmount($('#rate').val());
										}}
										style={isValid ? {} : { borderColor: "red" }}
									/>
								</div>
								{!isMobileOnly && (
									<div className="col-12 mb-5">
										<div
											className="modal-bottom-actions"
											// onClick={isValid ? handleSave : () => null}
											onClick={handleSave}
										>
											<button type="button" className="btn btn-filled w-100">
												Save
											</button>
										</div>
									</div>
								)}
							</form>
						</div>
						{isMobileOnly && (
							<div className="meanu-listing-mobile-footer checkout-footer">
								<div className="container">
									<div className="bg">
										<div className="modal-bottom-actions">
											<div className="place-order-btn-wrapper">
												<button
													type="button"
													className="btn btn-filled"
													onClick={handleSave}
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default DriverTip;
