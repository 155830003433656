import {
  ADD_TO_CART,
  APPLY_COUPAN,
  GIFT_CARD,
  CLEAN_GIFT_CARDS,
  WALLET,
  PLACE_ORDER_CALC,
  PLACE_ORDER_INSTRUCTION,
  PLACE_ORDER_INSTRUCTION_GROUP,
  PAYMENT_SETTINGS,
  CLIENT_TOKEN,
  COD,
  WALLET_PAY,
  BRAINTREE_PAY,
  ORDERSLIST,
  ADD_FUNDS,
  DISABLE_CART_ITEMS,
  SET_GROUP_CART_ITEMS,
  SET_GROUP_CART_PAYMENT_SUMMARY,
} from "./cartType";
import {
  setSelectedRest,
  setResturantId,
  selectedAddress,
  getItemAddon,
} from "../index";
import { toast } from "react-toastify";
import { setLoader } from "../Auth/authAction";
import { APIUtils, ApiNames } from "../../service";
import moment from "moment";

export const setCartItems = (payload) => {
  return {
    type: ADD_TO_CART,
    payload: payload,
  };
};

export const setGiftCard = (payload) => {
  return {
    type: GIFT_CARD,
    payload: payload,
  };
};

export const removeGiftCards = () => {
  return {
    type: CLEAN_GIFT_CARDS,
    payload: {},
  };
};

export const setCoupan = (payload) => {
  return {
    type: APPLY_COUPAN,
    payload: payload,
  };
};
export const setWallet = (payload) => {
  return {
    type: WALLET,
    payload: payload,
  };
};

export const updateWallet = (payload) => {
  return {
    type: ADD_FUNDS,
    payload: payload,
  };
};

export const setPlaceOrderCal = (payload) => {
  return {
    type: PLACE_ORDER_CALC,
    payload: payload,
  };
};
export const setPlaceOrderInst = (payload) => {
  return {
    type: PLACE_ORDER_INSTRUCTION,
    payload: payload,
  };
};
export const setPlaceOrderInstGroup = (payload) => {
  return {
    type: PLACE_ORDER_INSTRUCTION_GROUP,
    payload: payload,
  };
};
export const setPaymentSettings = (payload) => {
  return {
    type: PAYMENT_SETTINGS,
    payload: payload,
  };
};
export const setClientToken = (payload) => {
  return {
    type: CLIENT_TOKEN,
    payload: payload,
  };
};

export const setOrdersList = (payload) => {
  return {
    type: ORDERSLIST,
    payload: payload,
  };
};

export const setDisabledProducts = (payload) => {
  return {
    type: DISABLE_CART_ITEMS,
    payload: payload,
  };
};

export const setGroupCartItems = (payload) => {
  return {
    type: SET_GROUP_CART_ITEMS,
    payload: payload,
  };
};

export const setPaymentSummary = (payload) => {
  return {
    type: SET_GROUP_CART_PAYMENT_SUMMARY,
    payload: payload,
  };
};

export const requestCoupan =
  (data, cb = () => null, cbs = () => null, avoidMessage = false) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));
    const store = getState();
    APIUtils.post(ApiNames.APPLY_COUPAN, data)
      .then((response) => {
        if (response.data.data.coupon_id) {
          if (response.data.data.coupon_id) {
            if (
              Number(response.data.data.coupon_minimum_order) == 0 ||
              store.cart.cart.cartTotal >=
                Number(response.data.data.coupon_minimum_order)
            ) {
              dispatch(setCoupan(response.data.data));
              cb({ ...response.data.data, status: true });
              cbs("");
              if (!avoidMessage) {
                toast.success("Promo code applied successfully");
              }
            } else {
              toast.error(
                `Minimum order value of ${response.data.data.coupon_minimum_order} is required to apply coupon`
              );
            }
          } else {
            dispatch(setCoupan(response.data.data));
            cb({ ...response.data.data, status: true });
            cbs("");
            if (!avoidMessage) {
              toast.success("Promo code applied successfully");
            }
          }
        } else {
          if (response.data.data.giftcard_balance > 0) {
            dispatch(setGiftCard(response.data.data));
            if (!avoidMessage) {
              store.auth.token
                ? toast.success("Gift card added successfully")
                : toast.warn("Please signup/sign in to see your gift card");
            }
          } else {
            dispatch(setGiftCard({ ...response.data.data, isDelete: true }));
            if (!avoidMessage) {
              toast.error(
                store.auth.token
                  ? "Insufficent funds on choosen card"
                  : "Please signup/signin up to see your gift card"
              );
            }
          }
        }

        dispatch(setLoader(false));
      })
      .catch((error) => {
        cb(null);
        cbs("");
        // dispatch(setCoupan({}))
        dispatch(setLoader(false));
        if (!avoidMessage) {
          toast.error(error.response.data.message);
        }
      });
  };

export const getUsedGiftCards =
  (scb = () => null) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.GET_USER_GIFT_CARD, {
      // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
    })
      .then((response) => {
        scb(response.data.data);
        response.data.data.map((item) => {
          dispatch(setGiftCard(item));
        });
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

export const getWallet =
  (data, cb = () => null, cbs = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.VERIFY_GIFT_VOUCHER_AMOUNT)
      .then((response) => {
        dispatch(setWallet(response.data.data));
        dispatch(setLoader(false));
        // toast.success("Coupan applied successfully")
      })
      .catch((error) => {
        // cb(null)
        // cbs(null)
        dispatch(setLoader(false));
        // toast.error(error.response.data.message)
      });
  };


export const getPaymentSettings = (data, cb = () => null, cbs = () => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.post(ApiNames.TAX_SETTINGS, data)
			.then((response) => {
				dispatch(setPaymentSettings({...response.data.data, reward_points_conversion_value:(response.data.data?.reward_points_conversion_value)?response.data.data.reward_points_conversion_value:100}));
				cb(response.data.data)
				dispatch(setLoader(false));
				// toast.success("Coupan applied successfully")
			})
			.catch((error) => {
				dispatch(setPaymentSettings({}));
				dispatch(setLoader(false));
				// toast.error(error.response.data.message)
			});
	};

export const getClientToken =
  (setShowBraintreeDropIn) => (dispatch, getState) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.GENERATE_TOKEN, {
      // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
    })
      .then((response) => {
        dispatch(setClientToken(response.data.data));
        setShowBraintreeDropIn(true);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setClientToken(null));
        setShowBraintreeDropIn(true);
        dispatch(setLoader(false));
      });
  };

export const codApi = (data, scb, cash) => (dispatch, getState) => {


	dispatch(setLoader(true));
	const store = getState();
	const service_type = store.auth.headerTab == "Delivery" ? 1 : 2;
	const asap_or_advance = store.profile.deliveryDate ? 2 : 1;

	data = {
		...data,
		service_type,
		asap_or_advance,
	};
	APIUtils.post(cash?ApiNames.COD_CHARGE:ApiNames.INVOICE_PAYMENT, data)
		.then((response) => {
			scb(response.data.data);
			// scb(response.data.data.orders_id);
			dispatch(setLoader(false));
		})
		.catch((error) => {
			toast.error(
				Array.isArray(error.response.data.error)
					? error.response.data.error[0]
					: error.response.data.message,
			);

			dispatch(setLoader(false));
		});
};

export const walletChargeApi =
  (data, scb = () => null, ecb = () => null) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));
    const store = getState();
    const service_type = store.auth.headerTab == "Delivery" ? 1 : 2;
    const asap_or_advance = store.profile.deliveryDate ? 2 : 1;

    data = {
      ...data,
      service_type,
      asap_or_advance,
    };
    APIUtils.post(ApiNames.WALLET_PAY_CHARGE, data)
      .then((response) => {
        scb(response.data.data);
        // scb(response.data.data.orders_id);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        toast.error(
          Array.isArray(error.response.data.error)
            ? error.response.data.error[0]
            : error.response.data.message
        );
        ecb();
        dispatch(setLoader(false));
      });
  };

export const braintreeChargeApi =
  (data, scb = () => null, ecb = () => null) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));

    const store = getState();
    const service_type = store.auth.headerTab == "Delivery" ? 1 : 2;
    const asap_or_advance = store.profile.deliveryDate ? 2 : 1;
    data = {
      ...data,
      service_type,
      asap_or_advance,
    };

    APIUtils.post(ApiNames.BRAINTREE_CHARGE, data)
      .then((response) => {
        scb(response.data.data.orders_id);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        toast.error(
          Array.isArray(error.response.data.error)
            ? error.response.data.error[0]
            : error.response.data.message
        );
        ecb();
        dispatch(setLoader(false));
      });
  };

export const getSingleOrderDetails = (data, scb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.PAYMENT_SUMMARY, data)
    .then((response) => {
      scb(response.data.data);
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
    });
};

export const getOrdersList =
  (scb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.ALL_ORDERS, {})
      .then((response) => {
        dispatch(setOrdersList(response.data.data));
        scb(true);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        scb(true);
        dispatch(setLoader(false));
      });
  };

export const addFundApi =
  (data, scb = () => null, isMobile = false, setCongratsModal = () => null) =>
  (dispatch, getState) => {
    const store = getState();
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.ADD_FUND_API, data)
      .then((response) => {
        dispatch(updateWallet(response.data.data));
        // toast.success(response.data.message)
        if (!isMobile) toast.success(response.data.message);
        Object.keys(store.cart.giftCard).map((item, index) => {
          dispatch(
            requestCoupan(
              { code: store.cart.giftCard[item].giftcard_code },
              () => null,
              () => null,
              true
            )
          );
        });
        if (isMobile) scb(response.data.message);
        else {
          scb();
        }

        dispatch(setLoader(false));
        setCongratsModal(response.data.message);
      })
      .catch((error) => {
        toast.error(
          "The e-Gift card number may be invalid or has already been redeemed"
        );
        // toast.error(error.response.data.message);

        dispatch(setLoader(false));
      });
  };

export const cancelOrder =
  (data, cb = () => null, cbs = () => null, avoidMessage = false) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.CANCEL_ORDER, data)
      .then((response) => {
        dispatch(setLoader(false));
        cb();
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

export const reOrdeProcess =
  (data, orders_id, updateAddress, pickup_order, scb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    const params = new URLSearchParams();
    // data ={...data, restaurant_id:65454545646}
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element) params.append("" + key, "" + element);
      }
    }
    APIUtils.get(ApiNames.GET_RESTURANTS_DATA, { params })
      .then((response) => {
        if (response.data.data.length) {
          const {
            address,
            lat,
            lng,
            min_order,
            postcode,
            restaurant_delivery_fee,
            restaurant_id,
            name,
            service_fee,
            tax,
            service_type,
            allow_asap_advance_orders,
            minimum_pickup_order,
            minimum_deliver_order,
            rest_status,
          } = response.data.data[0];

          APIUtils.post(ApiNames.RE_ORDER, { order_id: orders_id })
            .then(async (reorderResponse) => {
              let items = reorderResponse.data.data.map(
                ({ attributes: optionValuesNew, ...rest }) => ({
                  optionValuesNew,
                  ...rest,
                })
              );

              items.map((x, index) => {
                let val = { ...x };
                let itemtotal = x.products_price * x.customers_basket_quantity;

                x.optionValuesNew &&
                  x.optionValuesNew.length &&
                  x.optionValuesNew.map((y) => {
                    itemtotal =
                      itemtotal +
                      y.options_values_price * x.customers_basket_quantity;
                  });
                val["total"] = itemtotal;
                items[index] = val;
              });

              // async function promises() {

              const arr = [...items];
              arr.map((item) => {
                
                dispatch(
                  getItemAddon({
                    address,
                    menu_id: item.menu_id,
                    closing_time: item.closing_time,
                    editIndex: null,
                    isEdit: false,
                    latlng: { lat, lng },
                    min_order: min_order,
                    opening_time: item.opening_time,
                    postcode: postcode,
                    products_description: item.products_description,
                    products_id: item.products_id,
                    products_image: item.products_image,
                    products_name: item.products_name,
                    products_price: +item.products_price,
                    restaurant_delivery_fee: restaurant_delivery_fee,
                    restaurant_id: restaurant_id,
                    restaurant_name: name,
                    selectedProduct: {},
                    service_fee: service_fee,
                    service_type: service_type,
                    minimum_pickup_order:minimum_pickup_order,
                    minimum_deliver_order: minimum_deliver_order,
                    tax: tax,
                  })
                );
              });
              dispatch(setSelectedRest(response.data.data[0]));
              dispatch(setResturantId(data.restaurant_id));
              if (!pickup_order) {
                dispatch(selectedAddress(updateAddress));
              }
              dispatch(
                setCartItems({
                  address,
                  latlng: { lat, lng },
                  min_order,
                  postcode,
                  restaurant_delivery_fee,
                  restaurant_id,
                  restaurant_name: name,
                  service_fee,
                  tax,
                  items,
                  service_type,
                  allow_asap_advance_orders,
                  minimum_pickup_order,
                  minimum_deliver_order
                })
              );

              dispatch(setLoader(false));
              scb(response.data.data[0]);
              if (response.data.data[0]?.rest_status == 1) {
                dispatch(validateCart());
              }
              // history.push({
              //   pathname: `${path}/${data.name.replace(/[^a-zA-Z0-9]+/g, "_")}_${
              //     data.restaurant_id
              //   }`,
              //   // search: '?query=abc',
              //   data: data,
              // });
            })
            .catch((errorReorder) => {});
        } else {
          toast.error("Specified restaurant is no longer available");
          dispatch(setLoader(false));
        }
      })
      .catch((error) => {});
  };

export const validateCart = (pathname) => (dispatch, getState) => {
 
  const store = getState();
  if(store.cart.cart.restaurant_id){
    dispatch(setLoader(true));
    let data = {};
    store.profile.deliveryDate
      ? (data = {
          service_type: store.auth.headerTab == "Delivery" ? 1 : 2,
          selected_date: `${store.profile.deliveryDate} ${moment(
            store.profile.deliveryTime,
            ["h:mm A"]
          ).format("HH:mm")}`,
          cart_items: store.cart.cart.items,
          restaurant_id: store.cart.cart.restaurant_id,
          asap_or_advance: store.profile.deliveryDate ? 2 : 1,
        })
      : (data = {
          service_type: store.auth.headerTab == "Delivery" ? 1 : 2,
          cart_items: store.cart.cart.items,
          restaurant_id: store.cart.cart.restaurant_id,
          asap_or_advance: store.profile.deliveryDate ? 2 : 1,
        });
    APIUtils.post(ApiNames.VALIDATE_PRODUCTS, data)
      .then((response) => {
        if (response.data.data) {
          // alert("cond 1")
          dispatch(setDisabledProducts(response.data.data));
          console.log("window.location.pathname", pathname)
          if(pathname && pathname.includes("webcheckout")){
            toast.error(response.data.message)
          }
      
        } else {
          // alert("cond 2")
          dispatch(setDisabledProducts({}));
        }
  
        dispatch(setLoader(false));
      })
      .catch((error) => {
        if (error.response.status == 422) {
          toast.error(error.response.data.message);
        }
        dispatch(setLoader(false));
      });
  }else{
    return
  }
  
};

export const addFundBraintree =
  (data, scb = () => null) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.BRAINTREE_ADD_FUND, data)
      .then((response) => {
        dispatch(getWallet());
        scb(response);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

export const getPaymentSummary = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.GET_PAYMENT_SUMMARY, data)
    .then((response) => {
      let data = response?.data?.data;
      setPaymentSummary(data);
      dispatch(setLoader(false));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        toast.error(error.response.data.message);
      }
      dispatch(setLoader(false));
    });
};

export const reOrderGroupOrder =
  (data, orders_id, updateAddress, pickup_order, scb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    const params = new URLSearchParams();
    // data ={...data, restaurant_id:65454545646}
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element) params.append("" + key, "" + element);
      }
    }
    APIUtils.get(ApiNames.GET_RESTURANTS_DATA, { params })
      .then((response) => {
        if (response.data.data.length) {
          APIUtils.post(ApiNames.GROUP_ORDER_RE_ORDER, { order_id: orders_id })
            .then(async (reorderResponse) => {
              scb(reorderResponse.data.data);
              dispatch(setLoader(false));
            })
            .catch((errorReorder) => {});
        } else {
          toast.error("Specified restaurant is no longer available");
          dispatch(setLoader(false));
        }
      })
      .catch((error) => {});
  };
