import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import { useParams } from "react-router-dom";
import { useWindowResize } from "../../components/useWindowResize";
import RowAccordian from "pages/GroupOrderCart/RowAccordian";

import {
  getGroupOrderBasket,
  getGroupOrderInfo,
  setGroupCartItems,
} from "redux-store";

import { SocketConfig } from "../../constants/socketConfig";

const GroupOrderItems = () => {
  const isMobileOnly = useWindowResize();
  const { groupToken } = useParams();
  const store = useSelector((store) => store);
  const { groupOrderInfo } = useSelector((store) => store.rest);
  const dispatch = useDispatch();
  const [groupBasket, setGroupBasket] = useState(null);

  useEffect(() => {
    if (groupOrderInfo) {
      startingConnection();
      if (groupToken) {
        getListing();
      }
    }
  }, [groupToken]);

  const startingConnection = () => {
    const pusher = new Pusher(SocketConfig.PUSHER_KEY, SocketConfig.config);

    Pusher.log = (msg) => {
      console.log(msg);
    };

    pusher.connection.bind("error", function (err) {
      if (err.error.data.code === 4004) {
        console.log("Over limit!");
        pusher.disconnect();
      }
    });

    const cartUpdateChannel = pusher.subscribe("cart-update");
    const groupOrderUpdateChannel = pusher.subscribe("group-order-update");

    cartUpdateChannel.bind("pusher:subscription_succeeded", function () {
      console.log("I am listening cart");
      addingCartUpdateListener(cartUpdateChannel);
    });

    groupOrderUpdateChannel.bind("pusher:subscription_succeeded", function () {
      console.log("I am listening order update");
      addingGroupOrderUpdateListener(groupOrderUpdateChannel);
    });
  };

  const addingCartUpdateListener = (channel) => {
    channel.bind("cart-update", (pusherData) => {
      if (pusherData) {
        let data = pusherData.data[0];
        if (data.id === groupOrderInfo?.id) {
          getListing();
        }
      }
    });
  };

  const addingGroupOrderUpdateListener = (channel) => {
    channel.bind("group-order-update", (pusherData) => {
      if (pusherData) {
        let data = pusherData.data[0];
        if (data.id === groupOrderInfo?.id) {
          hitGroupOrderInfo();
        }
      }
    });
  };

  const getListing = () => {
    dispatch(
      getGroupOrderBasket(groupToken, (res) => {
        setGroupBasket(res);
        let restInfo =
          store.rest.selectedResturantData[groupOrderInfo?.restaurant_id];
        let itemsMapped = res.map((it) =>
          it.items.map((it) => ({
            ...it,
            total: +it.final_price,
            products_id: +it.products_id,
            products_price: +it.products_price,
            options:
              it.options && it.options?.length
                ? it.options?.map((it) => ({
                    ...it,
                    options_id: it.products_options_id,
                    options_values_id: it.products_options_value_id,
                  }))
                : [],
          }))
        );
        let items = [].concat(...itemsMapped);
        dispatch(setGroupCartItems({ ...restInfo, items: items }));
      })
    );
  };

  const hitGroupOrderInfo = () => {
    dispatch(getGroupOrderInfo(groupToken));
  };

  return (
    <>
      {!isMobileOnly ? (
        <>
          <div class="order-details-wrapper">
            <div class="row">
              <div class="col-12 mt-4 pt-3">
                <div class="section_heading mb-3">
                  <h2>Your Group Orders</h2>
                </div>
              </div>
            </div>
            {groupBasket?.map((res, idx) => (
              <RowAccordian
                key={idx}
                member={res}
                isItMobileCart
                onWebCheckoutPage
                needRemoveButton={false}
                needEditButton={false}
                needItemCount={false}
                isItUserCart={false}
                isUserRemoved={groupOrderInfo?.is_deleted}
                isOrderCancelled={groupOrderInfo?.status === 2}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-12 my-4 pt-3">
              <div class="section_heading mb-0">
                <h2>Your Group Order</h2>
              </div>
            </div>
          </div>
          {groupBasket?.map((res, idx) => (
            <RowAccordian
              key={idx}
              member={res}
              isItMobileCart
              onMbWebCheckoutPage
              isItAdminLink
              needRemoveButton={true}
              needEditButton={true}
              needItemCount={false}
              isItUserCart={false}
              isUserRemoved={groupOrderInfo?.is_deleted}
              isOrderCancelled={groupOrderInfo?.status === 2}
            />
          ))}
        </>
      )}
    </>
  );
};

export default GroupOrderItems;
