import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import dropdownArrowIcon from "assets/images/dropdown-arrow.svg";
import removeIcon from "assets/images/remove-cart-icon.svg";
import {
  removeGroupOrderItem,
  selectedMenuItem,
  setSelectedGroupOrderItem,
} from "redux-store";

const RowAccordian = (props) => {
  const store = useSelector(s=>s)
  const {
    member,
    isItAdminLink,
    amountCalc,
    isItMobileCart,
    isLinkOpenedByAdmin,
    isItUserCart,
    needRemoveButton = true,
    needEditButton = true,
    needItemCount = true,
    isUserRemoved = false,
    isOrderCancelled = false,
    onMenuListingPage = false,
    onWebCheckoutPage = false,
    onMbMenuListingPage = false,
    onMbWebCheckoutPage = false,
  } = props;

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const dispatch = useDispatch();
  const { groupToken } = useParams();

  useEffect(() => {
    if (isItUserCart) {
      setToggleDropdown(true);
    }
  }, []);

  const handleAccordion = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const onItemClick = (item) => {
    if (!item.product_available || isUserRemoved || isOrderCancelled) return;
    if (member.member_id !== 0 && isItAdminLink) {
      return;
    }
    let total = amountCalc([member]);
    dispatch(
      setSelectedGroupOrderItem({
        ...item,
        ...member,
        ...store.rest.itemsDetail[item.products_id],
        selectedProduct: item,
        cartTotal: total,
        isEdit: true,
      })
    );
    dispatch(
      selectedMenuItem({
        ...item,
        ...member,
        ...store.rest.itemsDetail[item.products_id],
        selectedProduct: item,
        cartTotal: total,
        isEdit: true,
      })
    );
    // if (isMobileOnly) {
    //   history.push("/menuitemmobile");
    // } else {
    dispatch({ type: "ITEM_MODAL", payload: { status: true, type: "" } });
    //   // setIsOpen({ status: true, type: "" })
    // }
  };

  const onRemoveItem = (item) => {
    if (isUserRemoved || isOrderCancelled) return;
    dispatch(
      removeGroupOrderItem({
        customers_basket_id: item.customers_basket_id,
        member_token: groupToken,
      })
    );
  };

  const goToUserEdit = () => {
    return isUserRemoved || isOrderCancelled ? "#" : member?.link;
  };

  return (
    <>
      <div
        className={`order-details-wrapper accordian pwa-group-order web-paymentpage-gporder ${
          isItMobileCart ? "" : !isItAdminLink ? " mb-0 border-0" : ""
        } ${onMenuListingPage ? "menulistig-sidebar-gp" : ""} 
        ${onWebCheckoutPage ? "web-paymentpage-gporder-chkout" : ""} 
        ${onMbMenuListingPage ? "menulistig-sidebar-gp-pwa" : ""}
        ${onMbWebCheckoutPage ? "web-paymentpage-gporder-chkout-pwa" : ""}
        `}
      >
        <div className="row">
          <div className="col-12">
            <div
              className={`section_heading d-flex justify-content-between align-items-center mb-0`}
            >
              {!isItUserCart ? (
                <a
                  href
                  className="btn btn-accordian"
                  role="button"
                  onClick={handleAccordion}
                >
                  <h2>
                    {member?.member_name}
                    <span className="icon">
                      <img
                        src={dropdownArrowIcon}
                        alt="accordian-arrow"
                        className={toggleDropdown ? "rotate-180" : "rotate-0"}
                      />
                    </span>
                  </h2>
                </a>
              ) : (
                <small class="text-muted">{member?.items?.length} Items</small>
              )}
              {needEditButton ? (
                <>
                  {isItAdminLink && member.member_id !== 0 ? (
                    <a
                      href={goToUserEdit()}
                      rel="noreferrer"
                      className="link-type-btn mr-3"
                      role="button"
                    >
                      Edit
                    </a>
                  ) : null}
                </>
              ) : null}
              {member.member_id !== 0 ? (
                <button
                  className={`btn btn-secondary ${
                    isItMobileCart ? "d-block" : ""
                  }`}
                >
                  {member?.cart_status === 0 ? "Not Saved" : "Saved"}
                </button>
              ) : null}
            </div>

            {needItemCount ? (
              <>
                {!isItUserCart ? (
                  <>
                    <small class="text-muted">
                      {member?.items?.length} Items
                    </small>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        {toggleDropdown ? (
          <div className="order-details-cards-wrapper">
            <div className="row">
              <div className="col-12">
                {isItMobileCart ? (
                  <>
                    {member?.items && member?.items?.length ? (
                      <>
                        {member?.items?.map((item, idx) => (
                          <div class="order-details-card">
                            <div class="d-flex justify-content-between w-100">
                              <div
                                class="rightside"
                                role="button"
                                onClick={() => onItemClick(item)}
                              >
                                <span
                                  class="item-count"
                                  onClick={() => onItemClick(item)}
                                >
                                  {item.customers_basket_quantity}x
                                </span>
                                <div class="order-item-details">
                                  <h5>{item.products_name}</h5>
                                  <span>
                                    {item?.options &&
                                      item?.options?.map((value, index) => (
                                        <>
                                        {console.log("value.optionValues", value.optionValues)}

                                          {value.optionValues &&
                                            value.optionValues.map(
                                              (attr, idx) =>
                                                (attr.products_options_value_id ? attr.products_options_values_name :
                                                  attr.products_options_value_text) + 
                                                (idx ===
                                                value.optionValues.length - 1
                                                  ? ""
                                                  : ", ")
                                            )}
                                          {index === item?.options?.length - 1
                                            ? ""
                                            : ", "}
                                        </>
                                      ))}
                                    {/* {item?.options?.map((it, idx) =>
                                      it.products_options_values_name
                                        ? it.products_options_values_name +
                                          (idx === item?.options?.length - 1
                                            ? ""
                                            : ", ")
                                        : null
                                    )}
                                    {item?.options?.map((it, idx) =>
                                      it.products_options_value_text
                                        ? it.products_options_value_text +
                                          (idx === item?.options?.length - 1
                                            ? ""
                                            : ", ")
                                        : null
                                    )} */}
                                  </span>
                                </div>
                              </div>
                              <div class="order-price">
                                <span>${(+item.final_price).toFixed(2)}</span>
                                {needRemoveButton ? (
                                  <button
                                    class="btn delete-btn"
                                    onClick={() => onRemoveItem(item)}
                                  >
                                    <img
                                      class="img-fluid"
                                      src={removeIcon}
                                      alt=""
                                    />
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No items</p>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row align-items-start">
                      <ul className="shopping-cart-items">
                        {console.log("member ", member)}
                        {member?.items && member?.items?.length ? (
                          member?.items?.map((item, idx) => (
                            <li
                              className={`clearfix item flex-column`}
                              key={idx}
                            >
                              <div className="d-flex flex-row align-items-start">
                                {!item.product_available ? (
                                  <div class="overlay-disabled"></div>
                                ) : null}

                                <div className="item-quantity">
                                  <span
                                    className="quantity-count"
                                    id="quantity-count"
                                  >
                                    {item.customers_basket_quantity}x
                                  </span>
                                </div>
                                <div
                                  className="cart-item"
                                  role="button"
                                  onClick={() => onItemClick(item)}
                                >
                                  <p className="item-name">
                                    {item.products_name}
                                  </p>
                                  <p className="item-price">
                                    ${(+item.final_price).toFixed(2)}
                                  </p>
                                </div>
                                <div className="remove-item">
                                  <button onClick={() => onRemoveItem(item)}>
                                    <span className="icon">
                                      <img src={removeIcon} alt="remove" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              {item?.options && item?.options?.length > 0 ? (
                                <small className="mt-3 w-100">
                                  {item?.options &&
                                    item?.options?.map((value, index) => (
                                      <>
                                        {value.optionValues &&
                                          value.optionValues.map(
                                            (attr, idx) =>
                                            (attr.products_options_value_id ? attr.products_options_values_name :
                                              attr.products_options_value_text) +
                                              (idx ===
                                              value.optionValues.length - 1
                                                ? ""
                                                : ", ")
                                          )}
                                        {index === item?.options?.length - 1
                                          ? ""
                                          : ", "}
                                      </>
                                    ))}
                                  {/* {item?.options?.map((it, idx) =>
                                    it.products_options_values_name
                                      ? it.products_options_values_name +
                                        (idx === item?.options?.length - 1
                                          ? ""
                                          : ", ")
                                      : null
                                  )}
                                  {item?.options?.map((it, idx) =>
                                    it.products_options_value_text
                                      ? it.products_options_value_text +
                                        (idx === item?.options?.length - 1
                                          ? ""
                                          : ", ")
                                      : null
                                  )} */}
                                </small>
                              ) : null}
                              {!item.product_available ? (
                                <small class="redeem-points bg-beige mt-3 text-dark font-weight-bold d-block">
                                  Currently not available
                                </small>
                              ) : null}
                            </li>
                          ))
                        ) : (
                          <p>No items</p>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default RowAccordian;
