import React, { lazy, Suspense } from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Verification,
  Home,
  Login,
  SignUp,
  ForgotPassword,
  ResetPassword,
  SocialSignUp,
} from "pages";
import Loader from "components/Loader";
const PublicRoutes = ({ component: Component, token, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route

    <Route
      {...rest}
      render={(props) =>
        token && restricted ? (
          <Suspense fallback={<Loader />}>
            <Redirect to="/" />
          </Suspense>
        ) : (
          <Suspense fallback={<Loader />}>
            <Component {...props} {...rest} />
          </Suspense>
        )
      }
    />
  );
};

export default PublicRoutes;
