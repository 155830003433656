const initialState = {
  activeTab: "home",
  customers_username: "",
  routePlaceOrder: false,
  modalIsOpen: { status: false, code: "" },
  warningModalIsOpen: false,
  selectedPaymentMethods: {},
  radioNotes: "",
};

const tabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };
    case "SET_FORGOT_FIELD":
      return {
        ...state,
        customers_username: action.payload,
      };
    case "ROUTEPLACE":
      return {
        ...state,
        routePlaceOrder: action.payload,
      };
    case "ITEM_MODAL":
      return {
        ...state,
        modalIsOpen: action.payload,
      };
    case "WARNING_LEAVING_MODAL":
      return {
        ...state,
        modalIsOpen: false,
        warningModalIsOpen: action.payload,
      };
    case "PAYMENT_METHOD":
      return {
        ...state,
        selectedPaymentMethods: action.payload,
      };
    case "RADIO_NOTES":
      return {
        ...state,
        radioNotes: action.payload,
      };
    default:
      return state;
  }
};

export default tabsReducer;
