import React, { createRef, useEffect } from "react";
import moment from "moment";
import rome from "@bevacqua/rome/src/rome";
import "@bevacqua/rome/dist/rome.min.css";

rome.use(moment);

const DatePicker = (props) => {
  const { onSelectDate, invalidDates } = props;
  const elementRef = createRef(null);
  const pickerRef = createRef(null);
  let date = new Date();
  let fromDate = new Date();
  let toDate = new Date(date.setDate(date.getDate() + 30)); // adding 30 days


  useEffect(() => {
    let config = {
      time: false,
      min: fromDate,
      max: toDate,
    };
    if (invalidDates && invalidDates.length > 0) {
      config["dateValidator"] = rome.val.except(invalidDates);
    }
    pickerRef.current = rome(elementRef.current, config);

    pickerRef.current.on("next", (data) => props.setShow(true));
    pickerRef.current.on("back", (data) => props.setShow(true));
    pickerRef.current.on("data", (data) => onSelectDate(data));
    return () => pickerRef.current.off();
  }, []);

  return <div ref={elementRef} id="inline_cal" />;
};

export default DatePicker;
