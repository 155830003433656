import {
  SET_NOTIFICATION_LIST,
  SET_UNREAD,
  SET_TOTAL_NOTIFICATIONS,
} from "./notifyReducer";
import { APIUtils, ApiNames } from "../../service";
import { setCoupan } from "redux-store/Cart/cartAction";
import { toast } from "react-toastify";
import { setLoader } from "../Auth/authAction";

export const setNotifyList = (payload) => {
  return {
    type: SET_NOTIFICATION_LIST,
    payload: payload,
  };
};
export const setUnreadMessages = (payload) => {
  return {
    type: SET_UNREAD,
    payload: payload,
  };
};
export const setTotalNotifications = (payload) => {
  return {
    type: SET_TOTAL_NOTIFICATIONS,
    payload: payload,
  };
};

export const getNotifyList =
  (page = 1, size = 25, scb = () => null, ecb = () => null) =>
  (dispatch, getState) => {
    const store = getState();
    let params = new URLSearchParams();
    params.append("page", page - 1);
    params.append("pagesize", size);
    APIUtils.get(ApiNames.NOTIFICATION_LIST, { params: params })
      .then((res) => {
        let response = res.data.data;
        let oldList = store.notification.notificationList;
        let newList = response?.listing;
        let list = oldList.concat(newList);
        dispatch(setNotifyList(list));
        dispatch(setUnreadMessages(response?.unread_count));
        dispatch(setTotalNotifications(response?.total_count));
        scb(response);
      })
      .catch((err) => {
        ecb();
        console.log(err.response);
      });
  };

export const markNotificationRead =
  (id, scb = () => null, ecb = () => null) =>
  (dispatch, getState) => {
    const store = getState();
    APIUtils.patch(ApiNames.NOTIFICATION_READ + id, {})
      .then((response) => {
        console.log("response from single read notify ", response)
     
        let oldList = store.notification.notificationList;
        let unreadCount = store.notification.unreadNotify;
        let newList = [];
        if (oldList && oldList?.length) {
          newList = oldList.map((it) =>
            it.id === id ? { ...it, is_read: !it.is_read } : { ...it }
          );
        }
        dispatch(setNotifyList(newList));
        dispatch(setUnreadMessages(response.data.data.unread_count));
        if (scb) scb();
      })
      .catch((err) => {
        if (ecb) ecb();
        console.log(err.response);
      });
  };

export const addNotificationAtFirst =
  (data, scb = () => null, ecb = () => null) =>
  (dispatch, getState) => {
    const store = getState();
    const { unreadNotify, totalNotifications, notificationList } =
      store.notification;
    const oldList = notificationList;
    let newList = [data];
    let updatedList = [];
    if (oldList && oldList?.length) updatedList = newList.concat(oldList);
    else updatedList = newList;
    console.log("new list : ", newList, oldList, updatedList);
    dispatch(setNotifyList(updatedList));
    dispatch(setUnreadMessages(unreadNotify + 1));
    dispatch(setTotalNotifications(totalNotifications + 1));
  };

export const validateCouponCode =
  (couponCode, message = null, scb = () => null, ecb = () => null, isPromo=false) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.post(ApiNames.APPLY_COUPAN, { code: couponCode })
      .then((res) => {
        
        let response = res.data;
        if(isPromo && response.data.giftcard_code){
          toast.error("Invalid code");
          ecb()
          return;
        }
        console.log("ch re =>",response)
        if (response?.success) {
          dispatch(setCoupan(response.data));
          toast.success(message || "The Promo code has been applied successfully");
        }
        if (!response?.success) {
          toast.error("Invalid coupon code");
        }
        dispatch(setLoader(false));
        scb(response);
      })
      .catch((err) => {
        let response = err.response.data;
        if (!response?.success) {
          toast.error(response.message);
        }
        dispatch(setLoader(false));
        ecb();
      });
  };

  export const subscribeNewsLetter = ( token , scb=()=>null, ecb=()=>null ) => ( dispatch ) => {
    let params = new URLSearchParams();
    params.append("token", token);
    APIUtils.get(ApiNames.NEWS_LETTER, { params: params })
    .then(res=>{
      console.log("response of subscription =>", res)
      scb(res.data.message)
    }).catch((error)=>{
      console.log("error of subscription =>", error.response)
    })
  }
