import React, { useEffect, useState } from "react";
import MenuContent from "./MenuContent";
import { useLocation, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  getResturantsMenu,
  setResturantId,
  setRestaurantTimings,
  setWarningModalStatus,
} from "redux-store";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { isIPad13, deviceType, isTablet } from "react-device-detect";
import { toast } from "react-toastify";
import MobileCart from "../MobileCart";
import { BASE_URL_IMAGE } from "../../service/ApiNames";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import pageBackArrowImg from "assets/images/page-back-arrow.svg";
import searchFooterImg from "assets/images/search-footer.svg";
import fdlBgImg from "assets/images/fdl-bg-icon-mobile.svg";
import { useWindowResize } from "../../components/useWindowResize";
import { getTimeStops } from "../../service/common";
import moment from "moment";
import GroupOrder from "./group-order";
import ItemDetail from "components/ItemDetail";
import GroupOrderCart from "pages/GroupOrderCart";
import WarningLeavingModal from "./WarningModal";

const MenuListing = (props) => {
  const isMobileOnly = useWindowResize();
  const { url, pathname } = useLocation();
  const { restId, groupToken } = useParams();
  const words = restId?.split("_");

  const history = useHistory();
  const [disabledServiceType, setDisabledServiceType] = useState(null);
  const [disabledAsapAdavance, setDisabledAsapAdavance] = useState(null);
  const dispatch = useDispatch();
  const storeRe = useSelector((store) => store);
  const store = useSelector((store) => store.rest);

  const { groupOrderInfo, groupOrder } = store;

  const { menus, selectedResturantData } = store;
  const {
    name,
    restaurant_delivery_fee,
    restaurant_delivery_time,
    restaurant_cuisines,
    min_order,
    restaurant_id,
    service_fee,
    prep_time,
    image,
    opening_time,
    closing_time,
    lat,
    lng,
    tax,
    address,
    postcode,
    allow_asap_advance_orders,
    special_day_off_dates,
    service_fee_type,
    allow_special_instructions,
    minimum_pickup_order, 
    minimum_deliver_order

  } = selectedResturantData[words[words.length - 1]] || {};
  const unSelectedResturantData =
    store.unSelectedResturantData[[words[words.length - 1]]];
  const check =
    Object.keys(selectedResturantData).length > 0
      ? restaurant_id == words[words.length - 1]
      : false;

  useEffect(() => {
    dispatch(setResturantId(words[words.length - 1]));
    // if (menus[words[words.length - 1]] == undefined) {
    dispatch(
      getResturantsMenu(
        Object.keys(selectedResturantData).length > 0
          ? { restaurant_id: words[words.length - 1] }
          : { restaurant_id: words[words.length - 1] },
        restaurant_id == words[words.length - 1]
      )
    );
    // } else {
    // }
  }, [
    restId,
    storeRe.auth.headerTab,
    storeRe.profile.deliveryDate,
    storeRe.profile.deliveryTime,
  ]);

  useEffect(() => {
    if (menus) {
      // console.log("props.menus",menus )
    }
  }, [menus]);

  useEffect(() => {
    // if(groupOrderInfo && )
    if (
      !groupOrderInfo &&
      !groupOrderInfo?.members &&
      !storeRe.profile.selectedAddress.entry_street_address
    ) {
      toast.error("Please choose an delivery address");
    }
    let serviceType;
    let asapAdvance;
    const selectRest = storeRe.rest.resturants.find(
      (item) => item.restaurant_id == words[words.length - 1]
    );
    if (selectRest) {
      serviceType = selectRest.service_type;
      asapAdvance = selectRest.allow_asap_advance_orders;
    } else {
      serviceType = storeRe.cart.cart.service_type;
      asapAdvance = storeRe.cart.cart.allow_asap_advance_orders;
    }

    switch (serviceType) {
      case 0:
        break;
      case 1:
        setDisabledServiceType("Pickup");
        break;
      case 2:
        setDisabledServiceType("Delivery");
        break;
    }
    switch (asapAdvance) {
      case 0:
        break;
      case 1:
        setDisabledAsapAdavance("SCHEDULE");
        break;
      case 2:
        setDisabledAsapAdavance("ASAP");
        break;
    }
  }, []);

  useEffect(() => {
    if (
      selectedResturantData[words[words.length - 1]] &&
      selectedResturantData[words[words.length - 1]].opening_time
    ) {
      let intervalTime = getTimeStops(opening_time, closing_time, 15);
      let newArrQ = [];

      for (let i = 0; i < intervalTime.length; i++) {
        if (i == intervalTime.length - 1) {
          let x = moment(closing_time, "hh:mm").format("LT");
          // console.log("response of menu time array =>",x)
          newArrQ.push(`${intervalTime[i]} - ${x}`);
        } else {
          newArrQ.push(`${intervalTime[i]} - ${intervalTime[i + 1]}`);
        }
      }
      dispatch(
        setRestaurantTimings({
          timeArray: intervalTime,
          timeArrayNew: newArrQ,
        })
      );
    }
  }, [selectedResturantData, pathname]);

  const x = restaurant_id == words[words.length - 1];
  const [reRender, setRerender] = useState(true);
  useEffect(() => {
    if (!reRender) {
      setRerender(true);
    }
  }, [reRender == false]);

  // useEffect(()=>{

  // 	return () => alert("unmounted")
  // },[])

  const [scroll, setScroll] = useState(false);
  const [dis, setDis] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollCheck = window.scrollY;
    if (scrollCheck > 37 && !scroll) {
      setScroll(true);
    } else if (scrollCheck < 38 && !scroll) {
      setScroll(false);
    }
  };

  const handleGoToCheckoutGc = () => {
    if (storeRe.cart.cart.restaurant_id) {
      history.push("/mobilecart");
    } else {
      switch (storeRe.gc.gc_cart.restaurant_name) {
        case "/redeem-points-/EGIFT_CARD":
          history.push("/webcheckout_giftcards_egift");
          break;
        case "/redeem-points-/GIFT_CARD":
          history.push("/webcheckout_giftcards_gift");
          break;
        case "/buy-gift-card-/GIFT_CARD":
          history.push("/webcheckout_giftcards_buy_gift");
          break;
        case "/buy-gift-card-/EGIFT_CARD":
          history.push("/webcheckout_giftcards_buy_egift");
          break;
      }
    }
  };

  useEffect(() => {
    if (groupToken && !groupOrderInfo?.members) {
      // window.addEventListener("beforeunload", alertUser);
      window.addEventListener("unload", alertUser);
    }
    return () => {
      // if (groupToken && !groupOrderInfo?.members) {
      // window.removeEventListener("beforeunload", alertUser);
      window.addEventListener("unload", alertUser);
      // }
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    openWarningLeavingModal();
    return "";
  };

  const openWarningLeavingModal = () => {
    dispatch(setWarningModalStatus(true));
  };

  const closeWarningLeavingModal = () => {
    dispatch(setWarningModalStatus(false));
  };

  const onSubmitOfWarningModal = () => {
    closeWarningLeavingModal();
    history.push("/restaurants");
  };

  const onBack = () => {
    // if (groupToken) {
    if (groupToken && !groupOrderInfo?.members && !groupOrderInfo?.is_admin) {
      openWarningLeavingModal();
    } else {
      history.push("/restaurants");
    }
  };

  let min_del_val = x
  ? storeRe.auth.headerTab == "Delivery" ?minimum_deliver_order:minimum_pickup_order
  : storeRe.auth.headerTab == "Delivery" ?unSelectedResturantData?.minimum_deliver_order:unSelectedResturantData?.minimum_pickup_order

  return (
    <>
      {storeRe.tab.modalIsOpen.status ? <ItemDetail /> : null}
      {storeRe.tab.warningModalIsOpen ? (
        <WarningLeavingModal
          isMobileOnly={isMobileOnly}
          onSubmit={onSubmitOfWarningModal}
          closeModal={closeWarningLeavingModal}
          show={storeRe.tab.warningModalIsOpen}
        />
      ) : null}

      {Object.keys(selectedResturantData).length ||
      Object.keys(store.unSelectedResturantData).length ? (
        <>
          <Header
            invalidDates={
              x
                ? special_day_off_dates
                  ? special_day_off_dates.split(",")
                  : []
                : unSelectedResturantData?.day_off_dates
            }
            disableCart={deviceType == "browser" ? true : false}
            disabledServiceType={disabledServiceType}
            disabledAsapAdavance={disabledAsapAdavance}
            needNotifications
          />
          <section className="restaurants menu-listing">
            <div className="sidenav-cart-menu" style={{ width: "349px" }}>
              {!isMobileOnly && (
                <MobileCart groupCartSideNavCart showCart={true} />
              )}
            </div>
            <div className="rightside-main">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-delivery"
                  role="tabpanel"
                  aria-labelledby="nav-delivery-tab"
                >
                  <div className="d-md-none restra-name-search-header">
                    <div
                      className={`backbtn-wrapper ${
                        scroll ? "menuHeader__scroll" : ""
                      } ${isMobileOnly ? "backbtn-restra-mobile" : ""}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        href
                        className="go-back-btn d-flex"
                        onClick={onBack}
                        title="click to go back"
                      >
                        {isMobileOnly ? (
                          <>
                            <span className="icons menu-back-icon-pwa">
                              <img src={backArrowImg} alt="back arrow" />
                            </span>
                            <span className="resturant_name_mobile">
                              {x ? name : unSelectedResturantData?.name}
                            </span>{" "}
                          </>
                        ) : (
                          <>
                            <span className="icons">
                              <img src={pageBackArrowImg} alt="back arrow" />
                            </span>{" "}
                            <span className="newBack">Back</span>
                          </>
                        )}
                      </a>
                      {isMobileOnly ? (
                        <a
                          onClick={() =>
                            history.push("/search", {
                              foodSearch: true,
                              restName: x
                                ? name
                                : unSelectedResturantData?.name,
                            })
                          }
                          title="search button"
                        >
                          <span className="search_icons">
                            <img src={searchFooterImg} alt="seach icon" />
                          </span>{" "}
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="container">
                    <div
                      className={`backbtn-wrapper ${
                        isMobileOnly
                          ? "backbtn-restra-mobile d-none"
                          : "d-block"
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a href onClick={onBack} title="click to go back">
                        {isMobileOnly ? (
                          <>
                            <span className="icons">
                              <img src={backArrowImg} alt="back arrow" />
                            </span>
                            <span className="resturant_name_mobile">
                              {x ? name : unSelectedResturantData?.name}
                            </span>{" "}
                          </>
                        ) : (
                          <>
                            <span className="icons">
                              <img src={pageBackArrowImg} alt="back arrow" />
                            </span>{" "}
                            <span className="newBack">Back</span>
                          </>
                        )}
                      </a>
                      {isMobileOnly ? (
                        <a
                          onClick={() => history.push("/search")}
                          title="search button"
                        >
                          <span className="icons">
                            <img src={searchFooterImg} alt="back arrow" />
                          </span>{" "}
                        </a>
                      ) : null}
                    </div>

                    <section id="menu-listing-restra-header">
                      <div className="menu-listing-restra">
                        <div className="inner-wrapper">
                          <div className="img-box image-container-menu">
                            <img
                              src={
                                x
                                  ? `${BASE_URL_IMAGE}${image}`
                                  : `${BASE_URL_IMAGE}${unSelectedResturantData?.image}`
                              }
                              alt="restra image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="restra-details w-100 d-flex justify-content-between">
                            <div className="content-box">
                              <div className="restra-name">
                                <h5>
                                  {x ? name : unSelectedResturantData?.name}
                                </h5>
                              </div>
                              <div className="restra-cuisine">
                                <span>
                                  {x
                                    ? restaurant_cuisines
                                    : unSelectedResturantData?.restaurant_cuisines}
                                </span>
                              </div>
                              <div className="d-flex justify-between">
                                <div
                                  className="delivery-info"
                                  style={
                                    groupToken
                                      ? {}
                                      : storeRe.profile.selectedAddress
                                          .entry_street_address
                                      ? {}
                                      : {
                                          display: "none",
                                        }
                                  }
                                >
                                  <GroupOrder
                                    forSideText
                                    needInfoTextAtRestInfoPlace={!isMobileOnly}
                                    isMobile={isMobileOnly}
                                  >
                                    {storeRe.auth.headerTab == "Delivery" ? (
                                      <>
                                        <div
                                          className={`delivery-fee ${
                                            storeRe.auth.headerTab ==
                                              "Pickup" &&
                                            Number(
                                              x
                                                ? service_fee
                                                : unSelectedResturantData?.service_fee
                                            ) == 0
                                              ? "d-none"
                                              : ""
                                          }`}
                                        >
                                          {`${
                                           
                                            Number(
                                              x
                                                ? restaurant_delivery_fee
                                                : unSelectedResturantData?.restaurant_delivery_fee
                                            )
                                          }` == 0 ? (
                                            <span>Free Delivery</span>
                                          ) : (
                                            <span>{`$${
                                             
                                              Number(
                                                x
                                                  ? restaurant_delivery_fee
                                                  : unSelectedResturantData?.restaurant_delivery_fee
                                              )
                                            } Delivery fee`}</span>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* {`${Number(
                                          x
                                            ? service_fee
                                            : unSelectedResturantData?.service_fee
                                        )}` == 0 ? null : (
                                          <div
                                            className={`delivery-fee ${
                                              storeRe.auth.headerTab ==
                                                "Pickup" &&
                                              Number(
                                                x
                                                  ? service_fee
                                                  : unSelectedResturantData?.service_fee
                                              ) == 0
                                                ? "d-none"
                                                : ""
                                            }`}
                                          >
                                            <span>{`$${Number(
                                              x
                                                ? service_fee
                                                : unSelectedResturantData?.service_fee
                                            )} Service fee `}</span>
                                          </div>
                                        )} */}
                                      </>
                                    )}

                                    <div className="delivery-time">
                                      {storeRe.auth.headerTab == "Delivery" ? (
                                        <span>{`Delivery in ${
                                          x
                                            ? restaurant_delivery_time +
                                              prep_time
                                            : unSelectedResturantData?.restaurant_delivery_time
                                        } min`}</span>
                                      ) : (
                                        <span>{`Prepared in ${
                                          x
                                            ? prep_time
                                            : unSelectedResturantData?.restaurant_delivery_time
                                        } min`}</span>
                                      )}
                                    </div>

                                    {min_del_val>0?<div className="min-order">
                                      <span>
                                        $
                                        {x
                                          ? storeRe.auth.headerTab == "Delivery" ?minimum_deliver_order:minimum_pickup_order
                                          : storeRe.auth.headerTab == "Delivery" ?unSelectedResturantData?.minimum_deliver_order:unSelectedResturantData?.minimum_pickup_order}{" "}
                                        Minimum Order
                                      </span>
                                    </div>:null }
                                  </GroupOrder>
                                </div>
                              </div>
                            </div>
                            {!isMobileOnly && <GroupOrder forSideButton />}
                          </div>
                        </div>
                        {isMobileOnly && (
                          <>
                            <GroupOrder
                              forSideText
                              isMobile
                              needInfoTextAtRestInfoPlace={isMobileOnly}
                              extraClass="text-dark font-weight-bold"
                            />
                          </>
                        )}
                      </div>
                    </section>

                    {isMobileOnly && (
                      <section class="payment__page__wrapper py-2">
                        <div class="row">
                          <div class="col-12 col-xl-6">
                            <div class="text-right resend-order-tabs">
                              <GroupOrder isMobile forSideButton />
                            </div>
                          </div>
                        </div>
                      </section>
                    )}

                    <MenuContent
                      menus={
                        menus[words[words.length - 1]]
                          ? menus[words[words.length - 1]]
                          : []
                      }
                      restaurant_name={x ? name : unSelectedResturantData?.name}
                      opening_time={
                        x ? opening_time : unSelectedResturantData?.opening_time
                      }
                      closing_time={
                        x ? closing_time : unSelectedResturantData?.closing_time
                      }
                      restaurant_id={words[words.length - 1]}
                      latlng={{
                        lat: x ? lat : unSelectedResturantData?.lat,
                        lng: x ? lng : unSelectedResturantData?.lng,
                      }}
                      reRender={reRender}
                      setRerender={setRerender}
                      service_fee={
                        x
                          ? service_fee
                          : unSelectedResturantData?.service_fee
                          ? unSelectedResturantData?.service_fee
                          : 0
                      }
                      restaurant_delivery_fee={
                        x
                          ? restaurant_delivery_fee
                          : unSelectedResturantData?.restaurant_delivery_fee
                          ? unSelectedResturantData?.restaurant_delivery_fee
                          : 0
                      }
                      address={x ? address : unSelectedResturantData?.address}
                      min_order={
                        x
                          ? min_order
                          : unSelectedResturantData?.min_order
                          ? unSelectedResturantData?.min_order
                          : 0
                      }
                      // fee={storeRe.auth.headerTab == "Delivery" ? `${
                      // 	Number(
                      // 		x
                      // 			? service_fee
                      // 			: unSelectedResturantData?.service_fee,
                      // 	) +
                      // 	Number(
                      // 		x
                      // 			? restaurant_delivery_fee
                      // 			: unSelectedResturantData?.restaurant_delivery_fee,
                      // 	)
                      // }`:`${Number(
                      // 	x
                      // 		? service_fee
                      // 		: unSelectedResturantData?.service_fee,
                      // )}`}
                      tax={
                        x
                          ? tax
                          : unSelectedResturantData?.tax
                          ? unSelectedResturantData?.tax
                          : 0
                      }
                      postcode={
                        x
                          ? postcode
                            ? postcode
                            : 0
                          : unSelectedResturantData?.tax
                          ? unSelectedResturantData?.tax
                          : 0
                      }
                      allow_asap_advance_orders={allow_asap_advance_orders}
                      service_fee_type={service_fee_type?service_fee_type:unSelectedResturantData?.service_fee_type}
                      allow_special_instructions={allow_special_instructions?allow_special_instructions:unSelectedResturantData?.allow_special_instructions}
                      minimum_pickup_order={minimum_pickup_order?minimum_pickup_order:unSelectedResturantData?.minimum_pickup_order}
                      minimum_deliver_order={minimum_deliver_order?minimum_deliver_order:unSelectedResturantData?.minimum_deliver_order}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {isMobileOnly ? (
            <>
              {groupToken && groupOrderInfo && groupOrderInfo?.id ? (
                <GroupOrderCart isMobileCart />
              ) : (
                <>
                  {((storeRe.cart.cart.items &&
                    storeRe.cart.cart.items.length) ||
                    storeRe.gc.gc_cart.restaurant_id) && (
                    <section className="meanu-listing-mobile-footer view-cart-layout">
                      <div className="container" onClick={handleGoToCheckoutGc}>
                        <div className="bg">
                          <div className="view-cart-wrapper">
                            <p>
                              <span className="d-flex">
                                {storeRe.cart.cart.items ? (
                                  <img
                                    className="bag-icon mr-2 img-fluid"
                                    src={fdlBgImg}
                                  />
                                ) : null}
                                {storeRe.cart.cart.items
                                  ? "View Cart"
                                  : "Go to Checkout"}
                              </span>
                              <span>
                                $
                                {parseFloat(
                                  storeRe.cart.cart.cartTotal
                                    ? storeRe.cart.cart.cartTotal
                                    : storeRe.gc.gc_cart.cartTotal
                                ).toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ zIndex: 9, position: "absolute", width: " 100%" }}>
              <Footer />
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default MenuListing;
