import React from "react";

const BrainTreeShow = (props) => {
  return (
    <div
      class={`payment-cards saved-cards ${
        props.fromGcTab ? "pt-2 mt-2" : "pt-4 mt-2"
      }`}
    >
      <div id={"braintree-drop-in-div"} />
    </div>
  );
};

export default BrainTreeShow;
