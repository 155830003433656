import React from 'react';
import DateAndTime from '../../components/Header/DateAndTime';
import ModalWrapper from "../../components/ModalWrapper";
import { useHistory } from "react-router-dom";
const DateAndTimeMobile = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  return (
    <ModalWrapper
			headerText="Choose Date and Time"
			handleBack={handleBack}
		>
      <DateAndTime />
    </ModalWrapper>
  );
};

export default DateAndTimeMobile;