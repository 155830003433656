import { useState, useEffect } from "react";
// import { isMobileOnly as old } from "react-device-detect";

export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [isMobileOnly, setIsMobileOnly] = useState(window.innerWidth<768?true:false);

  const listener = () => {
    let orientation = !navigator.maxTouchPoints
      ? "desktop"
      : !window.screen.orientation.angle
      ? "portrait"
      : "landscape";

    if (orientation == "portrait") {
      if (window.innerWidth < 768  ) {
        setIsMobileOnly(true);
      } else {
        
        setIsMobileOnly(false);
      }
    } else {
      if (window.innerWidth < 897) {
        setIsMobileOnly(true);
      } else {
        setIsMobileOnly(false);
      }
    }
    // setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return isMobileOnly
  

  // return {
  //   width,
  //   height,
  //   isMobileOnly
  // };
};
