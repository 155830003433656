import React from "react";
import Modal from "react-bootstrap/Modal";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import closeImg from "assets/images/modal-close.svg";

const WarningLeavingModal = ({ show, closeModal, isMobileOnly, onSubmit }) => {
  return (
    <>
      <Modal
        id="add-balance"
        show={show}
        onHide={closeModal}
        keyboard={false}
        fullscreen={isMobileOnly}
        centered
        className="deliveryAddressModal"
      >
        {isMobileOnly ? (
          <>
            <main className="mobile-inner-screen addFund-mobile bg-warn">
              <div className="payment__page__wrapper">
                <div className="go-back-wrapper bg-transparent">
                  <a
                    href
                    className="go-back-btn ml-auto pr-2"
                    onClick={closeModal}
                  >
                    <img src={closeImg} alt="go back" />
                  </a>
                  <h1>{""}</h1>
                </div>
                <div className="main-wrapper d-flex align-items-center justify-content-center">
                  <section className="mobile-inner-section">
                    <div className="main">
                      <div className="container">
                        <div class="devilery-address-modal-wrapper">
                          <div lass="devilery-address-modal-wrapper-inner">
                            <div class="warning-wrapper leaving-gp-order">
                              <h1>Warning</h1>
                              <p>
                                You are leaving the group order. Do you want to
                                proceed?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="meanu-listing-mobile-footer checkout-footer bg-transparent">
                    <div className="container">
                      <div className="bg bg-transparent">
                        <div className="modal-bottom-actions">
                          <button
                            type="button"
                            class="btn btn-outline w-50"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            class="btn btn-filled w-50"
                            onClick={onSubmit}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </>
        ) : (
          <>
            <Modal.Header className="page_main_heading">
              <h1 class="modal-title" id="driverTipModalLabel">
                {""}
              </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">
                  <img src={closeImg} alt="icon" className="img-fluid" />
                </span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div class="devilery-address-modal-wrapper">
                <div lass="devilery-address-modal-wrapper-inner">
                  <div class="warning-wrapper">
                    <h1>Warning</h1>
                    <p>
                      You are leaving the group order. Do you want to proceed?
                    </p>
                  </div>
                  <div class="modal-bottom-actions d-flex justify-content-between">
                    <button
                      type="button"
                      class="btn btn-outline"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-filled mr-0"
                      onClick={onSubmit}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default WarningLeavingModal;
