/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import WebCheckOutMobile from "./WebCheckOutMobile";
import WebCheckOutDesktop from "./WebCheckOutDeskTop";
import dropin from "braintree-web-drop-in";
import DateAndTime from "../../components/Header/DateAndTime";
import { useSelector, useDispatch } from "react-redux";
import {
	getResturantsData,
	getEmptyUsers,
	selectDate,
	selectTime,
	setPaymentSummary,
	getPaymentSettings,
	getClientToken,
	setSelectedPaymentMethods,
	codApi,
	walletChargeApi,
	requestCoupan,
	braintreeChargeApi,
	setCoupan,
	saveAddress,
	getUsedGiftCards,
	selectedAddress,
	getGroupOrderInfo,
} from "redux-store";

import { useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import DriverTip from "./DriverTip";
import PopUp from "./PopUp";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import EditAddress from "./EditAddress";
import AddFunds from "./AddFunds";
import AddFund from "components/AddFund";
import CongratsPopup from "components/CongratsPopup";

import restaurantImg from "assets/images/icon-resturant.svg";
import addressImg from "assets/images/icon-address.svg";
import { useWindowResize } from "../../components/useWindowResize";
import WarningModal from "./WarningModal";

const WebCheckOutGroupOrder = (props) => {
	const { groupToken } = useParams();
	const { groupOrderInfo } = useSelector((store) => store.rest);

	const [isRestLoaded, setIsRestLoaded] = useState(false);
	const [addFundBraintree, setAddFundBrainTree] = useState(false);
	const [congratsModal, setCongratsModal] = useState(false);
	const isMobileOnly = useWindowResize();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const store = useSelector((store) => store);
	const cartStore = useSelector((store) => store.groupCart);

	const [nonce, setNonce] = useState(false);
	const [mapValue, setMapValue] = useState(null);
	const [mapProjection, setMapProjection] = useState(null);
	const [mapZoom, setMapZoom] = useState(1);

	const [driverTip, setDriverTip] = useState(cartStore.tips[1]);
	const [drverTipType, setDriverTipType] = useState(false);
	const [customTipAmount, setCustomTipAmount] = useState("");

	const [pySettingsData, setPySettingsData] = useState(null);
  const [loadPayment, setLoadPaymet] = useState(false)

	const [radio, setRadio] = useState(
		store.tab.radioNotes ? store.tab.radioNotes : "Meet at Door",
	);

	const [toggleWarning, setToggleWarning] = useState(false);
	const [noSavedUserList, setNoSavedUserList] = useState([]);

	const [modalIsOpen, setIsOpen] = React.useState({
		status: false,
		code: "",
	});
	const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);
	const [cash, setCash] = useState(false);

	const [localStatePayMethods, setLocalStatePayMethods] = useState({});
	const [braintreeInstance, setBraintreeInstance] = useState(undefined);

	const [isRedirect, setIsRedirect] = useState({
		status: false,
		data: { isNavigated: true, orderId: "" },
	});

	const [reRenderBraintree, setReRenderBraintree] = useState(false);

	const [disabledServiceType, setDisabledServiceType] = useState(null);
	const [disabledAsapAdavance, setDisabledAsapAdavance] = useState(null);
	const [invalidDates, setInValidDates] = useState([]);

  const [invoice, setInvoice] = useState(false);


	useEffect(() => {
		if (groupToken) {
			dispatch(
				getGroupOrderInfo(groupToken, (data) => {
					if (data.status !== 0) {
						history.replace("/orderlist");
					}
					getGroupOrderRest();
				}),
			);
		}
	}, [groupToken]);

	const getGroupOrderRest = () => {
		if (groupOrderInfo) {
			let data = groupOrderInfo;
			let date = data.selected_date?.split(" ");
			let model = {
				restaurant_id: data.restaurant_id,
				lat_from: data.latitude,
				lng_from: data.longitude,
				asap_or_advance: data.asap_or_advance,
				service_type: data.service_type,
				date: date[0],
				time: date[1],
			};
			setIsRestLoaded(false);
			dispatch(
				getResturantsData(model, (data) => {
					setIsRestLoaded(true);
				}),
			);
		}
	};

	useEffect(() => {
		const serviceType =
			store.rest.selectedResturantData[groupOrderInfo?.restaurant_id]
				?.service_type;
		const asapAdvance =
			store.rest.selectedResturantData[groupOrderInfo?.restaurant_id]
				?.allow_asap_advance_orders;
		const blockDates =
			store.rest.selectedResturantData[groupOrderInfo?.restaurant_id]
				?.special_day_off_dates;
		if (blockDates) {
			setInValidDates(blockDates.split(","));
		}

		switch (serviceType) {
			case 0:
				break;
			case 1:
				setDisabledServiceType("Pickup");
				break;
			case 2:
				setDisabledServiceType("Delivery");
				break;
			default:
				break;
		}
		switch (asapAdvance) {
			case 0:
				break;
			case 1:
				setDisabledAsapAdavance("SCHEDULE");
				break;
			case 2:
				setDisabledAsapAdavance("ASAP");
				break;
			default:
				break;
		}
	}, [groupOrderInfo, store.rest.selectedResturantData]);

	useEffect(() => {
		if (mapValue) {
			handleOnLoad(mapValue);
		}
	}, [store.auth.headerTab, store.profile.selectedAddress]);

	useEffect(() => {
    if (pySettingsData == null && loadPayment) {
  
      setPySettingsData(store.cart.taxdata);
      
    }
  }, [store.cart.taxdata, loadPayment]);

	useEffect(() => {
		dispatch(getClientToken(setShowBraintreeDropIn));
	}, []);

	const isExists = (arr = [], key) => {
		return true
    // console.log("isExists", arr);
    // let xy = arr.find((item) => item.id == key);
    // if (xy) {
    //   return true;
    // } else {
    //   return false;
    // }
  };
	useEffect(() => {

		if (showBraintreeDropIn && pySettingsData && loadPayment ) {
      let obj = {
        authorization: store.cart.clientToken,
        vaultManager: true,
        container: "#braintree-drop-in-div",
        preselectVaultedPaymentMethod: false,
      };

      let pp = null;
      if (
        isExists(pySettingsData.allowed_paymant_methods, 1) ||
        !store.auth.token
      ) {
        obj["paypal"] = {
          flow: "vault",
          singleUse: true,
        };
      }
      let gp = null;
      if (
        isExists(pySettingsData.allowed_paymant_methods, 2) ||
        !store.auth.token
      ) {
        obj["googlePay"] = {
          googlePayVersion: 2,
          merchantId: "merchant-id-from-google",
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: "16.50",
            currencyCode: "USD",
          },
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
                billingAddressRequired: true,
                billingAddressParameters: {
                  format: "FULL",
                },
              },
            },
          ],
        };
      }
      let ap = null;
      if (
        isExists(pySettingsData.allowed_paymant_methods, 4) ||
        !store.auth.token
      ) {
        obj["applePay"] = {
          displayName: "Food Dudes",
          paymentRequest: {
            total: {
              label: "Food Dudes",
              amount: parseFloat(store.cart.placeOrderCharges.total).toFixed(2),
            },
          },
        };
      }
      let ve = null;
      if (
        isExists(pySettingsData.allowed_paymant_methods, 3) ||
        !store.auth.token
      ) {
        obj["venmo"] = {
          allowNewBrowserTab: false,
        };
      }


      const initializeBraintree = () =>
        dropin.create(obj, function (error, instance) {
          if (error) console.error(error);
          else {
            setBraintreeInstance(instance);
          }
        });
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }

}, [showBraintreeDropIn, reRenderBraintree, pySettingsData, loadPayment, isRestLoaded]);


	useEffect(() => {
		if (braintreeInstance) {
			if (
				cartStore.placeOrderCharges.total <= 0 &&
				braintreeInstance.isPaymentMethodRequestable()
			) {
				braintreeInstance.clearSelectedPaymentMethod();
				toast.error("No more payment options needed");
			}
		}
	}, [reRenderBraintree]);


	const callBackEvent = (event) => {
		 // alert("paymentMethodRequestable")
		 let y = document.getElementsByClassName("active-cash-card");
		 if (y.length) {
			 y[0].className = "cash-card";
			 setCash(false);
		 } else {
		 }
		 let y1 = document.getElementsByClassName("active-cash-card-invoice");
		 if (y1.length) {
			 y1[0].className = "cash-card-invoice";
			 setInvoice(false);
		 }
		 //check if saved payment method is unchecked
		 var classname = document.getElementsByClassName("braintree-method");
		 var unselectBTMethod = function () {
			 if (this.className.match(/\bbraintree-method--active\b/)) {
				 braintreeInstance.clearSelectedPaymentMethod();
			 }
		 };
		 for (var i = 0; i < classname.length; i++) {
			 classname[i].addEventListener("click", unselectBTMethod, false);
		 }

		 let neIm = pySettingsData.allowed_paymant_methods.find(item=>item.id == 1)

	 


	 let test = document.getElementById("test-id")?.innerText
	 console.log("tip of driver 4", test)
	//  store.groupCart.placeOrderCharges.total<=0 || 
		 if(test == "$0.00" ){
			 toast.error("No more payment options needed");
			 braintreeInstance.clearSelectedPaymentMethod();
			 return
		 }
		
		 if(!neIm && event.type == "PayPalAccount"){
			 braintreeInstance.clearSelectedPaymentMethod();
			 toast.error("Payment method PayPal is not available for this restaurant. Please choose a different method")
		 }
	}

	useEffect(() => {
    if (braintreeInstance) {
			
      braintreeInstance.on("paymentMethodRequestable",callBackEvent);
    }
  }, [braintreeInstance]);

	useEffect(()=>{
    if(braintreeInstance && pySettingsData && pySettingsData.allowed_paymant_methods && store.auth.token && loadPayment){
      const paymentOptions = pySettingsData.allowed_paymant_methods.map(itm=>itm.id)
      const missingElem = [1,2,3,4].filter((it=>{
  
        return !paymentOptions.includes(it)
      }))
      
      hidePaymentsOptions(missingElem)
    }
  },[braintreeInstance, reRenderBraintree, pySettingsData, loadPayment])


  const hidePaymentsOptions = (disabledKeys=[]) => {
    disabledKeys.map((item,index)=>{
      switch(item){
        case 1:
          let p = document.getElementsByClassName("braintree-option__paypal")
          if(p.length){
				    p[0].className = "d-none";
			    }
          break;
        case 2:
          let gp = document.getElementsByClassName("braintree-option__googlePay")
			    if(gp.length){
				    gp[0].className = "d-none";
			    }
          break;
        case 3:
          let ve = document.getElementsByClassName("braintree-option__venmo")
			    if(ve.length){
				    ve[0].className = "d-none";
			    }
          break;
        case 4:
          let ap = document.getElementsByClassName("braintree-option__applePay")
			    if(ap.length){
				    ap[0].className = "d-none";
			    }
          break;
      }
    })
  }

	useEffect(() => {
		Object.keys(cartStore.giftCard).map((item, index) => {
			dispatch(
				requestCoupan(
					{ code: cartStore.giftCard[item].giftcard_code },
					() => null,
					() => null,
					true,
				),
			);
		});

		if (store.auth.token) {
			dispatch(getUsedGiftCards());
			if (
				!store.profile.selectedAddress.address_book_id &&
				store.profile.selectedAddress.entry_firstname
			) {
				dispatch(
					saveAddress(
						{
							...store.profile.selectedAddress,
							set_default:
								store.profile.selectedAddress.address_book_id ==
								store.profile.selectedAddress.customers_default_address_id
									? 1
									: 0,
							address_book_id: store.profile.selectedAddress.address_book_id,
							entry_country_id: "1",
							entry_zone_id: "1",
							address_type: "1",
						},
						() => null,
						true,
						true,
					),
				);
			}
		}
	}, []);

	function percentage(percent, total, type) {
    if (type == 1) {
      return +percent;
    }
    return +((percent / 100) * total).toFixed(2);
  }
	const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });

	////////// TBD: need to understand
	useEffect(() => {
		const { cartTotal, restaurant_delivery_fee, service_fee, tax, min_order, service_fee_type = 1 } =
			cartStore.cart;
	
		if (Object.keys(cartStore.taxdata).length > 0) {
			const {
				state_tax_rate,
				local_tax_rate,
				low_order_min_amount,
				low_order_fee,
				reward_points_conversion_value,
        maximum_limit_for_cod_orders,
			} = cartStore.taxdata;

			let discount = cartStore.coupan.coupon_amount
				? Number(cartStore.coupan.coupon_amount)
				: 0;

			let newServiceFeeWithType = percentage(
        +service_fee,
        +cartTotal,
        +service_fee_type
      );

			let service_fee_new =  +formatter.format(newServiceFeeWithType); 

			if (pySettingsData && pySettingsData.allowed_phone_order) {
        let phOrder =
          (+cartTotal * pySettingsData.phone_order_service_fee_rate) / 100;
        service_fee_new =  +formatter.format(service_fee_new + phOrder);
      }
			let groupDiscount = 0;
      if (pySettingsData && pySettingsData.invoice_discount ) {
        let pyGroupD = (+cartTotal * pySettingsData.invoice_discount) / 100;
        groupDiscount = +formatter.format(pyGroupD);
      }
			const calc = +cartTotal + 
			(store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 ) + ( +cartTotal > +low_order_min_amount ? 0 : +low_order_fee) + +service_fee_new - discount - groupDiscount

			let state_tax = (calc * state_tax_rate) / 100;
			let local_tax = (calc * local_tax_rate) / 100;
			let sales_tax = +formatter.format(state_tax + local_tax);
			if (sales_tax < 0) {
				sales_tax = 0.0;
			}
			if (store.auth.token && store.profile.customers_tax_exempt) {
        sales_tax = 0.0;
      }

			let tip = 0;

			if (drverTipType) {
				if (store.auth.headerTab == "Delivery") {
					tip = +driverTip;
				}
			} else {
				if (store.auth.headerTab == "Delivery") {
          let dtCalc =
            +cartTotal +
            +restaurant_delivery_fee +
            +service_fee_new +
            (+cartTotal > +low_order_min_amount
              ? 0
              : +low_order_fee) +
            +sales_tax;

          tip = (+driverTip * dtCalc) / 100;
        }
			}

			const totalAmount =
				cartTotal +
				service_fee_new +
				sales_tax +
				tip +
				(Number(cartTotal) > Number(low_order_min_amount)
					? 0
					: Number(low_order_fee));
			// let x = discount > totalAmount ? totalAmount : discount;
			let x = discount > totalAmount + (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 ) - groupDiscount ? (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 )  + totalAmount  - groupDiscount : discount;

			let xt =
				cartTotal +
				service_fee_new +
				sales_tax +
				tip +
				(Number(cartTotal) > Number(low_order_min_amount)
					? 0
					: Number(low_order_fee));

		
			let val = {
				sub_total: cartTotal,
				service_fee: service_fee_new,
				tax: sales_tax,
				discount: x,
				total: (store.auth.headerTab == "Delivery" ? +restaurant_delivery_fee :0 )  + xt - (x + groupDiscount),
				groupDiscount: groupDiscount,
				low_order:
					Number(cartTotal) > Number(low_order_min_amount)
						? 0
						: Number(low_order_fee),
				tip: tip,
			};

			console.log("tip of driver 4", val)
			let pymt = {};
			let plorderTot = val.total;
			if (Object.keys(localStatePayMethods).length) {
				Object.keys(localStatePayMethods).map((d) => {
					const data = localStatePayMethods[d];
					let deductAmount = data.giftcard_balance
						? data.giftcard_balance
						: data.amount;
					if (plorderTot > 0) {
						let x = deductAmount >= plorderTot ? true : false;
						let y = 0;
						if (x) {
							y = plorderTot;
							plorderTot = 0;
						} else {
							y = deductAmount;
							plorderTot = plorderTot - deductAmount;
						}
						pymt = { ...pymt, [d]: { ...data, balance_used: y } };
					} else {
						toast.error("No more payment options needed");
					}
				});
			}
			if (
				braintreeInstance &&
				plorderTot <= 0 &&
				braintreeInstance.isPaymentMethodRequestable()
			) {
				braintreeInstance.clearSelectedPaymentMethod();
			}
			dispatch(setSelectedPaymentMethods(pymt));
			dispatch(setPaymentSummary({ ...val, total: plorderTot }));
		}
	}, [
		cartStore.cart,
		cartStore.coupan,
		store.auth.headerTab,
		cartStore.taxdata,
		driverTip,
		drverTipType,
		localStatePayMethods,
		pySettingsData
	]);

	useEffect(() => {
		if (braintreeInstance) {
			braintreeInstance.updateConfiguration("applePay", "paymentRequest", {
				total: {
					label: "Food Dudes",
					amount: parseFloat(cartStore.placeOrderCharges.total).toFixed(2),
				},
			});
		}
	}, [cartStore.placeOrderCharges.total]);

	useEffect(() => {
		if (groupOrderInfo) {
			const restaurant_id = groupOrderInfo?.restaurant_id;
			const postcode = cartStore.cart;
			if (
				store.profile.selectedAddress.entry_postcode ||
				store.auth.headerTab === "Pickup"
			) {
				dispatch(
					getPaymentSettings({
						restaurant_id: restaurant_id,
						postcode:
							store.auth.headerTab === "Pickup"
								? postcode
								: store.profile.selectedAddress.entry_postcode,
					},()=>setLoadPaymet(true)),
				);
			} else {
				store.auth.headerTab === "Delivery" &&
					setIsOpen({ status: true, code: "Address" });
				if (
					location.pathname == "/webcheckout" ||
					location.pathname == "/webcheckout/"
				) {
					store.auth.headerTab === "Delivery" &&
						toast.warn("Please add your full address");
				}
			}
		}
	}, [groupOrderInfo, store.profile.selectedAddress]);

	// const [cities, setCities] = useState([]);
	// console.log("store.rest.selectedResturantData[groupOrderInfo?.restaurant_id] || {};",store.profile.selectedAddress )
	// useEffect(() => {
	//   if (isRestLoaded) {
	//     const { address_lat, address_lng } = store.profile.selectedAddress || {};
	//     const { lat, lng } =
	//       store.rest.selectedResturantData[groupOrderInfo?.restaurant_id] || {};

	//     let cities = [];
	//     if (store.auth.headerTab == "Delivery") {
	//       cities = [
	//         {
	//           img: restaurantImg,
	//           id: 1,
	//           name: "rest",
	//           position: { lat: Number(lat), lng: Number(lng) },
	//         },
	//         {
	//           img: addressImg,
	//           id: 2,
	//           name: "addr",
	//           position: {
	//             lat: Number(address_lat),
	//             lng: Number(address_lng),
	//           },
	//         },
	//       ];
	//     } else {
	//       cities = [
	//         {
	//           img: restaurantImg,
	//           id: 1,
	//           name: "rest",
	//           position: { lat: Number(lat), lng: Number(lng) },
	//         },
	//       ];
	//     }
	//     setCities(cities);
	//   }
	// }, [isRestLoaded]);

	let cities = [];
	const { address_lat, address_lng } = store.profile.selectedAddress || {};
	const { lat, lng } =
		store.rest.selectedResturantData[groupOrderInfo?.restaurant_id] || {};
	if (store.auth.headerTab == "Delivery") {
		cities = [
			{
				img: restaurantImg,
				id: 1,
				name: "rest",
				position: { lat: Number(lat), lng: Number(lng) },
			},
			{
				img: addressImg,
				id: 2,
				name: "addr",
				position: {
					lat: Number(address_lat),
					lng: Number(address_lng),
				},
			},
		];
	} else {
		cities = [
			{
				img: restaurantImg,
				id: 1,
				name: "rest",
				position: { lat: Number(lat), lng: Number(lng) },
			},
		];
	}

	const handleOnLoad = (map) => {
		setMapValue(map);
		const bounds = new window.google.maps.LatLngBounds();
		cities.forEach(({ position }) => bounds.extend(position));
		map.fitBounds(bounds);
		window.google.maps.event.addListenerOnce(
			map,
			"projection_changed",
			function () {
				setMapProjection(map.getProjection());
			},
		);
		window.google.maps.event.addListener(map, "zoom_changed", function () {
			setMapZoom(map.getZoom());
		});
	};

	const options = {
		strokeColor: "#FF0000",
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: "#FF0000",
		fillOpacity: 0.35,
		clickable: false,
		draggable: false,
		editable: false,
		visible: true,
		radius: 30000,
		zIndex: 1,
	};

	const deliverNow = () => {
		dispatch(selectDate(""));
		dispatch(selectTime(""));
		dispatch(
			getResturantsData(
				{
					lat_from: groupOrderInfo?.latitude,
					lng_from: groupOrderInfo?.longitude,
				},
				() => null,
			),
		);
	};

	const renderUi = () => {
		switch (modalIsOpen.code) {
			case "datentime":
				return (
					<DateAndTime
						invalidDates={invalidDates ? invalidDates : [""]}
						disabledAsapAdavance={disabledAsapAdavance}
						handleDateToggle={() => setIsOpen({ status: false, type: "" })}
						ids=""
						cname=""
					/>
				);
			case "tip":
				return (
					<div className="payment__page__wrapper">
						<DriverTip
							setIsOpen={setIsOpen}
							ids="driverTipModal"
							cname="modal fade deliveryAddressModal"
							drverTipType={drverTipType}
							setDriverTipType={setDriverTipType}
							setDriverTip={setDriverTip}
							customTipAmount={customTipAmount}
							setCustomTipAmount={setCustomTipAmount}
							store={store}
							cartStore={cartStore}
						/>
					</div>
				);
			case "Address":
				return (
					<EditAddress
						setIsOpen={setIsOpen}
						store={store}
						cartStore={cartStore}
						dispatch={dispatch}
						ids="deliveryAddressModal"
						cname="modal fade deliveryAddressModal"
					/>
				);
			case "funds":
				return (
					<AddFunds
						setIsOpen={setIsOpen}
						store={store}
						dispatch={dispatch}
						ids="addFundModal"
						cname="addFundModalLabel"
					/>
				);
			default:
				return;
		}
	};

	const handleSelection = (data, key, type) => {
		let y = document.getElementsByClassName("active-cash-card");

		if (y.length) {
			y[0].className = "cash-card";
			setCash(false);
		}

		let selectedPay = { ...store.tab.selectedPaymentMethods };
		if (+data.amount > 0 || +data.giftcard_balance > 0) {
			if (
				store.tab.selectedPaymentMethods[
					type == "gc" ? "GC_".concat(key) : "GV_".concat(key)
				]
			) {
				delete selectedPay[
					type == "gc" ? "GC_".concat(key) : "GV_".concat(key)
				];
			} else {
				selectedPay = {
					...selectedPay,
					[type == "gc" ? "GC_".concat(key) : "GV_".concat(key)]: data,
				};
			}
			setLocalStatePayMethods({ ...selectedPay });
		}
	};

	const onSuccess = (response) => {
		setAddFundBrainTree(false);
		setCongratsModal(response.data.message);
		// setReRenderBraintree(Math.random());
	};

	const handlePlaceOrder = () => {
		dispatch(
			getEmptyUsers(groupToken, (res) => {
				setNoSavedUserList(res.data);
				let noData = res.data?.length === 0;
				if (noData) {
					handlePlaceOrder1();
				} else {
					setToggleWarning(true);
				}
			}),
		);
	};

	const handlePlaceOrder1 = () => {
		if (store.profile.selectedAddress.address_book_id) {
			let addMatch = store.profile.allAddress.find(
				(add) =>
					store.profile.selectedAddress.address_book_id == add.address_book_id,
			);
			if (!addMatch) {
				const copy = { ...store.profile.selectedAddress };
				delete copy["address_book_id"];
				delete copy["customers_id"];
				delete copy["customers_default_address_id"];
				dispatch(selectedAddress(copy));
				setIsOpen({ status: true, code: "Address" });
				return;
			}
		}
		const checkAddFlag = !store.auth.token
			? store.profile.selectedAddress.entry_postcode
			: store.profile.selectedAddress.address_book_id;
		if (checkAddFlag || store.auth.headerTab === "Pickup") {
			if ( (store.rest.groupOrderInfo?.service_type == 1 ?cartStore.cart.minimum_deliver_order:cartStore.cart.minimum_pickup_order) > cartStore.cart.cartTotal) {
				toast.error(
					`Sorry! minimum order value is $${store.rest.groupOrderInfo?.service_type == 1 ?cartStore.cart.minimum_deliver_order:cartStore.cart.minimum_pickup_order}`,
				);
			} else {
				dispatch({ type: "ROUTEPLACE", payload: true });
				if (!store.auth.token) {
					history.push("/signin");
				} else {
					const copy = [...cartStore.cart.items];

					const cart_items = copy.map(
						({ options: attributes = [], ...rest }) => ({
							attributes,
							...rest,
						}),
					);
					let data = {
						address_id: store.profile.selectedAddress.address_book_id,
						pickup_order: store.auth.headerTab == "Delivery" ? "0" : "1",
						tip: driverTip,
						tip_in_percentage: drverTipType ? "0" : "1",
						coupon_code: cartStore.coupan.coupon_code,
						// giftcards:
						// selected_date
						cart_items: cart_items,
						restaurant_id: cartStore.cart.restaurant_id,
						// nonce
						order_note: radio,
						special_instruction: cartStore.instructionGroup,
						order_type: "2",
						group_order_id: groupOrderInfo?.id,
					};

					if (store.profile.deliveryTime) {
						data = {
							...data,
							selected_date: moment(
								`${store.profile.deliveryDate} ${store.profile.deliveryTime}`,
							).format("YYYY-MM-DD HH:mm"),
						};
					}
					// "2021-10-28 22:00:00"

					if (cash || invoice) {
					
						dispatch(
							codApi(data, ({ orders_id, order_type_asap }) => {
								// dispatch(setCartItems({}))
								dispatch(setCoupan({}));
								setIsRedirect({
									status: true,
									data: { isNavigated: true, orderId: orders_id },
								});
								// history.push({
								// 	pathname: `/orderdetails`,
								// 	data:{isNavigated:true, orderId:id},

								// });
							},cash ? 1 : 0),
							
						);
					} else if (nonce) {
						// dispatch(braintreeChargeApi({}))
						if (braintreeInstance) {
							if (braintreeInstance.isPaymentMethodRequestable()) {
								braintreeInstance.requestPaymentMethod((error, payload) => {
									if (error) {
										console.error(error);
									} else {
										const paymentMethodNonce = payload.nonce;

										// TODO: use the paymentMethodNonce to
										//  call you server and complete the payment here

										// ...
										// onPaymentCompleted();
									}
								});
							} else {
								toast.error("Please choose a payment method ");
							}
						}
					} else {
						if (Object.keys(store.tab.selectedPaymentMethods).length) {
							if (cartStore.placeOrderCharges.total == 0) {
								data = {
									...data,
									giftcards: store.tab.selectedPaymentMethods,
									wallet_selected: 1,
								};
								dispatch(
									walletChargeApi(
										data,
										({ orders_id, order_type_asap }) => {
											dispatch(setCoupan({}));

											setIsRedirect({
												status: true,
												data: {
													isNavigated: true,
													orderId: orders_id,
												},
											});
										},
										() => setNonce(false),
									),
								);
							} else {
								if (braintreeInstance) {
									if (braintreeInstance.isPaymentMethodRequestable()) {
										braintreeInstance.requestPaymentMethod((error, payload) => {
											if (error) {
												console.error(error);
											} else {
												const paymentMethodNonce = payload.nonce;
												if (
													Object.keys(store.tab.selectedPaymentMethods).length
												) {
													data = {
														...data,
														giftcards: store.tab.selectedPaymentMethods,
														wallet_selected: 1,
														payment_method_nonce: paymentMethodNonce,
													};
												} else {
													data = {
														...data,
														wallet_selected: 1,
														payment_method_nonce: paymentMethodNonce,
													};
												}
												dispatch(
													braintreeChargeApi(
														data,
														(id) => {
															dispatch(setCoupan({}));
															setIsRedirect({
																status: true,
																data: {
																	isNavigated: true,
																	orderId: id,
																},
															});
														},
														() => setNonce(false),
													),
												);
											}
										});
									} else {
										toast.error("Please choose more payment methods ");
									}
								}
							}
						} else {
							if (braintreeInstance) {
								if (braintreeInstance.isPaymentMethodRequestable()) {
									if (cartStore.placeOrderCharges.total > 0) {
										braintreeInstance.requestPaymentMethod((error, payload) => {
											if (error) {
												console.error(error);
											} else {
												const paymentMethodNonce = payload.nonce;
												// TODO: use the paymentMethodNonce to
												//  call you server and complete the payment here
												// ...
												data = {
													...data,
													payment_method_nonce: paymentMethodNonce,
												};

												dispatch(
													braintreeChargeApi(data, (id) => {
														// dispatch(setCartItems({}))
														dispatch(setCoupan({}));
														setIsRedirect({
															status: true,
															data: {
																isNavigated: true,
																orderId: id,
															},
														});
														// history.push({
														// 	pathname: `/orderdetails`,
														// 	data:{isNavigated:true, orderId:id}
														// });
													}),
												);
												// alert(`Payment completed with nonce=${paymentMethodNonce}`);

												// onPaymentCompleted();
											}
										});
									} else {
										toast.error("Order value must be greater than 0");
									}
								} else {
									if (cartStore.placeOrderCharges.total <= 0) {
										data = {
											...data,
											giftcards: store.tab.selectedPaymentMethods,
											wallet_selected: 1,
										};
										dispatch(
											walletChargeApi(
												data,
												({ orders_id, order_type_asap }) => {
													dispatch(setCoupan({}));

													setIsRedirect({
														status: true,
														data: {
															isNavigated: true,
															orderId: orders_id,
														},
													});
												},
												() => setNonce(false),
											),
										);
									} else {
										toast.error("Please choose a payment method ");
									}
								}
							} else {
								toast.error("Please choose a payment method ");
							}
						}
					}
				}
			}
		} else {
			setIsOpen({ status: true, code: "Address" });
			toast.warn("Please add the full address");
		}
	};

	return (
		<>
			{toggleWarning ? (
				<WarningModal
					show={toggleWarning}
					closeModal={() => setToggleWarning(false)}
					isMobileOnly={isMobileOnly}
					onSubmit={handlePlaceOrder1}
					userList={noSavedUserList}
				/>
			) : null}
			{isRestLoaded ? (
				<>
					{isMobileOnly ? (
						<>
							{isRedirect.status && (
								<Redirect
									to={{
										pathname: `/orderdetails/${isRedirect.data.orderId}`,
										data: isRedirect.data,
									}}
								/>
							)}
							<CongratsPopup
								show={congratsModal}
								onHide={() => setCongratsModal(false)}
							/>

							<WebCheckOutMobile
								cities={cities}
								handleOnLoad={handleOnLoad}
								dispatch={dispatch}
								polylineOptions={{
									...options,
									paths:
										store.auth.headerTab == "Delivery"
											? [
													cities[0]?.position,
													{
														lat: Number(
															store.profile.selectedAddress.address_lat,
														),
														lng: Number(
															store.profile.selectedAddress.address_lng,
														),
													},
											  ]
											: [cities[0]?.position, cities[0]?.position],
								}}
								store={store}
								cartStore={cartStore}
								setRadio={setRadio}
								radio={radio}
								showBraintreeDropIn={showBraintreeDropIn}
								setShowBraintreeDropIn={setShowBraintreeDropIn}
								driverTip={driverTip}
								setDriverTip={setDriverTip}
								setIsOpen={setIsOpen}
								deliverNow={deliverNow}
								handlePlaceOrder={handlePlaceOrder}
								drverTipType={drverTipType}
								handleSelection={handleSelection}
								braintreeInstance={braintreeInstance}
								setLocalStatePayMethods={setLocalStatePayMethods}
								setCash={setCash}
								cash={cash}
								mapProjection={mapProjection}
								mapZoom={mapZoom}
								mapValue={mapValue}
								disabledServiceType={disabledServiceType}
								disabledAsapAdavance={disabledAsapAdavance}
								setAddFundBrainTree={setAddFundBrainTree}
								pySettingsData={pySettingsData}
          			setInvoice={setInvoice}
							/>
							{addFundBraintree ? (
								<AddFund
									onSuccess={onSuccess}
									show={addFundBraintree}
									fullscreen={true}
									onHide={() => setAddFundBrainTree(false)}
								/>
							) : null}
							<Modal
								className="modal fade"
								id="congrats-modal"
								show={modalIsOpen.status}
								modalIsOpen={modalIsOpen}
								onHide={() => {
									setIsOpen({ status: false, type: "" });
								}}
								// size="lg"
								aria-labelledby="congrats-modalLabel"
								centered
							>
								{modalIsOpen.code == "Address" ? (
									<div
										className="deliveryAddressModal"
										style={{ padding: "16px", background: "white" }}
									>
										<EditAddress
											show={modalIsOpen.status}
											modalIsOpen={modalIsOpen}
											onHide={() => {
												setIsOpen({ status: false, type: "" });
											}}
											setIsOpen={setIsOpen}
											store={store}
											cartStore={cartStore}
											dispatch={dispatch}
											ids="deliveryAddressModal"
											cname="modal fade deliveryAddressModal"
										/>
									</div>
								) : (
									<>
										{modalIsOpen.code == "tip" ? (
											<div className="payment__page__wrapper">
												<DriverTip
													setIsOpen={setIsOpen}
													ids="driverTipModal"
													cname="modal fade deliveryAddressModal"
													drverTipType={drverTipType}
													setDriverTipType={setDriverTipType}
													setDriverTip={setDriverTip}
													customTipAmount={customTipAmount}
													setCustomTipAmount={setCustomTipAmount}
													store={store}
													cartStore={cartStore}
												/>
											</div>
										) : (
											<AddFunds
												setIsOpen={setIsOpen}
												store={store}
												dispatch={dispatch}
												ids="addFundModal"
												cname="addFundModalLabel"
											/>
										)}
									</>
								)}
							</Modal>
						</>
					) : (
						<>
							{isRedirect.status && (
								<Redirect
									to={{
										pathname: `/orderdetails/${isRedirect.data.orderId}`,
										data: isRedirect.data,
									}}
								/>
							)}
							<CongratsPopup
								show={congratsModal}
								onHide={() => setCongratsModal(false)}
							/>
							<WebCheckOutDesktop
								cities={cities}
								cartStore={cartStore}
								handleOnLoad={handleOnLoad}
								dispatch={dispatch}
								polylineOptions={{
									...options,
									paths:
										store.auth.headerTab == "Delivery"
											? [
													cities[0]?.position,
													{
														lat: Number(
															store.profile.selectedAddress.address_lat,
														),
														lng: Number(
															store.profile.selectedAddress.address_lng,
														),
													},
											  ]
											: [cities[0]?.position, cities[0]?.position],
								}}
								store={store}
								setRadio={setRadio}
								radio={radio}
								setIsOpen={setIsOpen}
								deliverNow={deliverNow}
								showBraintreeDropIn={showBraintreeDropIn}
								setShowBraintreeDropIn={setShowBraintreeDropIn}
								driverTip={driverTip}
								setDriverTip={setDriverTip}
								handlePlaceOrder={handlePlaceOrder}
								drverTipType={drverTipType}
								handleSelection={handleSelection}
								setLocalStatePayMethods={setLocalStatePayMethods}
								setCash={setCash}
								cash={cash}
								braintreeInstance={braintreeInstance}
								mapProjection={mapProjection}
								mapZoom={mapZoom}
								mapValue={mapValue}
								disabledServiceType={disabledServiceType}
								disabledAsapAdavance={disabledAsapAdavance}
								setAddFundBrainTree={setAddFundBrainTree}
								pySettingsData={pySettingsData}
        				setInvoice={setInvoice}
							/>
							<PopUp
								show={modalIsOpen.status}
								modalIsOpen={modalIsOpen}
								onHide={() => {
									setIsOpen({ status: false, type: "" });
								}}
							>
								{renderUi()}
							</PopUp>
							{addFundBraintree ? (
								<AddFund
									onSuccess={onSuccess}
									show={addFundBraintree}
									fullscreen={false}
									onHide={() => setAddFundBrainTree(false)}
									setCongratsModal={setCongratsModal}
								/>
							) : null}
						</>
					)}
				</>
			) : null}
		</>
	);
};

export default WebCheckOutGroupOrder;
