import React from 'react'
import WebContent from "./WebContent"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const WebCheckOutDesktop = (props) => {
  
  return (
    <>
    <Header checkoutHeader/>
      <section className="payment__page__wrapper">
        <WebContent {...props}/>
      </section>
      <div style={{
        position: "absolute",
        zIndex: 1,
        width: "100%",
      }}>
      <Footer />
      </div>
    </>
  )
}
export default WebCheckOutDesktop