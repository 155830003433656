
const GET_PROFILE_DATA = 'REGISTER_REQUEST'
const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS'
const SAVE_ADDRESS = 'SAVE_ADDRESS'
const DELETE_ADDRESS = "DELETE_ADDRESS"
const GET_ADDRESS_BYID = "GET_ADDRESS_BYID"
const GET_ADDRESS = "GET_ADDRESS"
const SET_PRIMARY_ADDRESS ="SET_PRIMARY_ADDRESS"
const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS"
const RECALL_API = "RECALL_API"
const SELECTED_DATE = "SELECTED_DATE"
const SELECTED_TIME = "SELECTED_TIME"
const SET_PREV_ADDRESS = "SET_PREV_ADDRESS"



export { GET_PROFILE_DATA, 
    SET_SELECTED_ADDRESS, 
   SET_PRIMARY_ADDRESS,
   GET_ADDRESS, 
   DELETE_ADDRESS, 
   SAVE_ADDRESS, 
   GET_ADDRESS_BYID, 
   SET_DEFAULT_ADDRESS,RECALL_API,
   SELECTED_TIME,
   SELECTED_DATE  , SET_PREV_ADDRESS 
  }