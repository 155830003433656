import React from "react";
import moment from "moment";
import { BASE_URL_IMAGE } from "../../service/ApiNames";

const Card = ({ item, goToMenu, ...props }) => {
  const {
    name,
    restaurant_id,
    restaurant_delivery_fee,
    restaurant_delivery_time,
    restaurant_cuisines,
    prep_time,
    // rest_status,
    service_fee,
    service_type,
    restaurant_distance,
    image,
  } = item;

  let rest_status = 0;

  switch (item?.rest_status) {
    case 0:
      rest_status = false;
      break;
    case 1:
      rest_status = true;
      break;
    case 2:
      rest_status = false;
      break;
    case 3:
      rest_status = false;
      break;
  }

  const testType = props.testType;
  return (
    <div
      className={`cards ${rest_status == 1 ? "" : "closed"}`}
      onClick={goToMenu}
      style={{
        display:
          service_type == 0
            ? "block"
            : testType == "Delivery" && service_type == 1
            ? "block"
            : testType == "Pickup" && service_type == 2
            ? "block"
            : "none",
      }}
    >
      <a>
        <div className="card-wrapper">
          <div className="img-box">
            {!rest_status == 1 ? (
              <div className="overlay-status">
                <span className="restaurant-status">
                  {rest_status == 1 ? "Open" : "Closed"}
                </span>
              </div>
            ) : null}
            <img
              className="img-fluid"
              src={`${BASE_URL_IMAGE}${image}`}
              alt="perkins"
            />
          </div>
          <div className="content-box">
            <div
              className="restra-name"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5>{name}</h5>
              {props.store.auth.headerTab == "Delivery" ? null : (
                <div className="distance" style={{ paddingTop: "10px" }}>
                  <span className="icon">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </span>
                  <span className="distance-count">
                    {+restaurant_distance.toFixed(1)} mile
                  </span>
                </div>
              )}
            </div>
            <div className="restra-cuisine">
              <span>{restaurant_cuisines}</span>
            </div>
            <div className="delivery-info">
              {props.store.auth.headerTab == "Delivery" ? (
                <>
                  {restaurant_delivery_fee == 0 ? (
                    <div className="delivery-fee">
                      <span>Free Delivery</span>
                    </div>
                  ) : (
                    <div className="delivery-fee">
                      <span>{`$${
                        restaurant_delivery_fee
                      } Delivery Fee`}</span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  
                </>
              )}
              <div className={`delivery-time ${props.store.auth.headerTab == "Delivery" ? "" : "pl-0"}`}>
                {props.store.auth.headerTab == "Delivery" ? (
                  // <span>{`Delivery in ${restaurant_delivery_time+moment.duration(prep_time).asSeconds()/60} min`}</span>
                  <span>{`Delivery in ${
                    restaurant_delivery_time + prep_time
                  } min`}</span>
                ) : (
                  <span>{`Ready in ${prep_time} min`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Card;
