import React from "react";
import { Modal, Button } from "react-bootstrap";
import closeImg from "assets/images/modal-close.svg";
import infoImg from "assets/images/info-icon-lg.svg";
const FeesAndTax = (props) => {
  const {
    show,
    fullscreen,
    centered,
    setShow = () => null,
    sf = 0,
    lof = 0,
    st = 0,
  } = props;
  return (
    <Modal
      className="modal fade"
      id="congrats-modal"
      show={show}
      centered = {centered}
      fullscreen={fullscreen}
      onHide={() => setShow(false)}
    >
      <div id="#congrats-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShow(false)}
              >
                <span aria-hidden="true">
                  <img src={closeImg} alt="icon" className="img-fluid" />
                </span>
              </button>
              <div className="congrates-wrapper feeandtaxs_wrapper">
                <div className="img-box">
                  <img src={infoImg} alt="icon" className="img-fluid" />
                </div>
                <div className="content-box mb-4">
                  <h1>Fees & Sales Tax</h1>
                  {/* <p>$12 has been added to your food dudes balance. Your new balance is $6000</p> */}
                </div>
                <div className="feeTax-data">
                  <div className="row">
                    {+sf>0?<div className="col-12 mb-3">
                      <div className="d-flex justify-content-between">
                        <span>Service Fee</span>
                        <span>${parseFloat(sf).toFixed(2)}</span>
                      </div>
                    </div>:null}
                    {+lof>0 ? <div className="col-12 mb-3">
                      <div className="d-flex justify-content-between">
                        <span>Low Order Fee</span>

                        <span>${parseFloat(+lof).toFixed(2)}</span>
                      </div>
                      </div>:null}
                    {+st>0?<div className="col-12">
                      <div className="d-flex justify-content-between">
                        <span>Sales Tax</span>
                        <span>${parseFloat(+st).toFixed(2)}</span>
                      </div>
                    </div>: null }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FeesAndTax;
