import React, { Suspense } from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
} from "react-router-dom";
import { Home } from "pages";
import Loader from "components/Loader";

const PrivateRoute = ({ component: Component, token, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Suspense fallback={<Loader />}>
            <Component {...props} {...rest}/>
          </Suspense>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
