import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import { useHistory, useParams } from "react-router-dom";
import fdlBgImg from "assets/images/fdl-bg-icon-mobile.svg";

import {
  cancelGroupOrder,
  getGroupOrderBasket,
  getGroupOrderInfo,
  saveGroupOrderCart,
  setGroupOrder,
} from "redux-store";

import RowAccordian from "./RowAccordian";
import { SocketConfig } from "../../constants/socketConfig";
import MobileGroupCart from "./mobile-view";
import CancelOrderModal from "./CancelOrderModal";
import { toast } from "react-toastify";

const GroupOrderCart = (props) => {
  const {
    groupCartHeaderCart = false,
    groupCartSideNavCart = false,
    isMobileCart = false,
    onWebCheckoutPage = false,
  } = props;
  const { groupOrderInfo, groupOrder, selectedResturantData, restaurant_id } =
    useSelector((store) => store.rest);
  const restaurant = selectedResturantData[restaurant_id];
  const [groupBasket, setGroupBasket] = useState(null);
  const [disableCheckout, setDisableCheckout] = useState(false)
  const isItAdminLink = groupOrderInfo?.members;
  const [finalAmount, setFinalAmount] = useState(0);
  const isLinkOpenedByAdmin = groupOrderInfo?.is_admin;
  const [userCart, setUserCart] = useState(null);
  const history = useHistory();
  const [toggleCancelOrder, setToggleCancelOrder] = useState(false);

  const [showMobileCart, setShowMobileCart] = useState(false);
  const dispatch = useDispatch();

  const { groupToken } = useParams();

  // channel subscriptions
  let pusher;
  let cartUpdateChannel;
  let groupOrderUpdateChannel;

  useEffect(() => {
    if (
      groupToken &&
      groupOrderInfo &&
      (groupCartHeaderCart ||
        groupCartSideNavCart ||
        isMobileCart ||
        onWebCheckoutPage)
    ) {
      if (groupOrderInfo && groupOrderInfo?.id) {
        if (groupCartSideNavCart || isMobileCart || onWebCheckoutPage) {
          startingConnection();
          getListing();
        }
      }
    }
    return () => {
      if (cartUpdateChannel && pusher) {
        cartUpdateChannel.unbind_all();
        pusher.unsubscribe("cart-update");
        pusher.disconnect();
      }
      if (groupOrderUpdateChannel && pusher) {
        groupOrderUpdateChannel.unbind_all();
        pusher.unsubscribe("group-order-update");
        pusher.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (groupBasket && groupBasket?.length) {
      setFinalAmount(amountCalculator(groupBasket));
      let count = 0
      groupBasket.map(it=>{
        let x = it.items.find(prod=>prod.product_available==0)
        if(x){
          count ++
          
        }
      })
      if(count){
        setDisableCheckout(true)
      }else{
        setDisableCheckout(false)
      }
    }
  }, [groupBasket]);

  const amountCalculator = (members) => {
    let priceArr = members.map((it) => it.items.map((it) => +it.final_price));
    let total = [].concat
      .apply([], priceArr)
      .reduce((partialSum, a) => partialSum + a, 0);
    return +total.toFixed(2);
  };

  const startingConnection = () => {
    pusher = new Pusher(SocketConfig.PUSHER_KEY, SocketConfig.config);
    Pusher.log = (msg) => {
      console.log(msg);
    };

    pusher.connection.bind("error", function (err) {
      if (err.error.data.code === 4004) {
        console.log("Over limit!");
        pusher.disconnect();
      }
    });

    cartUpdateChannel = pusher.subscribe("cart-update");
    groupOrderUpdateChannel = pusher.subscribe("group-order-update");
    cartUpdateChannel.bind("pusher:subscription_succeeded", function () {
      console.log("I am listening cart");
      addingCartUpdateListener();
    });

    groupOrderUpdateChannel.bind("pusher:subscription_succeeded", function () {
      console.log("I am listening order update");
      addingGroupOrderUpdateListener();
    });
  };

  const addingCartUpdateListener = () => {
    cartUpdateChannel.bind("cart-update", (pusherData) => {
      let groupId = groupOrderInfo.id || groupOrder.group_order_id;
      if (pusherData) {
        console.log("cart-update : ", pusherData);
        let data = pusherData.data[0];
        if (data.id == groupId) {
          getListing();
        }
      }
    });
  };

  const addingGroupOrderUpdateListener = () => {
    groupOrderUpdateChannel.bind("group-order-update", (pusherData) => {
      let groupId = groupOrderInfo.id || groupOrder.group_order_id;
      if (pusherData) {
        console.log("group-order-update : ", pusherData);
        let data = pusherData.data[0];
        if (data.id == groupId) {
          hitGroupOrderInfo();
        }
      }
    });
  };

  const getListing = () => {
    dispatch(
      getGroupOrderBasket(groupToken, (res) => {
        setGroupBasket(res);
        if (res && res.length > 0) {
          setUserCart(res[0]);
        }
      })
    );
  };

  const hitGroupOrderInfo = () => {
    dispatch(
      getGroupOrderInfo(groupToken, (res) => {
        if (+res.amount_limit !== +groupOrderInfo.amount_limit) {
     
          toast.success(`Group order limit changed to ${(+res.amount_limit!== 0 ? "$" + res.amount_limit:"None")}`);
          
        }
        getListing();
      })
    );
  };

  const onSaveOrder = () => {
    if (groupOrderInfo?.is_deleted || groupOrderInfo?.status === 2) {
      return;
    }
    dispatch(saveGroupOrderCart(groupToken));
  };

  const onEditOrder = () => {
    if (groupOrderInfo?.is_deleted || groupOrderInfo?.status === 2) {
      return;
    }
    onSaveOrder();
  };

  const openMobileCart = () => {
    setShowMobileCart(true);
  };

  const closeMobileCart = () => {
    setShowMobileCart(false);
  };

  const onCancelOrder = () => {
    if (groupOrderInfo?.is_deleted || groupOrderInfo?.status === 2) {
      return;
    }
    setToggleCancelOrder(!toggleCancelOrder);
  };

  const onCancelGroupOrder = () => {
    dispatch(
      cancelGroupOrder(groupToken, (data) => {
        onCancelOrder();
        hitGroupOrderInfo();
        dispatch(setGroupOrder(null));
        toast.success("Group order canceled successfully");
        history.replace("/restaurants");
      })
    );
  };

  const onCheckout = () => {
    goToCheckout();
  };

  const goToCheckout = () => {
    let min_order = groupOrderInfo?.service_type==1? restaurant.minimum_deliver_order:restaurant.minimum_pickup_order;
    if (!finalAmount && finalAmount === 0) {
      toast.error("Please add at least one item to proceed");
      return;
    }
    if (min_order && finalAmount < min_order) {
      toast.error(`Sorry! minimum order value is $${min_order}`);
      return;
    }

    if (groupOrderInfo?.is_deleted || groupOrderInfo?.status === 2) {
      return;
    }
    history.push(`/webcheckout/group/${groupToken}`);
  };

  return (
    <>
      {isMobileCart ? (
        <>
          {groupBasket && groupBasket?.length ? (
            <>
              <section className="meanu-listing-mobile-footer view-cart-layout">
                <div
                  className="container"
                  role="button"
                  onClick={openMobileCart}
                >
                  <div className="bg">
                    <div className="view-cart-wrapper">
                      <p>
                        <span className="d-flex">
                          <img
                            className="bag-icon mr-2 img-fluid"
                            src={fdlBgImg}
                            alt=""
                          />
                          View Cart
                        </span>
                        <span>
                          {finalAmount ? "$" + finalAmount.toFixed(2) : ""}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {showMobileCart ? (
                <MobileGroupCart
                  show={showMobileCart}
                  isMobileCart={isMobileCart}
                  closeModal={closeMobileCart}
                  groupOrderInfo={groupOrderInfo}
                  groupBasket={groupBasket}
                  isItAdminLink={isItAdminLink}
                  isLinkOpenedByAdmin={isLinkOpenedByAdmin}
                  amountCalc={amountCalculator}
                  userCart={userCart}
                  isUserRemoved={groupOrderInfo?.is_deleted}
                  isOrderCancelled={groupOrderInfo?.status === 2}
                  finalAmount={finalAmount}
                  onSaveOrder={onSaveOrder}
                  onEditOrder={onEditOrder}
                  onCancelOrder={onCancelOrder}
                  onCheckout={onCheckout}
                  needItemCount={false}
                >
                  {groupOrderInfo?.is_deleted ? (
                    <div class="redeem-points bg-beige text-dark mt-2 d-inline-block px-2 font-weight-bold">
                      You have been removed from the group order. You can not
                      add food items to the cart.
                    </div>
                  ) : null}
                  {groupOrderInfo?.status === 2 ? (
                    <div class="redeem-points bg-beige text-dark mt-2 d-inline-block px-2 font-weight-bold">
                      The group order has been canceled. You can not add any
                      food items to the cart anymore.
                    </div>
                  ) : null}
                </MobileGroupCart>
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <div className="shopping-cart">
          {groupBasket && groupBasket?.length ? (
            <div className="group-order-cartlisting">
              <div className="shopping-cart-header">
                <div className="d-flex justify-content-between">
                  <h4>Your Group Order</h4>
                  {onWebCheckoutPage ? null : (
                    <>
                      {isItAdminLink && groupOrderInfo?.status === 0 ? (
                        <div className="accordian border-0 m-0 p-0">
                          <a
                            role="button"
                            href
                            className="link-type-btn border-0 text-decoration-none"
                            onClick={onCancelOrder}
                          >
                            Cancel Order
                          </a>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                {onWebCheckoutPage ? null : (
                  <>
                    <div className="restra-container">
                      <label for="restraName">From</label>
                      <p className="restraName">
                        {groupOrderInfo?.restaurant_name}
                        {groupOrderInfo?.title
                          ? " - " + groupOrderInfo?.title
                          : ""}
                      </p>
                    </div>
                  </>
                )}
              </div>
              {groupOrderInfo?.is_deleted ? (
                <div class="redeem-points bg-beige text-dark mb-3 d-inline-block px-2 font-weight-bold">
                  You have been removed from the group order. You can not add
                  food items to the cart.
                </div>
              ) : null}
              <div className="group-order-lists">
                {groupBasket.map((res, idx) => (
                  <ul className="shopping-cart-items">
                    <li className="position-relative">
                      {groupOrderInfo?.is_deleted ? (
                        <div class="overlay-disabled"></div>
                      ) : null}
                      {groupOrderInfo?.status === 2 ? (
                        <div class="overlay-disabled"></div>
                      ) : null}
                      <RowAccordian
                        member={res}
                        key={idx}
                        isItUserCart={!isItAdminLink && !isLinkOpenedByAdmin}
                        isLinkOpenedByAdmin={isLinkOpenedByAdmin}
                        isItAdminLink={isItAdminLink}
                        amountCalc={amountCalculator}
                        isUserRemoved={groupOrderInfo?.is_deleted}
                        onMenuListingPage
                        isOrderCancelled={groupOrderInfo?.status === 2}
                      />
                    </li>
                  </ul>
                ))}
              </div>

              {onWebCheckoutPage ? null : (
                <>
                  <div className="position-relative">
                    {groupOrderInfo?.is_deleted ? (
                      <div class="overlay-disabled"></div>
                    ) : null}
                    {groupOrderInfo?.status === 2 ? (
                      <div class="overlay-disabled"></div>
                    ) : null}

                    {isItAdminLink ? (
                      <a
                        href
                        className={`checked-btn ${disableCheckout?'disabled-state':""}`}
                        // className={}
                        role="button"
                        onClick={disableCheckout?null:onCheckout}

                      >
                        <span>Checkout </span>{" "}
                        <span className="totalAmount">
                          {finalAmount ? "$" + finalAmount.toFixed(2) : ""}
                        </span>
                      </a>
                    ) : (
                      <>
                        {userCart ? (
                          <>
                            {userCart?.cart_status === 0 ? (
                              <a
                                href
                                className="checked-btn"
                                onClick={onSaveOrder}
                                role="button"
                              >
                                <span>Save Order</span>{" "}
                                <span className="totalAmount">
                                  {finalAmount
                                    ? "$" + finalAmount.toFixed(2)
                                    : ""}
                                </span>
                              </a>
                            ) : (
                              <a
                                href
                                className="checked-btn"
                                onClick={onEditOrder}
                                role="button"
                              >
                                <span>Edit Order</span>{" "}
                                <span className="totalAmount">
                                  {finalAmount
                                    ? "$" + finalAmount.toFixed(2)
                                    : ""}
                                </span>
                              </a>
                            )}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </>
              )}
              {groupOrderInfo?.status === 2 ? (
                <div class="redeem-points bg-beige text-dark mb-2 d-inline-block px-2 font-weight-bold">
                  The group order has been canceled. You can not add any food
                  items to the cart anymore.
                </div>
              ) : null}
            </div>
          ) : (
            <div className="no-items-in-cart">
              <h4>No items in your cart</h4>
            </div>
          )}
        </div>
      )}
      {toggleCancelOrder ? (
        <CancelOrderModal
          show={toggleCancelOrder}
          onSubmit={onCancelGroupOrder}
          closeModal={onCancelOrder}
          isMobileOnly={isMobileCart}
        />
      ) : null}
    </>
  );
};

export default GroupOrderCart;
