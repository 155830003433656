import React, { useState } from "react";
import ModalWrapper from "../../components/ModalWrapper";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { selectedAddress, setLoader } from "redux-store";
import { useHistory } from "react-router-dom";
import { formatter } from "../../service";
import SearchPlaces from "../../components/SearchPlaces";
import Geocode from "react-geocode";
import gpsLocationImg from "assets/images/gps-location.svg";
import locationMarkerImg from "assets/images/location-marker-icon.svg";

const ChooseAddress = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [address, setAddress] = useState("");
  const handleChange = (address) => {
    setAddress(address);
  };
  const handleCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const x = formatter({ results: [response.results[4]] });
          // props.handleChoosenAddress(x, {
          // 	lat: position.coords.latitude,
          // 	lng: position.coords.longitude,
          // });
          const {
            address,
            city,
            state,
            country,
            postal_code,
            street_number,
            route,
          } = x;
          dispatch(
            selectedAddress({
              address_lat: position.coords.latitude,
              address_lng: position.coords.longitude,
              entry_street_address: address,
              entry_postcode: postal_code,
              entry_suburb:
                street_number && route
                  ? street_number.concat(`, ${route}`)
                  : street_number
                  ? street_number
                  : route
                  ? route
                  : "",
              entry_state: state,
              entry_city: city,
            })
          );
          history.push(`/restaurants`);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };
  const handleAddChange = (item) => {
    dispatch(selectedAddress(item));
    history.push(`/restaurants`);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(async (results) => {
        const x = formatter({ results: results });
        const {
          address,
          city,
          state,
          country,
          postal_code,
          street_number,
          route,
        } = x;
        let latlong = await getLatLng(results[0]);
        return {
          latlong: latlong,
          address: results[0].formatted_address,
          entry_street_address: address,
          entry_postcode: postal_code,
          entry_suburb:
            street_number && route
              ? street_number.concat(`, ${route}`)
              : street_number
              ? street_number
              : route
              ? route
              : "",
          entry_state: state,
          entry_city: city,
        };
      })
      .then((latlong) => {
        const {
          entry_street_address,
          entry_postcode,
          entry_suburb,
          entry_state,
          entry_city,
        } = latlong;
        dispatch(
          selectedAddress({
            address_lat: latlong.latlong.lat,
            address_lng: latlong.latlong.lng,
            entry_street_address,
            entry_postcode,
            entry_suburb,
            entry_state,
            entry_city,
          })
        );
        history.push(`/restaurants`);
      })
      .catch((error) => console.error("Error", error));
  };
  return (
    <ModalWrapper
      headerText="Choose Address"
      handleBack={() => history.goBack()}
    >
      <section className="mobile-inner-section">
        <div className="change-address change-address-search">
          <div className="inner-wrapper pickaddress">
            <SearchPlaces
              address={address}
              handleChange={handleChange}
              handleSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div className="form-group mb-0">
                    <input
                      {...getInputProps({
                        type: "search",
                        className: "form-control",
                        id: "deliveryAddress",
                        placeholder: "Search address",
                      })}
                    />
                  </div>
                  <a
                    id="currentloaction"
                    className="current-loaction"
                    type="button"
                    style={{ paddingTop: "12px" }}
                  >
                    <div className="icon">
                      <img src={gpsLocationImg} alt="icon" />
                    </div>
                    <div
                      className="addressType"
                      onClick={handleCurrentLocation}
                    >
                      <p className="addressDetails">Use My Current Location</p>
                    </div>
                  </a>
                  {store.profile.allAddress.length &&
                  props.avoidSavedAddress == undefined && suggestions.length==0? (
                    <ul className="addressLists">
                      Saved Addresses
                      {store.profile.allAddress.map((item) => {
                        const {entry_street_address, entry_street_name, entry_suburb} = item
                        return ( 
                          <li onClick={() => handleAddChange(item)}>
                            <div className="addressType">
                              <p
                                className="addressDetails"
                                style={
                                  item.address_book_id ==
                                  item.customers_default_address_id
                                    ? { color: "#f6993f" }
                                    : {}
                                }
                              >
                                {entry_street_name? entry_suburb?entry_suburb + ", "+ entry_street_name:"" + entry_street_name:entry_street_address}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  <ul className="addressLists p-0">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <li
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <div className="icon">
                            <img src={locationMarkerImg} alt="icon" />
                          </div>
                          <div className="addressType">
                            <p>{suggestion.description} </p>
                            <p className="addressDetails">SD, USA</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </SearchPlaces>
          </div>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default ChooseAddress;
