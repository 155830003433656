import React, { useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import {
	selectedAddress,
	verifyAddress,
	getResturantsData,
	initiateGroupOrder,
} from "redux-store";
import { formatter } from "../../service";
import { useLocation, useParams } from "react-router-dom";
import SearchPlaces from "../../components/SearchPlaces";
import Geocode from "react-geocode";
import $ from "jquery";
import gpsLocationImg from "assets/images/gps-location.svg";
import { useWindowResize } from "../../components/useWindowResize";

const ChangeAddress = (props) => {
	const isMobileOnly = useWindowResize();
	const dispatch = useDispatch();
	const store = useSelector((store) => store);
	const [address, setAddress] = useState("");
	const handleChange = (address) => {
		setAddress(address);
	};

	const handleSelect = (address) => {
		geocodeByAddress(address)
			.then(async (results) => {
				let latlong = await getLatLng(results[0]);
				return {
					latlong: latlong,
					address: results[0].formatted_address,
					results,
				};
			})
			.then((latLng) => {
				const x = formatter({ results: latLng.results });
				props.handleChoosenAddress(x, latLng.latlong);
			})
			.catch((error) => console.error("Error", error));
	};

	const handleCurrentLocation = () => {
		$(".picktime .dropdown-menu").removeClass("show");
		navigator.geolocation.getCurrentPosition(function (position) {
			Geocode.fromLatLng(
				position.coords.latitude,
				position.coords.longitude,
			).then(
				(response) => {
					const x = formatter({ results: [response.results[4]] });
					props.handleChoosenAddress(x, {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
				},
				(error) => {
					console.error(error);
				},
			);
		});
	};

	const location = useLocation();

	const handleAddChange = (item) => {
		dispatch(selectedAddress(item));
		if (
			location.pathname != "/restaurants" &&
			location.pathname != "/restaurants/" &&
			!isMobileOnly
		) {
			dispatch(
				verifyAddress(
					{
						lat_from: item.address_lat.toString(),
						lng_from: item.address_lng.toString(),
						restaurant_id: store.rest.restaurant_id,
					},
					// ()=>{
					// 	setRedirectPage(true)
					// }
				),
			);
			if (Object.keys(store.profile.selectedAddress).length > 1) {
				dispatch(
					getResturantsData({
						lat_from: item.address_lat.toString(),
						lng_from: item.address_lng.toString(),
					}),
				);
			}
		} else {
			if (props.setRecall) {
				props.setRecall(true);
			}
		}
	};

	return (
		<section className="mobile-inner-section">
			<div className={isMobileOnly ? "change-address" : "form-wrapper"}>
				<div className="inner-wrapper pickaddress">
					<SearchPlaces
						address={address}
						handleChange={handleChange}
						handleSelect={handleSelect}
					>
						{({
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading,
						}) => (
							<div>
								<div className="form-group">
									<input
										{...getInputProps({
											type: "search",
											className: "form-control",
											id: "deliveryAddress",
											placeholder: "Search address",
										})}
										onClick={() => setAddress("")}
									/>
								</div>
								{isMobileOnly ? (
									<a
										id="currentloaction"
										className="current-loaction"
										type="button"
									>
										<div className="icon">
											<img src={gpsLocationImg} alt="icon" />
										</div>
										<div
											className="addressType"
											onClick={handleCurrentLocation}
										>
											<p className="addressDetails">Use My Current Location</p>
										</div>
									</a>
								) : null}
								{store.profile.allAddress.length &&
								props.avoidSavedAddress == undefined &&
								suggestions.length == 0 ? (
									<ul className="addressLists">
										Saved Addresses
										{store.profile.allAddress.map((item) => {
											const {
												entry_street_address,
												entry_street_name,
												entry_suburb,
											} = item;

											return (
												<li onClick={() => handleAddChange(item)}>
													<div className="addressType">
														<p
															className="addressDetails"
															style={
																item.address_book_id ==
																item.customers_default_address_id
																	? { color: "#f6993f" }
																	: {}
															}
														>
															{entry_street_name
																? entry_suburb
																	? entry_suburb + ", " + entry_street_name
																	: "" + entry_street_name
																: entry_street_address}
														</p>
													</div>
												</li>
											);
										})}
									</ul>
								) : null}

								{/* <ul className="addressLists p-0 landingpage-addresslist" style ={{marginLeft: '-22px'}}> */}
								{address.length > 3 ? (
									<ul className="addressLists p-0 ">
										{/*loading && <div>Loading...</div>*/}
										{suggestions.map((suggestion) => {
											const className = suggestion.active
												? "suggestion-item--active"
												: "suggestion-item";
											// inline style for demonstration purpose
											const style = suggestion.active
												? {
														backgroundColor: "#fafafa",
														cursor: "pointer",
												  }
												: {
														backgroundColor: "#ffffff",
														cursor: "pointer",
												  };
											return (
												<li
													{...getSuggestionItemProps(suggestion, {
														className,
														style,
													})}
												>
													<div className="addressType">
														<p>{suggestion.description} </p>
													</div>
												</li>
											);
										})}
									</ul>
								) : null}
							</div>
						)}
					</SearchPlaces>
				</div>
			</div>
		</section>
	);
};

export default ChangeAddress;
