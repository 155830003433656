const GET_RESTURANT_DATA = "GET_RESTURANT_DATA";
const GET_CUISINES_DATA = "GET_CUISINES_DATA";
const GET_RESTURANT_MENU = "GET_RESTURANT_MENU";
const SET_SELECTED_RESTURANT = "SET_SELECTED_RESTURANT";
const SET_UNSELECTED_RESTURANT = "SET_UNSELECTED_RESTURANT";
const RESTURANT_ID = "RESTURANT_ID";
const GET_ITEM_ADDON = "GET_ITEM_ADDON";
const SELECTED_MENU_ITEM = "SELECTED_MENU_ITEM";
const REST_TIME_SELECTION = "REST_TIME_SELECTION";
const SET_GROUP_ORDER = "SET_GROUP_ORDER";
const SET_GROUP_ORDER_INFO = "SET_GROUP_ORDER_INFO";
const SET_SELECTED_GROUP_ORDER_ITEM = "SET_SELECTED_GROUP_ORDER_ITEM";

export {
  GET_RESTURANT_DATA,
  GET_CUISINES_DATA,
  GET_RESTURANT_MENU,
  SET_SELECTED_RESTURANT,
  SET_UNSELECTED_RESTURANT,
  RESTURANT_ID,
  GET_ITEM_ADDON,
  SELECTED_MENU_ITEM,
  REST_TIME_SELECTION,
  SET_GROUP_ORDER,
  SET_GROUP_ORDER_INFO,
  SET_SELECTED_GROUP_ORDER_ITEM,
};
