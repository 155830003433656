import {
  ADD_TO_CART,
  APPLY_COUPAN,
  GIFT_CARD,
  CLEAN_GIFT_CARDS,
  WALLET,
  PLACE_ORDER_CALC,
  PLACE_ORDER_INSTRUCTION,
  PLACE_ORDER_INSTRUCTION_GROUP,
  PAYMENT_SETTINGS,
  CLIENT_TOKEN,
  ORDERSLIST,
  ADD_FUNDS,
  DISABLE_CART_ITEMS,
  SET_GROUP_CART_ITEMS,
  SET_GROUP_CART_PAYMENT_SUMMARY,
} from "./cartType";

const initialState = {
  cart: {},
  tips: [10, 15, 20, 25],
  giftCard: {},
  coupan: {},
  wallet_amount: { amount: 0, customer_id: "intial" },
  placeOrderCharges: {},
  instruction: "",
  instructionGroup:"",
  taxdata: {
    local_tax_rate: 0,
    low_order_fee: 0,
    low_order_min_amount: 0,
    state_tax_rate: 0,
  },
  clientToken: null,
  ordersList: [],
  disabledProducts: {},
  
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const arr = { ...action.payload };
      const { items = [] } = arr;
      const x = items.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total,
        0
      );
      return {
        ...state,
        cart: { ...action.payload, cartTotal: x },
      };
    case GIFT_CARD:
      if (action.payload.isDelete) {
        let copy = { ...state.giftCard };
        delete copy[action.payload.giftcard_id];
        return {
          ...state,
          giftCard: copy,
        };
      } else {
        return {
          ...state,
          giftCard: {
            ...state.giftCard,
            [action.payload.giftcard_id]: action.payload,
          },
        };
      }
    case CLEAN_GIFT_CARDS:
      return {
        ...state,
        giftCard: {},
      };

    case APPLY_COUPAN:
      return {
        ...state,
        coupan: action.payload,
      };
    case WALLET:
      return {
        ...state,
        wallet_amount: action.payload,
      };
    case PLACE_ORDER_CALC:
      return {
        ...state,
        placeOrderCharges: action.payload,
      };
    case PLACE_ORDER_INSTRUCTION:
      return {
        ...state,
        instruction: action.payload,
      };
    case PAYMENT_SETTINGS:
      return {
        ...state,
        taxdata: action.payload,
      };
    case CLIENT_TOKEN:
      return {
        ...state,
        clientToken: action.payload,
      };
    case ORDERSLIST:
      return {
        ...state,
        ordersList: action.payload,
      };
    case ADD_FUNDS:
      return {
        ...state,
        wallet_amount: { ...state.wallet_amount, ...action.payload },
      };
    case DISABLE_CART_ITEMS:
      return {
        ...state,
        disabledProducts: action.payload,
      };

    default:
      return state;
  }
};

const groupCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP_CART_ITEMS:
      const arr = { ...action.payload };
      const { items = [] } = arr;
      const selectedUserCart = items[0];
      const x = items.reduce(
        (previousValue, currentValue) => previousValue + +currentValue.total,
        0
      );
      return {
        ...state,
        cart: {
          ...action.payload,
          selectedUserCart: selectedUserCart,
          cartTotal: x,
        },
      };
    case GIFT_CARD:
      if (action.payload.isDelete) {
        let copy = { ...state.giftCard };
        delete copy[action.payload.giftcard_id];
        return {
          ...state,
          giftCard: copy,
        };
      } else {
        return {
          ...state,
          giftCard: {
            ...state.giftCard,
            [action.payload.giftcard_id]: action.payload,
          },
        };
      }
    case APPLY_COUPAN:
      return {
        ...state,
        coupan: action.payload,
      };
    case WALLET:
      return {
        ...state,
        wallet_amount: action.payload,
      };
    case SET_GROUP_CART_PAYMENT_SUMMARY:
      return {
        ...state,
        placeOrderCharges: action.payload,
      };
    case PLACE_ORDER_INSTRUCTION_GROUP:
      return {
        ...state,
        instructionGroup: action.payload,
      };
    case PLACE_ORDER_INSTRUCTION_GROUP: 
      return {
        ...state,
        instructionGroup: action.payload,
      }
    case PAYMENT_SETTINGS:
      return {
        ...state,
        taxdata: action.payload,
      };
    case CLIENT_TOKEN:
      return {
        ...state,
        clientToken: action.payload,
      };
    case ADD_FUNDS:
      return {
        ...state,
        wallet_amount: { ...state.wallet_amount, ...action.payload },
      };

    default:
      return state;
  }
};

export { cartReducer, groupCartReducer };
