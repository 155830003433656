import React, { useState, useEffect, useRef } from "react";
import { useWindowResize } from "../../components/useWindowResize";
import Geocode from "react-geocode";
import { formatter } from "../../service";
import Map from "../../components/Map";

Geocode.setApiKey("AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g");
Geocode.setLanguage("en");
const regPost = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const ScrollToTop = ({ children, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.step]);
  return children || null;
};
const Address = (props) => {
  const isMobileOnly = useWindowResize()
  const {
    entry_street_address = "",
    address_lat,
    address_lng,
    set_default,
  } = props.addrFields;
  const [marker, setMarker] = useState({
    showMarker: false,
    name: "Current position",
    position: {
      lat: 37.77,
      lng: -122.42,
    },
  });

  useEffect(() => {
    setMarker({
      ...marker,
      showMarker: true,
      position: { lat: Number(address_lat), lng: Number(address_lng) },
    });
  }, [props.addrFields]);

  useEffect(() => {
    props.setValue("entry_state", props.addrFields.entry_state, {
      shouldDirty: true,
    });
    props.setValue("entry_suburb", props.addrFields.entry_suburb, {
      shouldDirty: true,
    });
    props.setValue("entry_street_name", props.addrFields.entry_street_name, {
      shouldDirty: true,
    });
    props.setValue("entry_postcode", props.addrFields.entry_postcode, {
      shouldDirty: true,
    });
    props.setValue("entry_company", props.addrFields.entry_company, {
      shouldDirty: true,
    });
    props.setValue("entry_city", props.addrFields.entry_city, {
      shouldDirty: true,
    });
  }, []);

  useEffect(() => {
    if (!props.isEdit) {
      if (props.coords) {
        setMarker({
          ...marker,
          showMarker: true,
          position: {
            lat: Number(props.coords.latitude),
            lng: Number(props.coords.longitude),
          },
        });
        formatAddress(
          props.coords.latitude.toString(),
          props.coords.longitude.toString()
        );
      }
    } else {
      setMarker({
        ...marker,
        showMarker: true,
        position: { lat: Number(address_lat), lng: Number(address_lng) },
      });
    }
  }, []);
  const formatAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const x = formatter(response);
        props.handleChoosenAddress(x, { lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const [map, setMap] = React.useState(null);
  const handleOnLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(10);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);

  // The places I want to create markers for.
  // This could be a data-driven prop.
  const myPlaces = [
    { id: "place3", pos: { lat: 39.07602397235644, lng: -94.5184089401211 } },
  ];

  // Iterate myPlaces to size, center, and zoom map to contain all markers
  const fitBounds = (map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // // myPlaces.map(place => {
    //   bounds.extend({ lat: Number(marker.position.lat), lng: Number(marker.position.lng) });
    // //   return place.id;
    // // });
    // map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
    setZoom(15);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(13);
    } else {
      setZoom(17);
    }

    // if you want to center the selected Marker
    //setCenter(place.pos)
  };

  return (
    <ScrollToTop step={props.step}>
      <div className="modal-body">
        <section className="mobile-inner-section profile-details-section">
          <div className="row">
            <div className="col-12">
              <div className="location-wrapper position-relative">
                {
                  marker.showMarker && (
                    <Map
                      isAddress={{
                        showMarker: false,
                        name: "Current position",
                        position: {
                          lat: 37.77,
                          lng: -122.42,
                        },
                      }}
                      myPlaces={[
                        {
                          id: entry_street_address.split(",")[0],
                          pos: {
                            lat: Number(marker.position.lat),
                            lng: Number(marker.position.lng),
                          },
                        },
                      ]}
                      loadHandler={loadHandler}
                      setCenter={setCenter}
                      setClickedLatLng={setClickedLatLng}
                      center={{
                        lat: Number(marker.position.lat),
                        lng: Number(marker.position.lng),
                      }}
                      zoom={zoom}
                      markerLoadHandler={markerLoadHandler}
                      markerClickHandler={markerClickHandler}
                      markerMap={markerMap}
                      setInfoOpen={setInfoOpen}
                      infoOpen={infoOpen}
                      selectedPlace={selectedPlace}
                      mapRef={mapRef}
                      setSelectedPlace={setSelectedPlace}
                      setZoom={setZoom}
                    />
                  )
                  // 	<Map
                  // 	draggable={false}
                  // 	disableDoubleClickZoom={true}
                  // 	zoomControl={false}
                  // 	mapTypeControl={false}
                  // 	 scaleControl={false}
                  // 	 streetViewControl={false}
                  // 	 panControl={false}
                  // 	 rotateControl={false}
                  // 	 fullscreenControl={false}

                  // 	defaultOptions={{
                  // 		fullscreenControl: false,
                  // 	}}
                  // 		google={props.google}
                  // 		style={{
                  // 			position: "relative",
                  // 			width: "100%",
                  // 			height: "300px",
                  // 		}}
                  // 		initialCenter={marker.position}
                  // 		center={marker.position}
                  // 		zoom={14}
                  // 	>
                  // 		<Marker
                  // 			position={marker.position}
                  // 			draggable={false}
                  // 			onDragend={onMarkerDragEnd}
                  // 			name={marker.name}
                  // 			className="img-fluid"
                  // 		/>
                  // 	</Map>
                  // )
                }
              </div>
            </div>
          </div>
          <div className="form-wrapper">
            <form
              action=""
              onSubmit={props.handleSubmit(props.handleAddAddress)}
            >
              <div className="modal-subheading">
                <h4
                  style={
                    isMobileOnly
                      ? {
                          paddingTop: "15px",
                          paddingBottom: "5px",
                        }
                      : {}
                  }
                >
                  Set Your Delivery Location
                </h4>
              </div>
              <div className="form-group position-relative">
                <input
                  onFocus={() => props.setStep(2)}
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Rapid City"
                  name={"entry_street_address"}
                  // ref={props.register()}
                  value={entry_street_address}
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  style={
                    props.errors.entry_firstname
                      ? { borderColor: "#dc3545" }
                      : {}
                  }
                  id="firstName"
                  placeholder="First Name"
                  name="entry_firstname"
                  ref={props.register({
                    required: "First name is required",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "First name is required",
                    },
                  })}
                  maxLength={50}
                />
                {props.errors.entry_firstname && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_firstname.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  style={
                    props.errors.entry_lastname
                      ? { borderColor: "#dc3545" }
                      : {}
                  }
                  id="lastName"
                  placeholder="Last Name"
                  name="entry_lastname"
                  ref={props.register({
                    required: "Last name is required",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "Last name is required",
                    },
                  })}
                  maxLength={50}
                />
                {props.errors.entry_lastname && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_lastname.message}
                  </div>
                )}
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  style={
                    props.errors.entry_company ? { borderColor: "#dc3545" } : {}
                  }
                  id="lastName"
                  placeholder="Company name"
                  name="entry_company"
                  ref={props.register({})}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Apt/suite"
                  style={
                    props.errors.entry_suburb ? { borderColor: "#dc3545" } : {}
                  }
                  name={"entry_suburb"}
                  ref={props.register({
                    required: "Please enter Apt/suite",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "Please enter Apt/suite",
                    },
                  })}
                 
                />
                {props.errors.entry_suburb && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_suburb.message}
                  </div>
                )}
              </div>
              
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Street Name"
                  style={
                    props.errors.entry_street_name ? { borderColor: "#dc3545" } : {}
                  }

                  name={"entry_street_name"}
                  ref={props.register({
                    required: "Please enter street name",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "Please enter street name",
                    },
                  })}
                 
                />
                {props.errors.entry_street_name && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_street_name.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="City"
                  name={"entry_city"}
                  ref={props.register({
                    required: "Please enter your city",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "Please enter your city",
                    },
                  })}
                  style={
                    props.errors.entry_city ? { borderColor: "#dc3545" } : {}
                  }
                />
                {props.errors.entry_city && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_city.message}
                  </div>
                )}
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="State"
                  name={"entry_state"}
                  ref={props.register({
                    required: "Please enter your state",
                    pattern: {
                      value: /[^\s*$]/,
                      message: "Please enter your state",
                    },
                  })}
                  style={
                    props.errors.entry_state ? { borderColor: "#dc3545" } : {}
                  }
                  // value={entry_state}
                  // onChange={(e) =>
                  // 	props.setAddrFields({
                  // 		...props.addrFields,
                  // 		entry_state: e.target.value,
                  // 	})
                  // }
                />
                {props.errors.entry_state && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_state.message}
                  </div>
                )}
              </div>

              <div className="form-group pb-3">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Pincode"
                  name={"entry_postcode"}
                  ref={props.register({
                    required: "Please provide a postal code",
                    pattern: {
                      value: regPost,
                      message: "Please provide valid postal code",
                    },
                  })}
                  style={
                    props.errors.entry_postcode
                      ? { borderColor: "#dc3545" }
                      : {}
                  }
                  // value={entry_postcode}
                  // onChange={(e) =>
                  // 	props.setAddrFields({
                  // 		...props.addrFields,
                  // 		entry_postcode: e.target.value,
                  // 	})
                  // }
                />
                {props.errors.entry_postcode && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#e3342f",
                    }}
                  >
                    {props.errors.entry_postcode.message}
                  </div>
                )}
              </div>

              {/* <div className="form-group pb-2">
								<div className="radio-wrapper">
									<label
										className="radio-container"
										onClick={() =>
											props.setAddrFields({
												...props.addrFields,
												address_type: "1",
											})
										}
									>
										Home
										<input type="radio" checked="checked" name="radio" />
										<span className="checkmark"></span>
									</label>
									<label
										className="radio-container"
										onClick={() =>
											props.setAddrFields({
												...props.addrFields,
												address_type: "3",
											})
										}
									>
										Work
										<input type="radio" name="radio" />
										<span className="checkmark"></span>
									</label>
									<label
										className="radio-container"
										onClick={() =>
											props.setAddrFields({
												...props.addrFields,
												address_type: "2",
											})
										}
									>
										Others
										<input type="radio" name="radio" />
										<span className="checkmark"></span>
									</label>
								</div>
							</div> */}
              <div className="form-group pb-3">
                <div className="subscribe-offers">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="subscribe-email"
                      name={"set_default"}
                      // ref={props.register()}
                      onChange={(e) =>
                        props.isEdit
                          ? props.setAddrFields({
                              ...props.addrFields,
                              entry_firstname: props.addrFields.entry_firstname
                                ? props.addrFields.entry_firstname
                                : "",
                              entry_lastname: props.addrFields.entry_lastname
                                ? props.addrFields.entry_lastname
                                : "",
                              entry_company: props.addrFields.entry_company
                                ? props.addrFields.entry_company
                                : "",
                              entry_city: props.addrFields.entry_city
                                ? props.addrFields.entry_city
                                : "",
                              entry_state: props.addrFields.entry_state,
                              entry_suburb: props.addrFields.entry_suburb,
                              entry_street_name:props.addrFields.entry_street_name,
                              entry_postcode: props.addrFields.entry_postcode,
                              set_default: e.target.checked ? 1 : 0,
                            })
                          : props.setAddrFields({
                              ...props.addrFields,
                              set_default: e.target.checked ? 1 : 0,
                            })
                      }
                      checked={set_default}
                    />
                    <label
                      className="custom-control-label"
                      for="subscribe-email"
                    >
                      Make this Address as Primary
                    </label>
                  </div>
                </div>
              </div>
              <div className="actions-wrapper">
                <button
                  className="actn-btn actn-btn-filled"
                  // data-toggle="modal"
                  data-target="#addAddressModal"
                  // data-dismiss="modal"
                >
                  {props.isEdit ? "Update Address" : "Save Address"}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </ScrollToTop>
  );
};
// export default Address
// export default GoogleApiWrapper({
//   apiKey: ("AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g")
// })(Address)

export default Address;
