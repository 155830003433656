import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal,Button } from 'react-bootstrap';
const Popup = (props) => {
  let i = ""
  let c = ""
  switch(props.modalIsOpen.code){
    case  "tip":
      i = "driverTipModal"
      c = "modal fade deliveryAddressModal"
      break;
    case "Address":
      i = "deliveryAddressModal"
      c = "modal fade deliveryAddressModal"
      break;
    case  "datentime":
      i = ""
      c = "pickdate_on_checkout"
      break;
    case  "funds":
      i = ""
      c = ""
      break;
  }
  return(
    <Modal
      className={c}
      id={i}
    // className="modal fade deliveryAddressModal" id="deliveryAddressModal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {props.children}
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
  )


}

export default Popup