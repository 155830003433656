import React from "react";

const CitiesContainer = (props) => {
  const {
    c={},
    dispatch,
    selectedAddress
  } = props
	return (
		<li
			key={c.city_name}
			style={{ cursor: "pointer" }}
			onClick={() =>
				dispatch(
					selectedAddress({
						address_lat: Number(c.lat),
						address_lng: Number(c.lng),
						entry_street_address: c.city_name,
						entry_city:c.city_name,
					}),
				)
			}
		>
			<a>{c.city_name}</a>
		</li>
	);
};
export default CitiesContainer;
