/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useHistory, useParams } from "react-router-dom";

import deleteIcon from "assets/images/delete-icon-sp3.svg";
import {
  cancelGroupOrder,
  initiateGroupOrder,
  removeGroupMember,
} from "redux-store";

import closeImg from "assets/images/modal-close.svg";

Yup.addMethod(Yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    if (array && array?.length) {
      const uniqueData = Array.from(
        new Set(array.map((row) => row[field]?.toLowerCase()))
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
      );
      if (array[index][field] === "") {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    }
    return true;
  });
});

const GroupOrderForm = (props) => {
  const { members, toggleContacts, onComplete, isReorder } = props;
  const limits = [0, 15, 20, 25];
  const [customLimit, setCustomLimit] = useState(null);
  const minimumMembers = 1;
  const dispatch = useDispatch();
  const [selectedLimit, setSelectedLimit] = useState(0);
  const [openOthers, setOpenOthers] = useState(false);
  const [fieldLengthErrorMessage, setFieldLengthErrorMessage] = useState(null);
  const { groupOrderInfo, groupOrder } = useSelector((store) => store.rest);
  const { customers_email_address, customers_telephone } = useSelector(
    (store) => store.profile
  );
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { groupToken } = useParams();
  const history = useHistory();

  const [toggleRemoveModal, setToggleRemoveModal] = useState(false);
  const [selectedMemberToRemove, setMemberToRemove] = useState({
    idx: null,
    member: null,
  });

  const [isItFinalUserToRemove, setIsItFinalUserToRemove] = useState(false);

  const isGroupOrderStarted = groupOrder.group_order_id > 0;

  const memberSchema = Yup.object().shape(
    {
      id: Yup.number().default(0).required(),
      reinvite: Yup.boolean(),
      name: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9\s]+$/, {
          message: "Only alphanumeric characters allowed",
          excludeEmptyString: true,
        })
        .max(50, ({ max }) => `Name not more than ${max} characters`)
        .required("Please enter name"),
      email: Yup.string().when("phone", {
        is: "",
        then: Yup.string()
          .trim()
          .email("Invalid email format")
          .notOneOf(
            [customers_email_address],
            "Entered email id has been added for another user. Please enter a different email id"
          )
          .max(100, ({ max }) => `Email not more than ${max} characters`)
          .required("Please enter email"),
        otherwise: Yup.string()
          .trim()
          .email("Invalid email format")
          .notOneOf(
            [customers_email_address],
            "Entered email id has been added for another user. Please enter a different email id"
          )
          .max(100, ({ max }) => `Email not more than ${max} characters`),
      }),
      phone: Yup.string().when("email", {
        is: "",
        then: Yup.string()
          .trim()
          .matches(/^(\+\d{1,2})?(\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
            message: "Invalid phone format",
            excludeEmptyString: true,
          })
          .notOneOf(
            [customers_telephone ? "+1" + customers_telephone : ""],
            "Entered mobile number has been added for another user. Please enter a different mobile number"
          )
          .max(30, ({ max }) => `Phone not more than ${max} characters`)
          .required("Please enter phone"),
        otherwise: Yup.string()
          .matches(/^(\+\d{1,2})?(\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
            message: "Invalid phone format",
            excludeEmptyString: true,
          })
          .trim()
          .notOneOf(
            [customers_telephone ? "+1" + customers_telephone : ""],
            "Entered mobile number has been added for another user. Please enter a different mobile number"
          )
          .max(30, ({ max }) => `Phone not more than ${max} characters`),
      }),
    },
    [["email", "phone"]]
  );

  const validationSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(30, ({ max }) => `label not more than ${max} characters`),
    limit: Yup.number()
      .default(0)
      .transform((value) => (isNaN(value) ? undefined : value))
      .moreThan(-1, ({ more }) => `Limit cannot be negative`)
      .max(10000, ({ max }) => `Limit not more than ${max}`)
      .required(),
    member: Yup.array()
      .of(memberSchema)
      .min(minimumMembers, "Minimum one member is required")
      .unique(
        "email",
        "Entered email id has been added for another user. Please enter a different email id"
      )
      .unique(
        "phone",
        "Entered mobile number has been added for another user. Please enter a different mobile number"
      ),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    errors,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: groupOrderInfo?.id,
      name: groupOrderInfo?.title,
      limit: groupOrderInfo?.amount_limit,
      member: groupOrderInfo?.members?.map((it) => ({
        id: it.id,
        name: it.name,
        email: it.email,
        phone: it.phone,
        reinvite: false,
      })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "member",
    keyName: "memberId",
  });

  useEffect(() => {
    if (!groupOrderInfo?.members && members && members?.length === 0) {
      onAddMember();
    }
    if (members && members?.length > 0) {
      remove();
      let mam = members.map((it) => ({
        id: 0,
        name: it.name,
        email: it.email,
        phone: it.phone,
        reinvite: false,
      }));
      reset({ member: mam });
    }
  }, [members]);

  useEffect(() => {
    if (groupOrderInfo?.members && groupOrderInfo?.members?.length === 0) {
      onAddMember();
    }
    if (groupOrderInfo?.amount_limit > -1) {
      setOpenOthers(!limits.includes(groupOrderInfo?.amount_limit));
      setSelectedLimit(groupOrderInfo?.amount_limit);
      if (!limits.includes(groupOrderInfo?.amount_limit))
        setCustomLimit(groupOrderInfo?.amount_limit);
    }
  }, [groupOrderInfo]);

  useEffect(() => {
    setFieldLengthErrorMessage(null);
    if (fields && fields.length < minimumMembers) {
      setFieldLengthErrorMessage("Minimum one member is required");
    } else {
      setFieldLengthErrorMessage(null);
    }
  }, [fields]);

  const onSubmit = (data) => {
    if (openOthers && !customLimit) return;
    if (groupOrderInfo?.status === 2) return;

    let model = {
      ...data,
    };

    model["limit"] = +selectedLimit;

    dispatch(
      initiateGroupOrder(model, (res) => {
        onComplete();
        window.location.replace(res);
      })
    );
  };

  const onDeleteMember = (idx, member) => {
    if (groupOrderInfo?.status === 2) return;

    if (member?.id > 0) {
      let addedUserCount = fields?.filter((it) => it.id > 0)?.length;
      if (addedUserCount === 1) {
        setIsItFinalUserToRemove(true);
      } else {
        setIsItFinalUserToRemove(false);
      }
      setMemberToRemove({ idx: idx, member: member });
      openRemoveModal();
    } else {
      remove(idx);
    }
  };

  const removeMemberFromGroup = (idx, member) => {
    if (isItFinalUserToRemove) {
      dispatch(
        cancelGroupOrder(groupToken, () => {
          closeRemoveModal();
          history.replace("/restaurants");
        })
      );
    } else {
      let member = selectedMemberToRemove.member;
      let idx = selectedMemberToRemove.idx;
      dispatch(
        removeGroupMember(groupOrderInfo?.id, member.id, (res) => {
          closeRemoveModal();
          remove(idx);
        })
      );
    }
  };

  const openRemoveModal = () => {
    setToggleRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setToggleRemoveModal(false);
  };

  const onAddMember = () => {
    if (groupOrderInfo?.status === 2) return;
    append({ id: 0, name: "", email: "", phone: "", reinvite: false });
  };

  const onCustomLimit = (e) => {
    let val = e.target.value.replace(/-/g, "");

    setCustomLimit(val);
    setSelectedLimit(val);
    setValue("limit", val, { shouldValidate: true, shouldDirty: true });
  };

  const setLimit = () => {
    if (groupOrderInfo?.status === 2) return;
    let val = +getValues("limit");
    setSelectedLimit(val);
    setOpenOthers(false);
  };

  const onOtherHandler = () => {
    if (groupOrderInfo?.status === 2) return;
    if (openOthers === false) {
      setSelectedLimit(null);
      setValue("limit", null, { shouldValidate: true, shouldDirty: true });
    }
    setOpenOthers(true);
  };

  const onImportContact = () => {
    if (groupOrderInfo?.status === 2) return;
    toggleContacts(true);
  };

  const onSelectAll = (checked) => {
    if (groupOrderInfo?.status === 2) return;
    setIsSelectAll(!isSelectAll);
    let data = fields.map((it) => ({
      id: it.id,
      name: it.name,
      email: it.email,
      phone: it.phone,
      reinvite: checked,
    }));
    setValue("member", data);
  };

  return (
    <>
      <div className="devilery-address-modal-wrapper">
        <div className="devilery-address-modal-wrapper-inner">
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 mt-0 pt-0 mb-2">
                  <div className="otherway-payment-payments">
                    <div className="reorder-input-wrapper">
                      <input
                        name="id"
                        defaultValue={0}
                        ref={register()}
                        hidden
                      />
                      <input
                        type="text"
                        className={`form-control ${
                          errors?.name ? "is-invalid" : ""
                        }`}
                        placeholder="Group label"
                        name="name"
                        ref={register()}
                        readOnly={
                          !isReorder &&
                          (isGroupOrderStarted || groupOrderInfo?.status === 2)
                        }
                      />

                      <button
                        type="button"
                        onClick={onImportContact}
                        className="btn btn-outline import-cnct-btn"
                        disabled={
                          isGroupOrderStarted || groupOrderInfo?.status === 2
                        }
                      >
                        Import Contacts
                      </button>
                    </div>
                    {errors?.name?.message && (
                      <div className="invalid-feedback d-block">
                        {errors?.name?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="hr-dashed" />
            </div>

            <div className="col-12 mb-4 payment__page__wrapper">
              <div className="add-fund-wrapper align-items-center">
                <div className="amount w-auto mr-3">Per person order limit</div>
                <div className="balance-slab">
                  <div className="btn-group">
                    {limits &&
                      limits.map((it, idx) => (
                        <>
                          <label
                            htmlFor={"amt-" + idx}
                            className={`btn btn-secondary mb-0 ${
                              selectedLimit === it ? "active focus" : ""
                            }`}
                            key={idx}
                            onClick={setLimit}
                          >
                            <input
                              type="radio"
                              id={"amt-" + idx}
                              name="limit"
                              value={it}
                              defaultValue
                              ref={register()}
                            />
                            {it === 0 ? "None" : "$" + it}
                          </label>
                        </>
                      ))}
                    <label
                      className={`btn btn-secondary mb-0 ${
                        openOthers ? "active focus" : ""
                      }`}
                      onClick={onOtherHandler}
                    >
                      <input type="radio" id="amt-othr" />
                      Other
                    </label>
                  </div>
                </div>
                {openOthers && (
                  <input
                    type="number"
                    className={`form-control ${
                      errors?.limit || !customLimit ? "is-invalid" : ""
                    }`}
                    value={customLimit}
                    onChange={onCustomLimit}
                    placeholder="$"
                  />
                )}
              </div>
              {errors?.limit?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.limit?.message}
                </div>
              )}

              <div className="row">
                <div className="col-12">
                  <hr className="hr-dashed" />
                  <div className="otherway-payment-payments">
                    {isGroupOrderStarted && (
                      <>
                        {fields.length >= minimumMembers && (
                          <div className="d-block mb-2">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="selectAll"
                                onChange={(e) => onSelectAll(e.target.checked)}
                                checked={isSelectAll}
                              />
                              <label
                                className="custom-control-label pl-2"
                                htmlFor="selectAll"
                              >
                                Select All
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {fields &&
                      fields.map((mem, idx) => (
                        <>
                          <div
                            className="card-details-reorder-wrapper align-items-center"
                            key={mem.memberId}
                          >
                            <input
                              hidden
                              name={`member[${idx}].id`}
                              defaultValue={mem.id}
                              ref={register()}
                            />
                            {isGroupOrderStarted && (
                              <div className="d-block">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={"select" + idx}
                                    name={`member[${idx}].reinvite`}
                                    defaultChecked={mem.reinvite}
                                    ref={register()}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"select" + idx}
                                    onClick={() => setIsSelectAll(false)}
                                  ></label>
                                </div>
                              </div>
                            )}
                            <div className="d-block w-100 position-relative">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors?.member?.[idx]?.name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Name"
                                name={`member[${idx}].name`}
                                defaultValue={mem.name}
                                ref={register()}
                                readOnly={
                                  mem.id > 0 || groupOrderInfo?.status === 2
                                }
                              />
                              {errors?.member?.[idx]?.name?.message && (
                                <div className="invalid-feedback d-block position-absolute">
                                  {errors?.member?.[idx]?.name?.message}
                                </div>
                              )}
                            </div>
                            <div className="d-block w-100">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors?.member?.[idx]?.email &&
                                  errors?.member?.[idx]?.email?.type !==
                                    "required"
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Email ID"
                                name={`member[${idx}].email`}
                                defaultValue={mem.email}
                                ref={register()}
                                readOnly={
                                  mem.id > 0 || groupOrderInfo?.status === 2
                                }
                              />
                              {errors?.member?.[idx]?.email?.message &&
                                errors?.member?.[idx]?.email?.type !==
                                  "required" && (
                                  <div className="invalid-feedback d-block">
                                    {errors?.member?.[idx]?.email?.message}
                                  </div>
                                )}
                            </div>
                            <div className="d-block w-100">
                              <Controller
                                as={InputMask}
                                control={control}
                                mask="+19999999999"
                                type="text"
                                className={`form-control ${
                                  errors?.member?.[idx]?.phone &&
                                  errors?.member?.[idx]?.phone?.type !==
                                    "required"
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="123-456-7890"
                                name={`member[${idx}].phone`}
                                defaultValue={mem.phone}
                                readOnly={
                                  mem.id > 0 || groupOrderInfo?.status === 2
                                }
                              />
                              {errors?.member?.[idx]?.phone?.message &&
                                errors?.member?.[idx]?.phone?.type !==
                                  "required" && (
                                  <div className="invalid-feedback d-block">
                                    {errors?.member?.[idx]?.phone?.message}
                                  </div>
                                )}
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn delete-btn"
                                disabled={groupOrderInfo?.status === 2}
                                onClick={() => onDeleteMember(idx, mem)}
                              >
                                <img src={deleteIcon} alt="" />
                              </button>
                            </div>
                          </div>
                          {errors?.member?.[idx]?.email?.type === "required" &&
                          errors?.member?.[idx]?.phone?.type === "required" ? (
                            <div className="invalid-feedback d-block optional-error">
                              Email or phone is required
                            </div>
                          ) : null}
                        </>
                      ))}
                  </div>
                  {groupOrderInfo?.status === 2 ? null : (
                    <a
                      href
                      className="btn link-type-btn link-btn-cmn"
                      onClick={onAddMember}
                    >
                      +Add Another Person
                    </a>
                  )}
                </div>
                <div className="col-12">
                  {fieldLengthErrorMessage && (
                    <div className=" invalid-feedback d-block">
                      {fieldLengthErrorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12">
              <hr className="hr-dashed" />
              <div className="modal-bottom-actions text-right">
                <button
                  disabled={groupOrderInfo?.status === 2}
                  type="submit"
                  className="btn btn-filled mx-auto w-auto"
                >
                  {isReorder
                    ? "Reorder Group Order"
                    : isGroupOrderStarted
                    ? "Update Group Order"
                    : "Create Group Order"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {toggleRemoveModal ? (
        <Modal
          id="cancelOrder-modal"
          show={toggleRemoveModal}
          onHide={closeRemoveModal}
          keyboard={false}
          backdropClassName="cancelOrderBackdrop"
          centered
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeRemoveModal}
            >
              <span aria-hidden="true">
                <img src={closeImg} alt="icon" className="img-fluid" />
              </span>
            </button>
            <div className="congrates-wrapper">
              <div className="content-box">
                <h1>{isItFinalUserToRemove ? "Warning" : "Remove User"}</h1>
                <p>
                  {isItFinalUserToRemove ? (
                    <>
                      If you remove the last user from the list then the group
                      will be canceled.
                    </>
                  ) : (
                    <>
                      Are you sure you want to remove the{" "}
                      {selectedMemberToRemove.member?.name} from the group
                      order.
                    </>
                  )}
                </p>
              </div>
              <div className="modal-bottom-actions w-100">
                <div className="d-flex justify-content-between mt-5">
                  <button
                    type="button"
                    className="btn btn-outline p-3 mr-2 w-50"
                    onClick={closeRemoveModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-filled p-3 ml-2 w-50"
                    onClick={removeMemberFromGroup}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default GroupOrderForm;
