import React from "react";
import { Modal, Button } from "react-bootstrap";
const Popup = (props) => {
	return (
		<Modal
      className="modal fade"
      id="congrats-modal"
			{...props}
			// size="lg"
			aria-labelledby="congrats-modalLabel"
			centered
		>
			{props.children}
		</Modal>
	);
};

export default Popup;
