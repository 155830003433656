import React, { useState } from "react";
import ModalWrapper from "../../components/ModalWrapper";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import {
	selectedAddress,
	selectedMenuItem,
	setLoader,
	setSelectedGroupOrderItem,
} from "redux-store";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const SearchList = (props) => {
	let history = useHistory();
	const dispatch = useDispatch();

	const handleMenuItem = (item) => {
		dispatch(
			setSelectedGroupOrderItem({
				...item,
				products_price: Number(item.products_price),
				restaurant_name: props.selectedRestData.name,
				restaurant_id: props.selectedRestData.restaurant_id,
				latlng: {
					lat: props.selectedRestData.lat,
					lng: props.selectedRestData.lng,
				},
				service_fee: props.selectedRestData.service_fee,
				restaurant_delivery_fee: props.selectedRestData.restaurant_delivery_fee,
				min_order: props.selectedRestData.min_order,
				tax: props.selectedRestData.tax,
				isEdit: false,
				selectedProduct: {},
				editIndex: null,
				address: props.selectedRestData.address,
				service_type: props.selectedRestData.service_type,
				allow_asap_advance_orders:
					props.selectedRestData.allow_asap_advance_orders,
					minimum_pickup_order:props.selectedRestData.minimum_pickup_order, 
        minimum_deliver_order:props.selectedRestData.minimum_deliver_order
			}),
		);
		dispatch(
			selectedMenuItem({
				...item,
				products_price: Number(item.products_price),
				restaurant_name: props.selectedRestData.name,
				restaurant_id: props.selectedRestData.restaurant_id,
				latlng: {
					lat: props.selectedRestData.lat,
					lng: props.selectedRestData.lng,
				},
				// fee:props.selectedRestData.fee,
				service_fee: props.selectedRestData.service_fee,
				restaurant_delivery_fee: props.selectedRestData.restaurant_delivery_fee,
				min_order: props.selectedRestData.min_order,
				tax: props.selectedRestData.tax,
				isEdit: false,
				selectedProduct: {},
				editIndex: null,
				address: props.selectedRestData.address,
				service_type: props.selectedRestData.service_type,
				allow_asap_advance_orders:
					props.selectedRestData.allow_asap_advance_orders,
					minimum_pickup_order:props.selectedRestData.minimum_pickup_order, 
        minimum_deliver_order:props.selectedRestData.minimum_deliver_order
					
			}),
		);
		// if (isMobileOnly) {
		//   history.push("/menuitemmobile");
		// } else {
		dispatch({ type: "ITEM_MODAL", payload: { status: true, type: "" } });
		//   // setIsOpen({ status: true, type: "" })
		// }
	};

	return (
		<section
			className={`mobile-inner-section ${
				props.searchValue.length > 0 ? "" : "d-none"
			}`}
		>
			<div className="change-address change-address-search">
				<div className="inner-wrapper pickaddress">
					<ul
						className="addressLists p-0"
						// style={{
						//   width: "340px",
						// }}
					>
						<div style={{ padding: "20px" }}>
							{props.showNotfound ? (
								<li>
									<div className="addressType">
										<p>
											{props.menuSearch
												? "Food item not found"
												: "No matching result found"}
										</p>
									</div>
								</li>
							) : null}
							{props.list.map((item, index) => {
								console.log("items of product search =>", item);
								console.log(
									"items of product search issue =>",
									item?.products_name?.split(".")[
										item?.products_name?.split(".").length - 1
									],
								);
								return props?.menuSearch ? (
									<li
										key={item?.products_id}
										onClick={() => handleMenuItem(item)}
									>
										<div className="addressType">
											<p>
												{/* {
                          item?.products_name?.split(".")[
                            item?.products_name?.split(".").length - 1
                          ]
                        } */}
												{item?.products_name}{" "}
											</p>
											<p className="addressDetails">
												{item?.products_description}
											</p>
										</div>
									</li>
								) : (
									<li key={item.id} onClick={() => props.goToMenu(item)}>
										<div className="addressType">
											<p>{item.name} </p>
											<p className="addressDetails">
												{item.restaurant_cuisines}
											</p>
										</div>
									</li>
								);
							})}
						</div>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default SearchList;
