import { SOCKET_URL } from "../service/ApiNames";

export const SocketConfig = {
    PUSHER_KEY: "ed11848fc8d08dac3573==",
    config: {
        cluster: "mt1",
        enabledTransports: ["ws", "wss"],
        forceTLS: false,
        wsHost: SOCKET_URL,
        wsPort: 6009,
        wssHost: SOCKET_URL,
        wssPort: 443,
    },
};
