import React, { useState, useEffect } from "react";
import Address from "../Address";
import { useForm } from "react-hook-form";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import { formatter } from "../../service";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import Map from "../../components/Map";
import {
	selectedAddress as selectedAddressFunc,
	saveAddress,
	verifyAddress,
} from "redux-store";
import SearchPlaces from "../../components/SearchPlaces";
import { useWindowResize } from "../../components/useWindowResize";

const regPost = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const regName = /^\S*$/;

const EditAddress = (props) => {
	const isMobileOnly = useWindowResize();
	const { store, dispatch, cartStore } = props;
	const { selectedAddress } = store.profile;
	const { register, handleSubmit, errors, getValues, setValue } = useForm({
		defaultValues: {
			entry_firstname: selectedAddress.entry_firstname,
			entry_lastname: selectedAddress.entry_lastname,
			entry_suburb: selectedAddress.entry_suburb,
			entry_street_name: selectedAddress.entry_street_name,
			entry_postcode: selectedAddress.entry_postcode,
			entry_state: selectedAddress.entry_state,
			entry_city: selectedAddress.entry_city,
			set_default: selectedAddress.address_book_id
				? selectedAddress.customers_default_address_id ==
				  selectedAddress.address_book_id
				: 0,
			address_lat: selectedAddress.address_lat,
			address_lng: selectedAddress.address_lng,
			entry_street_address: selectedAddress.entry_street_address,
			entry_company: selectedAddress.entry_company,
		},
	});

	const handleAddAddress = (data) => {
		
		if (cartStore && cartStore.cart && cartStore.cart.restaurant_id) {
			data["restaurant_id"] = cartStore.cart.restaurant_id;
		}
		if (props.fromGcCheckout) {
			(() => {
				if (store.auth.token) {
					console.log("check data", {
						...data,
						set_default:
							data.address_book_id == data.customers_default_address_id
								? 1
								: 0,
						address_book_id: selectedAddress.address_book_id,
						entry_country_id: "1",
						entry_zone_id: "1",
						address_type: "1",
					})
					dispatch(
						saveAddress(
							{
								...data,
								set_default:
									data.address_book_id == data.customers_default_address_id
										? 1
										: 0,
								address_book_id: selectedAddress.address_book_id,
								entry_country_id: "1",
								entry_zone_id: "1",
								address_type: "1",
							},
							() => null,
							true,
						),
					);
				}
				dispatch(selectedAddressFunc(data));
			})();
		} else {
			dispatch(
				verifyAddress(
					data,
					() => {
						if (store.auth.token) {
							dispatch(
								saveAddress(
									{
										...data,
										set_default:
											data.address_book_id == data.customers_default_address_id
												? 1
												: 0,
										address_book_id: selectedAddress.address_book_id,
										entry_country_id: "1",
										entry_zone_id: "1",
										address_type: "1",
									},
									() => null,
									true,
								),
							);
						}
						dispatch(selectedAddressFunc(data));
					},
					true,
				),
			);
		}

		props.setIsOpen({ status: false, type: "" });
	};

	const successCallBack = () => {};

	const [address, setAddress] = useState(selectedAddress.entry_street_address);
	const handleChange = (address) => {
		setAddress(address);
	};

	const [reload, setReload] = useState(false);
	const handleSelect = (selectedaddress) => {
		geocodeByAddress(selectedaddress)
			.then(async (results) => {
				let latlong = await getLatLng(results[0]);
				return {
					latlong: latlong,
					address: results[0].formatted_address,
					results,
				};
			})
			.then((latLng) => {
				const x = formatter({ results: latLng.results });
				const {
					address,
					city,
					state,
					country,
					postal_code,
					street_number,
					route,
				} = x;
				const { lat, lng } = latLng.latlong;
				setAddress(latLng.address);
				setValue("entry_street_address", latLng.address, {
					shouldDirty: true,
				});
				setValue("address_lat", lat, {
					shouldDirty: true,
				});
				setValue("address_lng", lng, {
					shouldDirty: true,
				});
				setValue("entry_state", state, {
					shouldDirty: true,
				});
				setValue(
					"entry_street_name",
					street_number && route
						? street_number.concat(`, ${route}`)
						: street_number
						? street_number
						: route
						? route
						: "",
					{
						shouldDirty: true,
					},
				);
				setValue("entry_postcode", postal_code, {
					shouldDirty: true,
				});

				setValue("entry_city", city, {
					shouldDirty: true,
				});

				setReload((r) => !r);
			})
			.catch((error) => console.error("Error", error));
	};
	const [center, setCenter] = useState({ lat: 44.076613, lng: -98.362239833 });
	const [clickedLatLng, setClickedLatLng] = useState(null);
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [mapRef, setMapRef] = useState(null);
	const [markerMap, setMarkerMap] = useState({});
	const [infoOpen, setInfoOpen] = useState(false);

	const [marker, setMarker] = useState({
		showMarker: false,
		name: "Current position",
		position: {
			lat: 37.77,
			lng: -122.42,
		},
	});

	useEffect(() => {
		setMarker({
			...marker,
			showMarker: true,
			position: {
				lat: Number(getValues().address_lat),
				lng: Number(getValues().address_lng),
			},
		});
	}, [getValues().address_lat]);
	const [zoom, setZoom] = useState(10);
	const loadHandler = (map) => {
		// Store a reference to the google map instance in state
		setMapRef(map);
		// Fit map bounds to contain all markers
		fitBounds(map);
		setZoom(15);
	};

	// We have to create a mapping of our places to actual Marker objects
	const markerLoadHandler = (marker, place) => {
		return setMarkerMap((prevState) => {
			return { ...prevState, [place.id]: marker };
		});
	};

	const markerClickHandler = (event, place) => {
		// Remember which place was clicked
		setSelectedPlace(place);

		// Required so clicking a 2nd marker works as expected
		if (infoOpen) {
			setInfoOpen(false);
		}

		setInfoOpen(true);

		// If you want to zoom in a little on marker click
		if (zoom < 13) {
			setZoom(13);
		} else {
			setZoom(17);
		}

		// if you want to center the selected Marker
		//setCenter(place.pos)
	};
	const fitBounds = (map) => {};

	return (
		<>
			<div className="payment__page__wrapper">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
					<div
						className="modal-content"
						style={isMobileOnly ? { background: "white" } : {}}
					>
						{isMobileOnly ? (
							<div className="go-back-wrapper">
								<a
									className="go-back-btn"
									onClick={() => props.setIsOpen({ status: false, type: "" })}
								>
									<img src={backArrowImg} alt="go back" />
								</a>
								<h2 style={{ paddingTop: "4px", fontSize: "18px" }}>
									{store.auth.headerTab == "Delivery"
										? "Delivery Address"
										: "Billing Address"}
								</h2>
							</div>
						) : (
							<div className="modal-header page_main_heading">
								<h1 className="modal-title" id="deliveryAddressModalLabel">
									{store.auth.headerTab == "Delivery"
										? "Delivery Address"
										: "Billing Address"}
								</h1>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => props.setIsOpen({ status: false, type: "" })}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						)}
						<div className="modal-body">
							<div className="devilery-address-modal-wrapper">
								<div lass="devilery-address-modal-wrapper-inner">
									<div className="row mb-3 pb-3">
										<div className="col-12">
											<div className="location-wrapper position-relative">
												{marker.showMarker && (
													<Map
														isAddress={{
															showMarker: false,
															name: "Current position",
															position: {
																lat: 37.77,
																lng: -122.42,
															},
														}}
														myPlaces={[
															{
																id: getValues().entry_street_address.split(
																	",",
																)[0],
																pos: {
																	lat: Number(marker.position.lat),
																	lng: Number(marker.position.lng),
																},
															},
														]}
														loadHandler={loadHandler}
														setCenter={setCenter}
														setClickedLatLng={setClickedLatLng}
														center={{
															lat: Number(marker.position.lat),
															lng: Number(marker.position.lng),
														}}
														zoom={zoom}
														markerLoadHandler={markerLoadHandler}
														markerClickHandler={markerClickHandler}
														markerMap={markerMap}
														setInfoOpen={setInfoOpen}
														infoOpen={infoOpen}
														selectedPlace={selectedPlace}
														mapRef={mapRef}
														setSelectedPlace={setSelectedPlace}
														setZoom={setZoom}
													/>
												)}
											</div>
										</div>
									</div>
									<form
										action=""
										className="row"
										autoComplete="none"
										onSubmit={handleSubmit(handleAddAddress)}
									>
										<input type="hidden" ref={register} name="address_lat" />
										<input type="hidden" ref={register} name="address_lng" />
										<input
											type="hidden"
											ref={register}
											name="entry_street_address"
										/>

										<div className="col-12 col-md-6 mb-4 padding-half-right">
											<input
												type="text"
												className="form-control"
												style={
													errors.entry_firstname
														? { borderColor: "#dc3545" }
														: {}
												}
												name="entry_firstname"
												id="firstName"
												placeholder="First Name"
												ref={register({
													required: "First name is required",
													pattern: {
														value: /[^\s*$]/,
														message: "First name is required",
													},
												})}
												maxLength={50}
											/>
											{errors.entry_firstname && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_firstname.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-6 mb-4 padding-half-left">
											<input
												type="text"
												className="form-control"
												style={
													errors.entry_lastname
														? { borderColor: "#dc3545" }
														: {}
												}
												id="lastName"
												placeholder="Last Name"
												name="entry_lastname"
												ref={register({
													required: "Last name is required",
													pattern: {
														value: /[^\s*$]/,
														message: "Last name is required",
													},
												})}
												maxLength={50}
											/>
											{errors.entry_lastname && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_lastname.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-12 mb-4">
											<input
												type="text"
												className="form-control"
												style={
													errors.entry_company ? { borderColor: "#dc3545" } : {}
												}
												id="lastName"
												placeholder="Company name"
												name="entry_company"
												ref={register({})}
											/>
										</div>
										<div className="col-12 col-md-12 mb-4">
											<SearchPlaces
												address={address}
												handleChange={handleChange}
												handleSelect={handleSelect}
											>
												{({
													getInputProps,
													suggestions,
													getSuggestionItemProps,
													loading,
												}) => (
													<div>
														<div className="form-group chekout-edit-address-parent position-relative mb-0">
															<input
																{...getInputProps({
																	type: "search",
																	className: "form-control",
																	id: "deliveryAddress",
																	placeholder: "Search address",
																})}
																onClick={() => setAddress("")}
															/>

															{address && address.length > 3 ? (
																<ul className="addressLists chekout-edit-address payment-address-auto-search">
																	{/*loading && <div>Loading...</div>*/}
																	{suggestions.map((suggestion) => {
																		const className = suggestion.active
																			? "suggestion-item--active"
																			: "suggestion-item";
																		// inline style for demonstration purpose
																		const style = suggestion.active
																			? {
																					backgroundColor: "#fafafa",
																					cursor: "pointer",
																			  }
																			: {
																					backgroundColor: "#ffffff",
																					cursor: "pointer",
																			  };
																		return (
																			<li
																				{...getSuggestionItemProps(suggestion, {
																					// className,
																					// style,
																				})}
																			>
																				<div className="addressType">
																					<p>{suggestion.description} </p>
																				</div>
																			</li>
																		);
																	})}
																</ul>
															) : null}
														</div>
													</div>
												)}
											</SearchPlaces>
										</div>
										<div className="col-12 col-md-12 mb-4">
											<input
												type="text"
												className="form-control"
												// name="Apt/Suite"
												id=""
												placeholder="Apt/Suite"
												style={
													errors.entry_suburb ? { borderColor: "#dc3545" } : {}
												}
												name={"entry_suburb"}
												ref={register({
													required: "Please enter Apt/suite",
													pattern: {
														value: /[^\s*$]/,
														message: "Please enter Apt/suite",
													},
												})}
											/>
											{errors.entry_suburb && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_suburb.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-12 mb-4">
											<input
												type="text"
												className="form-control"
												// name="Apt/Suite"
												id=""
												placeholder="Street name"
												style={
													errors.entry_street_name ? { borderColor: "#dc3545" } : {}
												}
												name={"entry_street_name"}
												ref={register({
													required: "Please enter street name",
													pattern: {
														value: /[^\s*$]/,
														message: "Please enter street name",
													},
												})}
											/>
											{errors.entry_street_name && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_street_name.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-12 mb-4">
											<input
												type="text"
												className="form-control"
												placeholder="City"
												name={"entry_city"}
												ref={register({
													required: "Please enter your city",
													pattern: {
														value: /[^\s*$]/,
														message: "Please enter your city",
													},
												})}
												style={
													errors.entry_city ? { borderColor: "#dc3545" } : {}
												}
											/>
											{errors.entry_city && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_city.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-6 mb-4 padding-half-right">
											<input
												type="text"
												className="form-control"
												id=""
												placeholder="State/Province"
												name={"entry_state"}
												ref={register({
													required: "Please enter your state",
													pattern: {
														value: /[^\s*$]/,
														message: "Please enter your state",
													},
												})}
												style={
													errors.entry_state ? { borderColor: "#dc3545" } : {}
												}
											/>
											{errors.entry_state && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_state.message}
												</div>
											)}
										</div>
										<div className="col-12 col-md-6 mb-4 padding-half-left">
											<input
												type="text"
												className="form-control"
												id=""
												placeholder="Post/Zip Code"
												name={"entry_postcode"}
												ref={register({
													required: "Please provide a postal code",
													pattern: {
														value: regPost,
														message: "Please provide valid postal code",
													},
												})}
												style={
													errors.entry_postcode
														? { borderColor: "#dc3545" }
														: {}
												}
											/>
											{errors.entry_postcode && (
												<div
													style={{
														width: "100%",
														marginTop: "0.25rem",
														fontSize: "80%",
														color: "#e3342f",
													}}
												>
													{errors.entry_postcode.message}
												</div>
											)}
										</div>
										{store.auth.token && (
											<div className="col-12 mb-3 subscribe-offers">
												<div className="custom-control custom-checkbox mr-sm-2">
													<input
														type="checkbox"
														className="custom-control-input"
														id="Primary address"
														name={"set_default"}
														ref={register}
													/>
													<label
														className="custom-control-label"
														for="Primary address"
													>
														Make this Address as Primary
													</label>
												</div>
											</div>
										)}
										<div className="col-12">
											<div className="modal-bottom-actions">
												<button type="submit" className="btn btn-filled">
													Save
												</button>
												<button
													type="button"
													className="btn btn-theme-outlined"
													data-dismiss="modal"
													onClick={() =>
														props.setIsOpen({ status: false, type: "" })
													}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default EditAddress;
