import React, { useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { deleteAddress, setPrimaryAddress, setSubscription } from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import deleteImg from "assets/images/delete-icon.svg";
import editImg from "assets/images/edit-icon.svg";

const ProfileContent = (props) => {
  const { isProfile } = props;
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const store = useSelector((store) => store.profile);

  const {
    customers_email_address,
    customers_firstname,
    customers_lastname,
    customers_telephone,
    customers_newsletter,
    provider,
    defaultAddress,
    allAddress,
    provider_uid,
  } = store;
  const [cus_new, setNewsLetter] = useState(
    customers_newsletter == "1" ? true : false
  );
  const handleChangeNewsletter = (e) => {
    dispatch(
      setSubscription(
        e.target.checked ? 1 : 0,
        () => setNewsLetter(e.target.checked),
        () => null
      )
    );
    setNewsLetter(e.target.checked);
  };
  return (
    <>
      {isProfile && (
        <section className="mobile-inner-section profile-details-section">
          <div className="row">
            <div className="col-12">
              <div className="detail">
                <label for="userName" className="label">
                  Name
                </label>
                <p id="userName">{`${customers_firstname} ${customers_lastname}`}</p>
              </div>
            </div>
            <div className="col-12">
              <div className="detail">
                <label for="userEmail" className="label">
                  Email
                </label>
                <p id="userEmail">{customers_email_address}</p>
              </div>
            </div>
            <div className="col-12">
              <div className="change-phoneno-wrapper">
                <div className="detail">
                  <label for="phoneNumber" className="label">
                    Phone Number
                  </label>
                  <p id="phoneNumber">{customers_telephone}</p>
                </div>
                {/* <a>Change</a> */}
              </div>
            </div>
            {/* {provider == "facebook" && (
              <div className="col-12">
                <div className="detail">
                  <p id="fbId">
                    <span>Facebook ID:</span> {provider_uid}
                  </p>
                </div>
              </div>
            )} */}
            <div className="col-12">
              <div className="detail mb-0">
                <div className="subscribe-offers">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="subscribe-email"
                      onChange={handleChangeNewsletter}
                      checked={cus_new}
                    />
                    <label
                      className="custom-control-label"
                      for="subscribe-email"
                    >
                      Subscribe for exclusive email offers and discounts
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="mobile-inner-section profile-deliveryAddress">
        {!props.isProfile && (
          <ul>
            <p>Delivery Address</p>
            {/* <li className="profile-addressList">
					
						<div className="profile-address-detail">
							<div className="leftside-detail">
								<p>{defaultAddress.entry_street_address}</p>
							</div>
							<div className="rightside-detail">
							</div>
						</div>
					</li> */}
            {allAddress.map((item) => {
              const {
                address_book_id,
                entry_street_address,
                entry_street_name,
                entry_suburb,
                customers_default_address_id,
              } = item;
              return (
                <li className="profile-addressList" key={address_book_id}>
                  {/* <label className="label">Other</label> */}
                  <div className="profile-address-detail">
                    <div className="leftside-detail">
                      <p
                        style={
                          address_book_id == customers_default_address_id
                            ? { color: "#f6993f" }
                            : {}
                        }
                      >
                        {entry_street_name?entry_suburb?entry_suburb + ", "+ entry_street_name:"" + entry_street_name:entry_street_address}
                      </p>
                    </div>
                    <div className="rightside-detail">
                      {/* <a onClick={()=>dispatch(setPrimaryAddress(address_book_id,item))} className="actions">
									Mark As Primary
								</a> */}
                      <a
                        className="actions delete-address"
                        onClick={() =>
                          dispatch(deleteAddress({ address_book_id }))
                        }
                      >
                        <img src={deleteImg} alt="delete" />
                      </a>
                      <a
                        className="actions edit-address"
                        onClick={() => props.handleEditAddress(item)}
                      >
                        <img src={editImg} alt="edit" />
                      </a>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        <div className="actions-wrapper mt-4">
          {!isProfile ? (
            <button
              className="actn-btn actn-btn-filled"
              onClick={() => {
                props.setIsModal("asap");
                props.setAddrFields({
                  address_book_id: "",
                  entry_street_address: "",
                  entry_country_id: "1",
                  entry_zone_id: "1",
                  address_type: "1",
                  address_lat: "",
                  address_lng: "",
                  entry_suburb: "",
                  set_default: 0,
                  entry_state: "",
                  entry_postcode: "",
                });
                props.setIsEdit(false);
                props.setStep(2);
              }}
            >
              Add Address
            </button>
          ) : (
            <>
              {!provider && (
                <button
                  className="actn-btn"
                  onClick={() => {
                    props.setIsModal("asap");
                    props.setStep(3);
                  }}
                >
                  Change Password
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ProfileContent;
