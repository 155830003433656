import React, { useEffect, useState } from "react";
import DatePicker from "../DatePicker";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	getProfile,
	getResturantsData,
	getCuisinesData,
	selectDate,
	selectTime,
	timeIndex,
	initiateGroupOrder,
} from "redux-store";

import calendarIconImg from "assets/images/calendar-icon.svg";
import { useWindowResize } from "../useWindowResize";

const DateAndTime = (props) => {
	const { invalidDates } = props;
	const isMobileOnly = useWindowResize();
	const { data = {} } = useLocation();
	const store = useSelector((store) => store);
	const { timeArray = [], timeArrayNew = [] } = store.rest;
	const dispatch = useDispatch();
	const { address_lat, address_lng, entry_street_address } =
		store.profile.selectedAddress;
	const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
	const [show, setShow] = useState(false);

	const handleDate = (value) => {
		// setDate(moment(value).format("dddd, MMMM Do YY"))
		setDate(value);
		setShow(false);
	};
	const [time, setTime] = useState(moment(new Date()).format("hh:mm A"));
	const [disabledIndex, setDisabledIndex] = useState("");
	const [showTime, setShowTime] = useState(false);
	const [selectedTimeIndex, setSelectedTimeIndex] = useState("");
	const handleTime = (e, time, index) => {
		e.preventDefault();
		e.stopPropagation();
		setErrorBgColor(false);
		setShowTime(false);
		setSelectedTimeIndex(index);

		setTime(time);
	};
	const history = useHistory();

	const { groupToken } = useParams();
	const { groupOrderInfo } = store.rest;

	const onSubmit = (e) => {
		e.preventDefault();
	};

	const handleTimeChoosen = () => {
		// e.preventDefault()
		// e.stopPropagation();
		// alert("called")
		let disableT = 0;
		if (
			moment(new Date()).format("dddd, MMMM Do YY") ==
			moment(date).format("dddd, MMMM Do YY")
		) {
			const currentTime = moment(new Date()).format("HH:mm");
			// alert(currentTime)

			var fromdt = `2013-05-29T${currentTime}`;
			var from = new Date(fromdt);

			for (let i = 0; i < timeArray.length - 1; i++) {
				const currentTime = moment(timeArray[i], ["hh:mm A"]).format("HH:mm");
				var todt = `2013-05-29T${currentTime}`;

				var to = new Date(todt);
				if (from > to) {
					// alert("kk")
					// disableT = i
					disableT = i;
				} else {
					disableT = i + 3;
					break;
				}
			}

			// var momentObj = moment(currentTime.toString(), ["h:mm A"]);
			// for (let i = 0; i < 48; i++) {
			//   var momentObjTwo = moment(timeArray[i], ["h:mm A"]);
			//   if (
			//     momentObj.format("HH:mm").toString().replace(/:/g, "") <
			//     momentObjTwo.format("HH:mm").toString().replace(/:/g, "")
			//   ) {
			//     disableT = i - 1;
			//     break;
			//   }
			// }
		}
		setDisabledIndex(disableT);
	};
	useEffect(() => {
		handleTimeChoosen();
	}, [date, time, timeArray]);

	const scb = () => {
		if (isMobileOnly) {
			history.goBack();
		} else {
			setErrorBgColor(false);
		}
	};

	const scheduleDelivery = () => {
		if (selectedTimeIndex) {
			if (props.handleDateToggle) {
				props.handleDateToggle();
			}
			dispatch(selectDate(date));
			dispatch(selectTime(time));
			dispatch(timeIndex(timeArrayNew[selectedTimeIndex]));

			if (groupToken) {
				let model = {
					id: groupOrderInfo?.id,
					name: groupOrderInfo?.title,
					limit: groupOrderInfo?.amount_limit,
					member: groupOrderInfo?.members,
					restaurant_id: groupOrderInfo?.restaurant_id,
					lat: groupOrderInfo?.latitude,
					lng: groupOrderInfo?.longitude,
					service_type: groupOrderInfo?.service_type,
					asap_or_advance: 2,
					date: date,
					time: time,
				};
				dispatch(initiateGroupOrder(model));
			}

			dispatch(
				getResturantsData(
					{
						lat_from: address_lat.toString(),
						lng_from: address_lng.toString(),
						date: date,
						time: time,
					},
					scb,
				),
			);
		} else {
			if (store.profile.deliveryTime && store.profile.deliveryDate) {
				dispatch(
					getResturantsData(
						{
							lat_from: address_lat.toString(),
							lng_from: address_lng.toString(),
							date: store.profile.deliveryDate,
							time: store.profile.deliveryTime,
						},
						scb,
					),
				);
			} else {
				setErrorBgColor(true);
			}
		}
	};
	const [errorBgColor, setErrorBgColor] = useState(false);

	const deliverNow = () => {
		if (props.handleDateToggle) {
			props.handleDateToggle();
		}
		dispatch(selectDate(""));
		dispatch(selectTime(""));
		setSelectedTimeIndex("");

		if (groupToken) {
			let model = {
				id: groupOrderInfo?.id,
				name: groupOrderInfo?.title,
				limit: groupOrderInfo?.amount_limit,
				member: groupOrderInfo?.members,
				restaurant_id: groupOrderInfo?.restaurant_id,
				lat: groupOrderInfo?.latitude,
				lng: groupOrderInfo?.longitude,
				service_type: groupOrderInfo?.service_type,
				asap_or_advance: 1,
				date: "",
				time: "",
			};
			dispatch(initiateGroupOrder(model));
		}

		dispatch(
			getResturantsData(
				{
					lat_from: address_lat.toString(),
					lng_from: address_lng.toString(),
				},
				scb,
			),
		);
	};

	const handleDateModal = () => {
		setShowTime(false);
		setShow(true);
	};

	return (
		<div className="picktime mobile-picktime">
			<div className="form-wrapper">
				{!isMobileOnly && <h4>Choose Date and Time</h4>}
				<div
					className="calender-wrapper"
					style={!show ? { display: "none" } : { marginBottom: "0px" }}
				>
					<DatePicker
						invalidDates={invalidDates}
						onSelectDate={handleDate}
						setShow={setShow}
					/>
				</div>
				{!isMobileOnly?<a
					type="button"
					className="close"
					aria-label="Close"
					onClick={props.handleDateToggle ? props.handleDateToggle : () => null}
				>
					<span aria-hidden="true">&times;</span>
				</a>:null}
				<div
					className="form-group field-wrapper"
					style={show ? { display: "none" } : {}}
					onClick={
						(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
						"SCHEDULE"
							? null
							: handleDateModal
					}
				>
					<input
						type="text"
						className={`form-control ${
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? "disabled-state"
								: ""
						}`}
						id="datepicker"
						placeholder="Today, Mon, Jul19"
						disabled={
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? true
								: false
						}
						//editable={false}
						// moment(store.profile.deliveryDate).format("ddd, MMM DD")
						value={moment(date).format("ddd, MMM DD")}
						style={{ cursor: "pointer" }}
					/>
					<button
						className={`icon-btn ${
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? "disabled-state"
								: ""
						}`}
					>
						<img src={calendarIconImg} alt="calender icon" />
					</button>
				</div>
				<div className="form-group">
					<div
						// className="timeslotwrapper"
						className={`timeslotwrapper ${
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? "disabled-state"
								: ""
						}`}
					>
						{!showTime && (
							<button
								className="custom-select select-btn"
								// className={`custom-select select-btn ${(props.disabledAsapAdavance || data.disabledAsapAdavance)=="SCHEDULE"?"disabled-state":""}`}
								disabled={
									(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
									"SCHEDULE"
										? true
										: false
								}
								name="time-slot-select"
								onClick={
									(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
									"SCHEDULE"
										? null
										: (e) => {
												e.preventDefault();
												e.stopPropagation();
												setShow(false);
												setShowTime(true);
										  }
								}
								style={errorBgColor ? { borderColor: "rgb(220, 53, 69)" } : {}}
							>
								{/* 08:30 AM - 09:00 AM */}
								{/* {time}{" "}-{" "}{timeArray[selectedTimeIndex==47?0:selectedTimeIndex+1]} */}
								{/* {selectedTimeIndex
                  ? `${time} - ${
                      timeArray[
                        selectedTimeIndex == 47 ? 0 : selectedTimeIndex + 1
                      ]
                    }`
                  : "Please choose time"} */}
								{selectedTimeIndex
									? timeArrayNew[selectedTimeIndex]
									: store.profile.deliveryTime
									? store.profile.timeInd
									: "Please choose time"}
							</button>
						)}
						{showTime && (
							<div
								className="timeslots"
								style={{ marginTop: "unset", overflowY: "scroll" }}
							>
								{timeArray.map((item, index) => (
									<p
										onClick={
											index <= disabledIndex
												? () => null
												: (e) => handleTime(e, timeArray[index], index)
										}
										key={item}
										className="timseslot-options"
										style={
											index <= disabledIndex
												? { color: "#999", cursor: "not-allowed" }
												: { cursor: "pointer" }
										}
									>
										{timeArray[index] !=
										timeArray[
											index == timeArray.length - 1
												? timeArray.length - 1
												: index + 1
										]
											? `${timeArray[index]} - ${
													timeArray[
														index == timeArray.length - 1
															? timeArray.length - 1
															: index + 1
													]
											  }`
											: null}
									</p>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="actions-wrapper">
					<button
						// className="actn-btn actn-btn-filled"
						className={`actn-btn actn-btn-filled ${
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? "disabled-state"
								: ""
						}`}
						onClick={
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"SCHEDULE"
								? null
								: scheduleDelivery
						}
					>
						Schedule
					</button>
					<button
						// className="actn-btn"
						className={`actn-btn ${
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"ASAP"
								? "disabled-state"
								: ""
						}`}
						onClick={
							(props.disabledAsapAdavance || data.disabledAsapAdavance) ==
							"ASAP"
								? null
								: deliverNow
						}
					>
						{store.auth.headerTab == "Delivery" ? "Deliver Now" : "Pickup Now"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default DateAndTime;
