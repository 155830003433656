import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Map from "./Map";
import moment from "moment";
import { OrderStatuses } from "../../constants/orderStatus";
import DriverMap from "./DriverMap";
import pageBackArrowImg from "assets/images/page-back-arrow.svg";
import { Modal } from "react-bootstrap";
import closeImg from "assets/images/modal-close.svg";
import cancelOrderImg from "assets/images/cancel-order-icon.svg";
import alertCross from "assets/images/alert-cross.svg";
import dropdownIcon from "assets/images/dropdown-arrow.svg";
import { useDispatch } from "react-redux";
import { cancelOrder } from "redux-store";
import amazonGiftCard from "assets/images/amazon-gift-card.svg";
import FeesAndTax from "components/FeesAndTax";
import tooltipIcon from "assets/images/tooltip-icon.svg";

const DesktopView = (props) => {
	const {
		calcArray,
		orderInfo,
		orderStatus,
		driverStatus,
		products,
		notes,
		isDelivery,
		giftCard,
		order_type_asap,
		getSingleOrder,
		handleReorder,
		paymentMode,
		isGiftCardSection,
	} = props;

	const [orderProducts, setOrderProducts] = useState([]);
	const [showDropdown, setShowDropdown] = useState([]);
	const [show, setShow] = useState(false);

	console.log("item.op =>", products);
	useEffect(() => {
		if (products && products.length) {
			if (orderInfo[0].group_order_id) {
				let unique_member_ids = products
					.map((it) => it.member_id)
					.filter((value, index, self) => self.indexOf(value) === index);

				let memberArr = [];

				unique_member_ids.forEach((it) => {
					let elements = products.filter((data) => data.member_id === it);
					memberArr.push(elements);
				});

				let data = memberArr.map((it) => ({
					member_name: it[0].member_name,
					items: it,
				}));

				setOrderProducts(data);
			}
		}
	}, [products]);

	const [priceObj, setPriceObj] = useState({});
	const [showDriverMap, setShowDriverMap] = useState(false);
	const [currentDriverStatus, setCurrentDriverStatus] = useState();
	const [currentOrderStatus, setCurrentOrderStatus] = useState();

	const [deliveryTime, setDeliveryTime] = useState(null);
	const [readyTime, setReadyTime] = useState(null);
	const [cancelModal, setCancelModal] = useState(false);
	const dispatch = useDispatch();

	const handleCancelOrder = () => {
		setCancelModal(true);
	};

	useEffect(() => {
		const obj = {};
		calcArray.map((item) => {
			obj[item.title] = item;
		});
		setPriceObj(obj);
	}, [calcArray]);

	console.log("priceObj", priceObj);
	// cancel oprder map
	useEffect(() => {
		if (orderInfo?.[0]?.orders_status_id === 6) {
			setShowDriverMap(true);
		}
	}, [orderInfo?.[0]?.orders_status_id]);

	useEffect(() => {
		if (orderStatus) {
			if (orderStatus.order_id === +orderInfo[0].orders_id) {
				for (var key in OrderStatuses) {
					if (OrderStatuses.hasOwnProperty(key)) {
						if (OrderStatuses[key].id === +orderStatus?.order_status_id) {
							setCurrentOrderStatus({ ...OrderStatuses[key], ...orderStatus });
							// below block for only delivery type
							if (isDelivery && OrderStatuses[key].order === 4) {
								setShowDriverMap(false);
								setShowDriverMap(true);
								setCurrentDriverStatus(null);
							}
							if (OrderStatuses[key].order === 4) {
								setReadyTime(null);
								setDeliveryTime(null);
							}
							// below block will gives us time for step 2 and 3
							if ([2, 3].includes(OrderStatuses[key].order)) {
								if (isDelivery) {
									setReadyTime(null);
									let mins =
										+orderInfo[0].restaurant_eta +
										+orderInfo[0].duration_to_deliver;
									mins = mins + " mins";
									setDeliveryTime(mins);
								} else {
									setDeliveryTime(null);
									let mins = +orderInfo[0].restaurant_eta;
									mins = mins + " mins";
									setReadyTime(mins);
								}
							}
						}
					}
				}
			}
		}
	}, [orderStatus]);

	useEffect(() => {
		if (driverStatus) {
			if (driverStatus.order_id === +orderInfo[0].orders_id) {
				if (currentOrderStatus.order !== 4) {
					setShowDriverMap(true);
					setCurrentDriverStatus({
						latitude: +driverStatus.lat,
						longitude: +driverStatus.lng,
					});
				}
				if (currentOrderStatus.order === 4) {
					setDeliveryTime(null);
					setCurrentDriverStatus(null);
				}
			}
		}
	}, [driverStatus]);

	const isDataLoaded = Object.keys(priceObj).length;

	const deliveryTimeHandler = (val) => {
		if (val) {
			setDeliveryTime(val.text);
		}
	};

	const toggleMember = (index) => {
		let copy = showDropdown.map((it) => it);
		if (copy.includes(index)) {
			let pcopy = copy.filter((it) => it !== index);
			setShowDropdown(pcopy);
		} else {
			copy.push(index);
			setShowDropdown(copy);
		}
	};

	return (
		<>
			<FeesAndTax
				show={show}
				setShow={setShow}
				fullscreen={false}
				centered={true}
				sf={`${parseFloat(
					+parseFloat(
						Number(priceObj["Restaurant Service Fee"]?.value),
					).toFixed(2) +
						+parseFloat(
							Number(priceObj["Phone Order Service Fee"]?.value),
						).toFixed(2),
				).toFixed(2)}`}
				lof={+parseFloat(Number(priceObj["Low Order Fee"]?.value)).toFixed(2)}
				st={+parseFloat(Number(priceObj["Sales Tax"]?.value)).toFixed(2)}
			/>
			<Header checkoutHeader />

			<section className="payment__page__wrapper orderDetails__page__wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12" onClick={props.handleGoBack}>
							<div className="backbtn-wrapper">
								<a title="click to go back">
									<span className="icons">
										<img src={pageBackArrowImg} alt="" />
									</span>
									Back
								</a>
							</div>
						</div>
						<div className="col-12">
							<div className="page_main_heading">
								<h1>Order Details</h1>
								{[1, 4].includes(currentOrderStatus?.order) && (
									<button
										onClick={
											currentOrderStatus?.order === 4
												? handleReorder
												: handleCancelOrder
										}
									>
										{currentOrderStatus?.order === 4
											? "Reorder"
											: "Cancel Order"}
									</button>
								)}
							</div>
						</div>
					</div>
					{currentOrderStatus?.id === 6 ? (
						<div className="order-details-cancelled-alert">
							<div className="inner-wrapper">
								<div className="img-box">
									<img
										src={alertCross}
										alt="cross icon"
										className="img-fluid"
									/>
								</div>
								<div className="content-box">
									<p>
										{paymentMode?.toLowerCase() === "cash"
											? "This order is canceled."
											: "This order is canceled. The amount has been refunded to your payment method."}
									</p>
								</div>
							</div>
						</div>
					) : null}
					<Modal
						className="modal fade"
						id="cancelOrder-modal"
						show={cancelModal}
						onHide={() => setCancelModal(false)}
						// size="lg"
						aria-labelledby="cancelOrder-modalLabel"
						centered
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body">
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={() => setCancelModal(false)}
									>
										<span aria-hidden="true">
											<img src={closeImg} alt="icon" className="img-fluid" />
										</span>
									</button>
									<div className="congrates-wrapper">
										<div className="img-box">
											<img
												src={cancelOrderImg}
												alt="icon"
												className="img-fluid"
											/>
										</div>
										<div className="content-box">
											<h1>Cancel Order ?</h1>
											<p>Are you sure you want to cancel the order?</p>
											{paymentMode?.toLowerCase() === "cash" ? null : (
												<p>
													The order will be canceled and refund will be
													transferred to your respective payment method.
												</p>
											)}
										</div>
										<div
											className="cancel-order-action-wrapper"
											onClick={() => {
												dispatch(
													cancelOrder(
														{
															order_id: +orderInfo[0].orders_id,
														},
														() => {
															setCancelModal(false);
															getSingleOrder();
														},
													),
												);
											}}
										>
											<button>Cancel Order</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{currentOrderStatus?.id !== 6 ? (
						<>
							{isGiftCardSection ? (
								<>
									<div className="order-details-stepper">
										<div className="row">
											<div className="col-xs-12 col-md-12">
												<div className="order-status">
													<span>{currentOrderStatus?.text}</span>
												</div>
											</div>
											<div className="col-xs-12 col-md-12 mb-4 pb-3">
												<div className="wrapper-progressBar">
													<ul className="progressBar progressBar-single">
														<li
															className={
																currentOrderStatus?.order >= 1 ? "active" : null
															}
														></li>
														<li
															className={
																currentOrderStatus?.order >= 4 ? "active" : null
															}
														></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<div className="order-details-stepper">
									<div className="row">
										<div className="col-xs-12 col-md-12">
											<div className="order-status">
												<span>{currentOrderStatus?.text}</span>
											</div>
										</div>
										<div className="col-xs-12 col-md-12 mb-4 pb-3">
											<div className="wrapper-progressBar">
												<ul className="progressBar">
													<li
														className={
															currentOrderStatus?.order >= 1 ? "active" : null
														}
													></li>
													<li
														className={
															currentOrderStatus?.order >= 2 ? "active" : null
														}
													></li>
													<li
														className={
															currentOrderStatus?.order >= 3 ? "active" : null
														}
													></li>
													<li
														className={
															currentOrderStatus?.order >= 4 ? "active" : null
														}
													></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							)}
						</>
					) : null}
					<div className="row flex-column-reverse flex-lg-row justify-content-between">
						<div className="col-12 col-lg-6">
							<div className="order-info">
								{orderInfo.length ? (
									<div className="row">
										<div className="col-12">
											<div className="order-detail">
												<span>Order Number</span>
												<span>{+orderInfo[0].orders_id}</span>
											</div>
										</div>
										<div className="col-12">
											<div className="order-detail">
												<span>Restaurant Name</span>
												<span>{orderInfo[0].restaurants_name}</span>
											</div>
										</div>
										{isDelivery ? (
											<>
												<div className="col-12">
													<div className="order-detail">
														<span>
															{currentOrderStatus?.order === 4
																? "Delivery"
																: "Order"}{" "}
															Time
														</span>
														<span>
															{currentOrderStatus?.order === 4
																? moment(
																		currentOrderStatus?.date_deliver,
																  ).format("ddd, MM/DD/yyyy h:mm A")
																: moment(
																		currentOrderStatus?.date_purchased,
																  ).format("ddd, MM/DD/yyyy h:mm A")}
															{/* {`${

                                currentOrderStatus?.date_deliver
                                  ? moment(
                                      currentOrderStatus?.date_deliver
                                    ).format("ddd, MM/DD/yyyy h:mm A")
                                  : ""
                              }`} */}
														</span>
													</div>
												</div>
												<div className="col-12">
													<div className="order-detail">
														<span>Delivery Address</span>
														<span>{orderInfo[0].customers_street_address}</span>
														{notes.map((item) =>
															!item.note_type ? (
																<label for="order-detail">{item.note}</label>
															) : null,
														)}
													</div>
												</div>
											</>
										) : (
											<>
												<div className="col-12">
													<div className="order-detail">
														<span>Pickup Time</span>
														<span>
															{`${
																orderInfo[0].date_deliver
																	? moment(orderInfo[0].date_deliver).format(
																			"ddd, MM/DD/yyyy h:mm A",
																	  )
																	: "To be Picked Up"
															}`}
														</span>
													</div>
												</div>
												<div className="col-12">
													<div className="order-detail">
														<span>Pickup Address</span>
														<span>{orderInfo[0].restaurant_address}</span>
													</div>
												</div>
											</>
										)}
										<div className="col-12">
											{orderInfo[0].group_order_id ? (
												<>
													<div class="row">
														<div class="col-12 my-4 pt-3">
															<div class="section_heading mb-3">
																<h2>Your Group Orders</h2>
															</div>
														</div>
													</div>
													{orderProducts.map((member, index) => (
														<>
															<div class="order-details-wrapper accordian">
																<div class="row">
																	<div class="col-12">
																		<div class="section_heading mb-3">
																			<a
																				href
																				class="btn btn-accordian"
																				role="button"
																				onClick={() => toggleMember(index)}
																			>
																				<h2>
																					{member.member_name}
																					<span class="icon">
																						<img
																							src={dropdownIcon}
																							alt="accordian-arrow"
																							className={
																								showDropdown.includes(index)
																									? "rotate-180"
																									: "rotate-0"
																							}
																						/>
																					</span>
																				</h2>
																			</a>
																		</div>
																	</div>
																</div>
																{showDropdown.includes(index) ? (
																	<div class="order-details-cards-wrapper gp-order-details">
																		<div class="row">
																			<div class="col-12">
																				{member?.items &&
																					member?.items?.length &&
																					member?.items?.map((item, idx) => (
																						<div
																							class="order-details-card"
																							key={idx}
																						>
																							<div class="rightside">
																								<span class="item-count">
																									{item.products_quantity}x
																								</span>
																								<div class="order-item-details">
																									<h5>{item.products_name}</h5>
																									{item.options &&
																										item.options.map(
																											(value, index) => {
																												return (
																													<>
																														{value.optionValues &&
																															value.optionValues.map(
																																(attr, idx) => (
																																	<>
																																		{attr.products_options_values_id !=
																																		0 ? (
																																			<span
																																				key={
																																					idx
																																				}
																																			>
																																				{attr.products_options_values +
																																					(idx ===
																																					value
																																						.optionValues
																																						.length -
																																						1
																																						? ""
																																						: ", ")}
																																			</span>
																																		) : null}
																																	</>
																																),
																															)}
																													</>
																												);
																											},
																										)}
																									{item.options &&
																										item.options.map(
																											(value, index) =>
																												!value.optionValues[0]
																													.products_options_values_id ? (
																													<p>
																														{
																															value
																																.optionValues[0]
																																.products_options_values
																														}
																													</p>
																												) : null,
																										)}
																								</div>
																							</div>
																							<div class="order-price">
																								<span>
																									$
																									{parseFloat(
																										item.final_price,
																									).toFixed(2)}
																								</span>
																							</div>
																						</div>
																					))}
																			</div>
																		</div>
																	</div>
																) : null}
															</div>
														</>
													))}
												</>
											) : (
												<>
													<div
														className="order-details-wrapper normal-order-details"
														style={{ marginBottom: "0px" }}
													>
														<div className="row">
															<div className="col-12 mt-4 pt-3">
																<div className="section_heading mb-3">
																	<h2>
																		Summary{" "}
																		<span>{`(${products.length} Item)`}</span>
																	</h2>
																</div>
															</div>
														</div>
														<div className="order-details-cards-wrapper">
															<div className="row">
																<div className="col-12">
																	{products.map((item, index) => {
																		return (
																			<div className="order-details-card">
																				<div className="rightside">
																					<span className="item-count">
																						{item.products_quantity}x
																					</span>
																					<div className="order-item-details">
																						<h5>{item.products_name}</h5>
																						<div>
																							{item.options &&
																								item.options.map(
																									(value, index) => {
																										console.log("value check", value)
																										return (
																											<>
																												{value.optionValues &&
																													value.optionValues.map(
																														(attr) => (
																															<>
																																{attr.products_options_values_id !=
																																0 ? (
																																	<span
																																		className={
																																			"comma"
																																		}
																																	>
																																		{
																																			attr.products_options_values
																																		}
																																	</span>
																																) : null}
																															</>
																														),
																													)}
																											</>
																										);
																									},
																								)}
																						</div>

																						{item.options &&
																							item.options.map((value, index) =>
																								!value.optionValues[0]
																									.products_options_values_id ? (
																									<p>
																										{
																											value.optionValues[0]
																												.products_options_values
																										}
																									</p>
																								) : null,
																							)}
																					</div>
																				</div>
																				<div className="order-price">
																					<span>
																						$
																						{parseFloat(
																							item.final_price,
																						).toFixed(2)}
																					</span>
																				</div>
																			</div>
																		);
																	})}
																</div>
															</div>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								) : null}
								<div className="row">
									{notes.map((item) =>
										item.note_type ? (
											<div className="col-12 mt-4 pt-3">
												<div className="section_heading mb-2">
													<h2>Special Instructions</h2>
												</div>
												<div className="special-instruction">
													<p className="m-0">{item.note}</p>
												</div>
											</div>
										) : null,
									)}

									<div className="col-12 mt-4 pt-3">
										{/* <div className="section_heading mb-2">
                      <h2>Billing Details</h2>
                    </div> */}
										{isDataLoaded ? (
											<div className="billing__details mb-5 pb-4">
												{!isGiftCardSection ? (
													<>
														<div className="billing__data">
															<span>Subtotal</span>
															<span>
																$
																{parseFloat(
																	Number(priceObj["Subtotal"]?.value),
																).toFixed(2)}
															</span>
														</div>
														
														{isDelivery ? (
															<div className="billing__data">
																<span>Delivery Fee</span>
																<span>
																	$
																	{parseFloat(
																		+priceObj["Delivery"]?.value,
																	).toFixed(2)}
																</span>
															</div>
														) : (
															<>
																{/* <div className="billing__data">
                                <span>Service Fee</span>
                                <span>
                                  $
                                  {parseFloat(
                                    Number(priceObj["Service Fee"]?.value)
                                  ).toFixed(2)}
                                </span>
                              </div> */}
															</>
														)}

														<div className="billing__data">
															<span>
																Fees and Sales Tax
																<span class="tooltip-custom">
																	<span
																		data-tip
																		data-for="registerTip"
																		onClick={() => setShow(true)}
																	>
																		<img src={tooltipIcon} alt="tooltipIcon" />
																	</span>
																</span>
															</span>
															<span>
																$
																{`${parseFloat(
																	+parseFloat(
																		Number(priceObj["Sales Tax"]?.value),
																	).toFixed(2) +
																		+parseFloat(
																			Number(priceObj["Low Order Fee"]?.value),
																		).toFixed(2) +
																		+parseFloat(
																			Number(
																				priceObj["Restaurant Service Fee"]
																					?.value,
																			),
																		).toFixed(2) +
																		+parseFloat(
																			Number(
																				priceObj["Phone Order Service Fee"]
																					?.value,
																			),
																		).toFixed(2),
																).toFixed(2)}`}
															</span>
														</div>
														{isDelivery ? (
															<>
															{parseFloat(
																			Number(priceObj["Gratuity"]?.value),
																		).toFixed(2)== 0.0 ? null :
																<div className="billing__data">
																	<span>Driver Tip</span>
																	<span>
																		$
																		{parseFloat(
																			Number(priceObj["Gratuity"]?.value),
																		).toFixed(2)}
																	</span>
																</div>}
															</>
														) : null}
														{parseFloat(
															Number(priceObj["Group Pricing Discount"]?.value),
														).toFixed(2) == 0.0 ? null : (
															<div className="billing__data">
																<span>Group Discount</span>
																<span>
																	-$
																	{parseFloat(
																		Number(
																			priceObj["Group Pricing Discount"]?.value,
																		),
																	).toFixed(2)}
																</span>
															</div>
														)}

														{parseFloat(
															Number(priceObj["Coupon Discount"]?.value),
														).toFixed(2) == 0.0 ? null : (
															<div className="billing__data">
																<span>Discount Applied</span>
																<span>
																	-$
																	{parseFloat(
																		Number(priceObj["Coupon Discount"]?.value),
																	).toFixed(2)}
																</span>
															</div>
														)}
														{parseFloat(
															Number(priceObj["Wallet Amount"]?.value),
														).toFixed(2) == 0.0 ? null : (
															<div className="billing__data">
																<span>Food Dude Balance</span>
																<span>
																	-$
																	{parseFloat(
																		Number(priceObj["Wallet Amount"]?.value),
																	).toFixed(2)}
																</span>
															</div>
														)}
														{Object.keys(giftCard).map((item) => (
															<div className="billing__data">
																<span>
																	Gift Card {giftCard[item]?.giftcard_code}
																</span>
																<span>
																	-$
																	{parseFloat(
																		Number(giftCard[item]?.used_amount),
																	).toFixed(2)}
																</span>
															</div>
														))}
													</>
												) : null}

												<div className="billing__data total">
													<span>Total</span>
													<span>
														$
														{parseFloat(
															Number(priceObj["Total"]?.value),
														).toFixed(2)}
													</span>
												</div>
												<div className="paid-by">
													<div className="billing__data">
														{orderInfo[0]?.payment_method == "Cash" ? (
															currentOrderStatus?.id === 10 ||
															currentOrderStatus?.id == 13 ? (
																<span>Paid By</span>
															) : (
																<span>To Be Paid By</span>
															)
														) : (
															<span>Paid By</span>
														)}
														<span>{orderInfo[0]?.payment_method}</span>
													</div>
												</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
						{isDataLoaded && isGiftCardSection ? (
							<div class="col-12 col-lg-5">
								<div class="e-gift-card-coupon">
									<div class="card-img-box">
										<p>Amazon Gift Card - 2</p>
										<img alt="" class="mx-auto" src={amazonGiftCard} />
										<h1 class="ml-auto">
											${parseFloat(Number(priceObj["Total"].value)).toFixed(2)}
										</h1>
									</div>
								</div>
							</div>
						) : (
							<div className="col-12 col-lg-5">
								<div className="order-details-map-wrapper">
									<div className="img-box">
										{!showDriverMap ? (
											<Map
												cities={props.cities}
												handleOnLoad={props.handleOnLoad}
												dispatch={props.dispatch}
												polylineOptions={
													props.polylineOptions ? props.polylineOptions : false
												}
												mapProjection={props.mapProjection}
												mapZoom={props.mapZoom}
												isCheckOut={isDelivery}
												mapValue={props.mapValue}
												isPickUp={
													orderInfo.length && orderInfo[0].pickup_order ? 1 : 0
												}
											/>
										) : (
											<DriverMap
												driverPosition={currentDriverStatus}
												destinationPosition={{
													latitude: +orderInfo[0].customers_lat,
													longitude: +orderInfo[0].customers_lng,
												}}
												deliveryTimeHandler={deliveryTimeHandler}
											/>
										)}
									</div>
									{readyTime && (
										<div className="map-status">
											<span>Ready in {readyTime}</span>
										</div>
									)}
									{deliveryTime && (
										<div className="map-status">
											<span>Delivery in {deliveryTime}</span>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default DesktopView;
