export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_IMAGE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const REACT_APP_BASE_GC_IMAGE_URL =
  process.env.REACT_APP_BASE_GC_IMAGE_URL;

export const ApiNames = {
  REGISTER: `${BASE_URL}customer/register`,
  GENERATE_OTP: `${BASE_URL}otp/generate`,
  VERIFY_OTP: `${BASE_URL}otp/verify`,
  LOGIN: `${BASE_URL}customer/login`,
  SOCIAL_LOGIN: `${BASE_URL}customer/social-login`,
  FORGOT_PASSWORD: `${BASE_URL}customer/forgot-password`,
  RESET_PASSWORD: `${BASE_URL}customer/reset-password`,
  REQUEST_SUBSCRIPTION: `${BASE_URL}subscriber/request-subscription`,
  USER_EXISTS: `${BASE_URL}customer/user-already-exists`,
  PROVIDER_EXISTS: `${BASE_URL}customer/provider-already-exists`,
  GET_PROFILE: `${BASE_URL}customer/get-profile-data`,
  CHANGE_PASSWORD: `${BASE_URL}customer/change-password`,
  GET_RESTURANTS_DATA: `${BASE_URL}restaurants/get-data`,
  GET_CUISINES_DATA: `${BASE_URL}cuisines/get-list`,
  GET_RESTURANT_MENU: `${BASE_URL}restaurants/get-menu-listing`,
  SAVE_ADDRESS: `${BASE_URL}address/save`,
  DELETE_ADDRESS: `${BASE_URL}address/delete`,
  GET_ADDRESS: `${BASE_URL}address/get-addresses`,
  GET_ADDRESS_BYID: `${BASE_URL}address/get-address-by-id/`,
  SET_PRIMARY_ADDRESS: `${BASE_URL}address/set-primary/`,
  GET_SUBSCRIPTION: `${BASE_URL}customer/set-subscription/`,
  VERIFY_ADDRESS: `${BASE_URL}address/verify-address/`,
  STATE_CITIES: `${BASE_URL}address/get-states-data`,
  ITEM_ADDON: `${BASE_URL}restaurants/get-addons/`,
  APPLY_COUPAN: `${BASE_URL}cart/validate-code`,
  VERIFY_GIFT_VOUCHER_AMOUNT: `${BASE_URL}cart/validate-gv-amt`,
  TAX_SETTINGS: `${BASE_URL}cart/get-payment-settings`,
  GENERATE_TOKEN: `${BASE_URL}braintree/generate-token`,
  COD_CHARGE: `${BASE_URL}cod/charge`,
  WALLET_PAY_CHARGE: `${BASE_URL}wallet-pay/charge`,
  BRAINTREE_CHARGE: `${BASE_URL}braintree/payment-make`,
  PAYMENT_SUMMARY: `${BASE_URL}orders/order-products`,
  ALL_ORDERS: `${BASE_URL}orders/index`,
  ADD_FUND_API: `${BASE_URL}orders/add-funds`,
  GET_USER_GIFT_CARD: `${BASE_URL}customer/get-used-gift-cards`,
  CANCEL_ORDER: `${BASE_URL}orders/cancel-order`,
  RE_ORDER: `${BASE_URL}orders/re-order`,
  VALIDATE_PRODUCTS: `${BASE_URL}checkout/validate-products`,
  GROUP_ORDER_INITIATE: `${BASE_URL}group-order/initiate`,
  GET_CONTACTS_LIST: `${BASE_URL}group-order/get-group-orders`,
  GET_GROUP_MEMBERS_LIST: `${BASE_URL}group-order/get-members`,
  GET_GROUP_ORDER_INFO: `${BASE_URL}group-order/restaurant`,
  GET_GROUP_ORDER_BASKET: `${BASE_URL}group-order/items-listing`,
  ADD_TO_CART_GROUP_ORDER: `${BASE_URL}group-order/add-to-cart`,
  REMOVE_ITEM_FROM_GROUP_CART: `${BASE_URL}group-order/delete-item`,
  REMOVE_GROUP_MEMBER: `${BASE_URL}group-order/delete-member/`,
  SAVE_GROUP_CART: `${BASE_URL}group-order/save-cart`,
  GIFTCARD_GETLISTING: `${BASE_URL}gift-card/get-listing`,
  BRAINTREE_ADD_FUND: `${BASE_URL}braintree/add-funds`,
  REWARD_HISTORY: `${BASE_URL}reward/history`,
  VERIFY_GC_CODE: `${BASE_URL}cart/validate-giftcard-code`,
  CANCEL_GROUP_ORDER: `${BASE_URL}group-order/cancel/`,
  REDEEM_REWARDS: `${BASE_URL}reward/redeem`,
  GET_PAYMENT_SUMMARY: `${BASE_URL}cart/get-payment-summary`,
  GROUP_ORDER_RE_ORDER: `${BASE_URL}group-order/re-order`,
  GROUP_ORDER_EMPTY_MEMBERS: `${BASE_URL}group-order/empty-cart-members/`,
  VALIDATE_MASTER_TOKEN: `${BASE_URL}admin/master-login/`,
  NOTIFICATION_LIST: `${BASE_URL}notification/get-data`,
  NOTIFICATION_READ: `${BASE_URL}notification/set-read/`,
  NOTIFICATION_DRIVER_TIME: `${BASE_URL}notification/driver-distance`,
  DRIVER_SIGN_UP: `${BASE_URL}driver/signup-request`,
  RESTAURANT_SIGN_UP: `${BASE_URL}restaurants/signup-request`,
  CONTENT_PAGES: `${BASE_URL}content-page/view/`,
  CONTACT_US: `${BASE_URL}admin/contact-us`,
  FAQ_LISTING: `${BASE_URL}faq/list`,
  JOB_LIST: `${BASE_URL}content-page/get-jobs-listing`, 
  DRIVER_CITIES:`${BASE_URL}address/get-cities-data`,
  INVOICE_PAYMENT:`${BASE_URL}invoice/charge`,
  NEWS_LETTER:`${BASE_URL}subscriber/confirm-subscription`
};
