import React from "react";
import Modal from "react-bootstrap/Modal";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import closeImg from "assets/images/modal-close.svg";

const WarningModal = ({
  show,
  closeModal,
  isMobileOnly,
  onSubmit,
  userList,
}) => {
  return (
    <>
      <Modal
        id="add-balance"
        show={show}
        onHide={closeModal}
        keyboard={false}
        fullscreen={isMobileOnly}
        centered
        className="deliveryAddressModal"
      >
        {isMobileOnly ? (
          <>
            <main className="mobile-inner-screen bg-warn">
              <div className="payment__page__wrapper">
                <div className="go-back-wrapper px-3 bg-transparent justify-content-end">
                  <a href className="go-back-btn" onClick={closeModal}>
                    <img src={closeImg} alt="icon" className="img-fluid" />
                  </a>
                </div>
                <div className="main-wrapper">
                  <section className="mobile-inner-section">
                    <div className="main">
                      <div className="container">
                        <div class="devilery-address-modal-wrapper">
                          <div lass="devilery-address-modal-wrapper-inner">
                            <div class="warning-wrapper align-items-start">
                              <div class="d-flex align-items-center flex-column justify-content-center w-100">
                                <h1 className="modal-heading-pwa">Warning</h1>
                                <p class="mb-0">
                                  The following users have the empty cart/Not
                                  saved cart or food items added above the
                                  per-person amount limit.
                                </p>
                              </div>
                              <div
                                class="order-listing-wrapper w-100 mt-0"
                                style={{
                                  maxHeight: "226px",
                                  minHeight: "0",
                                  overflowY: "scroll",
                                }}
                              >
                                <table class="table order-listing-table mb-0">
                                  <tbody>
                                    {userList &&
                                      userList?.map((user) => (
                                        <>
                                          <tr>
                                            <td class="font-weight-bold">
                                              {user.name}
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                              <div class="w-100">
                                <div class="col-lg-12 mb-2 px-0 ">
                                  <hr class="mt-0" />
                                </div>
                              </div>
                              <div class="d-flex align-items-center flex-column justify-content-center w-100">
                                <p class="text-center w-auto">
                                  Please make the changes to the cart
                                  accordingly. Users with empty carts will be
                                  removed from the group order and the rest will
                                  be saved as it is.
                                </p>
                                <p class="text-center w-auto">
                                  Do you wish to proceed?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="meanu-listing-mobile-footer checkout-footer bg-transparent">
                    <div className="container">
                      <div className="bg bg-transparent">
                        <div className="modal-bottom-actions">
                          <div class="update-order-btn-wrapper justify-content-between d-flex">
                            <button
                              type="button"
                              class="btn btn-outline p-3 w-50 mr-2"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-filled p-3 w-50 mr-auto"
                              onClick={onSubmit}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </>
        ) : (
          <>
            <Modal.Header className="page_main_heading">
              <h1 class="modal-title" id="driverTipModalLabel">
                {""}
              </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">
                  <img src={closeImg} alt="icon" className="img-fluid" />
                </span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div class="devilery-address-modal-wrapper">
                <div lass="devilery-address-modal-wrapper-inner">
                  <div class="warning-wrapper align-items-start">
                    <div class="d-flex align-items-center flex-column justify-content-center w-100">
                      <h1 className="modal-heading-web">Warning</h1>
                      <p>
                        The following users have the empty cart/Not saved cart
                        or food items added above the per-person amount limit.
                      </p>
                    </div>
                    <div
                      class="w-100 grouporder-warning-list"
                      style={{
                        maxHeight: "226px",
                        overflowY: "scroll",
                      }}
                    >
                      <table class="table order-listing-table mb-0">
                        <tbody>
                          {userList &&
                            userList?.map((user) => (
                              <>
                                <tr
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  <td className="font-weight-bold">
                                    {user.name}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div class="w-100">
                      <div class="col-lg-12 mb-2 px-0 ">
                        <hr class="mt-0" />
                      </div>
                    </div>
                    <div class="d-flex align-items-center flex-column justify-content-center w-100">
                      <p class="text-center w-auto">
                        Please make the changes to the cart accordingly. Users
                        with empty carts will be removed from the group order
                        and the rest will be saved as it is.
                      </p>
                      <p class="text-center w-auto">Do you wish to proceed?</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 mb-2">
                      <hr class="hr-dashed" />
                    </div>
                  </div>

                  <div class="modal-bottom-actions justify-content-between d-flex">
                    <button
                      type="button"
                      class="btn btn-outline"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-filled mr-0"
                      onClick={onSubmit}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default WarningModal;
