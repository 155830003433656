import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { regEmail, regPhone, regPassword } from "../validation";
import { useSelector, useDispatch } from "react-redux";
import {
  logIn,
  checkIfProviderExists,
} from "redux-store";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import { isExpired, decodeToken } from "react-jwt";
import SocialLogins from "./../components/SocialLogins";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import showEyeImg from "assets/images/eye-show-img.svg";
import hidePassImg from "assets/images/hide-pass.svg";
import { useWindowResize } from "./../components/useWindowResize";



export const Login = () => {
  const isMobileOnly = useWindowResize()
  const [isLoadApp, setIsAppLoad] = useState(false)
 
  // useEffect(()=>{
  // 	document.addEventListener("AppleIDSignInOnSuccess", (data) => {
  // 		const {
  //       user: newUser,
  //       email,
  //       fullName,
  //       nonce,
  //       identityToken,
  //       authorizationCode,
  //       realUserStatus,
  //     } = data;
  // 		console.log("AppleIDSignInOnSuccess",email);
  // 		console.log("Token: " + data.detail.authorization.id_token);
  // 	});
  // },[])
  const [hideButtons, setHideButtons] = useState(false);
  // useEffect(() => {
  // 	const listener = (event) => {
  // 		if (event.code === "Enter") {
  // 			console.log("Enter key was pressed. Run your function.");
  // 			event.preventDefault();
  // 			// callMyFunction();
  // 		}
  // 	};
  // 	document.addEventListener("keydown", listener);
  // 	return () => {
  // 		document.removeEventListener("keydown", listener);
  // 	};
  // }, []);

  const [tab, setTab] = useState(true);
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { register, handleSubmit, errors, getValues } = useForm(); // initialize the hook
  const onSubmit = (data) => {
    dispatch(
      logIn(data, () => {
        history.push("/");
      })
    );
  };
  // const he = {
  // 	kid: "TX2H45R85D",
  // 	alg: "ES256"
  // }
  // const pa = {
  // 	iss: "87V754AN7E",
  // 		iat: 'decodeToken.iat',
  // 		exp: 'decodeToken.exp',
  // 		aud: "https://appleid.apple.com",
  // 		sub: "com.fooddudesstaging.signin"
  // }

  // const key1= "MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgkE08n6Hopyqg1qxFe2Ly8o4cTQlr9X0hrn2pUac6rJegCgYIKoZIzj0DAQehRANCAASC2lh3nAppf664xwpbASWxg1vtjUNJvKu8JQeYbuov6Xno3HDeQvGANeLt+0F8OkdxqOc4nIQia/gZwp2ZHDA1"

  const handleLoginWithApple = (response) => {
    const myDecodedToken = decodeToken(response.authorization.id_token);

    // const payload = {
    // 	iss: "87V754AN7E",
    // 	iat: decodeToken.iat,
    // 	exp: decodeToken.exp,
    // 	aud: "https://appleid.apple.com",
    // 	sub: "com.fooddudes.signin",
    // 	redirect_uri:'https://fdl-progwapp-dev4.netsol.in/'
    // }
    // const payload = {
    // 	iss: decodeToken.iss,
    // 	iat: decodeToken.iat,
    // 	exp: decodeToken.exp,
    // 	aud: decodeToken.aud,
    // 	sub: decodeToken.sub,
    // 	redirect_uri:'https://fdl-progwapp-dev4.netsol.in/'
    // }

    // const headers = {
    // 	kid: "DVW82AZ72H",
    // 	alg: "ES256"
    // }

    // const key = "MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgd1GFzUXWpHEWuah6k+MLVzq7pNT5O5jdgYrsNzomuRSgCgYIKoZIzj0DAQehRANCAAQFWAPfN9WxcCxo7HWZkE0sI3KGmsNdZ/NwfUeMa8KAeOZlVORTM3irdDVxk86XDPNq+GUhA7BW6SiNFyJlttlX"
    // const a = sign(payload, key, 'ES256');
    // generateAccessToken({
    // 	client_id:"com.fooddudes.signin",
    // 	client_secret:a,
    // 	grant_type:"authorization_code",
    // 	code:response.authorization.code
    // })

    dispatch(
      checkIfProviderExists(
        {
          provider: "apple",
          provider_uid: myDecodedToken.sub,
          provider_user_name: `${
            response.user
              ? response.user.name.firstName + " " + response.user.name.lastName
              : "asas"
          }`,
          provider_access_token: "0",
        },
        () => {
          history.push({
            pathname: `/${"apple"}/social-signup`,
            // search: '?query=abc',
            data: {
              customers_email_address: response.user ? response.user.email : "",
              provider: "apple",
              provider_uid: myDecodedToken.sub,
              provider_user_name: response.user
                ? response.user.name.firstName +
                  " " +
                  response.user.name.lastName
                : "",
              // provider_access_token: response.authorization.id_token,
              provider_access_token: "0",
              customers_firstname: response.user
                ? response.user.name.firstName
                : "",
              customers_lastname: response.user
                ? response.user.name.lastName
                : "",
            },
          });
        },
        () => {
          history.push("/");
        }
      )
    );
  };
  const handleTab = () => {
    setTab(!tab);
    history.push("/signup");
  };

  return (
    <>
      {!isMobileOnly ? <Header auth signupCustom /> : null}
      <main className="mobile-screen-bg signin-signup">
        <div className="container">
          <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
            <div className="wrapper">
              <div className="row mobile-logodiv backbtn-div d-md-none">
                <div className="col-12">
                  <div onClick={() => history.push("/")}>
                    <img src={backArrowMobileImg} alt="go back" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3 mb-md-4 for-mobile-only">
                  <h1>Sign In</h1>
                  <nav
                    className="d-md-none nav-justified"
                    id="signIn-Signup-tabs"
                  >
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        id="nav-logIn-tab"
                        data-toggle="tab"
                        href="#nav-logIn"
                        role="tab"
                        aria-controls="nav-logIn"
                        aria-selected="true"
                        onClick={handleTab}
                      >
                        Sign In
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="nav-signUp-tab"
                        data-toggle="tab"
                        // href="#nav-signUp"
                        role="tab"
                        aria-controls="nav-signUp"
                        aria-selected="false"
                        onClick={handleTab}
                      >
                        Sign Up
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-logIn"
                  role="tabpanel"
                  aria-labelledby="nav-logIn-tab"
                >
                  <div className="login-form mb-4">
                    <form
                      className="needs-validation signups"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className="form-control input-field-layout"
                            style={
                              errors.customers_username
                                ? {
                                    borderColor: "#dc3545",
                                  }
                                : {}
                            }
                            id="Email-or-Phone-Number"
                            placeholder="Email or Phone Number"
                            name="customers_username"
                            ref={register({
                              required:
                                "Please enter your registered Phone Number / Email ID",
                              pattern: {
                                value:
                                  /(^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^[0-9][0-9]{9,9}$))/,
                                message:
                                  "Please enter your registered Phone Number / Email ID",
                              },
                            })}
                            maxLength={50}
                          />
                          {errors.customers_username && (
                            <div
                              style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#e3342f",
                              }}
                            >
                              {errors.customers_username.message}
                            </div>
                          )}
                          <div className="invalid-feedback">
                            Please provide first name
                          </div>
                        </div>
                        <div className="col-12 mb-2 position-relative">
                          <input
                            type={!hideButtons ? "password" : "text"}
                            className="form-control input-field-layout"
                            id="password"
                            placeholder="Password"
                            name="customers_password"
                            ref={register({
                              required: "Please enter your password",
                              pattern: {
                                value: /[^\s*$]/,
                                message: "Please enter your password",
                              },
                            })}
                            onChange={(e) => {
                              e.target.value = e.target.value.trim();
                            }}
                            style={
                              errors.customers_password
                                ? {
                                    borderColor: "#dc3545",
                                  }
                                : {}
                            }
                            //   value={formValues.customers_passworde}
                            //   onChange={(e)=>setFormValues({...formValues, customers_password:e.target.value})}
                            //   required
                          />

                          <button
                            id="showpass"
                            type="button"
                            onClick={() => setHideButtons(!hideButtons)}
                          >
                            {hideButtons ? (
                              <span className="">
                                <img
                                  className="img-fluid"
                                  src={showEyeImg}
                                  alt="hide passowrd"
                                />
                              </span>
                            ) : (
                              <span className="">
                                <img
                                  className="img-fluid"
                                  src={hidePassImg}
                                  alt="show passowrd"
                                />
                              </span>
                            )}
                          </button>
                          {errors.customers_password && (
                            <div
                              style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#e3342f",
                              }}
                            >
                              {errors.customers_password.message}
                            </div>
                          )}
                        </div>
                        <div className="col-12 mb-3 text-right forgot-pass font-14">
                          <Link to={`/forgot-password`} title="food dudes">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <button
                        className="btn initial-screen-lg-btn font-20"
                        id="login-btn"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-12 text-center mb-4
								 font-16"
                >
                  <span>- OR -</span>
                </div>

                <SocialLogins signin />

                <div className="col-12 text-center font-16">
                  <p>
                    Don’t have an account?{" "}
                    <span className="signup-link">
                      <Link to="/signup" title="Sign up">
                        Sign Up
                      </Link>
                    </span>
                  </p>
                </div>
                {/* <div className="col-12 text-center font-16 mt-4">
									<p className="skip_step">
										<span className="signup-link">
											<Link title="skip">
												Skip
											</Link>
										</span>
									</p>
								</div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {!isMobileOnly ? <Footer /> : null}
    </>
  );
};

export default Login;
