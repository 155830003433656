import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  sendOtp,
  checkIfUserExists,
  checkIfProviderExists,
  setRouteDataVerificationPage,
} from "redux-store";
import { regEmail, regPhone, regPassword, regName } from "../validation";
// import AppleLogin from "react-apple-login";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import SocialLogins from "./../components/SocialLogins";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import eyeShowImg from "assets/images/eye-show-img.svg";
import hidePassImg from "assets/images/hide-pass.svg";
import { useWindowResize } from "./../components/useWindowResize";

const SignUp = () => {
  const isMobileOnly = useWindowResize()

  const [hideButtons, setHideButtons] = useState(false);
  const [tab, setTab] = useState(true);
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      customers_newsletter: false,
    },
  }); // initialize the hook
  const onSubmit = (data) => {
    dispatch(
      checkIfUserExists(
        {
          customers_email_address: data.customers_email_address,
          customers_telephone: data.customers_telephone,
        },
        () => {
          dispatch(
            sendOtp(
              {
                // customers_telephone: data.customers_email_address,
                customers_telephone: data.customers_telephone,
                expiry_interval: "+30 minutes",
              },
              () => {
                dispatch(
                  setRouteDataVerificationPage({
                    ...data,
                    customers_newsletter: data.customers_newsletter ? "1" : "0",
                    // fromSignUp: true,
                    fromSignUp: false,

                  })
                );
                history.push({
                  pathname: `/verification`,
                  // search: '?query=abc',
                  data: {
                    ...data,
                    customers_newsletter: data.customers_newsletter ? "1" : "0",
                    fromSignUp: true,
                  },
                });
              },
              "signup"
            )
          );
        }
      )
    );
  };
  const handleTab = () => {
    setTab(!tab);
    history.push("/signin");
  };
  return (
    <>
      {!isMobileOnly ? <Header auth /> : null}
      <main className="mobile-screen-bg signin-signup">
        <div className="container">
          <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
            <div className="wrapper">
              <div className="row mobile-logodiv backbtn-div d-md-none">
                <div className="col-12">
                  <div onClick={() => history.push("/")}>
                    <img src={backArrowImg} alt="go back" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3 mb-md-4 for-mobile-only">
                  <h1>Sign Up</h1>
                  <nav
                    className="d-md-none nav-justified"
                    id="signIn-Signup-tabs"
                  >
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link"
                        id="nav-signUp-tab"
                        data-toggle="tab"
                        // href="#nav-signUp"
                        role="tab"
                        aria-controls="nav-signUp"
                        aria-selected="false"
                        onClick={handleTab}
                      >
                        Sign In
                      </a>
                      <a
                        className="nav-item nav-link active"
                        id="nav-logIn-tab"
                        data-toggle="tab"
                        href="#nav-logIn"
                        role="tab"
                        aria-controls="nav-logIn"
                        aria-selected="true"
                        onClick={handleTab}
                      >
                        Sign Up
                      </a>
                    </div>
                  </nav>
                </div>
                <SocialLogins />
                <div className="col-12 text-center mb-4 font-16">
                  <span>- OR -</span>
                </div>
              </div>

              <div className="signup-form mb-4">
                <form
                  className="needs-validation signups"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-6 col-md-12 mb-4">
                      <input
                        type="text"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_firstname
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="firstName"
                        placeholder="First Name"
                        name="customers_firstname"
                        ref={register({
                          required: "First name is required",
                          pattern: {
                            value: /[^\s*$]/,
                            message: "First name is required",
                          },
                        })}
                        maxLength={50}
                      />
                      {errors.customers_firstname && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_firstname.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide first name
                      </div>
                    </div>
                    <div className="col-6 col-md-12 mb-4">
                      <input
                        type="text"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_lastname
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="lastName"
                        placeholder="Last Name"
                        name="customers_lastname"
                        ref={register({
                          required: "Last name is required",
                          pattern: {
                            value: /[^\s*$]/,
                            message: "Last name is required",
                          },
                        })}
                        maxLength={50}
                      />
                      {errors.customers_lastname && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_lastname.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide last name
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="input-group position-relative">
                        <div className="phone-ext">+1</div>
                        <input
                          type="text"
                          className="form-control input-field-layout"
                          style={
                            errors.customers_telephone
                              ? { borderColor: "#dc3545" }
                              : {}
                          }
                          id="phoneNumber"
                          placeholder="Phone Number"
                          name="customers_telephone"
                          ref={register({
                            required: "Phone Number is required",
                            pattern: {
                              value: regPhone,
                              message:
                                "Phone Number must be 10 digits long and only contain digits",
                            },
                          })}
                        />
                        {errors.customers_telephone && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#e3342f",
                            }}
                          >
                            {errors.customers_telephone.message}
                          </div>
                        )}
                        <div className="invalid-feedback">
                          Please provide phone number
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <input
                        type="email"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_email_address
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="email"
                        placeholder="Your Email"
                        name="customers_email_address"
                        ref={register({
                          required: "Email is required",

                          pattern: {
                            value: regEmail,
                            message: "Please provide valid email",
                          },
                        })}
                      />
                      {errors.customers_email_address && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_email_address.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide Your email
                      </div>
                    </div>
                    <div className="col-12 mb-4 position-relative">
                      <input
                        type={!hideButtons ? "password" : "text"}
                        className="form-control input-field-layout"
                        style={
                          errors.customers_password
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="password"
                        placeholder="Password"
                        name="customers_password"
                        ref={register({
                          required: "Please set a password",
                          pattern: {
                            value: regPassword,
                            message:
                              "Password must meet the complexity criteria ( 8 chars long, Alphanumeric + 1 capital letter + at least 1 special character)",
                          },
                        })}
                      />
                      <button
                        id="showpass"
                        type="button"
                        onClick={() => setHideButtons(!hideButtons)}
                      >
                        {hideButtons ? (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={eyeShowImg}
                              alt="hide passowrd"
                            />
                          </span>
                        ) : (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={hidePassImg}
                              alt="show passowrd"
                            />
                          </span>
                        )}
                      </button>
                      {errors.customers_password ? (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_password.message}
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            // color: "#e3342f",
                          }}
                        >
                          {
                            "Password must meet the complexity criteria ( 8 chars long, Alphanumeric + 1 capital letter + at least 1 special character)"
                          }
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide valid password
                      </div>
                    </div>
                    <div className="col-12 mb-2 subscribe-offers">
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="subscribeemailoffers"
                          name="customers_newsletter"
                          ref={register()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="subscribeemailoffers"
                        >
                          Subscribe for exclusive email offers and discounts
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="cautions">
                        <p>
                          By signing up, I agree to the Food Dudes Terms and
                          Conditions and Privacy Policy
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn initial-screen-lg-btn font-20"
                    id="signup-btn"
                    type="submit"
                  >
                    Sign Up
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {!isMobileOnly ? <Footer /> : null}
    </>
  );
};

export default SignUp;
