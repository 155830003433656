import React, { useState, useEffect } from "react";
import { addFundApi } from "redux-store";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import closeImg from "assets/images/modal-close.svg";
import { useWindowResize } from "../../components/useWindowResize";
import ConfirmationPopup from "../OrderDetails/ConfirmationPopup";
import congratsImg from "assets/images/congrats-icon.svg";

const AddFundBody = (props) => {
	const isMobileOnly = useWindowResize();
	const { dispatch } = props;
	const [gcValue, setGcValue] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [message, setMessage] = useState("");
	const { setCongratsModal = () => null } = props;
	return (
		<>
			{isMobileOnly ? (
				<main className="mobile-inner-screen addFund-mobile">
					<div
						className="payment__page__wrapper"
						style={{ height: "100vh-154px", background: "#fff" }}
					>
						<div className="go-back-wrapper">
							<a
								onClick={() => props.setIsOpen({ status: false, type: "" })}
								className="go-back-btn"
							>
								<img src={backArrowImg} alt="go back" />
							</a>
							<h2>Redeem E-Gift Card</h2>
						</div>
						<div className="main-wrapper">
							<section className="mobile-inner-section">
								<div className="main">
									<div className="container">
										<div className="addFund-wrapper">
											<h3 className="subheading-pwa">Enter E-Gift Code</h3>
											<div className="addFund_field">
												<input
													type="text"
													name=""
													id=""
													className="form-control"
													placeholder="Enter E-Gift Card Number"
													onChange={(e) =>
														setGcValue(e.target.value.replace(/^\s+|\s+$/g, ""))
													}
													value={gcValue}
													style={
														gcValue
															? {}
															: gcValue == null || gcValue == ""
															? {}
															: { border: "1px solid red" }
													}
												/>
											</div>
											<div className="note">
												<p>
													Note: E-Gift Card redemptions will be added into your
													Food Dudes Balance.
												</p>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="meanu-listing-mobile-footer">
								<div className="container">
									<div className="bg">
										<div className="modal-bottom-actions align-items-start flex-column-reverse">
											<button
												type="submit"
												style={{ borderWidth: "0px" }}
												// className="btn btn-filled w-100 payment-method-btn"
												className={`"btn btn-filled w-100 payment-method-btn" ${
													gcValue && gcValue.length ? "" : "disabled-state"
												}`}
												onClick={!gcValue?null:() =>
													dispatch(
														addFundApi(
															{ giftcard_code: gcValue },
															(message) => {
																setMessage(message);
																setShowModal(true);
																setTimeout(() => {
																	setShowModal(false);
																	props.setIsOpen({ status: false, type: "" });
																}, 3000);
															},
															true,
															setCongratsModal,
														),
													)
												}
											>
												Reedem e-Gift Card
											</button>
											<button
												type="button"
												className="btn btn-theme-outlined mb-3 w-100 payment-method-btn"
												data-dismiss="modal"
												onClick={() =>
													props.setIsOpen({ status: false, type: "" })
												}
											>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</main>
			) : (
				<div id="addFundModal">
					<div className="">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">
									<img
										src={closeImg}
										alt="icon"
										className="img-fluid"
										onClick={() => props.setIsOpen({ status: false, type: "" })}
									/>
								</span>
							</button>
							<div className="addFund-wrapper">
								<div className="modal_heading">
									<h2>Redeem E-Gift Card</h2>
								</div>
								<div className="addFund_field">
									{/* <h3 className="subheading-pwa">Enter E-Gift Code</h3> */}
									<span className="regift-head">Enter E-Gift Code</span>
									<input
										type="text"
										name=""
										id=""
										className="form-control"
										placeholder="Enter E-Gift Card Number"
										onChange={(e) =>
											setGcValue(e.target.value.replace(/^\s+|\s+$/g, ""))
										}
										value={gcValue}
										style={
											gcValue
												? {}
												: gcValue == null || gcValue == ""
												? {}
												: { border: "1px solid red" }
										}
									/>
								</div>
								{/* <div className="note">
                  <p>
                    Note: E-Gift Cards you redeem will be credited into Food
                    Dude Balance
                  </p>
                </div> */}
								<div className="modal-bottom-actions">
									<button
										type="button"
										className="btn btn-theme-outlined"
										data-dismiss="modal"
										onClick={() => props.setIsOpen({ status: false, type: "" })}
									>
										Cancel
									</button>
									<button
										type="submit"
										style={{ borderWidth: "0px" }}
										className={`"btn btn-filled active-bg" ${
											gcValue && gcValue.length ? "" : "disabled-state"
										}`}
										onClick={!gcValue?null:() =>
											dispatch(
												addFundApi(
													{ giftcard_code: gcValue },
													() => props.setIsOpen({ status: false, type: "" }),
													false,
													setCongratsModal,
												),
											)
										}
									>
										Redeem e-Gift Card
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<ConfirmationPopup
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
			>
				<div id="#congrats-modal">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => {
										// props.setIsOpen({ status: false, type: "" })
										setShowModal(false);
									}}
								>
									<span aria-hidden="true">
										<img src={closeImg} alt="icon" className="img-fluid" />
									</span>
								</button>
								<div className="congrates-wrapper">
									<div className="img-box">
										<img src={congratsImg} alt="icon" className="img-fluid" />
									</div>
									<div className="content-box">
										<h1>Congrats!</h1>
										<p>{message}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ConfirmationPopup>
		</>
	);
};

export default AddFundBody;
