import React from "react";
import Modal from "react-bootstrap/Modal";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import closeImg from "assets/images/modal-close.svg";
import cancelOrderImg from "assets/images/cancel-order-icon.svg";

const CancelOrderModal = ({ show, closeModal, isMobileOnly, onSubmit }) => {
  return (
    <>
      <Modal
        id="cancelOrder-modal"
        show={show}
        onHide={closeModal}
        keyboard={false}
        fullscreen={isMobileOnly}
        centered
      >
        {isMobileOnly ? (
          <>
            <main className="mobile-inner-screen driver-tip-mobile">
              <div className="payment__page__wrapper">
                <div className="go-back-wrapper" onClick={closeModal}>
                  <a href className="go-back-btn">
                    <img src={backArrowMobileImg} alt="go back" />
                  </a>
                  <h2>Cancel Order</h2>
                </div>
                <div className="main-wrapper">
                  <section className="mobile-inner-section">
                    <div className="main">
                      <div className="container">
                        <div id="cancelOrder-modal">
                          <div className="congrates-wrapper">
                            <div className="img-box">
                              <img
                                src={cancelOrderImg}
                                alt="icon"
                                className="img-fluid"
                              />
                            </div>
                            <div className="content-box">
                              <h1>Cancel Order ?</h1>
                              <p>Are you sure you want to cancel the order?</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="meanu-listing-mobile-footer checkout-footer">
                    <div className="container">
                      <div className="bg">
                        <div className="modal-bottom-actions">
                          <div className="place-order-btn-wrapper">
                            <button
                              type="button"
                              className="btn btn-filled"
                              onClick={onSubmit}
                            >
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </>
        ) : (
          <>
            <Modal.Body>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">
                  <img src={closeImg} alt="icon" className="img-fluid" />
                </span>
              </button>
              <div className="congrates-wrapper">
                <div className="img-box">
                  <img src={cancelOrderImg} alt="icon" className="img-fluid" />
                </div>
                <div className="content-box">
                  <h1>Cancel Order ?</h1>
                  <p>Are you sure you want to cancel the order?</p>
                </div>
                <div className="cancel-order-action-wrapper" onClick={onSubmit}>
                  <button>Cancel Order</button>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default CancelOrderModal;
