import React, { useEffect, useState } from "react";

const ItemAttributes = ({ optionValuesNew=[], specialInstruction, ...props }) => {
	// const [showAttribute, setshowAttribute] = useState({
	// 	itemsToShow: 3,
	// 	expanded: false,
	// });

	// const showMore = (optionValuesNew) => {
	// 	showAttribute.itemsToShow === 3
	// 		? setshowAttribute({
	// 				itemsToShow: optionValuesNew.length,
	// 				expanded: true,
	// 		  })
	// 		: setshowAttribute({ itemsToShow: 3, expanded: false });
	// };
	// const [spInst, setSpInst] = useState("");
	// useEffect(() => {
	// 	optionValuesNew.map((value, index) =>
	// 		value.products_options_values_text
	// 			? setSpInst(value.products_options_values_text)
	// 			: null,
	// 	);
	// }, [optionValuesNew]);

	return (
		<div className="order-item-details-wrapper">
			<div className="order-item-details">
				<div>
					{optionValuesNew.map((value, index) => (
							<>
								<span className={`comma ${value.products_options_values_text ? "spl_instruction" : ""}`}>
									{value.options_values_id
										? value.products_options_values_name
										: value.products_options_values_text?value.products_options_values_text:null}
								</span>
							</>
						))}
				
				</div>

				<p>{specialInstruction}</p>
			</div>
		</div>
	);
	// return (
	// 	<div className="order-item-details-wrapper">
	// 		<div className="order-item-details">
	// 			<div>
	// 				{optionValuesNew
	// 					.slice(0, showAttribute.itemsToShow)
	// 					.map((value, index) => (
	// 						<>
	// 							<span className={"comma"}>
	// 								{value.products_options_values_name
	// 									? value.products_options_values_name
	// 									: null}
	// 							</span>
	// 						</>
	// 					))}
	// 				{(spInst ? optionValuesNew.length - 1 : optionValuesNew.length) >
	// 				3 ? (
	// 					<a className="btn btn-show-more-less" onClick={showMore}>
	// 						{showAttribute.expanded ? (
	// 							<span className="showLess">Show less</span>
	// 						) : (
	// 							<span className="showMore">
	// 								Show more (
	// 								{(spInst
	// 									? optionValuesNew.length - 1
	// 									: optionValuesNew.length) - 3}
	// 								)
	// 							</span>
	// 						)}
	// 					</a>
	// 				) : (
	// 					""
	// 				)}
	// 			</div>
	// 			{spInst && <p>{spInst}</p>}

	// 			<p>{specialInstruction}</p>
	// 		</div>
	// 	</div>
	// );
};

export default ItemAttributes;
