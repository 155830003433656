import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./Auth/authReducer";
import profileReducer from "./Profile/profileReducer";
import restReducer from "./Resturants/restReducer";
import tabsReducer from "./Tabs/tabsReducer";
import { cartReducer, groupCartReducer } from "./Cart/cartReducer";
import giftCardReducer from "redux-store/GiftCards/GiftCardReducer";
import notifyReducer from "./Notification/notifyReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profile", "rest", "cart", "gc"],
};
const restPersistConfig = {
  key: "rest",
  storage,
  blacklist: ["groupOrder", "groupOrderInfo", "selectedGroupOrderItem"],
};
const groupCartPersistConfig = {
  key: "groupCart",
  storage,
  blacklist: ["cart", "clientToken"],
};
const notifyPersistConfig = {
  key: "notification",
  storage,
  blacklist: ["notificationList", "unreadNotify", "totalNotifications"],
};

const appReducer = combineReducers({
  tab: tabsReducer,
  auth: authReducer,
  profile: profileReducer,
  rest: persistReducer(restPersistConfig, restReducer),
  cart: cartReducer,
  gc: giftCardReducer,
  groupCart: persistReducer(groupCartPersistConfig, groupCartReducer),
  notification: persistReducer(notifyPersistConfig, notifyReducer),
});

const initialState = {
  reduxSetup: true,
  loader: false,
  token: false,
  headerTab: "Delivery",
  stateAndCites: [],
  cities: [],
};
const initialStateProfile = {
  loader: false,
  selectedAddress: {},
  defaultAddress: {},
  allAddress: [],
  updateStatus: false,
  deliveryDate: "",
  deliveryTime: "",
  timeInd: "",
  prevAddress:{}

};
const initialStateRest = {
  resturants: [],
  cuisines: [],
  menus: {},
  selectedResturantData: {},
  unSelectedResturantData: {},
  restaurant_id: 0,
  itemsDetail: {},
  selectedMenuItem: {},
  timeArray: [],
  timeArrayNew: [],
  groupOrder: {},
  groupOrderInfo: {},
  selectedGroupOrderItem: {},
};
const cartInitialState = {
  cart: {},
  tips: [10, 15, 20, 25],
  giftCard: {},
  coupan: {},
  wallet_amount: { amount: 0 },
  placeOrderCharges: {},
  instruction: "",
  instructionGroup:"",
  taxdata: {
    local_tax_rate: 0,
    low_order_fee: 0,
    low_order_min_amount: 0,
    state_tax_rate: 0,
  },
  clientToken: null,
  ordersList: [],
  disabledProducts: {},
};
const giftCardInitialState = {
  gc_cart: {},
  rewardHistoryData: {},
  editData: {},
};
const notifyInitialState = {
  notificationList: [],
  unreadNotify: 0,
  totalNotifications: 0,
};

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    return {
      ...state,
      tab: {
        activeTab: "home",
        customers_username: "",
        routePlaceOrder: false,
        modalIsOpen: { status: false, code: "" },
        selectedPaymentMethods: {},
        radioNotes: "",
      },
      auth: initialState,
      profile: initialStateProfile,
      rest: initialStateRest,
      cart: cartInitialState,
      gc: giftCardInitialState,
      notification:notifyInitialState,
    };
  } else {
    // appReducer
  }
  return appReducer(state, action);
};
export default persistReducer(persistConfig, rootReducer);
