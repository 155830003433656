import React from "react";

const Heading = (props) => {
  return (
    <div className="col-12" style={props.margin ? { marginTop: "8px" } : {}}>
      <div className="wrapper featured-restaurants-wrapper">
        <h2>{props.headingText}</h2>
      </div>
    </div>
  );
};

export default Heading;
