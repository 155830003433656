import React, { Fragment, useEffect } from "react";
import AddOn from "./AddOn";

const MenuItemContent = (props) => {
	const {
		products_id,
		products_image,
		products_name,
		products_description,
		options = [],
	} = props.data ? props.data : {};

	useEffect(() => {
		let r1 = {};
		options.map((itemFir) => {
			switch (itemFir.products_options_types_name) {
				case "Dropdown":
					break;
				case "Radio":
					itemFir.optionValues.map((item) => {
						if (item.attributes_price_base_included) {
							r1 = {
								...r1,
								[itemFir.options_id]: {
									...item,
									options_id: itemFir.options_id,
									products_options_types_name:
										itemFir.products_options_types_name,
									products_options_name: itemFir.products_options_name,
								},
							};
						}
					});
					break;
				case "Text":
					break;
				case "Checkbox":
					break;
			}
			props.setRadio(r1);
		});
	}, []);

	const renderOptions = (item, index) => {
		return (
			<Fragment key={item.options_id}>
				<div className="col-12">
					<div className="portion d-flex justify-content-between">
						<span className="heading">{item.products_options_name}</span>
						{/* <span>Required</span> */}
					</div>
				</div>
				<AddOn
					optionValues={item.optionValues}
					type={item.products_options_types_name}
					options_id={item.options_id}
					products_options_types_name={item.products_options_types_name}
					products_options_name={item.products_options_name}
					{...props}
				/>
			</Fragment>
		);
	};

	return (
		<div className="row">
			<div className="col-12 mb-2">
				<h2>{products_name}</h2>
				<p>{products_description}</p>
			</div>
			{options.map(renderOptions)}

			{/* <div className="col-12 mt-3">
				<div className="portion d-flex justify-content-between mb-3">
					<span className="heading">Special Instructions</span>
				</div>
				<div className="special-instruction">
					<textarea
						id="splinstruction"
						className="form-control"
						name="special-instruction"
						// rows="4"
						cols="50"
						value={props.specialInstruction}
						placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl at ornare nullam gravida. Sapien in tristique odio quam aliquam mus enim sit. Dapibus tempor, eget habitant mauris eu ornare nullam gravid apien in tristique odio rutrum augue."
						onChange={(e)=>props.setSpecialInstruction(e.target.value)}
					></textarea>
				</div>
			</div> */}
			{props.children}
		</div>
	);
};
export default MenuItemContent;
