import React from "react";
import CitiesContainer from "./CitiesContainer";

const StateContainer = (props) => {
	const { item = {} } = props;
  const renderCities = (item) => <CitiesContainer c={item}  {...props} />
	return (
		<div className="col-6 col-md-3 pl-0 mb-4" key={item.id}>
			<h3>{item.name}</h3>
			<ul>
        {item.cities && item.cities.length && item.cities.map(renderCities)}
			</ul>
		</div>
	);
};
export default StateContainer;
