import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { regEmail, regPhone } from "../validation";
import { resetPassword } from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import { regPassword } from "../validation";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import resetPassImg from "assets/images/reset-pass.svg";
import eyeShowImg from "assets/images/eye-show-img.svg";
import hidePassImg from "assets/images/hide-pass.svg";
import { useWindowResize } from "./../components/useWindowResize";

const ResetPassword = (props) => {
  const isMobileOnly = useWindowResize();

  let location = useLocation();
  const { dataReset } = useSelector((store) => store.auth);
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues } = useForm();
  // const {data} = useLocation();
  const history = useHistory();

  const reset = dataReset.otp;
  const onSubmit = (value) => {
    if (reset) {
      dispatch(
        resetPassword({ ...dataReset, ...value }, () => {
          history.push("/signin");
        })
      );
    } else {
    }

    // alert(JSON.stringify(value))
  };
  const [hideButtons, setHideButtons] = useState({ btn1: "", btn2: "" });
  return (
    <>
      {location.data ? null : <Redirect to="/" />}
      {!isMobileOnly ? <Header auth /> : null}
      <main className="mobile-screen-bg">
        <div className="container">
          <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
            <div className="wrapper">
              <div className="row mobile-logodiv backbtn-div d-md-none">
                <div className="col-12">
                  <a onClick={()=>history.goBack()}>
                    <img src={backArrowImg} alt="go back" />
                  </a>
                </div>
              </div>
              <div className="row despktop-logodiv mb-4 mb-md-5">
                <div className="col-12">
                  <div className="top-logo-wrapper">
                    <a>
                      <img src={resetPassImg} alt="reset password" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row centered-heading">
                <div className="col-12 mb-2 mb-md-4">
                  {reset ? <h1>Reset Password</h1> : <h1>Change Password</h1>}
                </div>
                {reset && (
                  <div className="col-12 mb-3 mb-md-4 text-center">
                    {/* <p>
                      Don’t worry, just enter your phone number and <br />
                      we will send you a 6-digit Code to reset it.
                    </p> */}
                  </div>
                )}
              </div>
              <div className="forgot-password mb-4">
                <form
                  className="needs-validation signups"
                  novalidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-12 mb-4 position-relative">
                      <input
                        type={!hideButtons.btn1 ? "password" : "text"}
                        className="form-control input-field-layout"
                        id="newpassword"
                        placeholder="New Password"
                        style={
                          errors.customers_password
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        name="customers_password"
                        ref={register({
                          required: "Password is required",
                          pattern: {
                            value: regPassword,
                            message:
                              "Password must meet the complexity criteria ( 8 chars long, Alphanumeric + 1 capital letter + at least 1 special character)",
                          },
                        })}
                      />
                      <button
                        id="showpass"
                        type="button"
                        onClick={() =>
                          setHideButtons({
                            ...hideButtons,
                            btn1: hideButtons.btn1 ? false : true,
                          })
                        }
                      >
                        {hideButtons.btn1 ? (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={eyeShowImg}
                              alt="hide passowrd"
                            />
                          </span>
                        ) : (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={hidePassImg}
                              alt="show passowrd"
                            />
                          </span>
                        )}
                      </button>
                      {errors.customers_password && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_password.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide valid password
                      </div>
                    </div>
                    <div className="col-12 mb-4 position-relative">
                      <input
                        type={!hideButtons.btn2 ? "password" : "text"}
                        className="form-control input-field-layout"
                        id="confirmpassword"
                        placeholder="Confirm Password"
                        style={
                          errors.customers_password_confirmation
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        name="customers_password_confirmation"
                        ref={register({
                          required: "Confirm password is required",
                          validate: () =>
                            getValues()["customers_password"] ===
                            getValues()["customers_password_confirmation"]
                              ? true
                              : "New Password and Re-enter Password do not match",
                        })}
                      />
                      <button
                        id="showpass"
                        type="button"
                        onClick={() =>
                          setHideButtons({
                            ...hideButtons,
                            btn2: hideButtons.btn2 ? false : true,
                          })
                        }
                      >
                        {hideButtons.btn2 ? (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={eyeShowImg}
                              alt="hide passowrd"
                            />
                          </span>
                        ) : (
                          <span className="">
                            <img
                              className="img-fluid"
                              src={hidePassImg}
                              alt="show passowrd"
                            />
                          </span>
                        )}
                      </button>
                      {errors.customers_password_confirmation && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_password_confirmation.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn initial-screen-lg-btn font-20 mt-2"
                    id="rest-password-btn"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {!isMobileOnly ? <Footer /> : null}
    </>
  );
};

export default ResetPassword;
