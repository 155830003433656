import {
  GET_PROFILE_DATA,
  SET_SELECTED_ADDRESS,
  SET_PRIMARY_ADDRESS, 
  GET_ADDRESS, 
  DELETE_ADDRESS, 
  SAVE_ADDRESS,
  GET_ADDRESS_BYID,
  SET_DEFAULT_ADDRESS,
  RECALL_API,
  SELECTED_TIME,
  SELECTED_DATE, 
  SET_PREV_ADDRESS
} from './profileType'

const initialState = {
  loader:false,
  selectedAddress:{},
  defaultAddress: {},
  allAddress: [],
  updateStatus:false,
  deliveryDate:'',
  deliveryTime:'',
  timeInd:'',
  prevAddress:{} 
}



const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA: return {
      ...state,
      ...action.payload
    }
    case SET_SELECTED_ADDRESS: return {
      ...state,
      selectedAddress: action.payload
    }
    case SET_PREV_ADDRESS: return {
      ...state,
      prevAddress: action.payload
    }
    case SET_DEFAULT_ADDRESS: 
    if(Object.keys(state.selectedAddress).length > 0){
      return {
        ...state,
        defaultAddress: action.payload,
        // selectedAddress: action.payload
      }
    }else{
      
      return {
        ...state,
        defaultAddress: action.payload,
        selectedAddress: Object.keys(state.prevAddress).length?{...state.prevAddress}:action.payload,
        prevAddress:{}
      }
    }
    
    case GET_ADDRESS: return {
      ...state,
      allAddress: action.payload
    }
    case RECALL_API: return {
      ...state,
      updateStatus:!state.updateStatus
    }
    case SELECTED_DATE: return {
      ...state,
      deliveryDate:action.payload
    }
    case SELECTED_TIME: return {
      ...state,
      deliveryTime:action.payload
    }
    case "TIME_INDEX": return {
      ...state,
      timeInd:action.payload
    }
    default: return state
  }
}

export default profileReducer