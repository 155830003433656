import React, { useState } from "react";
import notificationImg from "assets/images/notification.svg";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useWindowResize } from "components/useWindowResize";
import { markNotificationRead, validateCouponCode } from "redux-store";
import notificationIocn from "assets/images/notification-big-icon.svg";

const NotificationDropdown = () => {
  const isMobileOnly = useWindowResize();
  const history = useHistory();
  const dispatch = useDispatch();

  const { notificationList = [], unreadNotify } = useSelector(
    (store) => store.notification
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const getLink = (notify) => {
    markNotificationAsRead(notify);
    if (notify?.entity_type === 1) {
      history.push({
        pathname: `/orderdetails/${notify?.entity_id}`,
        hash: 'notify',
        // state: { detail: response.data }
      });
    }
  };

  const markNotificationAsRead = (notify) => {
    if (!notify?.is_read) {
      dispatch(markNotificationRead(notify.id));
    }
  };

  const applyCouponCode = (e, notify) => {
    e.stopPropagation();
    checkValidationOfCouponCode(notify);
  };

  const checkValidationOfCouponCode = (notify) => {
    dispatch(validateCouponCode(notify.coupon_code));
    markNotificationAsRead(notify);
  };

  return (
    <>
      <div className="d-inline notification-wrapper">
        <Dropdown
          className="d-inline-block"
          drop="right"
          onToggle={setShowDropdown}
          show={showDropdown}
        >
          <Dropdown.Toggle id="notification-dropdown">
            <div className={`if_notification ${showDropdown ? "open" : ""}`}>
              <img src={notificationImg} alt="notifications" />
              {unreadNotify ? <span className="notification_dot"></span> : null}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {notificationList && notificationList?.length > 0 ? (
              <>
                <div className="notification_menu_wrapper">
                  <h4>Notifications</h4>
                  <ul className="notification_lists mb-0">
                    {notificationList.slice(0, 4).map((notify, idx) => (
                      <li
                        key={idx}
                        className={notify?.is_read ? "read-notify" : ""}
                      >
                        <a href onClick={() => getLink(notify)}>
                          <p>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: notify.message,
                              }}
                            ></span>
                            {notify?.entity_type === 3 ? (
                              <>
                                <button
                                  className="apply-link"
                                  onClick={(e) => applyCouponCode(e, notify)}
                                >
                                  Apply Now
                                </button>
                              </>
                            ) : null}
                          </p>
                          <span className="notification_lists_timestamp">
                            {moment(notify.created_at).format(
                              "ddd MM/DD/YYYY h:mm A"
                            )}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                  {notificationList && notificationList.length > 0 ? (
                    <Link to="/notifications">
                      <button className="view_all_btn">View All</button>
                    </Link>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="notification_menu_wrapper no_notification in-dropdown">
                <div className="empty-box">
                <div className="img-box">
                  <img src={notificationIocn} alt="notification icon" />
                </div>
                <div className="content-box">
                  <p>No notifications right now.</p>
                </div>
              </div>
                </div>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default NotificationDropdown;
