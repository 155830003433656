import React, { useState, useEffect } from "react";
import MenuItemContent from "./MenuItemContent";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import { BASE_URL_IMAGE } from "service/ApiNames";

const MobileView = (props) => {
  const { groupToken } = useParams();
  const { limitExceedMessage, data, store, dispatch, isItemNotAvailable } =
    props;

  const [isDisabled, setIsDisabled] = useState(
    data?.isDisabled || isItemNotAvailable
  );

  const { status } = store.tab.modalIsOpen;

  useEffect(() => {
    setIsDisabled(data?.isDisabled || isItemNotAvailable);
  }, [props]);

  useEffect(() => {
    return () => {
      closeItemModal();
    };
  }, []);

  const closeItemModal = () => {
    dispatch({ type: "ITEM_MODAL", payload: { status: false, type: "" } });
  };

  return (
    <>
      <Modal
        className="modal fade menu-item-detailsModal"
        id="menu-item-detailsModal"
        fullscreen
        show={status}
        onHide={closeItemModal}
        aria-labelledby="menu-item-detailsModalLabel"
        centered
      >
        {props.data ? (
          <main className="menu-item-detailsModal">
            <div className="container-wrapper">
              <div className="main-wrapper">
                <section className="mobile-inner-section">
                  <div id="menu-item-detailsModal">
                    <div className="modal-dialog-mobile">
                      <div className="go-back-wrapper">
                        <a
                          href
                          onClick={closeItemModal}
                          className="go-back-btn"
                        >
                          <img src={backArrowMobileImg} alt="go back" />
                        </a>
                      </div>
                      {props.data && props.data?.products_image ? (
                        <div className="menu-item-banner-image">
                          <img
                            src={`${BASE_URL_IMAGE}${props?.data?.products_image}`}
                            alt="menu-item"
                            className="img-fluid"
                          />
                        </div>
                      ) : null}

                      <div
                        className={`modal-content border-0 ${
                          props.data && props.data?.products_image ? "" : "pt-5"
                        }`}
                      >
                        <div className="container">
                          <div className="modal-body">
                            <div className="menu-item-modal-wrapper">
                              <div lass="menu-item-modal-wrapper-inner">
                                {limitExceedMessage ? (
                                  <div className="row">
                                    <div class="col-12 mb-2">
                                      <div class="redeem-points bg-beige text-dark d-inline-block font-weight-bold">
                                        {limitExceedMessage}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <MenuItemContent {...props} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="meanu-listing-mobile-footer">
                  <div className="container">
                    <div className="bg">
                      <div
                        className={`modal-bottom-actions ${
                          isDisabled ? "disabled-state" : ""
                        }`}
                      >
                        <div className="quantity-actions">
                          <span
                            className="quantity-changes"
                            onClick={
                              props.quantityLoader
                                ? () => null
                                : props.customers_basket_quantity == 1
                                ? () => null
                                : () =>
                                    isDisabled
                                      ? null
                                      : props.handleQuantity(false)
                            }
                          >
                            -
                          </span>
                          {/* <span>{props.customers_basket_quantity}</span> */}
                          <span className="quantity-input-box">
                            <input
                              value={props.customers_basket_quantity}
                              onChange={
                                isDisabled ? () => null : props.onChangeInput
                              }
                              style={{ border: "none" }}
                            />
                          </span>
                          <span
                            className="quantity-changes"
                            onClick={
                              props.quantityLoader
                                ? () => null
                                : () =>
                                    isDisabled
                                      ? null
                                      : props.handleQuantity(true)
                            }
                          >
                            +
                          </span>
                        </div>
                        <div className="update-order-btn-wrapper">
                          <button
                            type="button"
                            disabled={isDisabled || limitExceedMessage}
                            className="btn btn-filled"
                            onClick={
                              props.customers_basket_quantity
                                ? isDisabled || limitExceedMessage
                                  ? null
                                  : props.handleAddToCart
                                : () => toast.error("Please add quantity")
                            }
                          >
                            {groupToken ? (
                              <>{props.isEdit ? "Update Item" : "Add Item"}</>
                            ) : (
                              <>
                                {props.isEdit ? "Update Order" : "Add To Order"}
                              </>
                            )}
                            <span className="total-order-value">
                              ${parseFloat(props.total).toFixed(2)}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        ) : (
          <div>
            <section className="loading">
              <div className="sk-spinner-pulse"></div>
            </section>
          </div>
        )}
      </Modal>
    </>
  );
};
export default MobileView;
