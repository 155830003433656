import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { regEmail, regPhone } from "../../validation";
import { resetPassword, changePassword } from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { regPassword } from "../../validation";
import Header from "../../components/Header";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import resetPassImg from "assets/images/reset-pass.svg";
import eyeShowImg from "assets/images/eye-show-img.svg";
import hidePassImg from "assets/images/hide-pass.svg";

const ChangePassword = (props) => {
  const profile = useSelector((s) => s.profile);
  const { customers_email_address } = profile;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues } = useForm();
  const { data } = useLocation();
  const history = useHistory();
  const [hideButtons, setHideButtons] = useState({
    btn1: "",
    btn2: "",
    btn3: "",
  });

  const onSubmit = (value) => {
    dispatch(
      changePassword({
        ...value,
        customers_username: customers_email_address,
      })
    );
  };
  return (
    <>
      <div className="container">
        <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens pt-md-0">
          <div className="wrapper">
            
            <div className="row despktop-logodiv mb-4 mb-md-5">
              <div className="col-12">
                <div className="top-logo-wrapper">
                  <a>
                    <img src={resetPassImg} alt="reset password" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row centered-heading">
              <div className="col-12 mb-2 mb-md-4">
                <h1>Change Password</h1>
              </div>
            </div>
            <div className="forgot-password mb-4">
              <form
                className="needs-validation signups"
                novalidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-row">
                  <div className="col-12 mb-4 position-relative">
                    <input
                      type={!hideButtons.btn1 ? "password" : "text"}
                      className="form-control input-field-layout"
                      id="newpassword"
                      placeholder="Current password"
                      style={
                        errors.current_password
                          ? { borderColor: "#dc3545" }
                          : {}
                      }
                      name="current_password"
                      ref={register({
                        required: "Current password is required",
                      })}
                    />
                    <button
                      id="showpass"
                      type="button"
                      onClick={() =>
                        setHideButtons({
                          ...hideButtons,
                          btn1: hideButtons.btn1 ? false : true,
                        })
                      }
                    >
                      {hideButtons.btn1 ? (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={eyeShowImg}
                            alt="hide passowrd"
                          />
                        </span>
                      ) : (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={hidePassImg}
                            alt="show passowrd"
                          />
                        </span>
                      )}
                    </button>
                    {errors.current_password && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#e3342f",
                        }}
                      >
                        {errors.current_password.message}
                      </div>
                    )}
                  </div>

                  <div className="col-12 mb-4 position-relative">
                    <input
                      type={!hideButtons.btn2 ? "password" : "text"}
                      className="form-control input-field-layout"
                      id="newpassword"
                      placeholder="New Password"
                      style={
                        errors.customers_password
                          ? { borderColor: "#dc3545" }
                          : {}
                      }
                      name="new_password"
                      ref={register({
                        required: "New password is required",
                        pattern: {
                          value: regPassword,
                          message:
                            "Password must meet the complexity criteria ( 8 chars long, Alphanumeric + 1 capital letter + at least 1 special character)",
                        },
                      })}
                    />
                    <button
                      id="showpass"
                      type="button"
                      onClick={() =>
                        setHideButtons({
                          ...hideButtons,
                          btn2: hideButtons.btn2 ? false : true,
                        })
                      }
                    >
                      {hideButtons.btn2 ? (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={eyeShowImg}
                            alt="hide passowrd"
                          />
                        </span>
                      ) : (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={hidePassImg}
                            alt="show passowrd"
                          />
                        </span>
                      )}
                    </button>
                    {errors.new_password && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#e3342f",
                        }}
                      >
                        {errors.new_password.message}
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-4 position-relative">
                    <input
                      type={!hideButtons.btn3 ? "password" : "text"}
                      className="form-control input-field-layout"
                      id="confirmpassword"
                      placeholder="Confirm Password"
                      style={
                        errors.customers_password_confirmation
                          ? { borderColor: "#dc3545" }
                          : {}
                      }
                      name="new_password_confirmation"
                      ref={register({
                        required: "Confirm password is required",
                        validate: () =>
                          getValues()["new_password"] ===
                          getValues()["new_password_confirmation"]
                            ? true
                            : "New Password and Re-enter Password do not match",
                      })}
                    />
                    <button
                      id="showpass"
                      type="button"
                      onClick={() =>
                        setHideButtons({
                          ...hideButtons,
                          btn3: hideButtons.btn3 ? false : true,
                        })
                      }
                    >
                      {hideButtons.btn3 ? (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={eyeShowImg}
                            alt="hide passowrd"
                          />
                        </span>
                      ) : (
                        <span className="">
                          <img
                            className="img-fluid"
                            src={hidePassImg}
                            alt="show passowrd"
                          />
                        </span>
                      )}
                    </button>
                    {errors.new_password_confirmation && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#e3342f",
                        }}
                      >
                        {errors.new_password_confirmation.message}
                      </div>
                    )}
                    <div className="invalid-feedback">
                      Please provide valid password
                    </div>
                  </div>
                </div>
                <button
                  className="btn initial-screen-lg-btn font-20 mt-2"
                  id="rest-password-btn"
                  type="submit"
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
