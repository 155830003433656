import React, { useEffect } from "react";
import SideNav from "../../components/Header/SideNav";
import Footer from "../../components/Footer";
import { setActiveTabs } from "redux-store";
import { useSelector, useDispatch } from "react-redux";

const Account = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveTabs("account"));
  }, []);

  return (
    <>
      <SideNav />
      <Footer />
    </>
  );
};

export default Account;
