export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const SET_UNREAD = "SET_UNREAD";
export const SET_TOTAL_NOTIFICATIONS = "SET_TOTAL_NOTIFICATIONS";

const initialState = {
  notificationList: [],
  unreadNotify: 0,
  totalNotifications: 0,
};

const notifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case SET_UNREAD:
      return {
        ...state,
        unreadNotify: action.payload,
      };
    case SET_TOTAL_NOTIFICATIONS:
      return {
        ...state,
        totalNotifications: action.payload,
      };
    default:
      return state;
  }
};

export default notifyReducer;
