import { ADD_TO_CART_GC, REWARD_TRANSACTION_HISTORY, EDIT_DATA } from "./GiftCardsType";
const initialState = {
  gc_cart: {},
  rewardHistoryData: {},
  editData:{}
};

const giftCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_GC:
      const arr = { ...action.payload };
      const { cart_items = [] } = arr;

      const x = cart_items.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total,
        0
      );
      console.log("items=>test", { ...action.payload, cartTotal: x });
      return {
        ...state,
        gc_cart: { ...action.payload, cartTotal: x },
      };
    case REWARD_TRANSACTION_HISTORY:
      return {
        ...state,
        rewardHistoryData: action.payload,
      };
    case EDIT_DATA:
      return {
        ...state,
        editData: action.payload,
      };
      
    default:
      return state;
  }
};

export default giftCardReducer;
