import {
  GET_PROFILE_DATA,
  SET_SELECTED_ADDRESS,
  SET_PRIMARY_ADDRESS,
  GET_ADDRESS,
  DELETE_ADDRESS,
  SAVE_ADDRESS,
  GET_ADDRESS_BYID,
  SET_DEFAULT_ADDRESS,
  RECALL_API,
  SELECTED_TIME,
  SELECTED_DATE,
  SET_PREV_ADDRESS
} from "./profileType";
import { toast } from "react-toastify";

import { AUTH_LOADER } from "../Auth/authType";
import { setLoader } from "../Auth/authAction";
import { APIUtils, ApiNames, axios } from "../../service";

export const setProfileData = (payload) => {
  return {
    type: GET_PROFILE_DATA,
    payload: payload,
  };
};

export const selectedAddress = (payload) => {
  return {
    type: SET_SELECTED_ADDRESS,
    payload: payload,
  };
};
export const setPrevAddress = (payload) => {
  return {
    type: SET_PREV_ADDRESS,
    payload: payload,
  };
};
export const setDefaultAddress = (payload) => {
  return {
    type: SET_DEFAULT_ADDRESS,
    payload: payload,
  };
};

export const setAllAddress = (payload) => {
  return {
    type: GET_ADDRESS,
    payload: payload,
  };
};
export const recallApis = () => {
  return {
    type: RECALL_API,
  };
};

export const selectDate = (payload) => {
  return {
    type: SELECTED_DATE,
    payload: payload,
  };
};
export const selectTime = (payload) => {
  return {
    type: SELECTED_TIME,
    payload: payload,
  };
};
export const timeIndex = (payload) => {
  return {
    type: "TIME_INDEX",
    payload: payload,
  };
};

export const getProfile = () => (dispatch, getState) => {
  dispatch(setLoader(true));
  const store = getState(s=>s)
  APIUtils.get(ApiNames.GET_PROFILE, {
    // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
  })
    .then((response) => {
      const values = response.data.data[0];

      const {
        customers_email_address,
        customers_firstname,
        customers_lastname,
        customers_telephone,
        customers_default_address_id,
        customers_newsletter,
        provider,
        provider_uid,
        customers_id,
        braintree_customer_id,
        customers_tax_exempt,
      } = values;
      dispatch(
        setProfileData({
          customers_email_address,
          customers_firstname,
          customers_lastname,
          customers_telephone,
          customers_default_address_id,
          customers_newsletter,
          provider,
          provider_uid,
          customers_id,
          braintree_customer_id,
          customers_tax_exempt,
        })
      );
      // cb()
      // toast.success(response.data.message)



      if(customers_default_address_id){
        dispatch(getAddressByID(customers_default_address_id));
      }else {
       
          dispatch(selectedAddress({...store.profile.prevAddress}));
          dispatch(setPrevAddress({}))

      }
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
      // toast.error(error.response.data.message)
    });
};

export const saveAddress =
  (data, cb, isCheckout, avoidMessage) => (dispatch, getState) => {
    dispatch(setLoader(true));
    const store = getState();
    APIUtils.post(ApiNames.SAVE_ADDRESS, data)
      .then((response) => {
        if (!avoidMessage) {
          toast.success(response.data.message);
        }
        dispatch(setLoader(false));
        dispatch(recallApis());
        if (isCheckout) {
          // store.profile.selectedAddress
          dispatch(getAddress());
          dispatch(selectedAddress(response.data.data));
        }
        cb();
      })
      .catch((error) => {
        dispatch(setLoader(false));
        toast.error(error.response.data.message);
      });
  };
export const getAddress = () => (dispatch, getState) => {
  dispatch(setLoader(true));
  APIUtils.get(ApiNames.GET_ADDRESS, {
    // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
  })
    .then((response) => {
      // dispatch(setDefaultAddress(response.data.data))
      dispatch(setAllAddress(response.data.data));
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
    });
};

export const getAddressByID = (id) => (dispatch, getState) => {
  const store = getState(s=>s)
  dispatch(setLoader(true));
  APIUtils.get(`${ApiNames.GET_ADDRESS_BYID}${id}`, {
    // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
  })
    .then((response) => {
      dispatch(setDefaultAddress(response.data.data));
      // dispatch(setSelec())
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
    });
};

export const deleteAddress = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.DELETE_ADDRESS, data)
    .then((response) => {
      // dispatch(setToken(response.data.token))
      // cb()
      toast.success(response.data.message);
      dispatch(recallApis());
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const setPrimaryAddress = (id, item) => (dispatch, getState) => {
  dispatch(setLoader(true));
  APIUtils.get(`${ApiNames.SET_PRIMARY_ADDRESS}${id}`, {
    // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
  })
    .then((response) => {
      // dispatch(setDefaultAddress(item))
      toast.success(response.data.message);
      // dispatch(recallApis())

      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
    });
};

export const changePassword = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.CHANGE_PASSWORD, data)
    .then((response) => {
      // dispatch(setToken(response.data.token))
      toast.success(response.data.message);
      dispatch(setLoader(false));
      // cb()
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const setSubscription = (id, scb, ecb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.get(`${ApiNames.GET_SUBSCRIPTION}${id}`, {
    // headers: { 'Authorization': `Bearer ${getState().auth.token}` }
  })
    .then((response) => {
      dispatch(setLoader(false));
      scb();
    })
    .catch((error) => {
      dispatch(setLoader(false));
      ecb();
    });
};

export const verifyAddress =
  (data, scb = () => null, status) =>
  (dispatch, getState) => {
    const store = getState();
    dispatch(setLoader(true));
    const dt = status
      ? {
          lat_from: data.address_lat.toString(),
          lng_from: data.address_lng.toString(),
          restaurant_id: data?.restaurant_id || store.cart.cart.restaurant_id,
        }
      : {
          lat_from: store.profile.selectedAddress.address_lat.toString(),
          lng_from: store.profile.selectedAddress.address_lng.toString(),
          restaurant_id: data?.restaurant_id || store.rest.restaurant_id,
        };
    APIUtils.post(ApiNames.VERIFY_ADDRESS, dt)
      .then((response) => {
        // dispatch(setToken(response.data.token))
        // toast.success(response.data.message)
        scb();
        dispatch(setLoader(false));
        // dispatch(recallApis())
        // cb()
      })
      .catch((error) => {
        dispatch(setLoader(false));
        // ecb()
        toast.error(error.response.data.message);
      });
  };
