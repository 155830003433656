/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import deleteIcon from "assets/images/delete-icon-sp3.svg";
import backIcon from "assets/images/back-arrow-mobile.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  cancelGroupOrder,
  initiateGroupOrder,
  removeGroupMember,
} from "redux-store";

import { getContactList, getGroupMembers } from "redux-store";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import { useHistory, useParams } from "react-router-dom";
import closeImg from "assets/images/modal-close.svg";

Yup.addMethod(Yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    if (array && array?.length) {
      const uniqueData = Array.from(
        new Set(array.map((row) => row[field]?.toLowerCase()))
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
      );
      if (array[index][field] === "") {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    }
    return true;
  });
});

const MobileView = (props) => {
  const { onClose, isReorder } = props;

  const [toggleForm, setToggleForm] = useState(true);
  const [toggleAddMember, setToggleAddMember] = useState(false);
  const [toggleContacts, setToggleContacts] = useState(false);
  const [addedMembers, setAddedMembers] = useState([]);
  const [contactList, setContactList] = useState([]);

  const limits = [0, 15, 20, 25];
  const [customLimit, setCustomLimit] = useState(null);

  const { groupToken } = useParams();
  const history = useHistory();

  const minimumMembers = 1;
  const dispatch = useDispatch();
  const [selectedLimit, setSelectedLimit] = useState(0);
  const [openOthers, setOpenOthers] = useState(false);
  const [fieldLengthErrorMessage, setFieldLengthErrorMessage] = useState(null);
  const { customers_email_address, customers_telephone } = useSelector(
    (store) => store.profile
  );
  const { groupOrderInfo, groupOrder } = useSelector((store) => store.rest);

  const [selectedMember, setSelectedMember] = useState(undefined);
  const [selectedMembersForReinvite, setSelectedMembersForReinvite] = useState(
    []
  );

  const [toggleRemoveModal, setToggleRemoveModal] = useState(false);
  const [selectedMemberToRemove, setMemberToRemove] = useState({
    idx: null,
    member: null,
  });

  const [isItFinalUserToRemove, setIsItFinalUserToRemove] = useState(false);

  const isGroupOrderStarted = groupOrder.group_order_id > 0;
  const memberSchema = Yup.object().shape(
    {
      id: Yup.number().default(0).required(),
      reinvite: Yup.boolean(),
      name: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9\s]+$/, {
          message: "Only alphanumeric characters allowed",
          excludeEmptyString: true,
        })
        .max(50, ({ max }) => `Name not more than ${max} characters`)
        .required("Please enter name"),
      email: Yup.string().when("phone", {
        is: "",
        then: Yup.string()
          .trim()
          .email("Invalid email format")
          .notOneOf(
            [
              customers_email_address,
              ...addedMembers
                .filter(
                  (it) => it.email !== "" && selectedMember?.email !== it.email
                )
                ?.map((it) => it?.email),
            ],
            "Entered email id has been added for another user. Please enter a different email id"
          )
          .max(100, ({ max }) => `Email not more than ${max} characters`)
          .required("Please enter email"),
        otherwise: Yup.string()
          .trim()
          .email("Invalid email format")
          .notOneOf(
            [
              customers_email_address,
              ...addedMembers
                .filter(
                  (it) => it.email !== "" && selectedMember?.email !== it.email
                )
                ?.map((it) => it?.email),
            ],
            "Entered email id has been added for another user. Please enter a different email id"
          )
          .max(100, ({ max }) => `Email not more than ${max} characters`),
      }),
      phone: Yup.string().when("email", {
        is: "",
        then: Yup.string()
          .trim()
          .matches(/^(\+\d{1,2})?(\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
            message: "Invalid phone format",
            excludeEmptyString: true,
          })
          .notOneOf(
            [
              customers_telephone ? "+1" + customers_telephone : "",
              ...addedMembers
                .filter(
                  (it) => it.phone !== "" && selectedMember?.phone !== it.phone
                )
                ?.map((it) => it?.phone),
            ],
            "Entered mobile number has been added for another user. Please enter a different mobile number"
          )
          .max(30, ({ max }) => `Phone not more than ${max} characters`)
          .required("Please enter phone"),
        otherwise: Yup.string()
          .trim()
          .matches(/^(\+\d{1,2})?(\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
            message: "Invalid phone format",
            excludeEmptyString: true,
          })
          .notOneOf(
            [
              customers_telephone ? "+1" + customers_telephone : "",
              ...addedMembers
                .filter(
                  (it) => it.phone !== "" && selectedMember?.phone !== it.phone
                )
                ?.map((it) => it?.phone),
            ],
            "Entered mobile number has been added for another user. Please enter a different mobile number"
          )
          .max(30, ({ max }) => `Phone not more than ${max} characters`),
      }),
    },
    [["email", "phone"]]
  );

  const validationSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(30, ({ max }) => `label not more than ${max} characters`),
    limit: Yup.number()
      .default(0)
      .transform((value) => (isNaN(value) ? undefined : value))
      .moreThan(-1, ({ more }) => `Limit cannot be negative`)
      .max(10000, ({ max }) => `Limit not more than ${max}`)
      .required(),
  });

  const { register, handleSubmit, setValue, errors, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: groupOrderInfo?.id,
      name: groupOrderInfo?.title,
      limit: groupOrderInfo?.amount_limit,
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    reset: reset2,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    validateCriteriaMode: "all",
    resolver: yupResolver(memberSchema),
  });

  useEffect(() => {
    if (toggleContacts) {
      dispatch(
        getContactList((data) => {
          setContactList(data);
        })
      );
    }
  }, [toggleContacts]);

  useEffect(() => {
    if (groupOrderInfo) {
      if (groupOrderInfo?.members && groupOrderInfo?.members?.length) {
        let dat = groupOrderInfo?.members?.map((it, idx) => ({
          ...it,
          id: it.id,
          index: idx,
        }));
        setAddedMembers(dat);
      }
      if (groupOrderInfo?.amount_limit > -1) {
        setOpenOthers(!limits.includes(groupOrderInfo?.amount_limit));
        setSelectedLimit(groupOrderInfo?.amount_limit);
        if (!limits.includes(groupOrderInfo?.amount_limit)) {
          setCustomLimit(groupOrderInfo?.amount_limit);
        }
      }
    }
  }, [groupOrderInfo]);

  useEffect(() => {
    setFieldLengthErrorMessage(null);
    if (addedMembers && addedMembers.length < minimumMembers) {
      setFieldLengthErrorMessage("Minimum one member is required");
    } else {
      setFieldLengthErrorMessage(null);
    }
  }, [addedMembers]);

  useEffect(() => {
    console.log("group order form errors of member : ", errors2);
  }, [errors2]);

  useEffect(() => {
    console.log("group order form errors of form : ", errors);
  }, [errors]);

  const onSubmit = (data) => {
    if (openOthers && !customLimit) return;
    if (groupOrderInfo?.status === 2) return;

    let model = {
      ...data,
      member: addedMembers.map((it) => ({
        id: it.id,
        name: it.name,
        email: it.email,
        phone: it.phone,
        reinvite: [0, ...selectedMembersForReinvite].includes(it.id),
      })),
    };

    model["limit"] = +selectedLimit;

    dispatch(
      initiateGroupOrder(model, (res) => {
        handleBack();
        if (!isGroupOrderStarted) window.location.replace(res);
      })
    );
  };

  const onCustomLimit = (e) => {
    let val = e.target.value.replace(/-/g, "");

    setCustomLimit(val);
    setSelectedLimit(val);
    setValue("limit", val, { shouldValidate: true, shouldDirty: true });
  };

  const setLimit = () => {
    if (groupOrderInfo?.status === 2) return;
    let val = +getValues("limit");
    setSelectedLimit(val);
    setOpenOthers(false);
  };

  const onOtherHandler = () => {
    if (groupOrderInfo?.status === 2) return;

    if (openOthers === false) {
      setSelectedLimit(null);
      setValue("limit", null, { shouldValidate: true, shouldDirty: true });
    }
    setOpenOthers(true);
  };

  // import contacts methods
  const onImportContact = () => {
    setToggleContacts(true);
    setToggleForm(false);
  };

  const getMembers = (id) => {
    dispatch(
      getGroupMembers(id, (data) => {
        let dat = data.map((it, idx) => ({ ...it, index: idx, id: 0 }));
        setAddedMembers(dat);
        setToggleContacts(false);
        setToggleForm(true);
      })
    );
  };
  // end import contacts methods

  const onSelectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      let arrOfIds = addedMembers.filter((it) => +it.id !== 0);
      setSelectedMembersForReinvite(arrOfIds);
    } else {
      setSelectedMembersForReinvite([]);
    }
  };

  const onSingleMemberCheckbox = (e, member) => {
    let checked = e.target.checked;
    if (member?.id) {
      if (checked) {
        let selectedArr = selectedMembersForReinvite.map((it) => it);
        selectedArr.push(member?.id);
        setSelectedMembersForReinvite(selectedArr);
      } else {
        let selectedArr = selectedMembersForReinvite.filter(
          (it) => it !== +member?.id
        );
        setSelectedMembersForReinvite(selectedArr);
      }
    }
  };

  // member CRUD
  const onDeleteMember = (idx, member) => {
    if (groupOrderInfo?.status === 2) return;
    if (member?.id > 0) {
      let addedUserCount = addedMembers?.filter((it) => it.id > 0)?.length;
      if (addedUserCount === 1) {
        setIsItFinalUserToRemove(true);
      } else {
        setIsItFinalUserToRemove(false);
      }
      setMemberToRemove({ idx: idx, member: member });
      openRemoveModal();
    } else {
      removeMemberFromAddedMemberArray(member);
    }
  };

  const removeMemberFromGroup = () => {
    if (isItFinalUserToRemove) {
      dispatch(
        cancelGroupOrder(groupToken, () => {
          closeRemoveModal();
          history.replace("/restaurants");
        })
      );
    } else {
      let member = selectedMemberToRemove.member;
      dispatch(
        removeGroupMember(groupOrderInfo?.id, member.id, (res) => {
          closeRemoveModal();
          removeMemberFromAddedMemberArray(member);
        })
      );
    }
  };

  const openRemoveModal = () => {
    setToggleForm(false);
    setToggleRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setToggleForm(true);
    setToggleRemoveModal(false);
  };

  const onAddMember = () => {
    setSelectedMember({ index: addedMembers.length });
    setToggleAddMember(true);
    setToggleForm(false);
  };

  const editMember = (idx, member) => {
    if (groupOrderInfo?.status === 2) return;
    setSelectedMember({ ...member, index: idx });
    setToggleAddMember(true);
    setToggleForm(false);
  };

  const onSubmitMember = (data) => {
    addMemberInAddedMemberArray(data);
    onCancelMember();
  };

  const onCancelMember = () => {
    handleBack();
    reset2();
    setSelectedMember(undefined);
  };

  const addMemberInAddedMemberArray = (member) => {
    let added = addedMembers.map((it) => it);
    if (added && added.length) {
      added.splice(member.index, 1, member);
      setAddedMembers(added);
    } else {
      added.push(member);
      setAddedMembers(added);
    }
  };

  const removeMemberFromAddedMemberArray = (member) => {
    let added = addedMembers
      ?.filter((it, idx) => idx !== +member.index)
      ?.map((it, idx) => ({ ...it, index: idx }));
    setAddedMembers(added);
  };
  // end member CRUD

  const handleBack = () => {
    if (toggleContacts) {
      setToggleContacts(false);
      setToggleForm(true);
    } else if (toggleAddMember) {
      reset2();
      setToggleAddMember(false);
      setToggleForm(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {toggleForm ? (
        <main class="mobile-inner-screen addFund-mobile">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="go-back-wrapper">
                  <a href class="go-back-btn" onClick={handleBack}>
                    <img src={backIcon} alt="go back" />
                  </a>
                  <h2>Group Order</h2>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="main-wrapper payment__page__wrapper group-order">
                <section class="mobile-inner-section profile-details-section">
                  <div class="otherway-payment-payments">
                    <div class="card-details-wrapper">
                      <input
                        name="id"
                        defaultValue={0}
                        ref={register()}
                        hidden
                      />
                      <input
                        type="text"
                        className={`form-control ${
                          errors?.name ? "is-invalid" : ""
                        }`}
                        placeholder="Group label"
                        name="name"
                        ref={register()}
                        readOnly={
                          !isReorder &&
                          (isGroupOrderStarted || groupOrderInfo?.status === 2)
                        }
                      />
                      {errors?.name?.message && (
                        <div className="invalid-feedback d-block">
                          {errors?.name?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                <section class="mobile-inner-section profile-deliveryAddress">
                  <div class="row">
                    <div class="col-12">
                      <div class="section_heading mb-3">
                        <h2>Per person order limit</h2>
                      </div>
                    </div>
                  </div>

                  <div class="add-fund-wrapper">
                    <div class="balance-slab w-100">
                      <div class="btn-group btn-group-toggle d-inline-flex w-100">
                        {limits &&
                          limits.map((it, idx) => (
                            <label
                              htmlFor={"amt-" + idx}
                              className={`btn btn-secondary ${
                                selectedLimit === +it ? "active focus" : ""
                              }`}
                              key={idx}
                              onClick={setLimit}
                            >
                              <input
                                type="radio"
                                id={"amt-" + idx}
                                name="limit"
                                value={it}
                                ref={register()}
                              />
                              {it === 0 ? "None" : "$" + it}
                            </label>
                          ))}
                        <label
                          class={`btn btn-secondary ${
                            openOthers ? "active focus" : ""
                          }`}
                          onClick={onOtherHandler}
                        >
                          <input type="radio" name="options" id="amt-othr" />{" "}
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  {openOthers && (
                    <div class="addFund_field my-3">
                      <input
                        type="number"
                        className={`form-control ${
                          errors?.limit || !customLimit ? "is-invalid" : ""
                        }`}
                        placeholder="$"
                        value={customLimit}
                        onChange={onCustomLimit}
                      />
                    </div>
                  )}
                  {errors?.limit?.message && (
                    <div className="invalid-feedback d-block">
                      {errors?.limit?.message}
                    </div>
                  )}

                  <div class="mt-4 added_gp_members_lists_wrapper">
                    <div class="row">
                      <div class="col-12">
                        <div className="added_gp_members_lists">
                          {addedMembers &&
                            addedMembers.map((member, idx) => (
                              <div class="order-details-card mb-4" key={idx}>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div>
                                    <div class="rightside">
                                      <div
                                        class={`order-item-details ${
                                          isGroupOrderStarted ? "d-flex" : ""
                                        }`}
                                      >
                                        {isGroupOrderStarted && (
                                          <>
                                            <div class="custom-control custom-checkbox mr-sm-2">
                                              <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id={"m1-check-" + idx}
                                                onChange={(e) =>
                                                  onSingleMemberCheckbox(
                                                    e,
                                                    member
                                                  )
                                                }
                                                defaultChecked={false}
                                                value={[
                                                  0,
                                                  ...selectedMembersForReinvite,
                                                ].includes(member?.id)}
                                              />
                                              <label
                                                class="custom-control-label label-custom"
                                                htmlFor={"m1-check-" + idx}
                                              ></label>
                                            </div>
                                          </>
                                        )}
                                        <h5
                                          class="mb-0"
                                          onClick={() =>
                                            editMember(idx, member)
                                          }
                                        >
                                          {member.name}
                                        </h5>
                                      </div>
                                    </div>
                                    <div
                                      class={`order-item-details ${
                                        isGroupOrderStarted ? "pl-4" : ""
                                      }`}
                                      onClick={() => editMember(idx, member)}
                                    >
                                      <p>{member.email}</p>
                                      <p>{member.phone}</p>
                                    </div>
                                  </div>
                                  <div class="order-price">
                                    <button
                                      type="button"
                                      class="btn delete-btn"
                                      onClick={() =>
                                        onDeleteMember(idx, member)
                                      }
                                    >
                                      <img
                                        class="img-fluid"
                                        src={deleteIcon}
                                        alt="delete"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      {groupOrderInfo?.status === 2 ? null : (
                        <div class="section_heading">
                          <button
                            class="link-type-btn"
                            type="button"
                            onClick={onAddMember}
                          >
                            Add Another Person
                          </button>
                        </div>
                      )}
                      {fieldLengthErrorMessage && (
                        <div className=" invalid-feedback d-block">
                          {fieldLengthErrorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                <section class="meanu-listing-mobile-footer">
                  <div class="container actions-wrapper">
                    <div class="bg">
                      <button
                        type="button"
                        class="actn-btn"
                        onClick={onImportContact}
                        disabled={
                          isGroupOrderStarted || groupOrderInfo?.status === 2
                        }
                      >
                        Import Contacts
                      </button>
                      <button
                        type="submit"
                        class="actn-btn actn-btn-filled"
                        disabled={
                          addedMembers.length < minimumMembers ||
                          groupOrderInfo?.status === 2
                        }
                      >
                        {isReorder
                          ? " Reorder Group Order"
                          : isGroupOrderStarted
                          ? "Resend Invite"
                          : "Create Group Order"}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          </div>
        </main>
      ) : null}

      {toggleAddMember ? (
        <>
          <main class="mobile-inner-screen addFund-mobile add-another-person">
            <div class="payment__page__wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div class="go-back-wrapper">
                      <a href class="go-back-btn" onClick={onCancelMember}>
                        <img src={backIcon} alt="go back" />
                      </a>
                      <h2>Add Another Person</h2>
                    </div>
                    <div class="main-wrapper">
                      <form onSubmit={handleSubmit2(onSubmitMember)}>
                        <section class="mobile-inner-section">
                          <div class="main">
                            <div class="container px-0">
                              <div class="addFund-wrapper">
                                <input
                                  name="id"
                                  defaultValue={selectedMember?.id || 0}
                                  ref={register2()}
                                  hidden
                                />
                                <input
                                  name="index"
                                  defaultValue={selectedMember.index}
                                  ref={register2()}
                                  hidden
                                />
                                <div class="addFund_field mb-3">
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={`form-control ${
                                      errors2?.name ? "is-invalid" : ""
                                    }`}
                                    name="name"
                                    defaultValue={selectedMember?.name || ""}
                                    ref={register2()}
                                    readOnly={selectedMember?.id > 0}
                                  />
                                  {errors2?.name?.message && (
                                    <div className="invalid-feedback d-block">
                                      {errors2?.name?.message}
                                    </div>
                                  )}
                                </div>
                                <div class="addFund_field mb-3">
                                  <input
                                    type="text"
                                    placeholder="Email Address"
                                    className={`form-control ${
                                      errors2?.email &&
                                      errors2?.email?.type !== "required"
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name="email"
                                    defaultValue={selectedMember?.email || ""}
                                    ref={register2()}
                                    readOnly={selectedMember?.id > 0}
                                  />
                                  {errors2?.email?.message &&
                                    errors2?.email?.type !== "required" && (
                                      <div className="invalid-feedback d-block">
                                        {errors2?.email?.message}
                                      </div>
                                    )}
                                </div>
                                <div class="addFund_field mb-3">
                                  <Controller
                                    as={InputMask}
                                    control={control}
                                    mask="+19999999999"
                                    type="text"
                                    placeholder="Phone Number"
                                    className={`form-control ${
                                      errors2?.phone &&
                                      errors2?.phone?.type !== "required"
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name="phone"
                                    defaultValue={selectedMember?.phone || ""}
                                    readOnly={selectedMember?.id > 0}
                                  />
                                  {errors2?.phone?.message &&
                                    errors2?.phone?.type !== "required" && (
                                      <div className="invalid-feedback d-block">
                                        {errors2?.phone?.message}
                                      </div>
                                    )}
                                </div>
                                {errors2?.email?.type === "required" &&
                                errors2?.phone?.type === "required" ? (
                                  <div className="invalid-feedback d-block">
                                    Email or phone is required
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </section>
                        <section class="meanu-listing-mobile-footer">
                          <div class="container">
                            <div class="bg">
                              <div class="modal-bottom-actions">
                                <button
                                  type="button"
                                  class="btn btn-theme-outlined "
                                  onClick={handleBack}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  class="btn btn-filled mx-0"
                                >
                                  Add Person
                                </button>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : null}

      {toggleContacts ? (
        <>
          <main class="mobile-inner-screen import-contacts-mobile-view-scroller">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="go-back-wrapper">
                    <a href onClick={handleBack} class="go-back-btn">
                      <img src={backIcon} alt="go back" />
                    </a>
                    <h2>Import Contacts</h2>
                  </div>
                </div>
              </div>
              <div class="import-contact-lists">
                <section class="mobile-inner-section profile-deliveryAddress">
                  <ul>
                    {contactList && contactList?.length > 0 ? (
                      contactList.map((ctc, idx) => (
                        <li
                          class="profile-addressList"
                          key={idx}
                          onClick={() => getMembers(ctc?.id)}
                        >
                          <div class="profile-address-detail">
                            <div class="import-contact w-100">
                              <div className="contact-card">
                                <h1>
                                  {ctc?.title ? ctc?.title + " - " : ""}
                                  {ctc?.restaurant_name}
                                </h1>
                                <p>
                                  {moment(ctc?.date_deliver).format(
                                    "Do MMMM, YYYY - h:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li class="profile-addressList">
                        <div class="profile-address-detail">
                          <div class="import-contact w-100">
                            <h1>
                              There are no previous group order to import
                              contacts
                            </h1>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </section>
              </div>
            </div>
          </main>
        </>
      ) : null}

      {toggleRemoveModal ? (
        <Modal
          id="cancelOrder-modal"
          show={toggleRemoveModal}
          onHide={closeRemoveModal}
          keyboard={false}
          fullscreen={true}
          centered
        >
          <main className="mobile-inner-screen driver-tip-mobile">
            <div className="payment__page__wrapper">
              <div
                className="go-back-wrapper justify-content-end"
                onClick={closeRemoveModal}
              >
                <a href className="go-back-btn pr-3">
                  <img src={closeImg} alt="icon" className="img-fluid" />
                </a>
              </div>
              <div className="main-wrapper">
                <section className="mobile-inner-section">
                  <div className="main">
                    <div className="container">
                      <div id="cancelOrder-modal">
                        <div className="congrates-wrapper">
                          <div className="content-box">
                            <h1>
                              {isItFinalUserToRemove
                                ? "Warning"
                                : "Remove User"}
                            </h1>
                            <p>
                              {isItFinalUserToRemove ? (
                                <>
                                  If you remove the last user from the list then
                                  the group will be canceled.
                                </>
                              ) : (
                                <>
                                  Are you sure you want to remove the{" "}
                                  {selectedMemberToRemove.member?.name} from the
                                  group order.
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="meanu-listing-mobile-footer checkout-footer">
                  <div className="container">
                    <div className="bg">
                      <div className="modal-bottom-actions">
                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-outline p-3 w-50 mr-2"
                            onClick={closeRemoveModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-filled p-3 w-50 ml-2"
                            onClick={removeMemberFromGroup}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </Modal>
      ) : null}
    </>
  );
};

export default MobileView;
