import { toast } from "react-toastify";
import { setLoader } from "../Auth/authAction";
import { APIUtils, ApiNames, axios } from "../../service";
import {
	ADD_TO_CART_GC,
	REWARD_TRANSACTION_HISTORY,
	EDIT_DATA,
} from "./GiftCardsType";

export const setGcardCartItems = (payload) => {
	return {
		type: ADD_TO_CART_GC,
		payload: payload,
	};
};

export const setRewarHistory = (payload) => {
	return {
		type: REWARD_TRANSACTION_HISTORY,
		payload: payload,
	};
};
export const setEditData = (payload) => {
	return {
		type: EDIT_DATA,
		payload: payload,
	};
};

export const getGiftCardListApi =
	(data, scb = () => null, ecb = () => null) =>
	(dispatch, getState) => {
		dispatch(setLoader(true));
		APIUtils.post(ApiNames.GIFTCARD_GETLISTING, data)
			.then(async (response) => {
				const pr = [];

				var results = await Promise.all(
					response.data.data.map(async (item, index) => {
						if (index < 8) {
							await APIUtils.get(`${ApiNames.ITEM_ADDON}${item.products_id}`)
								.then((response) => {
									pr.push({ ...item, ...response.data.data[0] });
								})
								.catch((error) => {
									dispatch(setLoader(false));
									ecb();
								});
						}
					}),
				);
				setTimeout(dispatch(setLoader(false)), 1500);
				scb(pr, response.data.rewards);
			})
			.catch((error) => {
				dispatch(setLoader(false));
				ecb();
			});
	};

export const getRewardHistory = () => (dispatch, getState) => {
	dispatch(setLoader(true));
	APIUtils.get(`${ApiNames.REWARD_HISTORY}`)
		.then((response) => {
			dispatch(setLoader(false));
			dispatch(setRewarHistory(response.data.data));
		})
		.catch((error) => {
			dispatch(setLoader(false));
		});
};

export const verifyGcCode =
	(data, scb = () => null, ecb = () => null) =>
	(dispatch, getState) => {
		dispatch(setLoader(true));
		APIUtils.post(`${ApiNames.VERIFY_GC_CODE}`, data)
			.then((response) => {
				dispatch(setLoader(false));
				scb(response.data.data.giftcard_balance);
				// dispatch(setRewarHistory(response.data.data))
			})
			.catch((error) => {
				dispatch(setLoader(false));
				// toast.error(error.response?.data?.message);
				toast.error("Please enter a valid gift card number");
				ecb();
			});
	};

export const redeemRewards = (data, scb) => (dispatch, getState) => {
	dispatch(setLoader(true));

	APIUtils.post(`${ApiNames.REDEEM_REWARDS}`, data)
		.then((response) => {
			scb(response.data.data.orders_id);
			dispatch(setLoader(false));
		})
		.catch((error) => {
			toast.error(error.response.data.message);
			dispatch(setLoader(false));
		});
};
