import React from "react";
import backArrowImg from "assets/images/back-arrow-mobile.svg";

const GoBackHeader = (props) => {
  const {title="", handleBackArrow = ()=> null} = props
	return (
		<div class="go-back-wrapper">
			<a class="go-back-btn" onClick={handleBackArrow}>
				<img src={backArrowImg} alt="go back" />
			</a>
			<h2>{title}</h2>
		</div>
	);
};

export default GoBackHeader;
