import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function BraintreeDropIn(props) {
	const {
		show,
		onPaymentCompleted,
		clientToken,
		braintreeInstance,
		cash,
		setCash,
		setLocalStatePayMethods,
		maximum_limit_for_cod_orders,
		isCodAvailabe,
		allowed_invoice_payment,
		setInvoice

	} = props;

	// const [braintreeInstance, setBraintreeInstance] = useState(undefined);

	// useEffect(() => {
	// 	if (show) {
	// 		const initializeBraintree = () =>
	// 			dropin.create(
	// 				{
	// 					// authorization: "sandbox_fwhv76cc_xht69cj4j5d2j4wn", // insert your tokenization key or client token here
	// 					authorization:clientToken,
	// 					container: "#braintree-drop-in-div",
	// 					paypal: {
	// 						flow: "vault",
	// 					},
	// 					venmo: {
	// 						allowNewBrowserTab: false,
	// 					},
	// 					googlePay: {
	// 						googlePayVersion: 2,
	// 						merchantId: "merchant-id-from-google",
	// 						transactionInfo: {
	// 							totalPriceStatus: "FINAL",
	// 							totalPrice: "123.45",
	// 							currencyCode: "USD",
	// 						},
	// 						allowedPaymentMethods: [
	// 							{
	// 								type: "CARD",
	// 								parameters: {
	// 									// We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
	// 									billingAddressRequired: true,
	// 									billingAddressParameters: {
	// 										format: "FULL",
	// 									},
	// 								},
	// 							},
	// 						],
	// 					},
	// 				},
	// 				function (error, instance) {
	// 					if (error) console.error(error);
	// 					else setBraintreeInstance(instance);
	// 				},
	// 			);

	// 		if (braintreeInstance) {
	// 			braintreeInstance.teardown().then(() => {
	// 				initializeBraintree();

	// 			});
	// 		} else {
	// 			initializeBraintree();
	// 		}
	// 	}
	// }, [show]);



	useEffect(() => {
		if (braintreeInstance && isCodAvailabe) {
			appendCod();
		}
	}, [braintreeInstance, props.selectCash]);
	useEffect(() => {
		if (braintreeInstance && allowed_invoice_payment) {
			appendInvoice();
		}
	}, [braintreeInstance, allowed_invoice_payment]);

	const appendInvoice = () => {
		let y = document.getElementsByClassName("braintree-options-list")[0];
		if (y) {
			y.appendChild(invoiceUi());
		}
	};

	// useEffect(()=>{

	// },[])

	const handleCash = () => {
		// alert(props.selectCash)
		let test = document.getElementById("test-id")?.innerText
		if(test == "$0.00"){
			toast.error("No more payment options needed");
			braintreeInstance.clearSelectedPaymentMethod();
			return
		}
		if (props.selectCash) {
			let x = document.getElementsByClassName("cash-card");
			let y = document.getElementsByClassName("active-cash-card");
			let x1 = document.getElementsByClassName("active-cash-card-invoice");
			if (x.length) {
				x[0].className = "active-cash-card";
				braintreeInstance.clearSelectedPaymentMethod();
				if(x1.length){
					// console.log("x1 how =>", x1)
					handleInvoice()
				}
				
				setInvoice(false)
				setCash(true);
			} else {
				y[0].className = "cash-card";
				setCash(false);
			}
			setLocalStatePayMethods({});
		} else {
			toast.error(
				`COD not allowed on orders above $${maximum_limit_for_cod_orders}`,
			);
		}
	};
	

	const handleInvoice = () => {

		let test = document.getElementById("test-id")?.innerText
		console.log("test placeorder charges from event", test)
		if(test == "$0.00"){
			toast.error("No more payment options needed");
			braintreeInstance.clearSelectedPaymentMethod();
			return
		}


		// alert(props.selectCash)

		// if (props.selectCash) {
			let x = document.getElementsByClassName("cash-card-invoice");
			let y = document.getElementsByClassName("active-cash-card-invoice");
			let x1 = document.getElementsByClassName("active-cash-card");

			if (x.length) {
				x[0].className = "active-cash-card-invoice";
				braintreeInstance.clearSelectedPaymentMethod();
				if(x1.length){
					// console.log("x1 how =>", x1)
					handleCash()
				}
				setCash(false);
				setInvoice(true)
			} else {
				y[0].className = "cash-card-invoice";
				setInvoice(false)
			}
			setLocalStatePayMethods({});
		// } else {
		// 	toast.error(
		// 		`COD not allowed on orders above $${maximum_limit_for_cod_orders}`,
		// 	);
		// }
	};
	const appendCod = () => {
		let y = document.getElementsByClassName("braintree-options-list")[0];
		if (y) {
			y.appendChild(codUi());
		}

		let z = document.getElementsByClassName("braintree-toggle")[0];
		if (z) {
			z.click();
		}
	};

	const [cashColor, setCashColor] = useState(false);

	const codUi = () => {
		var el = document.getElementById("div-02");
		if (el) {
			el.remove();
		}
		let div_obj = document.createElement("div");
		if (cash) {
			div_obj.setAttribute("class", "active-cash-card");
			div_obj.setAttribute("id", "div-02");
		} else {
			div_obj.setAttribute("class", "cash-card");
			div_obj.setAttribute("id", "div-02");
		}
		div_obj.innerHTML = "Cash";
		div_obj.onclick = handleCash;
		return div_obj;
	};

	const invoiceUi = () => {
		var el = document.getElementById("div-03");
		if (el) {
			el.remove();
		}
		let div_obj = document.createElement("div");
		if (cash) {
			div_obj.setAttribute("class", "active-cash-card-invoice");
			div_obj.setAttribute("id", "div-03");
		} else {
			div_obj.setAttribute("class", "cash-card-invoice");
			div_obj.setAttribute("id", "div-03");
		}
		div_obj.innerHTML = "Invoice";
		div_obj.onclick = handleInvoice;
		return div_obj;
	};

	return (
		<div style={{ display: `${show ? "block" : "none"}` }}>
			<div id={"braintree-drop-in-div"} />

			{/* <Button
				className={"braintreePayButton"}
				type="primary"
				disabled={!braintreeInstance}
				onClick={() => {
					if (braintreeInstance) {
						braintreeInstance.requestPaymentMethod((error, payload) => {
							if (error) {
								console.error(error);
							} else {
								const paymentMethodNonce = payload.nonce;
								console.log("payment method nonce", payload.nonce);

								// TODO: use the paymentMethodNonce to
								//  call you server and complete the payment here

								// ...

								alert(`Payment completed with nonce=${paymentMethodNonce}`);

								onPaymentCompleted();
							}
						});
					}
				}}
			>
				{"Pay"}
			</Button> */}
		</div>
	);
}

export default BraintreeDropIn;

const NewComponent = (props) => {
	return (
		<>
			<h1>{props.test}</h1>
		</>
	);
};
