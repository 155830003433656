import React, {useState,  useEffect } from "react";
import { Dropdown } from "react-bootstrap";



const AddOn = (props) => {
  const [errorInst, setErrorInst] = useState(false)

	useEffect(() => {
		switch (props.type) {
			case "Dropdown":
				break;
			case "Radio":
				// props.optionValues.map(item=>{
				// 	if(item.attributes_default){
				// 		props.setRadio({
				// 			...props.radio,
				// 			[props.options_id]:{
				// 			...item,
				// 			options_id: props.options_id,
				// 			products_options_types_name: props.products_options_types_name,
				// 			products_options_name: props.products_options_name
				// 			}
				// 		})
				// 	}
				// })
				break;
			case "Text":
				break;
			case "Checkbox":
				break;
		}
	}, []);
	const renderRadioButton = (item, index) => {
		console.log("item check", item)
		const isCheck =
			item.options_values_id ==
			(props.radio[props.options_id]
				? props.radio[props.options_id].options_values_id
				: -1);
		const x = parseFloat(item.options_values_price).toFixed(2);
		const showValue = x == 0.0 ? false : x;

		return (
			<div className="form-group mb-2">
				<div
					className="radio-wrapper"
					onClick={() =>
						props.setRadio({
							...props.radio,
							[props.options_id]: {
								...item,
								options_id: props.options_id,
								products_options_types_name: props.products_options_types_name,
								products_options_name: props.products_options_name,
							},
						})
					}
				>
					<label className="radio-container">
						{item.products_options_values_name}
						<input type="radio" checked={isCheck} name={props.options_id} />
						<span className="checkmark"></span>
					</label>
					{showValue ? (
						<span className="price-added">{`$${showValue}`}</span>
					) : null}
					{/* <span className="price-added">{`+$${parseFloat(item.options_values_price).toFixed(2)}`}</span> */}
				</div>
			</div>
		);
	};

	const renderDropDown = (item, index) => {
		const x = parseFloat(item.options_values_price).toFixed(2);
		const showValue = x == 0.0 ? false : x;
		return (
			<div
				className="dropdown-item"
				onClick={() =>
					props.setDropDown({
						...item,
						options_id: props.options_id,
						products_options_types_name: props.products_options_types_name,
						products_options_name: props.products_options_name,
					})
				}
			>
				<span>{item.products_options_values_name}</span>
				{showValue ? (
					<span className="price-added">{`$${showValue}`}</span>
				) : null}
				{/* <span className="price-added">{`+$${parseFloat(item.options_values_price).toFixed(2)}`}</span> */}
			</div>
		);
	};
	const renderCheckBox = (item, index) => {
		// const isCheck = item.options_values_id == (props.radio?props.radio.options_values_id:-1)

		const isCheck = props.checkBox
			? props.checkBox.some(
					(itemtwo) => itemtwo.options_values_id == item.options_values_id,
			  )
			: false;
		const x = parseFloat(item.options_values_price).toFixed(2);
		const showValue = x == 0.0 ? false : x;
		return (
			<div className="select-modifiers">
				<div className="d-flex justify-content-between">
					<div className="custom-control custom-checkbox mr-sm-2">
						<input
							type="checkbox"
							className="custom-control-input"
							id={item.options_values_id + "m1"}
							checked={isCheck}
							onClick={() =>
								handleCheckBoxes(
									{
										...item,
										options_id: props.options_id,
										products_options_types_name:
											props.products_options_types_name,
										products_options_name: props.products_options_name,
									},
									isCheck,
								)
							}
						/>
						<label
							className="custom-control-label"
							for={item.options_values_id + "m1"}
						>
							{item.products_options_values_name}
						</label>
					</div>
					{showValue ? (
						<span className="price-added">{`$${showValue}`}</span>
					) : null}
				</div>
			</div>
		);
	};

	const handleCheckBoxes = (item, isCheck) => {
		if (isCheck) {
			const copy = [...props.checkBox];
			const newCopy = copy.filter(
				(newitem) => newitem.options_values_id != item.options_values_id,
			);
			props.setCheckBox(newCopy);
		} else {
			const copy = [...props.checkBox];
			copy.push(item);
			props.setCheckBox(copy);
		}
	};

	switch (props.type) {
		case "Dropdown":
			return (
				<div className="col-12">
					<div className="dropdown product-select-dropdown">
						<button
							className="dropdown-toggle"
							type="button"
							id="dropdownMenuButton"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							{props.dropDown ? (
								<>
									<span>{props.dropDown.products_options_values_name}</span>
									<span className="price-added">{`$${props.dropDown.options_values_price}`}</span>
								</>
							) : (
								"Select"
							)}
						</button>
						<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							{props.optionValues && props.optionValues.map(renderDropDown)}
						</div>
					</div>
				</div>
			);

		case "Radio":
			return (
				<div className="col-12 mb-2 mb-md-4 pb-2">
					<div className="portion-selection">
						{props.optionValues && props.optionValues.map(renderRadioButton)}
					</div>
				</div>
			);
		case "Text":
			// console.log("Text",props.text.products_options_values_text, props.text.products_options_value_text )
			return (
				<div className="col-12 mt-2 mt-md-2 mb-2 mb-md-4 pb-2">
					<div className="special-instruction">
						{props.optionValues &&
							props.optionValues.map((item, index) => (
								<textarea
									maxlength="500"
									id="splinstruction"
									className="form-control"
									name="special-instruction"
									// rows="4"
									cols="50"
									value={
										props.text ? props.text.products_options_values_text : ""
									}
									placeholder="Add Special instructions"
									onChange={(e) =>{
										if(e.target.value.length>500){
                      setErrorInst(true)
                    }else{
                      setErrorInst(false)
                    }
										props.setText({
											...item,
											options_id: props.options_id,
											products_options_types_name:
												props.products_options_types_name,
											products_options_name: props.products_options_name,
											products_options_values_text: e.target.value.trimStart(),
										})
									}
									
									}
									style={errorInst?{borderColor:"red"}:{}}
								></textarea>
							))}
					</div>
				</div>
			);
		case "Checkbox":
			return (
				<div className="col-12">
					{/* <p className="mb-0">Select upto 2</p> */}
					<div className="modifiers-wrapper">
						{props.optionValues && props.optionValues.map(renderCheckBox)}
					</div>
				</div>
			);
	}
};

export default AddOn;
