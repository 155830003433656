import React from "react";
import WebContent from "./WebContent";
import { useHistory, useParams } from "react-router-dom";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import { useSelector } from "react-redux";

const WebCheckOutMobile = (props) => {
  const { cartStore, handlePlaceOrder } = props;
  const { groupToken } = useParams();
  const { groupOrderInfo } = useSelector((store) => store.rest);
  const history = useHistory();
  return (
    <>
      <main className="mobile-inner-screen">
        <div className="payment__page__wrapper">
          <div className="go-back-wrapper" style={{ paddingBottom: "10px" }}>
            <a href className="go-back-btn" onClick={() => history.goBack()}>
              <img src={backArrowImg} alt="go back" />
            </a>
            <h2>
              {groupToken
                ? groupOrderInfo?.restaurant_name
                : cartStore.cart.restaurant_name}
            </h2>
          </div>
          <div className="main-wrapper">
            <section className="mobile-inner-section">
              <WebContent {...props} />
            </section>
            <section className="meanu-listing-mobile-footer">
              <div className="container">
                <div className="bg">
                  <div className="modal-bottom-actions">
                    <div className="place-order-btn-wrapper">
                      {cartStore.placeOrderCharges.total != undefined && (
                        <button
                          type="button"
                          className="btn btn-filled"
                          onClick={handlePlaceOrder}
                        >
                          Place Order{" "}
                          <span className="total-order-value">
                            $
                            {parseFloat(
                              cartStore.placeOrderCharges.total
                            ).toFixed(2)}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};
export default WebCheckOutMobile;
