import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,

} from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { selectedAddress, requestSubscription, setDraweState } from "redux-store";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Map from "../../components/Map";
import SearchPlaces from "../../components/SearchPlaces";
import StateContainer from "./StateContainer";
import { useForm } from "react-hook-form";
import { regEmail } from "../../validation";
import { formatter } from "../../service";
import signupRestraImg from "assets/images/signup-restra.svg";
import earnRewardImg from "assets/images/eearn-rewards.svg";
import becomeDriverImg from "assets/images/become-driver.svg";
import logoImg from "assets/images/food-dudes-dot-com.svg";
import appStoreImg from "assets/images/app-store.svg";
import googlePlayImg from "assets/images/google-play.svg";
import appStoreImgblk from "assets/images/app-store-black.svg";
import googlePlayImgblk from "assets/images/google-play-black.svg";
import { useWindowResize } from "../../components/useWindowResize";
import Geocode from "react-geocode";

export const Home = (props) => {
  const isMobileOnly = useWindowResize();

  const dispatch = useDispatch();

  const store = useSelector((store) => store);

  const history = useHistory();
  const searchParam = useLocation();
  
  const [address, setAddress] = useState("");
  const [customers_email_address, setEmailAddress] = useState("");
  const handleSubscription = (e) => {
    e.preventDefault();
    dispatch(requestSubscription({ customers_email_address }));
  };
  const handleChange = (str) => {
    if (!str) {
      setAddress(str);
    } else {
      setAddress(str.replace(/^\s+/g, ""));
    }
  };
  const intialRender = useRef(true);
  useEffect(() => {
    // dispatch({type:"ROUTEPLACE",payload:false})
    if (intialRender.current) {
      intialRender.current = false;
    } else {
      if (address) {
        setEmptySearch(false);
      } else {
        setEmptySearch(true);
      }
    }
  }, [address]);
  useEffect(()=>{
    if(isMobileOnly && !searchParam.search){
      handleCurrentLocation()
    }
  },[])

  const handleSelect = (address) => {
  
    geocodeByAddress(address)
      .then(async (results) => {
        // const x = formatter(results);
        const x = formatter({ results: results });
        const {
          address,
          city,
          state,
          country,
          postal_code,
          street_number,
          route,
        } = x;
        let latlong = await getLatLng(results[0]);
        return {
          latlong: latlong,
          address: results[0].formatted_address,
          entry_street_address: address,
          entry_postcode: postal_code,
          entry_suburb:
            street_number && route
              ? street_number.concat(`, ${route}`)
              : street_number
              ? street_number
              : route
              ? route
              : "",
          entry_state: state,
          entry_city: city,
        };
      })
      .then((latlong) => {
        const {
          entry_street_address,
          entry_postcode,
          entry_suburb,
          entry_state,
          entry_city,
        } = latlong;
        dispatch(
          selectedAddress({
            address_lat: latlong.latlong.lat,
            address_lng: latlong.latlong.lng,
            entry_street_address,
            entry_postcode,
            entry_suburb,
            entry_state,
            entry_city,
          })
        );
      })
      .catch((error) => console.error("Error", error));
  };

  const [emptySeacrh, setEmptySearch] = useState(false);
  const { register, handleSubmit, errors, getValues } = useForm();
  const onSubmit = (data) => {
    dispatch(requestSubscription(data));
  };
  const wrapperRef = useRef(null);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    store.auth.cities.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };
  const renderStateCities = (item) => (
    <StateContainer
      item={item}
      dispatch={dispatch}
      selectedAddress={selectedAddress}
    />
  );
  const [mapCenter, setMapCenter] = useState("null");

  const handleFindFood = (e) => {
    e.preventDefault();
    if (!address) {
      setEmptySearch(true);
    }
    if (address.length > 3) {
      handleSelect(address + " ");
    }
  };
  const handleCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const x = formatter({ results: [response.results[4]] });
          // props.handleChoosenAddress(x, {
          // 	lat: position.coords.latitude,
          // 	lng: position.coords.longitude,
          // });
          const {
            address,
            city,
            state,
            country,
            postal_code,
            street_number,
            route,
          } = x;
          dispatch(
            selectedAddress({
              address_lat: position.coords.latitude,
              address_lng: position.coords.longitude,
              entry_street_address: address,
              entry_postcode: postal_code,
              entry_suburb:
                street_number && route
                  ? street_number.concat(`, ${route}`)
                  : street_number
                  ? street_number
                  : route
                  ? route
                  : "",
              entry_state: state,
              entry_city: city,
            })
          );
          history.push(`/restaurants`);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  // const [avoidHeader, setAvoidHeader] = useState(true)

  // useEffect(()=> setTimeout(()=>setAvoidHeader(false),2000))

  return (
    <>
      {true? <Xyz />:null}
      <section className="banner-section"  onClick={store.auth.drawerState?() => dispatch(setDraweState(false)):null}>
        <div className="banner landingpage-banner">
          <div className="content-wrapper">
            <div className="content">
              <h1>Order from your favorite local restaurants</h1>
              <p className="banner-text d-none">
                Enter your location and let Food Dudes find restaurants/stores
                around you.
              </p>
              <SearchPlaces
                address={address}
                handleChange={handleChange}
                handleSelect={handleSelect}
                value={address}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <section className="mobile-inner-section">
                    <div
                      className={
                        isMobileOnly
                          ? "change-address change-address-search"
                          : "form-wrapper"
                      }
                    >
                      <div className="inner-wrapper pickaddress position-relative">
                        <div className="inner-wrapper pickaddress">
                          <div className="banner-search">
                            <form
                              id="banner-search-form"
                              onSubmit={handleFindFood}
                            >
                              <input
                                onFocus={
                                  isMobileOnly
                                    ? () => history.push("/choose-address")
                                    : () => null
                                }
                                {...getInputProps({
                                  type: "search",
                                  name: "banner-search",
                                  id: "banner-search",
                                  placeholder: "Enter your delivery address",
                                 
                                })}
                                style={
                                  address.length > 3 && suggestions.length
                                    ? {
                                        border: emptySeacrh
                                          ? "1px solid red"
                                          : "0",
                                        borderBottomLeftRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                        boxShadow: "none",
                                      }
                                    : {
                                        border: emptySeacrh
                                          ? "1px solid red"
                                          : "0",
                                      }
                                }
                              />
                              <button
                                type="submit"
                                className="search-btn btn-orange"
                                title="search"
                                style={
                                  address.length > 3 && suggestions.length
                                    ? {
                                        borderBottomRightRadius: "0px",
                                        boxShadow: "none",
                                      }
                                    : {}
                                }
                              >
                                Find Food
                              </button>
                            </form>
                          </div>
                          <ul
                            className="addressLists p-0 landingpage-addresslist"
                            ref={wrapperRef}
                          >
                            {/* {address.length>3 && loading && <div>Loading...</div>} */}
                            {address.length > 3 &&
                              suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#FAFAFA",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#FFFFFF",
                                      cursor: "pointer",
                                    };
                                return (
                                  <li
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    <div className="addressType">
                                      <p>{suggestion.description}</p>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </SearchPlaces>
            </div>
          </div>
        </div>
      </section>

      <section className="local-favt-section landingpage">
        <div className="container">
          <div className="local-favt">
            <div className="row">
              <div className="col-12 col-md-4 cards">
                <Link to="/restaurant-signup">
                  <div className="card-wrapper">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={signupRestraImg}
                        alt="restro"
                      />
                    </div>
                    <div className="content-box">
                      <h5>Sign Up for Restaurant</h5>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-4 cards">
                <Link to={store.auth.token?"/giftcardtab/rewards": "/signin"}>
                  <div className="card-wrapper">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={earnRewardImg}
                        alt="restro"
                      />
                    </div>
                    <div className="content-box">
                      <h5>Earn Rewards with every Order</h5>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-4 cards">
                <Link to="/driver-signup">
                  <div className="card-wrapper">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={becomeDriverImg}
                        alt="restro"
                      />
                    </div>
                    <div className="content-box">
                      <h5>Become a Driver</h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="download-app-section d-md-none">
        <div className="download-app-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <img src={logoImg} alt="foot dude" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Download the app for a faster experience</p>
                <div className="download-apps">
                  <a title="Apple app store">
                    <img
                      className="img-fluid"
                      src={appStoreImgblk}
                      alt="apple store logo"
                    />
                  </a>
                  <a title="Google play store">
                    <img
                      className="img-fluid"
                      src={googlePlayImgblk}
                      alt="google play store logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-section">
        <div className="container">
          <div className="locations">
            <div className="row">
              <div className="col-12">
                <div className="wrapper">
                  <h2>Locations We Serve</h2>
                </div>
                <div className="col-12 px-0">
                  <div className="location-map-block">
                    <Map
                      cities={store.auth.cities}
                      handleOnLoad={handleOnLoad}
                      setActiveMarker={setActiveMarker}
                      handleActiveMarker={handleActiveMarker}
                      dispatch={dispatch}
                      selectedAddress={selectedAddress}
                      activeMarker={activeMarker}
                      mapCenter={mapCenter}
                      setMapCenter={setMapCenter}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="places">
                    <div className="row">
                      {store.auth.stateAndCites &&
                        store.auth.stateAndCites.map(renderStateCities)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingpage-newsletter">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-3 pb-md-4">
                <div className="wrapper">
                  <h2>Subscribe to Our Newsletter</h2>
                </div>
              </div>
              <div className="col-12">
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                  <div className="subsform-wrapper">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        aria-describedby="signup-for-newletter"
                        style={
                          errors.customers_email_address
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        name="customers_email_address"
                        ref={register({
                          required: "Email is required",

                          pattern: {
                            value: regEmail,
                            message: "Please provide a valid email",
                          },
                        })}
                      />

                      <div className="input-group-append">
                        <button
                          className="btn"
                          type="submit"
                          id="signup-for-newletter"
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="subscribctions-errors">
                    {errors.customers_email_address && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#e3342f",
                        }}
                      >
                        {errors.customers_email_address.message}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;

const Xyz = React.memo(() => <Header auth needNotifications />);
