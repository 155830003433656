import React, { useState, useEffect, useRef, useCallback } from "react";
import CategorySlider from "./CategorySlider";
import Heading from "./Heading";
import Card from "./Card";
import moment from "moment";
import {
	getProfile,
	getResturantsData,
	getCuisinesData,
	setSelectedRest,
	getAddress,
	setCuisines,
	setActiveTabs,
	setPrevAddress,
	headerTabSelected,
	setResturants,
	setLoader,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import {
	useHistory,
	useLocation,
	Link,
	useRouteMatch,
	useParams,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Header from "components/Header";
import NotFound from "components/NotFound";
import Footer from "components/Footer";
import { MenuListing } from "../index";
import scrollIntoView from "scroll-into-view-if-needed";
import useOnScreen from "../MenuListing/InViewPort";
import $ from "jquery";
import { Dropdown } from "react-bootstrap";
import fdlBgImg from "assets/images/fdl-bg-icon-mobile.svg";
import filterWhiteImg from "assets/images/filter-icon-white.svg";
import backArrowSliderImg from "assets/images/back-arrow-slider.svg";
import nextArrowSliderImg from "assets/images/next-arrow-slider.svg";

import selectedOrangeCheckImg from "assets/images/selected-check-orange-icon.svg";
import { useWindowResize } from "../../components/useWindowResize";
import GroupOrderCart from "pages/GroupOrderCart";

const ResturantList = () => {
	const isMobileOnly = useWindowResize();
	const [filterDropdown, setFilterDropdown] = useState(false);
	const location = useLocation();
	useEffect(() => {
		dispatch(setActiveTabs("home"));
		dispatch(setPrevAddress({}));
		// dispatch({type:"ROUTEPLACE",payload:false})
	}, []);
	const [filteredRest, setFilteredRest] = useState([]);
	const [featuredRest, setFeaturedRest] = useState([]);
	let { id, groupToken } = useParams();
	const store = useSelector((store) => store);
	const [isAddressScreen, setIsAddressScreen] = useState(false);
	const { address_lat, address_lng, entry_street_address, entry_street_name ,entry_suburb} =
		store.profile.selectedAddress;
	const { resturants, cuisines, groupOrderInfo } = store.rest;
	const dispatch = useDispatch();
	const history = useHistory();
	let { path, url } = useRouteMatch();
	const goToMenu = (data) => {
		dispatch(setSelectedRest(data));
		history.push({
			pathname: `${path}/${data.name.replace(/[^a-zA-Z0-9]+/g, "_")}_${
				data.restaurant_id
			}`,
			// search: '?query=abc',
			data: data,
		});
	};
	useEffect(() => {
		// if (!store.rest.cuisines.length) {
		dispatch(getCuisinesData());
		// }
	}, []);

	// useEffect(() => {
	// 	if (store.auth.token) {
	// 		dispatch(getAddress());
	// 	}
	// }, []);
	const [pageLoader, setPageLoader] = useState(true);

	useEffect(() => {
		if (location.pathname.length < 13) {
			if (Object.keys(store.profile.selectedAddress).length > 1) {
				dispatch(
					getResturantsData(
						{
							lat_from: address_lat.toString(),
							lng_from: address_lng.toString(),
						},
						() => {
							setApiCall(false);
							setTimeout(() => setPageLoader(false), 500);
						},
						() => {
							setApiCall(false);
							setTimeout(() => setPageLoader(false), 500);
						},
					),
				);
			}
		}
	}, []);

	useEffect(() => {
		if (location.pathname.length > 12) {
			setPageLoader(false);
		}
	}, [location.pathname]);

	const [apiCall, setApiCall] = useState(true);

	const initialRender = useRef(true);
	useEffect(() => {
		handleFilter();
	}, [resturants]);

	const [checkedFilter, setCheckedFilter] = useState(new Set());
	const [random, setRandom] = useState(false);
	const [sortBy, setSortBy] = useState("");
	const handleFilter = (item) => {
		item &&
			test.current[item.id] &&
			setTimeout(
				() =>
					scrollIntoView(test.current[item.id], {
						behavior: "smooth",
						block: "nearest",
						inline: isMobileOnly ? "start" : "center",
						scrollMode: "if-needed",
					}),
				100,
			);
		const userIds = new Set(checkedFilter);
		// sliderRef.current.slickNext();

		if (item) {
			if (userIds.has(item.id)) {
				userIds.delete(item.id);
			} else {
				userIds.add(item.id);
			}
		}
		if (sortBy) {
			var copy = [...resturants];
			var filtersCuisine = [...userIds];
		} else {
			var copy = [...resturants];
			var filtersCuisine = [...userIds];
		}
		if (userIds.size < 1) {
			const fr = copy.filter((item, index) => item.featured == "1");
			setFilteredRest(copy);
			setFeaturedRest(fr);
		} else {
			const filterArray = copy.filter((item) => {
				let x = false;
				if (item.restaurant_cuisines != null) {
					const arr = item.restaurant_cuisines_id.split(",");
					arr.map((newitem) => {
						filtersCuisine.map((fitem) => {
							if (newitem == fitem) {
								x = true;
							}
						});
					});
				}

				return x;
			});
			const fr = filterArray.filter((item, index) => item.featured == "1");
			setFeaturedRest(fr);
			setFilteredRest(filterArray);
		}
		setCheckedFilter(userIds);
		setRandom((r) => !r);
	};

	useEffect(() => {
		// alert('called 3')
		// setCuisines(cuisines);
		// let isFiltered = false
		if (checkedFilter.size > 0) {
			// isFiltered= true
			var copy = [...filteredRest];
			var filtersCuisine = [...checkedFilter];
		} else {
			var copy = [...resturants];
			var x = cuisines.map((item) => item.id);
			var filtersCuisine = [...x];
		}

		switch (sortBy) {
			case "":
				const filterArray = copy.filter((item) => {
					let x = false;
					if (item.restaurant_cuisines != null) {
						const arr = item.restaurant_cuisines_id.split(",");
						arr.map((newitem) => {
							filtersCuisine.map((fitem) => {
								if (newitem == fitem) {
									x = true;
								}
							});
						});
					}

					return x;
				});

				const fr = filterArray.filter((item, index) => item.featured == "1");
				setFeaturedRest(fr);
				setFilteredRest(filterArray);

				break;
			case "name":
				copy.sort(function (a, b) {
					return a.name.localeCompare(b.name); //using String.prototype.localCompare()
				});

				setFeaturedRest(copy.filter((item, index) => item.featured == "1"));
				setFilteredRest(copy);

				break;
			// case "create_date":
			// 	sort_order = "desc";
			// 	break;
			case "rest_status":
				copy.sort(function (a, b) {
					return a?.rest_status - b?.rest_status;
				});

				setFeaturedRest(copy.filter((item, index) => item.featured == "1"));
				setFilteredRest(copy);

				break;
			case "restaurant_distance":
				copy.sort(function (a, b) {
					return a.restaurant_distance - b.restaurant_distance;
				});

				setFeaturedRest(copy.filter((item, index) => item.featured == "1"));
				setFilteredRest(copy);

				break;

			case "service_fee":
				const checkForDelivery =
					store.auth.headerTab == "Delivery" ? true : false;
				copy.sort(function (a, b) {
					if (checkForDelivery) {
						return (
							a.service_fee +
							a.restaurant_delivery_fee -
							(b.restaurant_delivery_fee + b.service_fee)
						);
					}
					return a.service_fee - b.service_fee;
				});

				setFeaturedRest(copy.filter((item, index) => item.featured == "1"));
				setFilteredRest(copy);

				break;
			case "create_date":
				copy.sort(function (a, b) {
					return new Date(b.create_date) - new Date(a.create_date);
				});
				setFeaturedRest(copy.filter((item, index) => item.featured == "1"));
				setFilteredRest(copy);
				break;
		}

		// if (!apiCall) {
		// 	if (pageLoader) {
		// 		setPageLoader(false);
		// 	}
		// }
	}, [sortBy, random]);

	const handleSortBy = (value) => {
		setSortBy(value);
	};

	const test = useRef({});
	const sliderRef = useRef(null);

	let x = null;
	let xOne = null;
	if (cuisines.length) {
		x = cuisines[0].id;
		xOne = cuisines[cuisines.length - 2 > 0 ? cuisines.length - 2 : 0].id;
	}
	let y = false;
	if (Object.keys(test.current).length > 0 && x && xOne) {
		y = true;
	}
	const isVisible = useOnScreen(y ? test.current[x] : null);
	const isVisibleOne = useOnScreen(y ? test.current[xOne] : null);

	// detect scroll for Restrant

	// const [yaxis, setYAxis] = useState(window.scrollY);
	// const subheader = document.getElementById("subheader");

	// const handleNavigation = useCallback(
	// 	(e) => {
	// 	  const window = e.currentTarget;
	// 	  subheader.style = "display: block";
	// 	  if (yaxis > window.scrollY) {
	// 		console.log("scrolling up");
	// 		subheader.style = "display: block";
	// 	  } else if (yaxis < window.scrollY) {
	// 		console.log("scrolling down");
	// 		subheader.style = "display: none";
	// 	  }
	// 	  setYAxis(window.scrollY);
	// 	},
	// 	[y]
	//   );

	const [scroll, setScroll] = useState(true);
	useEffect(() => {
		document.addEventListener("scroll", handleScroll);
		return () => document.removeEventListener("scroll", handleScroll);
	}, []);
	const handleScroll = () => {
		const scrollCheck = window.scrollY;
		if (scrollCheck > 1 && scroll) {
			setScroll(false);
		} else if (scrollCheck == 0) {
			setScroll(true);
		}
	};

	// useEffect(() => {
	// setYAxis(window.scrollY);
	// window.addEventListener("scroll", handleNavigation);

	// return () => {
	//   window.removeEventListener("scroll", handleNavigation);
	// };
	// }, [handleNavigation]);

	// detect scroll for Restrant

	const handleGoToCheckoutGc = () => {
		if (store.cart.cart.restaurant_id) {
			history.push("/mobilecart");
		} else {
			switch (store.gc.gc_cart.restaurant_name) {
				case "/redeem-points-/EGIFT_CARD":
					history.push("/webcheckout_giftcards_egift");
					break;
				case "/redeem-points-/GIFT_CARD":
					history.push("/webcheckout_giftcards_gift");
					break;
				case "/buy-gift-card-/GIFT_CARD":
					history.push("/webcheckout_giftcards_buy_gift");
					break;
				case "/buy-gift-card-/EGIFT_CARD":
					history.push("/webcheckout_giftcards_buy_egift");
					break;
			}
		}
	};
	console.log("featuredRest",featuredRest)
	console.log("filteredRest",filteredRest)

	return (
		<Switch>
			<Route exact path={path}>
				<>
					{Object.keys(store.profile.selectedAddress).length < 1 ? (
						<Redirect to="/" />
					) : null}
					<Header />
					{isMobileOnly ? (
						<>
							<>
								{((store.cart.cart.items && store.cart.cart.items.length) ||
									(store.gc.gc_cart.cart_items &&
										store.gc.gc_cart.cart_items.length)) && (
									<section
										className="meanu-listing-mobile-footer view-cart-layout"
										style={{ bottom: "67px" }}
									>
										<div className="container" onClick={handleGoToCheckoutGc}>
											<div className="bg">
												<div className="view-cart-wrapper">
													<p>
														<span className="d-flex">
															{store.cart.cart.items ? (
																<img
																	className="bag-icon mr-2 img-fluid"
																	src={fdlBgImg}
																/>
															) : null}
															{store.cart.cart.items
																? "View Cart"
																: "Go to Checkout"}
														</span>
														<span>
															$
															{parseFloat(
																store.cart.cart.cartTotal
																	? store.cart.cart.cartTotal
																	: store.gc.gc_cart.cartTotal,
															).toFixed(2)}
														</span>
													</p>
												</div>
											</div>
										</div>
									</section>
								)}
							</>
							<main id="loggedin-main">
								<div className="mobile-main-tabs-wrapper">
									<div className="tab-content" id="pills-tabContent">
										<div
											className="tab-pane fade show active"
											id="pills-home"
											role="tabpanel"
											aria-labelledby="pills-home-tab"
										>
											<div className="hometab-wrapper">
												<div className="bg">
													<div className="container">
														<ul
															className="nav nav-pills mb-3 nav-justified"
															id="pills-tab"
															role="tablist"
														>
															<li className="nav-item">
																<a
																	className={`nav-link ${
																		store.auth.headerTab == "Delivery"
																			? "active"
																			: ""
																	}`}
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		dispatch(headerTabSelected("Delivery"))
																	}
																	// id="pills-delivery-tab"
																	// data-toggle="pill"
																	// href="#pills-delivery"
																	// role="tab"
																	// aria-controls="pills-delivery"
																	// aria-selected="true"
																>
																	Delivery
																</a>
															</li>
															<li className="nav-item">
																<a
																	className={`nav-link ${
																		store.auth.headerTab == "Pickup"
																			? "active"
																			: ""
																	}`}
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		dispatch(headerTabSelected("Pickup"))
																	}
																	// id="pills-pickup-tab"
																	// data-toggle="pill"
																	// href="#pills-pickup"
																	// role="tab"
																	// aria-controls="pills-pickup"
																	// aria-selected="false"
																>
																	Pickup
																</a>
															</li>
														</ul>
														<div className="diveryandadresss">
															<div className="devilry">
																<Link to="/choose-date-time">
																	<span className="mobile-inline-text">
																		{store.profile.deliveryDate
																			? `${moment(
																					store.profile.deliveryDate,
																			  ).format("ddd, MMM DD")} ${
																					store.profile.timeInd
																			  }`
																			: "ASAP"}
																	</span>
																</Link>
															</div>
															<div className="to">
																<a href="#">to</a>
															</div>
															<div className="address">
																<Link to="/choose-address">
																	<span className="mobile-inline-text">
																		{entry_street_name?entry_suburb?entry_suburb + ", "+ entry_street_name:"" + entry_street_name:entry_street_address &&
																			entry_street_address.split(",")[0]}
																	</span>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{resturants.length ? (
									<>
										{pageLoader ? (
											<div>
												<section className="loading">
													<div className="sk-spinner-pulse"></div>
												</section>
											</div>
										) : (
											<section
												id="subheader"
												className={`${scroll ? "d-block" : "d-none"}`}
											>
												<div className="container">
													<div className="sub-header-wrapper">
														<div className="row">
															<div className="col-auto col-md-auto">
																<div className="product-selection-sort">
																	<Dropdown
																		className="restraShot"
																		show={filterDropdown}
																		onToggle={(nextShow) =>
																			setFilterDropdown(nextShow)
																		}
																	>
																		<Dropdown.Toggle
																			id="restraShot"
																			variant="transparent"
																		>
																			<img
																				src={filterWhiteImg}
																				alt="filter icon"
																			/>
																		</Dropdown.Toggle>

																		<Dropdown.Menu>
																			<a
																				href
																				type="button"
																				className="close"
																				aria-label="Close"
																				onClick={() => setFilterDropdown(false)}
																			>
																				<span aria-hidden="true">×</span>
																			</a>
																			<div
																				className="row"
																				onClick={() =>
																					handleSortBy("create_date")
																				}
																			>
																				<div className="col-12 mb-3 subscribe-offers">
																					<div className="custom-control custom-checkbox mr-sm-2">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="new"
																							checked={
																								sortBy == "create_date"
																									? true
																									: false
																							}
																						/>
																						<label
																							className="custom-control-label"
																							for="new"
																						>
																							New to food dudes
																						</label>
																					</div>
																				</div>
																			</div>
																			<div
																				className="row"
																				onClick={() => handleSortBy("name")}
																			>
																				<div className="col-12 mb-3 subscribe-offers">
																					<div className="custom-control custom-checkbox mr-sm-2">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="alphabetical-order"
																							checked={
																								sortBy == "name" ? true : false
																							}
																						/>
																						<label
																							className="custom-control-label"
																							for="alphabetical-order"
																						>
																							Alphabetical order
																						</label>
																					</div>
																				</div>
																			</div>
																			<div
																				className="row"
																				onClick={() =>
																					handleSortBy("rest_status")
																				}
																			>
																				<div className="col-12 mb-3 subscribe-offers">
																					<div className="custom-control custom-checkbox mr-sm-2">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="openNow"
																							checked={
																								sortBy == "rest_status"
																									? true
																									: false
																							}
																						/>
																						<label
																							className="custom-control-label"
																							for="openNow"
																						>
																							Open Now
																						</label>
																					</div>
																				</div>
																			</div>
																			<div
																				className="row"
																				onClick={() =>
																					handleSortBy("restaurant_distance")
																				}
																			>
																				<div className="col-12 mb-3 subscribe-offers">
																					<div className="custom-control custom-checkbox mr-sm-2">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="nearMe"
																							checked={
																								sortBy == "restaurant_distance"
																									? true
																									: false
																							}
																						/>
																						<label
																							className="custom-control-label"
																							for="nearMe"
																						>
																							Near Me
																						</label>
																					</div>
																				</div>
																			</div>
																			<div
																				className="row"
																				onClick={() =>
																					handleSortBy("service_fee")
																				}
																			>
																				<div className="col-12 mb-3 subscribe-offers">
																					<div className="custom-control custom-checkbox mr-sm-2">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="fees"
																							checked={
																								sortBy == "service_fee"
																									? true
																									: false
																							}
																						/>
																						<label
																							className="custom-control-label"
																							for="fees"
																						>
																							Service fees
																						</label>
																					</div>
																				</div>
																			</div>
																			<div
																				className="actions-wrapper mt-2"
																				onClick={() => handleSortBy("")}
																			>
																				<button className="actn-btn">
																					Clear{" "}
																				</button>
																			</div>
																		</Dropdown.Menu>
																	</Dropdown>
																</div>
															</div>

															<div className="col-11 col-md-11 px-0 new-rest-slider-container onmobile-slider-right">
																{cuisines.map((item, index) => (
																	<div
																		onClick={() => handleFilter(item, index)}
																		key={item.id}
																		ref={(el) => (test.current[item.id] = el)}
																	>
																		<div
																			className="btn-group-toggle"
																			data-toggle="buttons"
																		>
																			<label
																				className="btn slider-content-button"
																				style={
																					checkedFilter.has(item.id)
																						? {
																								border: "solid 1px #f06f00",
																								color: " #f06f00",
																								paddingRight: "36px",
																								background: `transparent url(${selectedOrangeCheckImg}) no-repeat 92%`,
																								backgroundSize: "12px",
																								width: "max-content",
																								marginRight: "15px",
																						  }
																						: {
																								width: "max-content",
																								marginRight: "15px",
																						  }
																				}
																			>
																				<input type="checkbox" />
																				{item.title}
																			</label>
																		</div>
																	</div>
																))}
															</div>
														</div>
													</div>
												</div>
											</section>
										)}

										{filteredRest.length ? (
											<div className="tab-content" id="pills-tabContent">
												<div
													className="tab-pane fade show active"
													id="pills-delivery"
													role="tabpanel"
													aria-labelledby="pills-delivery-tab"
												>
													<div className="delivery-tab-wrapper">
														<section className="featured-restaurants">
															<div className="container">
																{featuredRest.length ? (
																	<div className="local-favt restra">
																		<div className="row">
																			{
																				<Heading
																					headingText="Featured"
																					margin
																				/>
																			}
																			<div className="col-12">
																				<CategorySlider
																					settings={
																						isMobileOnly
																							? resturantSliderMobile
																							: resturantSlider
																					}
																				>
																					{featuredRest.map((item, index) => (
																						<Card
																							testType={store.auth.headerTab}
																							store={store}
																							goToMenu={() => goToMenu(item)}
																							// key={index}
																							key={item.restaurant_id}
																							item={item}
																						/>
																					))}
																				</CategorySlider>
																			</div>
																		</div>
																	</div>
																) : null}

																<div className="all-restaurants local-favt restra ">
																	<div className="row">
																		{<Heading headingText="All Restaurants" />}
																		{filteredRest.map((item, index) => (
																			<div
																				className="col-12 col-sm-6 col-md-6 col-lg-4"
																				key={item.restaurant_id}
																			>
																				<Card
																					testType={store.auth.headerTab}
																					store={store}
																					goToMenu={() => goToMenu(item)}
																					key={item.restaurant_id}
																					item={item}
																				/>
																			</div>
																		))}
																	</div>
																</div>
															</div>
														</section>
													</div>
												</div>
											</div>
										) : null}
									</>
								) : (
									<>
										{!pageLoader ? (
											<NotFound
												msgTitle="Restaurants not found"
												msgDescription={
													"According to the specified address no available institutions present for food orders at home."
												}
											/>
										) : (
											<div>
												<section className="loading">
													<div className="sk-spinner-pulse"></div>
												</section>
											</div>
										)}
									</>
								)}
							</main>
						</>
					) : (
						<main id="restaurants-listing-body">
							{resturants.length ? (
								<>
									<section
										id="subheader"
										className={`${scroll ? "d-block" : "d-none"}`}
									>
										<div className="container">
											<div
												className="sub-header-wrapper "
												style={{ paddingBottom: "0px" }}
											>
												<div className="row ">
													<div
														className="col-12 col-md-12 col-lg-12 main-outer-container "
														style={{
															flexDirection: "row",
															display: "flex",
															alignItems: "baseline",
														}}
													>
														<div className="product-selection-sort">
															<Dropdown
																className="restraShot"
																show={filterDropdown}
																onToggle={(nextShow) =>
																	setFilterDropdown(nextShow)
																}
															>
																<Dropdown.Toggle
																	id="restraShot"
																	variant="transparent"
																>
																	<img src={filterWhiteImg} alt="filter icon" />
																</Dropdown.Toggle>

																<Dropdown.Menu aria-labelledby="restraShot">
																	<a
																		href
																		type="button"
																		className="close"
																		aria-label="Close"
																		onClick={() => setFilterDropdown(false)}
																	>
																		<span aria-hidden="true">×</span>
																	</a>
																	<div
																		className="row"
																		onClick={() => handleSortBy("create_date")}
																	>
																		<div className="col-12 mb-3 subscribe-offers">
																			<div className="custom-control custom-checkbox mr-sm-2">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					id="new"
																					checked={
																						sortBy == "create_date"
																							? true
																							: false
																					}
																				/>
																				<label
																					className="custom-control-label"
																					for="new"
																				>
																					New to food dudes
																				</label>
																			</div>
																		</div>
																	</div>
																	<div
																		className="row"
																		onClick={() => handleSortBy("name")}
																	>
																		<div className="col-12 mb-3 subscribe-offers">
																			<div className="custom-control custom-checkbox mr-sm-2">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					id="alphabetical-order"
																					checked={
																						sortBy == "name" ? true : false
																					}
																				/>
																				<label
																					className="custom-control-label"
																					for="alphabetical-order"
																				>
																					Alphabetical order
																				</label>
																			</div>
																		</div>
																	</div>
																	<div
																		className="row"
																		onClick={() => handleSortBy("rest_status")}
																	>
																		<div className="col-12 mb-3 subscribe-offers">
																			<div className="custom-control custom-checkbox mr-sm-2">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					id="openNow"
																					checked={
																						sortBy == "rest_status"
																							? true
																							: false
																					}
																				/>
																				<label
																					className="custom-control-label"
																					for="openNow"
																				>
																					Open Now
																				</label>
																			</div>
																		</div>
																	</div>
																	<div
																		className="row"
																		onClick={() =>
																			handleSortBy("restaurant_distance")
																		}
																	>
																		<div className="col-12 mb-3 subscribe-offers">
																			<div className="custom-control custom-checkbox mr-sm-2">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					id="nearMe"
																					checked={
																						sortBy == "restaurant_distance"
																							? true
																							: false
																					}
																				/>
																				<label
																					className="custom-control-label"
																					for="nearMe"
																				>
																					Near Me
																				</label>
																			</div>
																		</div>
																	</div>
																	<div
																		className="row"
																		onClick={() => handleSortBy("service_fee")}
																	>
																		<div className="col-12 mb-3 subscribe-offers">
																			<div className="custom-control custom-checkbox mr-sm-2">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					id="fees"
																					checked={
																						sortBy == "service_fee"
																							? true
																							: false
																					}
																				/>
																				<label
																					className="custom-control-label"
																					for="fees"
																				>
																					Service fees
																				</label>
																			</div>
																		</div>
																	</div>
																	<div
																		className="actions-wrapper mt-2"
																		onClick={() => handleSortBy("")}
																	>
																		<button className="actn-btn">Clear </button>
																	</div>
																</Dropdown.Menu>
															</Dropdown>
														</div>
														{!isVisible ? (
															<div className="left-arrow-btn-menu-list">
																<div className="right-arrow-btn-menu-list-inner">
																	<img
																		src={backArrowSliderImg}
																		alt="arrow icon"
																		onClick={() =>
																			$("#scrollArea").animate(
																				{
																					scrollLeft:
																						$("#scrollArea").scrollLeft() - 400,
																				},
																				300,
																			)
																		}
																	/>
																</div>
															</div>
														) : null}
														<div
															className="d-flex align-items-center"
															style={{
																width: "max-content",
																overflow: "hidden",
																alignItems: "center",
																// marginTop: "17px"
															}}
														>
															<div
																className="new-rest-slider-container"
																id="scrollArea"
																style={{ marginTop: "15px" }}
															>
																{cuisines.map((item, index) => (
																	<div
																		onClick={() => handleFilter(item, index)}
																		key={item.id}
																		ref={(el) => (test.current[item.id] = el)}
																	>
																		<div
																			className="btn-group-toggle"
																			data-toggle="buttons"
																		>
																			<label
																				className="btn slider-content-button"
																				style={
																					checkedFilter.has(item.id)
																						? {
																								border: "solid 1px #f06f00",
																								color: " #f06f00",
																								paddingRight: "36px",
																								background: `transparent url(${selectedOrangeCheckImg}) no-repeat 92%`,
																								backgroundSize: "12px",
																								width: "max-content",
																								marginRight: "15px",
																						  }
																						: {
																								width: "max-content",
																								marginRight: "15px",
																						  }
																				}
																			>
																				<input type="checkbox" />
																				{item.title}
																			</label>
																		</div>
																	</div>
																))}
															</div>
														</div>
														{!isVisibleOne ? (
															<div className="right-arrow-btn-menu-list">
																<div className="right-arrow-btn-menu-list-inner">
																	<img
																		src={nextArrowSliderImg}
																		alt="arrow icon"
																		onClick={() =>
																			$("#scrollArea").animate(
																				{
																					scrollLeft:
																						$("#scrollArea").scrollLeft() + 500,
																				},
																				300,
																			)
																		}
																	/>
																</div>
															</div>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</section>

									{filteredRest.length ? (
										<>
											<section
												className="restaurants"
												style={{ minHeight: "400px" }}
											>
												<div className="tab-content" id="nav-tabContent">
													<div
														className="tab-pane fade show active"
														id="nav-delivery"
														role="tabpanel"
														aria-labelledby="nav-delivery-tab"
													>
														<section className="featured-restaurants">
															<div className="container">
																{featuredRest.length ? (
																	<div className="local-favt restra">
																		<div className="row">
																			<Heading headingText="Featured" />

																			<div className="col-12">
																				<CategorySlider
																					settings={resturantSlider}
																				>
																					{featuredRest.map((item, index) => {
																						return (
																							<Card
																								testType={store.auth.headerTab}
																								store={store}
																								goToMenu={() => goToMenu(item)}
																								key={item.restaurant_id}
																								item={item}
																							/>
																						);
																					})}
																				</CategorySlider>
																			</div>
																		</div>
																	</div>
																) : null}
																{filteredRest.length ? (
																	<div className="all-restaurants local-favt restra">
																		<div className="row">
																			<Heading headingText="All Restaurants" />

																			{filteredRest.map((item, index) => (
																				<div
																					className="col-12 col-sm-6 col-md-6 col-lg-4"
																					key={item.id}
																				>
																					<Card
																						testType={store.auth.headerTab}
																						store={store}
																						goToMenu={() => goToMenu(item)}
																						item={item}
																						key={item.restaurant_id}
																					/>
																				</div>
																			))}
																		</div>
																	</div>
																) : (
																	<NotFound
																		msgTitle="Restaurants not found"
																		msgDescription={
																			"According to the specified cuisines no available institutions present for food orders at home."
																		}
																	/>
																)}
															</div>
														</section>
													</div>
												</div>
											</section>
										</>
									) : (
										<NotFound
											msgTitle="Restaurants not found"
											msgDescription={
												"According to the specified cuisines no available institutions present for food orders at home."
											}
										/>
									)}
								</>
							) : (
								<>
									{!pageLoader ? (
										<NotFound
											msgTitle="Restaurants not found"
											msgDescription={
												"According to the specified address no available institutions present for food orders at home."
											}
										/>
									) : (
										<div>
											<section className="loading">
												<div className="sk-spinner-pulse"></div>
											</section>
										</div>
									)}
								</>
							)}
						</main>
					)}
					{!pageLoader || isMobileOnly ? <Footer /> : null}
				</>
			</Route>
			<Route path={`${path}/:topicId`}>
				<MenuListing />
			</Route>
		</Switch>
	);
};

export default ResturantList;

const settings = {
	className: "fooodRestra-options",
	infinite: false,
	speed: 300,
	slidesToShow: 10,
	slidesToScroll: 4,
	cssEase: "linear",
	variableWidth: true,
	variableHeight: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: false,
				dots: false,
				cssEase: "linear",
				variableWidth: true,
				variableHeight: true,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				// arrows: true,
				cssEase: "linear",
				variableWidth: true,
				variableHeight: true,
				swipe: true,
				infinite: false,
			},
		},
		{
			breakpoint: 480,
			settings: {
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: false,
				cssEase: "linear",
				variableWidth: true,
				variableHeight: true,
				swipe: true,
			},
		},
	],
};
const resturantSlider = {
	className: "featured-restaurants-slider",
	infinite: false,
	// autoplay:true,
	speed: 300,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},

		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				dots: true,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
			},
		},
	],
};
const resturantSliderMobile = {
	className: "featured-restaurants-slider featured-restaurants-slider-mobile",
	infinite: false,
	speed: 300,
	slidesToShow: 10,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				infinite: true,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				infinite: true,
			},
		},
	],
};
