import React from "react";
import { useSelector, useDispatch } from "react-redux";
import notFoundImg from "assets/images/not-found.svg";

const NotFound = (props) => {
  const { msgTitle, msgDescription } = props;
  const store = useSelector((store) => store);
  return (
    <div className="error-pages">
      <div className="container">
        <div className="error-wrapper">
          <div className="error-wrapper-inner">
            {!store.auth.loader && (
              <>
                <div className="img-box">
                  <img
                    src={props?.icon ?props.icon :notFoundImg}
                    alt="Not Found"
                    className="img-fluid"
                  />
                </div>
                <div className="error-content">
                  <h1>{msgTitle}</h1>
                  <p className="mb-2">{msgDescription} {props?.orderDesktop ? <span role="button" onClick={props.orderNowFunc?props.orderNowFunc:null} style={{color:"#f06f00"}}>Order Now</span>:null}  </p>
                  {props?.orderNow ? <p role="button" onClick={props.orderNowFunc?props.orderNowFunc:null} style={{color:"#f06f00"}}>Order Now</p> : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
