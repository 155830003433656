/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import GroupOrderModal from "./GroupOrderModal";
import {
  getGroupOrderInfo,
  setGroupOrderInfo,
  getResturantsData,
  initiateGroupOrder,
} from "redux-store";

const GroupOrder = (props) => {
  const {
    forSideText,
    forSideButton,
    isMobile = false,
    extraClass = "",
    needInfoTextAtRestInfoPlace,
  } = props;

  const { auth, rest } = useSelector((store) => store);
  const { groupOrder, groupOrderInfo } = rest;
  const isGroupOrderInitiated = groupOrder?.group_order_id > 0;
  const { groupToken } = useParams();
  console.log("groupToken test", groupToken)
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const isAdminOpenThisLink = groupOrderInfo?.is_admin;
  const isAdminToken = groupOrderInfo?.members?.length > 0;
  const location = useLocation();
  const [isReorder, setIsReorder] = useState(false);

  console.log("token we get", groupToken);

  useEffect(() => {
    if (location.hash) {
      let hash = location.hash;
      if (hash.toLowerCase() === "#reorder") {
        setIsReorder(true);
      } else {
        setIsReorder(false);
      }
    }
  }, [location]);

  const onGroupOrder = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (groupToken && forSideButton) {
      dispatch(
        getGroupOrderInfo(groupToken, () => {
          if (isReorder) {
            onGroupOrder();
          }
        })
      );
    } else dispatch(setGroupOrderInfo(null));
  }, [groupToken, isReorder]);

  useEffect(() => {
    if (groupOrderInfo && groupToken) {
      let data = groupOrderInfo;
      let date = data.selected_date?.split(" ");
      let model = {
        restaurant_id: data.restaurant_id,
        lat_from: data.latitude,
        lng_from: data.longitude,
        asap_or_advance: data.asap_or_advance,
        service_type: data.service_type,
      };
      if (date) {
        model["date"] = date[0];
        model["time"] = date[1];
      }
      dispatch(getResturantsData(model));
    }
  }, [groupOrderInfo]);

  const changeServiceType = (val) => {
    let model = {
      id: groupOrderInfo?.id,
      name: groupOrderInfo?.title,
      limit: groupOrderInfo?.amount_limit,
      member: groupOrderInfo?.members,
      restaurant_id: groupOrderInfo?.restaurant_id,
      lat: groupOrderInfo?.latitude,
      lng: groupOrderInfo?.longitude,
      service_type: val == "Delivery" ? 1 : 2,
      asap_or_advance: 1,
      date: "",
      time: "",
    };
    dispatch(initiateGroupOrder(model));
  };

  return (
    <>
      {forSideText ? (
        groupToken && !isAdminToken ? (
          needInfoTextAtRestInfoPlace ? (
            <>
              {isAdminOpenThisLink ? (
                <div
                  className={`redeem-points bg-beige mt-2 d-inline-block px-2 ${extraClass}`}
                >
                  This is the order of {groupOrderInfo?.name}.{" "}
                  {groupOrderInfo?.amount_limit > 0 ? (
                    <>
                      The maximum amount limit is{" "}
                      <span>${groupOrderInfo?.amount_limit}</span>
                    </>
                  ) : null}
                </div>
              ) : (
                <div
                  className={`redeem-points bg-beige mt-2 d-inline-block px-2 ${extraClass}`}
                >
                  You have been invited to order by{" "}
                  {groupOrderInfo?.customers_name}.{" "}
                  {groupOrderInfo?.amount_limit > 0 ? (
                    <>
                      The maximum amount limit is{" "}
                      <span>${groupOrderInfo?.amount_limit}</span>
                    </>
                  ) : null}
                </div>
              )}
            </>
          ) : null
        ) : (
          props.children
        )
      ) : null}

      {forSideButton ? (
        auth?.token ? (
          isGroupOrderInitiated ? (
            groupOrder?.group_order_link ? (
              groupToken ? (
                isAdminToken ? (
                  <>
                    {isMobile ? (
                      <div className="row">
                        <div className="col-8">
                          <div className="hometab-wrapper resend-home-tab">
                            <ul
                              className="nav nav-pills nav-justified"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  href
                                  className={`nav-link ${
                                    auth.headerTab == "Delivery" ? "active" : ""
                                  }`}
                                  onClick={() => changeServiceType("Delivery")}
                                >
                                  Delivery
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  href
                                  className={`nav-link ${
                                    auth.headerTab == "Pickup" ? "active" : ""
                                  }`}
                                  onClick={() => changeServiceType("Pickup")}
                                >
                                  Pickup
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-auto px-0">
                          <button
                            className="btn btn-filled ml-auto mt-auto"
                            onClick={onGroupOrder}
                          >
                            {isReorder
                              ? "Reorder Group Order"
                              : "Resend Invite"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="btn btn-filled ml-auto mt-auto"
                        onClick={onGroupOrder}
                      >
                        {isReorder ? "Reorder Group Order" : "Resend Invite"}
                      </button>
                    )}
                  </>
                ) : null
              ) : (
                <a
                  href={groupOrder?.group_order_link}
                  rel="noreferrer"
                  className="btn btn-filled ml-auto mt-auto"
                >
                  View Group Order
                </a>
              )
            ) : (
              <button
                className="btn btn-filled ml-auto mt-auto"
                onClick={onGroupOrder}
              >
                Group Order
              </button>
            )
          ) : (
            <>
              {groupOrderInfo?.is_admin &&
              groupToken &&
              groupOrderInfo?.status === 2 ? (
                <button
                  className="btn btn-filled ml-auto mt-auto"
                  onClick={onGroupOrder}
                >
                  Resend Invite
                </button>
              ) : (
                <button
                  className="btn btn-filled ml-auto mt-auto"
                  onClick={onGroupOrder}
                >
                  Group Order
                </button>
              )}
            </>
          )
        ) : null
      ) : null}
      {openModal ? (
        <GroupOrderModal
          show={openModal}
          onClose={onModalClose}
          isReorder={isReorder}
        />
      ) : null}
    </>
  );
};

export default GroupOrder;
