import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PublicRoutes from "../../PrivateRoute";
import PrivateRoutes from "../../PrivateRoute";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Profile,
  Address,
  ChangeAddressMobile,
  ChangePassword,
} from "../../pages";
import ModalWrapper from "../../components/ModalWrapper";
import { getAddress, saveAddress } from "redux-store";
import { useForm } from "react-hook-form";
import { useWindowResize } from "../../components/useWindowResize";

const NestedModal = () => {
  const isMobileOnly = useWindowResize()

  const store = useSelector((store) => store);

  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    defaultValues: {
      entry_firstname: store.profile.customers_firstname,
      entry_lastname: store.profile.customers_lastname,
    },
  });

  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const { data = {} } = useLocation();
  useEffect(() => {
    dispatch(getAddress());
  }, [store.profile.updateStatus]);
  useEffect(() => {
    setStep(0);
    setIsModal("modal");
    return () => {
      setStep(0);
      setIsModal(null);
    };
  }, []);

  const [addrFields, setAddrFields] = useState({
    address_book_id: "",
    entry_street_address: "",
    entry_country_id: "1",
    entry_zone_id: "1",
    address_type: "1",
    address_lat: "",
    address_lng: "",
    entry_suburb: "",
    set_default: 0,
    entry_state: "",
    entry_postcode: "",
    entry_firstname: "",
    entry_lastname: "",
    entry_company: "",
    entry_city: "",
    entry_street_name:""
  });
  const handleChoosenAddress = (x, latlong) => {
    const { address, city, state, country, postal_code, street_number, route } =
      x;
    const { lat, lng } = latlong;
    setAddrFields({
      ...addrFields,
      entry_street_address: address,
      entry_postcode: postal_code,
      entry_street_name:
        street_number && route
          ? street_number.concat(`, ${route}`)
          : street_number
          ? street_number
          : route
          ? route
          : "",
      entry_state: state,
      entry_city: city,
      address_lat: lat,
      address_lng: lng,
    });
    step == 2 ? setStep(1) : handleBack();
  };

  let history = useHistory();
  const [step, setStep] = useState(0);
  const [isModal, setIsModal] = useState(null);
  const handleBack = () => {
    switch (step) {
      case 0:
        setIsModal("modal");
        history.goBack();
        break;
      case 1:
        setIsModal("modal");
        setStep(0);
        break;
      case 2:
        if (isMobileOnly) {
          setStep(1);
        } else {
          history.goBack();
        }

        break;
      case 3:
        setIsModal("modal");
        setStep(0);
        break;
    }
  };

  const handleEditAddress = (item) => {
    setIsEdit(true);
    // setValue("firstName", "Bill")

    setAddrFields({
      ...addrFields,
      ...item,
      set_default:
        item.address_book_id == item.customers_default_address_id ? 1 : 0,
    });
    setStep(1);
  };

  const handleAddAddress = (e) => {
    // e.preventDefault()
    let addr = { ...addrFields };
    console.log("{ ...addr, ...e }", { ...addr, ...e })
    if (!isEdit) {
      delete addr["address_book_id"];
    }

    dispatch(
      saveAddress({ ...addr, ...e }, () => {
        setStep(0);
      })
    );
  };

  const handleChangePassword = () => {};

  const wrapperRef = useRef(null);

  // useOutsideAlerter(wrapperRef, setStep, () => history.goBack());

  const [stayInProfile, setStatyInProfile] = useState(false);
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setStatyInProfile(true);
    } else {
      history.goBack();
    }
  }, []);
  return (
    <>
      {Object.keys(data).length < 1 ? (
        <Redirect to="/" />
      ) : (
        <ModalWrapper
          test={wrapperRef}
          headerText={
            step == 0
              ? data.isProfile
                ? "My Account"
                : "Addresses"
              : step == 1
              ? !isEdit
                ? "Add Address"
                : "Edit Address"
              : step == 3
              ? "My Profile"
              : "Choose Address"
          }
          handleBack={handleBack}
          isModal={isModal}
          custom
        >
          <div
          // ref={wrapperRef}
          >
            {step == 0 && (
              <Profile
                setStep={setStep}
                step={step}
                handleBack={handleBack}
                handleEditAddress={handleEditAddress}
                setIsModal={setIsModal}
                isProfile={data.isProfile}
                setAddrFields={setAddrFields}
                setIsEdit={setIsEdit}
              />
            )}
            {step == 1 && (
              <Address
                setStep={setStep}
                step={step}
                handleBack={handleBack}
                addrFields={addrFields}
                setAddrFields={setAddrFields}
                handleChoosenAddress={handleChoosenAddress}
                isEdit={isEdit}
                setIsEdit={isEdit}
                handleAddAddress={handleAddAddress}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
            {step == 2 && (
              <ChangeAddressMobile
                setStep={setStep}
                step={step}
                handleBack={handleBack}
                handleChoosenAddress={handleChoosenAddress}
                avoidSavedAddress
              />
            )}
            {step == 3 && (
              <ChangePassword
                setStep={setStep}
                step={step}
                handleBack={handleBack}
                handleChoosenAddress={handleChoosenAddress}
              />
            )}
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default NestedModal;
// ref={props.test?props.test:null}
function useOutsideAlerter(ref, setList, goBack) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("called")
        setList(0);
        goBack();
        // alert("You clicked outside of me!");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
