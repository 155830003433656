import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { regEmail, regPhone } from "../validation";
import {
  forgotPassword,
  setRouteDataVerificationPage,
  setForgotField,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Loader from "../components/Loader";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import forgetPassImg from "assets/images/forgot-pass.svg";
import backArrowMobileImg from "assets/images/back-arrow-mobile.svg";
import { useWindowResize } from "./../components/useWindowResize";

const ForgotPassword = (props) => {
  const isMobileOnly = useWindowResize()
  const store = useSelector((store) => store);
  const { loader } = useSelector((store) => store.auth);
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue } = useForm();

  let match = useRouteMatch();
  const onSubmit = (data) => {
    dispatch(setForgotField(data.customers_username));
    dispatch(setRouteDataVerificationPage({ ...data, reset: true }));
    dispatch(
      forgotPassword(data, () => {
        history.push({
          pathname: `/verification`,
          // search: '?query=abc',
          data: { ...data, reset: true },
        });
      })
    );
  };
  useEffect(() => {
    setValue("customers_username", store.tab.customers_username, {
      shouldDirty: true,
    });
  }, []);

  // console.log("process", process.env.APP_URL)
  return (
    <>
      {!isMobileOnly ? <Header auth /> : null}
      <main className="mobile-screen-bg">
        <div className="container">
          <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
            <div className="wrapper">
              <Loader loader={loader} />
              <div className="row mobile-logodiv backbtn-div d-md-none">
                <div className="col-12">
                  <a>
                    <img
                      src={backArrowMobileImg}
                      alt="go back"
                      onClick={() => history.goBack()}
                    />
                  </a>
                </div>
              </div>
              <div className="row despktop-logodiv mb-4 mb-md-5">
                <div className="col-12">
                  <div className="top-logo-wrapper">
                    <a>
                      <img src={forgetPassImg} alt="forgot password" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row centered-heading">
                <div className="col-12 mb-2 mb-md-4">
                  <h1 style={{ display: "block" }}>Forgot Password</h1>
                </div>
                <div className="col-12 mb-3 mb-md-4 text-center">
                  <p>
                    Don’t worry, just enter your phone number/email and <br />{" "}
                    we will send you a 6-digit Code to reset it.
                  </p>
                </div>
              </div>
              <div className="forgot-password mb-4">
                <form
                  className="needs-validation signups"
                  novalidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control input-field-layout"
                        id="forgotpassphone"
                        placeholder="Phone Number/Email"
                        style={
                          errors.customers_username
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        name="customers_username"
                        ref={register({
                          required: "This Phone/email is not registered",
                          validate: () =>
                            regEmail.test(getValues()["customers_username"]) ||
                            regPhone.test(getValues()["customers_username"]),
                        })}
                      />
                      {errors.customers_username && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_username.message == ""
                            ? "This Phone/email is not registered"
                            : errors.customers_username.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide Phone Number
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn initial-screen-lg-btn font-20"
                    id="send-code-btn"
                    type="submit"
                  >
                    Send Code
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {!isMobileOnly ? <Footer /> : null}
    </>
  );
};

export default ForgotPassword;
