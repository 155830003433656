import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CategorySlider =  React.forwardRef((props,ref) => {
	const { settings, children } = props 
	
	return (
		<Slider {...settings} ref={ref}>
			{children}
		</Slider>
	);
});

export default CategorySlider;
