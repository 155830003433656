import React, { useState, useEffect } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import { useHistory } from "react-router-dom";

import GcCart from "components/GcCart";
import { REACT_APP_BASE_GC_IMAGE_URL } from "../../service/ApiNames";

const DesktopViewGc = (props) => {
	const history = useHistory()
	const [productItems, setProductItems] = useState([]);
	const [priceObj, setPriceObj] = useState({});
	const {
		orderInfo,
		products,
		dispatch,
		handleGoBack,
		orderStatus,
		calcArray,
		store
	} = props;
	// useEffect(()=>{

	//   if(products.length){
	//     let items = products[0].options.map(
	//       ({ attributes: optionValues, ...rest }) => ({
	//         optionValues,
	//         ...rest,
	//       })
	//     )
	//     console.log("prod =>1 products",products)
	//     console.log("prod =>1",items)
	//     setProductItems([{...products[0],options:items}])
	//     console.log("prod =>1",[{...products[0],options:items}])
	//   }

	//   ;
	// },[ products ])

	useEffect(() => {
		const obj = {};
		calcArray.map((item) => {
			obj[item.title] = item;
		});
		setPriceObj(obj);
	}, [calcArray]);

	return (
		<>
			<Header checkoutHeader />
			<section class="payment__page__wrapper orderDetails__page__wrapper gc-order-page">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="backbtn-wrapper">
								<a title="click to go back"
								//  onClick={()=>history.goBack()}
								 onClick={props.handleGoBack}
								 >
									<span class="icons">
										<img src="./assets/images/page-back-arrow.svg" alt="" />
									</span>
									Back
								</a>
							</div>
						</div>
						<div class="col-12">
							<div class="page_main_heading">
								<h1>Order Details</h1>
							</div>
						</div>
					</div>
					<div class="order-details-stepper">
						<div class="row">
							<div class="col-xs-12 col-md-12">
								<div class="order-status">
									<span>
										{(orderStatus && orderStatus.orders_status_id == 1) ||
										(orderStatus && orderStatus.orders_status_id == 14)
											? "Pending"
											: orderStatus && orderStatus.orders_status_id == 10
											? "Completed"
											: ""}
									</span>
								</div>
							</div>
							<div class="col-xs-12 col-md-12 mb-4 pb-3">
								<div class="wrapper-progressBar">
									<ul class="progressBar progressBar-single">
										<li
											className={
												(orderStatus && orderStatus.orders_status_id == 1) ||
												(orderStatus && orderStatus.orders_status_id == 14) ||
												(orderStatus && orderStatus.orders_status_id == 10)
													? "active"
													: ""
											}
										></li>
										<li
											className={
												orderStatus && orderStatus.orders_status_id == 10
													? "active"
													: ""
											}
										></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row flex-column-reverse flex-lg-row justify-content-between pb-4 pb-xl-5">
						<div class="col-12 col-lg-6">
							<div class="order-info">
								<div class="row">
									<div class="col-12">
										<div class="order-detail">
											<span>Order Number</span>
											<span  className="font-weight-bold">
												{orderInfo && orderInfo[0] && orderInfo[0].orders_id}
											</span>
										</div>
									</div>
									<div class="col-12">
										<div class="order-detail">
											<span class>Gift Card</span>
											<span className="font-weight-bold">
												{orderInfo &&
													orderInfo[0] &&
													orderInfo[0].restaurants_name}
											</span>
										</div>
									</div>
									{(orderInfo &&
													orderInfo[0] &&
													orderInfo[0].shipping_method=="Delivery")?<div class="col-12">
										<div class="order-detail">
											<span class>Delivery Address</span>
											<span className="font-weight-bold">
											
												{orderInfo &&
													orderInfo[0] &&
													orderInfo[0].customers_street_address}
											</span>
										</div>
									</div>:null}
									

									<div class="col-12">
										<div class="order-details-wrapper mt-4 pt-3 mb-0">
											<div class="row">
												<div class="col-12 ">
													<div class="section_heading order-details-head">
														<h2 class="mb-0">
															Order Details <span>({products && products.length} {(products && products.length<2)?"Items":"Items"})</span>
														</h2>
													</div>
												</div>
											</div>
											<hr class="seprator" />
											<GcCart
												fromGcards
												cartItems={products}
												cartTotal={
													priceObj["Total"]
														? parseFloat(
																Number(priceObj["Total"].value),
														  ).toFixed(2)
														: 0
												}
												isRewards={
													orderInfo &&
													orderInfo[0] &&
													orderInfo[0].payment_method == "Reward Points"
														? true
														: false
												}
											/>
										</div>
									</div>
								</div>
								<div class="row mb-5">
									<div class="col-12 mt-4 pt-3">
										<div class="billing__details">
											<div class="billing__data total">
												<span>Total</span>

												{orderInfo &&
												orderInfo[0] &&
												orderInfo[0].payment_method == "Reward Points" ? (
													<span>
														{priceObj["Subtotal"] && priceObj["Subtotal"].value
															? `${
																	parseFloat(
																		Number(priceObj["Subtotal"].value),
																	).toFixed(2) * store.cart.taxdata.reward_points_conversion_value
															  }pts. ($${
																	priceObj["Subtotal"]
																		? parseFloat(
																				Number(priceObj["Subtotal"].value),
																		  ).toFixed(2)
																		: 0
															  })`
															: null}
													</span>
												) : (
													<span>
														$
														{priceObj["Subtotal"]
															? parseFloat(
																	Number(priceObj["Subtotal"].value),
															  ).toFixed(2)
															: 0}
													</span>
												)}
											</div>
											<div class="paid-by">
												<div class="billing__data">
													<span>Paid By</span>
													<span>
														{orderInfo &&
															orderInfo[0] &&
															orderInfo[0].payment_method}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-5">
							{products.map((item, index) => {
								const { final_price, products_image, products_name } = item;
								return (
									<>
										<div
											class={`e-gift-card-coupon ${index != 0 ? "pt-2" : ""}`}
										>
											<div class="card-img-box">
												<p>{products_name}</p>
												<img
													class="mx-auto"
													src={`${REACT_APP_BASE_GC_IMAGE_URL}${products_image}`}
												/>

												<h1 class="ml-auto">
													${parseFloat(+final_price).toFixed(2)}
												</h1>
											</div>
										</div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default DesktopViewGc;
