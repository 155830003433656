import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { regEmail, regPhone } from "../validation";
import { sendOtp, setRouteDataVerificationPage } from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import congratsImg from "assets/images/congrats.svg";
import { useWindowResize } from "./../components/useWindowResize";

const SocialSignUp = (props) => {
  const isMobileOnly = useWindowResize()

  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useLocation();
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: data,
  });
  const onSubmit = (value) => {
    dispatch(
      sendOtp(
        {
          // customers_telephone: value.customers_telephone,
          customers_username:value.customers_telephone,
          expiry_interval: "+30 minutes",
        },
        () => {
          dispatch(
            setRouteDataVerificationPage({
              ...data,
              ...value,
              provider_user_name: `${value.customers_firstname} ${value.customers_lastname}`,
              customers_newsletter: value.customers_newsletter ? "1" : "0",
              type: "social",
            })
          );
          history.push({
            pathname: `/verification`,
            data: {
              ...data,
              ...value,
              customers_newsletter: value.customers_newsletter ? "1" : "0",
              type: "social",
            },
          });
        },
        "isSignup"
      )
    );
  };

  return (
    <>
      {!isMobileOnly ? <Header auth /> : null}
      <main className="mobile-screen-bg">
        <div className="container">
          <div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
            <div className="wrapper">
              <div className="row mobile-logodiv backbtn-div d-md-none">
                <div className="col-12">
                  <a onClick={()=>history.goBack()}>
                    <img src={backArrowImg} alt="go back" />
                  </a>
                </div>
              </div>
              <div className="row despktop-logodiv mb-4 mb-md-5">
                <div className="col-12">
                  <div className="top-logo-wrapper">
                    <a href="#">
                      <img src={congratsImg} alt="congrats" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row centered-heading">
                <div className="col-12 mb-2 mb-md-4">
                  <h1>You are almost done!</h1>
                </div>
                <div className="col-12 mb-3 mb-md-4 text-center">
                  <p>
                    Please provide the following additonal <br />
                    information to proceed.
                  </p>
                </div>
              </div>
              <div className="signup-form mb-4">
                <form
                  className="needs-validation signups"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-12 mb-4">
                      <input
                        type="text"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_firstname
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="firstName"
                        placeholder="First Name"
                        name="customers_firstname"
                        ref={register({
                          required: "First name is required",
                        })}
                      />
                      {errors.customers_firstname && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_firstname.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide first name
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <input
                        type="text"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_lastname
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="lastName"
                        placeholder="Last Name"
                        name="customers_lastname"
                        ref={register({
                          required: "Last name is required",
                        })}
                      />
                      {errors.customers_lastname && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_lastname.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide last name
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="input-group position-relative">
                        <div className="phone-ext">+1</div>
                        <input
                          type="text"
                          className="form-control input-field-layout"
                          style={
                            errors.customers_telephone
                              ? { borderColor: "#dc3545" }
                              : {}
                          }
                          id="phoneNumber"
                          placeholder="Phone Number"
                          name="customers_telephone"
                          ref={register({
                            required: "Phone Number is required",
                            pattern: {
                              value: regPhone,
                              message:
                                "Phone Number must be 10 digits long and only contain digits",
                            },
                          })}
                        />
                        {errors.customers_telephone && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#e3342f",
                            }}
                          >
                            {errors.customers_telephone.message}
                          </div>
                        )}
                        <div className="invalid-feedback">
                          Please provide phone number
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <input
                        type="email"
                        className="form-control input-field-layout"
                        style={
                          errors.customers_email_address
                            ? { borderColor: "#dc3545" }
                            : {}
                        }
                        id="email"
                        placeholder="Your Email"
                        name="customers_email_address"
                        ref={register({
                          required: "Your Email is required",

                          pattern: {
                            value: regEmail,
                            message: "Please provide valid email",
                          },
                        })}
                      />
                      {errors.customers_email_address && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#e3342f",
                          }}
                        >
                          {errors.customers_email_address.message}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        Please provide Your email
                      </div>
                    </div>
                    <div className="col-12 mb-2 subscribe-offers">
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="subscribeemailoffers"
                          name="customers_newsletter"
                          ref={register()}
                        />
                        <label
                          className="custom-control-label"
                          for="subscribeemailoffers"
                        >
                          Subscribe for exclusive email offers and discounts
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="cautions">
                        <p>
                          By signing up, I agree to the Food Dudes Terms and
                          Conditions and Privacy Policy
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn initial-screen-lg-btn font-20"
                    id="signup-btn"
                    type="submit"
                  >
                    Sign Up
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {!isMobileOnly ? <Footer /> : null}
    </>
  );
};

export default SocialSignUp;
