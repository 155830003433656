const ADD_TO_CART = "ADD_TO_CART";
const APPLY_COUPAN = "APPLY_COUPAN";
const GIFT_CARD = "GIFT_CARD";
const WALLET = "WALLET";
const PLACE_ORDER_CALC = "PLACE_ORDER_CALC";
const PLACE_ORDER_INSTRUCTION = "PLACE_ORDER_INSTRUCTION";
const PLACE_ORDER_INSTRUCTION_GROUP = "PLACE_ORDER_INSTRUCTION_GROUP"
const PAYMENT_SETTINGS = "PAYMENT_SETTINGS";
const CLIENT_TOKEN = "CLIENT_TOKEN";
const COD = "COD";
const WALLET_PAY = "WALLET_PAY";
const BRAINTREE_PAY = "BRAINTREE_PAY";
const SINGLE_ORDER_DETAIL = "SINGLE_ORDER_DETAIL";
const ORDERSLIST = "ORDERSLIST";
const ADD_FUNDS = "ADD_FUNDS";
const DISABLE_CART_ITEMS = "DISABLE_CART_ITEMS";
const CLEAN_GIFT_CARDS = "CLEAN_GIFT_CARDS";


const SET_GROUP_CART_ITEMS = "SET_GROUP_CART_ITEMS";
const SET_GROUP_CART_PAYMENT_SUMMARY = "SET_GROUP_CART_PAYMENT_SUMMARY";

export {
  ADD_TO_CART,
  APPLY_COUPAN,
  GIFT_CARD,
  CLEAN_GIFT_CARDS,
  WALLET,
  PLACE_ORDER_CALC,
  PLACE_ORDER_INSTRUCTION,
  PLACE_ORDER_INSTRUCTION_GROUP,
  PAYMENT_SETTINGS,
  CLIENT_TOKEN,
  COD,
  WALLET_PAY,
  BRAINTREE_PAY,
  SINGLE_ORDER_DETAIL,
  ORDERSLIST,
  ADD_FUNDS,
  DISABLE_CART_ITEMS,
  SET_GROUP_CART_ITEMS,
  SET_GROUP_CART_PAYMENT_SUMMARY,
};
