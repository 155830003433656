import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useHistory, useParams } from "react-router-dom";
import Map from "../../components/Map";
import moment from "moment";
import BraintreeDropIn from "./BraintreeDropIn";
import FeesAndTax from "components/FeesAndTax";
import {
	requestCoupan,
	getWallet,
	setPlaceOrderCal,
	setPlaceOrderInst,
	selectedMenuItem,
	setSelectedRest,
	setCoupan,
	initiateGroupOrder,
	setSelectedGroupOrderItem,
	setPlaceOrderInstGroup,
} from "redux-store";
import { toast } from "react-toastify";
import pageBackArrowImg from "assets/images/page-back-arrow.svg";
import tooltipIcon from "assets/images/tooltip-icon.svg";
import locationMarkerImg from "assets/images/location-marker-big-block.svg";
import logoCardImg from "assets/images/food-dudes-icon-card.svg";
import promoCancelImg from "assets/images/promo-cancel.svg";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { headerTabSelected } from "redux-store";
import { useWindowResize } from "../../components/useWindowResize";
import GroupOrderItems from "./GroupOrderItems";

const WebContent = (props) => {
	console.log("props webcontent =>", props);
	const { cartStore } = props;
	const isMobileOnly = useWindowResize();

	const history = useHistory();
	const [showDetail, setToggleDetail] = useState(false);
	const [errorInst, setErrorInst] = useState(false);
	const [show, setShow] = useState(false);
	const { store, dispatch, disabledServiceType, pySettingsData } = props;
	console.log("pySettingsData", pySettingsData);
	const {
		// maximum_limit_for_cod_orders = 100,
		allowed_paymant_methods = [],
		allowed_invoice_payment = false,
	} = pySettingsData || {};

	const maximum_limit_for_cod_orders = pySettingsData
		? pySettingsData.maximum_limit_for_cod_orders === null
			? 10000000000
			: pySettingsData.maximum_limit_for_cod_orders
		: 1000000000;
	const { groupToken } = useParams();
	const { groupOrderInfo } = store.rest;

	const {
		sub_total,
		service_fee,
		tax,
		discount,
		low_order,
		// total,
		groupDiscount,
		tip,
	} = cartStore.placeOrderCharges;
	const {
		items = [],
		restaurant_id,
		restaurant_name,
		cartTotal,
	} = cartStore.cart;

	const [coupon_code, setCoupanCode] = useState(null);
	const [isApplied, setIsApplied] = useState(null);
	const [showAppliedCode, SetShowAppliedCode] = useState("");

	const apply = () => {
		if (coupon_code) {
			let isCheck = { status: false, type: "" };
			let gift = Object.keys(cartStore.giftCard);
			let coupan = Object.keys(cartStore.coupan);

			if (cartStore.coupan.coupon_code == coupon_code) {
				isCheck = { status: true, type: "coupan" };
			} else {
				for (let i = 0; i < gift.length; i++) {
					let key = gift[i];
					if (cartStore.giftCard[key].giftcard_code == coupon_code) {
						isCheck = { status: true, type: "gift" };
						break;
					}
				}
			}

			if (isCheck.status) {
				isCheck.type == "gift"
					? toast.error(`Gift card ${coupon_code} is already added`)
					: toast.error(`Coupon ${coupon_code} is already applied`);
			} else {
				dispatch(
					requestCoupan(
						{ code: coupon_code, restaurant_id: store.cart.cart.restaurant_id },
						setIsApplied,
					),
				);
			}

			setTimeout(() => setCoupanCode(""), 300);
			// dispatch(requestCoupan({giftcard_code:coupon_code},setIsApplied, setCoupanCode))
		} else {
			toast.error(`Please enter Promo Code/Gift Cards`);
		}
	};
	useEffect(() => {
		if (store.auth.token) {
			dispatch(getWallet());
		}
	}, [store.auth.token]);

	const cus = cartStore.tips.find((x) => x == props.driverTip);

	const handleEdit = (item, index) => {
		const {
			items = [],
			restaurant_id,
			restaurant_name,
			cartTotal,
			min_order,
		} = cartStore.cart;

		dispatch(
			setSelectedGroupOrderItem({
				...item,
				...store.rest.itemsDetail[item.products_id],
				products_price: Number(item.products_price),
				restaurant_name: restaurant_name,
				restaurant_id: restaurant_id,
				isEdit: true,
				selectedProduct: item,
				editIndex: index,
			}),
		);

		dispatch(
			selectedMenuItem({
				...item,
				...store.rest.itemsDetail[item.products_id],
				products_price: Number(item.products_price),
				restaurant_name: restaurant_name,
				restaurant_id: restaurant_id,
				isEdit: true,
				selectedProduct: item,
				editIndex: index,
			}),
		);

		// isMobileOnly
		//   ? history.push("/menuitemmobile")
		//   :
		dispatch({
			type: "ITEM_MODAL",
			payload: { status: true, type: "" },
		});
	};

	const goToMenu = () => {
		// props.dispatch(setSelectedRest(data));
		history.push(
			`/restaurants/${restaurant_name.replace(
				/[^a-zA-Z0-9]+/g,
				"_",
			)}_${restaurant_id}`,
		);
		// history.push({
		// 	pathname: `${path}/${data.name.replace(/[^a-zA-Z0-9]+/g, "_")}_${
		// 		data.restaurant_id
		// 	}`,
		// 	// search: '?query=abc',
		// 	data: data,
		// });
	};

	const handleAddFunds = (e) => {
		e.stopPropagation();
		props.setIsOpen({ status: true, code: "funds" });
		// alert("Called")
	};

	const handleTabs = (val) => {
		if (groupToken) {
			let model = {
				id: groupOrderInfo?.id,
				name: groupOrderInfo?.title,
				limit: groupOrderInfo?.amount_limit,
				member: groupOrderInfo?.members,
				restaurant_id: groupOrderInfo?.restaurant_id,
				lat: groupOrderInfo?.latitude,
				lng: groupOrderInfo?.longitude,
				service_type: val == "Delivery" ? 1 : 2,
				asap_or_advance: 1,
				date: "",
				time: "",
			};
			dispatch(initiateGroupOrder(model));
		} else {
			dispatch(headerTabSelected(val));
		}
	};

	return (
		<>
			<FeesAndTax
				show={show}
				setShow={setShow}
				fullscreen={isMobileOnly ? true : false}
				centered={true}
				sf={+service_fee}
				lof={low_order ? +low_order : 0}
				st={+tax}
			/>
			<div className="main">
				{isMobileOnly && (
					<div className="map-section">
						<div className="map-wrapper">
							<Map
								isCheckOut
								cities={props.cities}
								handleOnLoad={props.handleOnLoad}
								// setActiveMarker={setActiveMarker}
								// handleActiveMarker={handleActiveMarker}
								dispatch={props.dispatch}
								polylineOptions={
									store.auth.headerTab == "Delivery"
										? props.polylineOptions
										: false
								}
								mapProjection={props.mapProjection}
								mapZoom={props.mapZoom}
								mapValue={props.mapValue}
								// selectedAddress={selectedAddress}
								// activeMarker={activeMarker}
								// mapCenter={mapCenter}
								// setMapCenter={setMapCenter}
							/>
						</div>
					</div>
				)}
				<div className="container">
					<div className="row">
						{!isMobileOnly && (
							<>
								<div className="col-12">
									<div className="backbtn-wrapper">
										<a
											onClick={() => history.goBack()}
											title="click to go back"
										>
											<span className="icons">
												<img src={pageBackArrowImg} alt="" />
											</span>
											Back
										</a>
									</div>
								</div>
								<div className="col-12">
									<div className="page_main_heading">
										<h1 onClick={goToMenu} className="d-inline" role="button">
											{groupToken
												? groupOrderInfo?.restaurant_name
												: restaurant_name}
										</h1>
									</div>
								</div>
								<div className="col-12 mt-3">
									<div className="map-section">
										<div className="map-wrapper">
											<Map
												isCheckOut
												cities={props.cities}
												handleOnLoad={props.handleOnLoad}
												// setActiveMarker={setActiveMarker}
												// handleActiveMarker={handleActiveMarker}
												dispatch={props.dispatch}
												polylineOptions={
													store.auth.headerTab == "Delivery"
														? props.polylineOptions
														: false
												}
												mapProjection={props.mapProjection}
												mapZoom={props.mapZoom}
												mapValue={props.mapValue}
												// selectedAddress={selectedAddress}
												// activeMarker={activeMarker}
												// mapCenter={mapCenter}
												// setMapCenter={setMapCenter}
											/>
										</div>
									</div>
								</div>
							</>
						)}
						<div className="col-12 mt-4 pt-3">
							<div className="delivery__options__tabs">
								<div
									className="btn-group btn-group-toggle"
									data-toggle="buttons"
								>
									<label
										className={`btn btn-secondary active ${
											props.disabledServiceType == "Delivery"
												? "disabled-state"
												: ""
										}`}
										style={
											store.auth.headerTab == "Delivery"
												? {
														background: "#1c1b1a",
														color: "#fff",
														border: "solid 1px #1c1b1a",
												  }
												: {
														background: "none",
														color: "#1c1b1a",
														border: "solid 1px #cacaca",
												  }
										}
										onClick={() =>
											props.disabledServiceType == "Delivery"
												? null
												: handleTabs("Delivery")
										}
									>
										<input type="radio" name="options" id="delivery" checked />
										Delivery
									</label>
									<label
										className={`btn btn-secondary ${
											props.disabledServiceType == "Pickup"
												? "disabled-state"
												: ""
										}`}
										style={
											store.auth.headerTab == "Pickup"
												? {
														background: "#1c1b1a",
														color: "#fff",
														border: "solid 1px #1c1b1a",
												  }
												: {
														background: "none",
														color: "#1c1b1a",
														border: "solid 1px #cacaca",
												  }
										}
										onClick={() =>
											props.disabledServiceType == "Pickup"
												? null
												: handleTabs("Pickup")
										}
									>
										<input type="radio" name="options" id="pickup" /> Pickup
									</label>
								</div>
							</div>
						</div>
						{store.auth.headerTab == "Delivery" && (
							<>
								<div className="col-12 col-md-12 mt-4 pt-3 mb-md-4">
									<div className="location-wrapper checkout-edit-add">
										<div className="d-flex justify-content-between">
											<div className="leftside">
												<div className="img-box">
													<img src={locationMarkerImg} alt="location" />
												</div>
												<div className="content-box">
													<h6>
														{store.profile.selectedAddress &&
														store.profile.selectedAddress
															.entry_street_address &&
														store.profile.selectedAddress.entry_street_name
															? store.profile.selectedAddress.entry_suburb
																? store.profile.selectedAddress.entry_suburb +
																  ", " +
																  store.profile.selectedAddress
																		.entry_street_name
																: "" +
																  store.profile.selectedAddress
																		.entry_street_name
															: store.profile.selectedAddress.entry_street_address.split(
																	",",
															  )[0]}
													</h6>
													{/* <span>New York</span> */}
												</div>
											</div>
											<div className="rightside">
												<button
													className="edit-location-btn"
													onClick={() =>
														!isMobileOnly
															? props.setIsOpen({
																	status: true,
																	code: "Address",
															  })
															: props.setIsOpen({
																	status: true,
																	code: "Address",
															  })
													}
												>
													Edit
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 mt-4 pt-md-0 mt-md-3 mb-md-4">
									<div className="delivery-types-options">
										<div className="time-choice-wrapper">
											<div className="form-group mb-0">
												<div className="radio-wrapper">
													<label
														className="radio-container mb-3 mb-md-0"
														onClick={() => props.setRadio("Meet at Door")}
													>
														Meet at Door
														<input
															type="radio"
															checked={
																props.radio == "Meet at Door" ? true : false
															}
															name="radio"
														/>
														<span className="checkmark"></span>
													</label>
													<label
														className="radio-container mb-3 mb-md-0"
														onClick={() => props.setRadio("Meet Outside")}
													>
														Meet Outside
														<input
															type="radio"
															name="radio"
															checked={
																props.radio == "Meet Outside" ? true : false
															}
														/>
														<span className="checkmark"></span>
													</label>
													<label
														className="radio-container mb-3 mb-md-0"
														onClick={() => props.setRadio("Leave at Door")}
													>
														Leave at Door
														<input
															type="radio"
															name="radio"
															checked={
																props.radio == "Leave at Door" ? true : false
															}
														/>
														<span className="checkmark"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						{store.auth.headerTab == "Pickup" && (
							<>
								<div className="col-12 col-md-12 mt-4 pt-3">
									<div className="location-wrapper checkout-edit-add">
										<div className="d-flex justify-content-between">
											<div className="leftside pb-3">
												<div className="img-box">
													<img src={locationMarkerImg} alt="location" />
												</div>
												<div className="content-box">
													<h6 className="text-left">
														{cartStore.cart.address
															? cartStore.cart.address
															: store.profile.selectedAddress &&
															  store.profile.selectedAddress
																	.entry_street_address &&
															  store.profile.selectedAddress.entry_street_address.split(
																	",",
															  )[0]}
													</h6>
													<span>
														{/* {store.profile.selectedAddress.entry_street_address} */}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						<div className="col-12 mt-4 pt-md-0 mt-md-3">
							<div className="section_heading">
								<h2>
									{store.auth.headerTab == "Delivery"
										? "Delivery Time"
										: "Pick-up Time"}
								</h2>
							</div>
							<div className="time-choice-wrapper">
								<div className="form-group mb-0">
									<div className="radio-wrapper">
										<label
											className={`radio-container ${
												props.disabledAsapAdavance == "ASAP"
													? "disabled-state"
													: ""
											}`}
											onClick={
												props.disabledAsapAdavance == "ASAP"
													? null
													: props.deliverNow
											}
										>
											ASAP
											<input
												type="radio"
												checked={store.profile.deliveryDate ? false : true}
												name="dt"
											/>
											<span
												className={`checkmark ${
													store.profile.deliveryDate ? "" : ""
												}`}
											></span>
										</label>
										<label
											className={`radio-container ${
												props.disabledAsapAdavance == "SCHEDULE"
													? "disabled-state"
													: ""
											}`}
											onClick={
												props.disabledAsapAdavance == "SCHEDULE"
													? null
													: () =>
															!isMobileOnly
																? props.setIsOpen({
																		status: true,
																		code: "datentime",
																  })
																: history.push({
																		pathname: "/choose-date-time",
																		// search: '?query=abc',
																		data: {
																			disabledAsapAdavance:
																				props.disabledAsapAdavance,
																		},
																  })
											}
										>
											{store.profile.deliveryDate
												? `${moment(store.profile.deliveryDate).format(
														"ddd, MMM DD",
												  )} ${store.profile.timeInd}`
												: "Schedule"}
											<input
												type="radio"
												name="dt"
												checked={store.profile.deliveryDate ? true : false}
											/>
											<span
												className={`checkmark ${
													store.profile.deliveryDate ? "" : ""
												}`}
											></span>
										</label>
									</div>
								</div>
							</div>
						</div>

						{isMobileOnly && store.auth.headerTab == "Delivery" && (
							<div className="col-12 mb-3">
								<div className="drivers-tip">
									<div className="driver-tip-amount">
										<div className="row">
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<label>Driver Tip</label>
													{props.driverTip ? (
														<span>{`${
															props.drverTipType ? "$ " : ""
														}${parseFloat(props.driverTip).toFixed(2)}${
															props.drverTipType ? "" : " %"
														}`}</span>
													) : null}
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="tip_percentage_wrapper">
												{cartStore.tips.map((item, index) => (
													<div
														className={`tip_percentage ${
															props.driverTip == item ? "active" : ""
														}`}
														key={index}
														onClick={() => props.setDriverTip(item)}
													>
														<span>{`${props.drverTipType ? "$ " : ""}${item}${
															props.drverTipType ? "" : " %"
														}`}</span>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="other_tip_percentage_wrapper">
												<div
													className={`tip_percentage ${
														!cus && props.driverTip ? "active" : ""
													}`}
													onClick={() =>
														props.setIsOpen({
															status: true,
															code: "tip",
														})
													}
												>
													<span>Custom</span>
												</div>
												<div
													className={`tip_percentage ${
														props.driverTip == 0 ? "active" : ""
													}`}
													onClick={() => props.setDriverTip(0)}
												>
													<span>None</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}

						{store.auth.token && (
							<>
								<div className="col-12 mt-3 mt-md-4 pt-1">
									<div className="section_heading mb-3">
										<h2>Payment</h2>
										{isMobileOnly && (
											<button
												className="link-type-btn"
												onClick={() => props.setAddFundBrainTree(true)}
											>
												Add Funds
											</button>
										)}
									</div>
									<div className="payment-cards">
										<div
											className="btn-group-toggle cards-wrapper"
											data-toggle="buttons"
										>
											<label
												className={`btn btn-secondary clickable-cards ${
													store.tab.selectedPaymentMethods[
														cartStore.wallet_amount.customer_id
													]
														? "active"
														: ""
												}`}
												onClick={() =>
													props.handleSelection(
														cartStore.wallet_amount,
														cartStore.wallet_amount.customer_id,
														"wallet",
													)
												}
												style={
													store.tab.selectedPaymentMethods[
														"GV_" + cartStore.wallet_amount.customer_id
													]
														? {
																borderRadius: "8px",
																color: "#000",
																backgroundColor: "#fff",
																border: "solid 2px #f06f00",
														  }
														: {
																borderRadius: "8px",
																color: "#000",
																backgroundColor: "#fff",
																border: "solid 1px #cacaca",
														  }
												}
											>
												<input
													type="checkbox"
													name="options"
													id={cartStore.wallet_amount.customer_id + "m1"}
													checked={
														store.tab.selectedPaymentMethods[
															cartStore.wallet_amount.customer_id
														]
															? true
															: false
													}
													autocomplete="off"
													disabled
												/>
												<div className="paycard-inner">
													<div className="rightside">
														<div className="img-box">
															<img
																src={logoCardImg}
																alt="card-icon"
																className="img-fluid"
															/>
														</div>
														<div className="card-data">
															<h6>Food Dudes Balance</h6>
															{!isMobileOnly && (
																<button
																	className="link-type-btn"
																	// onClick={handleAddFunds}
																	onClick={() =>
																		props.setAddFundBrainTree(true)
																	}
																>
																	Add Funds
																</button>
															)}
														</div>
													</div>
													<div className="leftside">
														<span className="amount">
															$
															{parseFloat(
																cartStore.wallet_amount.amount,
															).toFixed(2)}
														</span>
													</div>
												</div>
											</label>
											{Object.keys(cartStore.giftCard).map((item, index) => {
												if (cartStore.giftCard[item].giftcard_balance <= 0) {
													return null;
												}
												return (
													<label
														className={`btn btn-secondary clickable-cards ${
															store.tab.selectedPaymentMethods[
																cartStore.giftCard[item].giftcard_id
															]
																? "active"
																: ""
														}`}
														style={
															store.tab.selectedPaymentMethods[
																"GC_" + cartStore.giftCard[item].giftcard_id
															]
																? {
																		// padding: "14px 24px 15px 28.5px",
																		borderRadius: "8px",
																		color: "#000",
																		backgroundColor: "#fff",
																		border: "solid 2px #f06f00",
																  }
																: {
																		// padding: "14px 24px 15px 28.5px",
																		borderRadius: "8px",
																		color: "#000",
																		backgroundColor: "#fff",
																		border: "solid 1px #cacaca",
																  }
														}
														key={item}
														onClick={() =>
															props.handleSelection(
																cartStore.giftCard[item],
																cartStore.giftCard[item].giftcard_id,
																"gc",
															)
														}
													>
														<input
															type="checkbox"
															name="options"
															id={cartStore.giftCard[item].giftcard_id + "m1"}
															autocomplete="off"
															checked={
																store.tab.selectedPaymentMethods[
																	cartStore.giftCard[item].giftcard_id
																]
																	? true
																	: false
															}
															disabled
														/>
														<div className="paycard-inner">
															<div className="rightside">
																<div className="img-box">
																	<img
																		src={logoCardImg}
																		alt="card-icon"
																		className="img-fluid"
																	/>
																</div>
																<div className="card-data">
																	<h6>
																		Ending with{" "}
																		{cartStore.giftCard[item].giftcard_code}
																	</h6>
																	<span>Gift Card</span>
																</div>
															</div>
															<div className="leftside">
																<span className="amount">
																	$
																	{parseFloat(
																		cartStore.giftCard[item].giftcard_balance,
																	).toFixed(2)}
																</span>
															</div>
														</div>
													</label>
												);
											})}
										</div>
									</div>
								</div>
							</>
						)}
						<div className="col-12 mt-3 mt-md-4">
							{!store.auth.token ? (
								<div className="section_heading">
									<h2>Payments</h2>
								</div>
							) : null}
							<div className="otherway-payment-payments">
								{cartStore.clientToken ? (
									<BraintreeDropIn
										show={props.showBraintreeDropIn}
										clientToken={cartStore.clientToken}
										onPaymentCompleted={() => {
											// props.setShowBraintreeDropIn(false);
											// setNumberOfProducts(1);
										}}
										braintreeInstance={props.braintreeInstance}
										setLocalStatePayMethods={props.setLocalStatePayMethods}
										setCash={props.setCash}
										cash={props.cash}
										selectCash={
											parseFloat(cartStore.placeOrderCharges.sub_total).toFixed(
												2,
											) > +maximum_limit_for_cod_orders
												? false
												: true
										}
										maximum_limit_for_cod_orders={+maximum_limit_for_cod_orders}
										isCodAvailabe={
											allowed_paymant_methods.find((it) => it.id == 6)
												? true
												: false
										}
										allowed_invoice_payment={allowed_invoice_payment}
										setInvoice={props.setInvoice}
									/>
								) : null}
							</div>
						</div>
						<div className="col-12 col-xl-6 mt-md-3 mt-md-4 pt-3">
							<div className="section_heading mb-3">
								<h2>Gift Cards/Promo Code</h2>
							</div>
							<div className="promo-code-details-wrapper">
								<div className="input-group">
									<input
										type="text"
										className="form-control"
										placeholder="Gift Cards/Promo Code"
										aria-label="Gift Cards/Promo Code"
										aria-describedby="button-addon2"
										onChange={(e) =>
											setCoupanCode(e.target.value.replace(/^\s+|\s+$/g, ""))
										}
										value={coupon_code}
										style={
											coupon_code
												? {}
												: coupon_code == null || coupon_code == ""
												? {}
												: { border: "1px solid red" }
										}
									/>
									<div className="input-group-append">
										<button
											className="btn"
											type="button"
											id="button-addon2"
											onClick={apply}
										>
											Apply
										</button>
									</div>
								</div>
								{discount ? (
									<div className="promocode__message__wrapper">
										<div className="promocode__message">
											<div className="promocode__message__iconBox">
												<img src={promoCancelImg} alt="icon" />
											</div>
											<div className="promocode__message__contentBox">
												<p>
													<b>{cartStore.coupan.coupon_code}</b> applied
												</p>
												<p>${parseFloat(discount).toFixed(2)}</p>
											</div>
										</div>
										<div
											className="cancel__promo"
											onClick={() => dispatch(setCoupan({}))}
										>
											<span>Remove</span>
										</div>
									</div>
								) : null}
							</div>
						</div>
						{store.cart.cart.allow_special_instructions ||
						true ||
						groupToken ? (
							<div className="col-12 mt-3 mt-md-4 pt-3">
								<div className="section_heading mb-3">
									<h2>Special Instructions</h2>
								</div>
								<div className="special-instruction">
									<textarea
										maxlength="500"
										onChange={(e) => {
											if (e.target.value.length > 500) {
												setErrorInst(true);
											} else {
												setErrorInst(false);
											}
											dispatch(
												!groupToken
													? setPlaceOrderInst(e.target.value.trimStart())
													: setPlaceOrderInstGroup(e.target.value.trimStart()),
											);
										}}
										id="splinstruction"
										className="form-control"
										name="special-instruction"
										rows="3"
										cols="50"
										placeholder="Add Special instructions"
										value={
											!groupToken
												? cartStore.instruction
												: cartStore.instructionGroup
										}
										style={errorInst ? { borderColor: "red" } : {}}
									></textarea>
								</div>
							</div>
						) : null}
					</div>

					{isMobileOnly ? (
						<>
							{groupToken && groupOrderInfo ? (
								<GroupOrderItems />
							) : (
								<div className="order-details-wrapper">
									<div className="row">
										<div className="col-12 mt-3 mt-md-4 pt-3">
											<div className="section_heading">
												<h2>
													Order Details{" "}
													<span>
														({items.length}{" "}
														{items.length > 1 ? "Items" : "Item"})
													</span>
												</h2>
											</div>
										</div>
										<div className="col-12 mb-md-3 expanded">
											<div className="order-in-details">
												<a
													href
													role="button"
													aria-expanded={showDetail ? "true" : "false"}
													onClick={() => {
														setToggleDetail(!showDetail);
													}}
												>
													{showDetail ? "Hide" : "View"} Details
												</a>
											</div>
										</div>
									</div>
									{showDetail ? (
										<div
											className="order-details-cards-wrapper"
											id="orderDetails"
										>
											<div className="row">
												<div className="col-12">
													{items.map((item, index) => {
														const {
															optionValuesNew,
															products_id,
															products_name,
															total,
															customers_basket_quantity,
														} = item;
														return (
															<div
																className="order-details-card"
																key={products_id + index}
																onClick={() => handleEdit(item, index)}
																style={{
																	cursor: "pointer",
																}}
															>
																<div className="d-flex justify-content-between">
																	<div className="rightside">
																		<span className="item-count">
																			{customers_basket_quantity}x
																		</span>
																		<div className="order-item-details">
																			<h5>{products_name}</h5>
																		</div>
																	</div>
																	<div className="order-price">
																		<span>${parseFloat(total).toFixed(2)}</span>
																	</div>
																</div>
																<div className="order-item-details">
																	<div>
																		{optionValuesNew.map((value, index) => (
																			<span className={"comma"}>
																				{value.options_values_id
																					? value.products_options_values_name
																					: value.products_options_values_text
																					? value.products_options_values_text
																					: null}
																			</span>
																		))}
																	</div>

																	{/* {optionValuesNew.map((value, index) =>
                                value.products_options_values_text ? (
                                  <p>{value.products_options_values_text}</p>
                                ) : null
                              )} */}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									) : null}
								</div>
							)}
						</>
					) : (
						<>
							{groupToken && groupOrderInfo ? (
								<GroupOrderItems />
							) : (
								<div className="order-details-wrapper">
									<div className="row">
										<div className="col-12 mt-3 mt-md-4 pt-3">
											<div className="section_heading mb-3">
												<h2>
													Order Details{" "}
													<span>
														({items.length}{" "}
														{(items && items.length) > 1 ? "Items" : "Item"})
													</span>
												</h2>
											</div>
										</div>
									</div>
									<div className="order-details-cards-wrapper">
										<div className="row">
											<div className="col-12">
												{items.map((item, index) => {
													const {
														optionValuesNew,
														products_id,
														products_name,
														total,
														specialInstruction,
														customers_basket_quantity,
													} = item;
													return (
														<div
															className="order-details-card"
															onClick={() => handleEdit(item, index)}
															style={{
																cursor: "pointer",
															}}
														>
															<div className="rightside">
																<span className="item-count">
																	{customers_basket_quantity}x
																</span>
																<div className="order-item-details">
																	<h5>{products_name}</h5>

																	<div>
																		{optionValuesNew?.length
																			? optionValuesNew.map((value, index) => (
																					<span className={"comma"}>
																						{value.options_values_id
																							? value.products_options_values_name
																							: value.products_options_values_text
																							? value.products_options_values_text
																							: null}
																					</span>
																			  ))
																			: null}
																	</div>
																	{/* {optionValuesNew.map((value, index) =>
                                value.products_options_values_text ? (
                                  <p>{value.products_options_values_text}</p>
                                ) : null
                              )} */}
																</div>
															</div>
															<div className="order-price">
																<span>${parseFloat(total).toFixed(2)}</span>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>

				<div className="sidenav_right" style={{}}>
					<div className="inner-wrapper">
						{!isMobileOnly && (
							<div className="row" onClick={props.handlePlaceOrder}>
								<div className="col-12">
									<button type="submit" className="btn btn-theme-filled">
										Place Order
									</button>
								</div>
							</div>
						)}
						<div className="calculation-details">
							{true && (
								<div className="subtotatals">
									<div className="row">
										<div className="col-12">
											<div className="d-flex justify-content-between">
												<span>Subtotal</span>
												<span>${parseFloat(sub_total).toFixed(2)}</span>
											</div>
										</div>
										{store.auth.headerTab == "Delivery" ? (
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<span>Delivery Fee</span>

													<span>
														$
														{parseFloat(
															+store.cart.cart.restaurant_delivery_fee ||
																store.groupCart.cart.restaurant_delivery_fee,
														).toFixed(2)}
													</span>
												</div>
											</div>
										) : null}

										<div className="col-12">
											<div className="d-flex justify-content-between">
												<span>
													Fees and Sales Tax{" "}
													<span class="tooltip-custom">
														<span
															data-tip
															data-for="registerTip"
															onClick={() => setShow(true)}
														>
															<img src={tooltipIcon} alt="tooltipIcon" />
														</span>

														{/* <ReactTooltip
															id="registerTip"
															place="top"
															effect="solid"
															className="tooltip-custom-inner"
														>
															It includes the service fee, low order fees &
															sales tax fees.
														</ReactTooltip> */}
													</span>
												</span>

												<span>{`$${parseFloat(
													(low_order ? +low_order : 0) + +service_fee + +tax,
												).toFixed(2)}`}</span>
												{/* <span>${parseFloat(sub_total).toFixed(2)}</span> */}
											</div>
										</div>
										{/* {low_order ? (
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <span>Low Order Fee</span>
                          <span>${parseFloat(low_order).toFixed(2)}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 d-none"></div>
                    )} */}
										{/* <div className="col-12">
                      <div className="d-flex justify-content-between">
                        <span>
                          {store.auth.headerTab == "Delivery"
                            ? "Delivery Fee"
                            : "Service Fee"}
                        </span>
                        <span>${parseFloat(service_fee).toFixed(2)}</span>
                      </div>
                    </div> */}
										{/* {tax ? (
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <span>Sales Tax</span>
                          <span>${parseFloat(tax).toFixed(2)}</span>
                        </div>
                      </div>
                    ) : null} */}
										{tip ? (
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<span>Driver's tip</span>
													<span>${parseFloat(tip).toFixed(2)}</span>
												</div>
											</div>
										) : (
											<div className="col-12 d-none"></div>
										)}
										{groupDiscount ? (
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<span>Group Discount</span>
													<span>
														-$
														{parseFloat(groupDiscount).toFixed(2)}
													</span>
												</div>
											</div>
										) : null}

										<div
											className="col-12"
											style={discount ? {} : { display: "none" }}
										>
											<div className="d-flex justify-content-between">
												<span>Discount Applied</span>
												<span>
													-$
													{parseFloat(discount).toFixed(2)}
												</span>
											</div>
										</div>
										{Object.keys(store.tab.selectedPaymentMethods).map(
											(item) => {
												return (
													<div
														className="col-12"
														// style={discount ? {} : { display: "none" }}
													>
														<div className="d-flex justify-content-between">
															<span>
																{store.tab.selectedPaymentMethods[item]
																	.customer_id
																	? `Food Dude Balance`
																	: `Gift Card ${store.tab.selectedPaymentMethods[item].giftcard_code}`}
															</span>
															<span>
																-$
																{parseFloat(
																	Number(
																		store.tab.selectedPaymentMethods[item]
																			.balance_used,
																	),
																).toFixed(2)}
															</span>
														</div>
													</div>
												);
											},
										)}
									</div>
								</div>
							)}
							<div className="final-amount">
								{true && (
									<div className="row">
										<div className="col-12">
											<div className="d-flex justify-content-between">
												<span>Total</span>
												<span id="test-id">
													$
													{parseFloat(
														cartStore.placeOrderCharges.total,
													).toFixed(2)}
												</span>
											</div>
										</div>
									</div>
								)}
							</div>
							{!isMobileOnly && store.auth.headerTab == "Delivery" && (
								<div className="drivers-tip">
									<div className="driver-tip-amount">
										<div className="row">
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<label>Driver Tip</label>
													{props.driverTip ? (
														<span>{`${
															props.drverTipType ? "$ " : ""
														}${parseFloat(props.driverTip).toFixed(2)}${
															props.drverTipType ? "" : " %"
														}`}</span>
													) : null}
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="tip_percentage_wrapper">
												{cartStore.tips.map((item, index) => (
													<div
														className={`tip_percentage ${
															props.driverTip == item ? "active" : ""
														}`}
														key={index}
														onClick={() => props.setDriverTip(item)}
													>
														<span>{`${props.drverTipType ? "$ " : ""}${item}${
															props.drverTipType ? "" : " %"
														}`}</span>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="other_tip_percentage_wrapper">
												<div
													className={`tip_percentage ${
														!cus && props.driverTip != 0 ? "active" : ""
													}`}
													onClick={() =>
														props.setIsOpen({
															status: true,
															code: "tip",
														})
													}
												>
													<span>Custom</span>
												</div>
												<div
													className={`tip_percentage ${
														props.driverTip == 0 ? "active" : ""
													}`}
													onClick={() => props.setDriverTip(0)}
												>
													<span>None</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default WebContent;
