import React,{useEffect} from 'react';
import ProfileContent from "./ProfileContent"

const ScrollToTop = ({ children, ...props }) => {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [props.step]);
  return children || null;
};

const Profile = (props) => {
  
  return (
    <ScrollToTop step={props.step} handleBack={props.handleBack}>
      <ProfileContent {...props}/>
    </ScrollToTop>
  )
};

export default Profile;

// state: { background: location }