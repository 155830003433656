import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "redux-store/store";
import Route from "Route";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "assets/css/app.css";
import "assets/fonts/lato/stylesheet.css";
import "assets/css/rome.css";
import "assets/css/style.css";
import "assets/css/responsive.css";

import ScrollToTop from "ScrollToTop";
import NotificationCounter from "components/NotificationCounter";

const App = () => {
  
  return (
    <Provider store={store}>
      <Router>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <NotificationCounter />
          <Loader />
          <ScrollToTop />
          <Route />
        </PersistGate>
      </Router>
      <ToastContainer autoClose={3000} position="top-center" />
    </Provider>
  );
};

export default App;
