import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import AppleLogin from "react-apple-login";
import { useSelector, useDispatch } from "react-redux";
import { checkIfProviderExists } from "redux-store";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import googleImg from "assets/images/google.svg";
import facebookImg from "assets/images/facebook-login.svg";
import appleImg from "assets/images/apple.svg";
import { decodeToken } from "react-jwt";

const SocialLogin = (props) => {
	const { pathname } = useLocation();
	const [reload, setReload] = useState(false);
	useEffect(() => {
		setReload(true);
	}, []);
	const history = useHistory();
	const dispatch = useDispatch();
	const handleFailure = (value) => {};
	const handleLoginWithGoogle = (res) => {
		dispatch(
			checkIfProviderExists(
				{
					provider: "google",
					provider_uid: res.googleId,
					provider_user_name: res.profileObj.name,
					provider_access_token: res.accessToken,
				},
				() => {
					history.push({
						pathname: `/${"google"}/social-signup`,
						// search: '?query=abc',
						data: {
							customers_email_address: res.profileObj.email,
							provider: "google",
							provider_uid: res.googleId,
							provider_user_name: res.profileObj.name,
							provider_access_token: res.accessToken,
							customers_firstname: res.profileObj.givenName,
							customers_lastname: res.profileObj.familyName,
						},
					});
				},
				() => {
					history.push("/");
				},
			),
		);
	};
	const handleLoginWithFb = (res) => {
		dispatch(
			checkIfProviderExists(
				{
					provider: "facebook",
					provider_uid: res.id,
					provider_user_name: res.name,
					provider_access_token: res.accessToken,
				},
				() => {
					history.push({
						pathname: `/${"facebook"}/social-signup`,
						// search: '?query=abc',
						data: {
							customers_email_address: res.email ? res.email : null,
							provider: "facebook",
							provider_uid: res.id,
							provider_user_name: res.name,
							provider_access_token: res.accessToken,
							customers_firstname: res.first_name,
							customers_lastname: res.last_name,
						},
					});
				},
				() => {
					history.push("/");
				},
			),
		);
	};
	const handleLoginWithApple = (response) => {
		const myDecodedToken = decodeToken(response.authorization.id_token);
		dispatch(
			checkIfProviderExists(
				{
					provider: "apple",
					provider_uid: myDecodedToken.sub,
					provider_user_name: `${
						response.user
							? response.user.name.firstName + " " + response.user.name.lastName
							: "asas"
					}`,
					provider_access_token: "0",
				},
				() => {
					history.push({
						pathname: `/${"apple"}/social-signup`,
						// search: '?query=abc',
						data: {
							customers_email_address: myDecodedToken.email
								? myDecodedToken.email
								: "",
							provider: "apple",
							provider_uid: myDecodedToken.sub,
							provider_user_name: response.user
								? response.user.name.firstName +
								  " " +
								  response.user.name.lastName
								: "",
							// provider_access_token: response.authorization.id_token,
							provider_access_token: "0",
							customers_firstname: response.user
								? response.user.name.firstName
								: "",
							customers_lastname: response.user
								? response.user.name.lastName
								: "",
						},
					});
				},
				() => {
					history.push("/");
				},
			),
		);
	};

	const renderGoogleButton = (props) => {
		const { disabled, onClick } = props;

		return (
			<>
				{!disabled ? (
					<div onClick={!disabled ? onClick : undefined}>
						<span className="social-icon">
							<img className="img-fluid" src={googleImg} alt="Google login" />
						</span>
						<span className="icon-text">
							{pathname == "/signin"
								? "Sign In with Google"
								: "Sign up with Google"}
						</span>
					</div>
				) : (
					<div
						onClick={() =>
							alert("Google could not be loaded please refresh the page")
						}
					>
						<span className="social-icon">
							<img className="img-fluid" src={googleImg} alt="Google login" />
						</span>
						<span className="icon-text">Loading...</span>
					</div>
				)}
			</>
		);
	};
	return (
		<div className="col-12 text-center login-social-links mb-3 ">
			<GoogleLogin
				// clientId="868711881574-08n2ivdn3h7g6714k2unv15qimeimglh.apps.googleusercontent.com"
				clientId="868711881574-7va8sl1urqkg88gibq3vg4ugni26ii63.apps.googleusercontent.com"
				buttonText="Login"
				onSuccess={handleLoginWithGoogle}
				onFailure={handleFailure}
				cookiePolicy={"single_host_origin"}
				// sz={700}
				// disabled={isLoading}
				render={renderGoogleButton}
				accessType="online"
			/>

			<FacebookLogin
				// appId={"3072217726325415"}
				appId={"665811934740294"}
				autoLoad={false}
				fields="name,email,picture.width(800).height(800), first_name, last_name "
				scope="public_profile,email"
				callback={handleLoginWithFb}
				onFailure={handleFailure}
				isMobile={false}
				render={({ isDisabled, onClick }) => {
					return (
						<div onClick={!isDisabled && onClick}>
							<span className="social-icon fb-icon">
								<img
									className="img-fluid"
									src={facebookImg}
									alt="facebook login"
								/>
							</span>
							<span className="icon-text">
								{pathname == "/signin"
									? "Sign In with Facebook"
									: "Sign up with Facebook"}
							</span>
						</div>
					);
				}}
			/>

			<AppleLogin
				// clientId="com.fooddudes.signin"
				// redirectURI="https://fdl-progwapp-dev4.netsol.in/"
				clientId="com.fooddudesstaging.signin"
				// redirectURI="https://fdl-progwapp.agilecollab.com/"
				redirectURI="https://fdl-progwapp-qc.agilecollab.com/"
				// redirectURI={process.env.REACT_APP_WEBSITE_URL}

				usePopup={true}
				responseType={"id_token"}
				responseMode={"query"}
				scope="name email"
				// usePopup={false}
				designProp={{
					height: 30,
					width: 140,
					color: "black",
					border: false,
					type: "sign-in",
					border_radius: 15,
					scale: 1,
					locale: "en_US",
				}}
				callback={handleLoginWithApple}
				render={(renderProps) => {
					return (
						<div
							onClick={renderProps.onClick ? renderProps.onClick : () => null}
						>
							<span className="social-icon">
								<img className="img-fluid" src={appleImg} alt="Apple login" />
							</span>
							<span className="icon-text">
								{pathname == "/signin"
									? "Sign In with Apple"
									: "Sign up with Apple"}
							</span>
						</div>
					);
				}}
			/>
		</div>
	);
};
export default SocialLogin;
