import React from "react";
import { Modal, Button } from "react-bootstrap";
import closeImg from "assets/images/modal-close.svg";
import congratsImg from "assets/images/congrats-icon.svg";

const CongratsPopup = (props) => {
	return (
		<Modal
      className="modal fade"
      id="congrats-modal"
			{...props}
			// size="lg"
			aria-labelledby="congrats-modalLabel"
			centered
		>
			<div id="#congrats-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={props.onHide}
                >
                  <span aria-hidden="true">
                    <img src={closeImg} alt="icon" className="img-fluid" />
                  </span>
                </button>
                <div className="congrates-wrapper">
                  <div className="img-box">
                    <img src={congratsImg} alt="icon" className="img-fluid" />
                  </div>
                  <div className="content-box">
                    <h1>Congrats!</h1>
                        {/* <p>$12 has been added to your food dudes balance. Your new balance is $6000</p> */}
                        <p>{props.show}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
		</Modal>
	);
};

export default CongratsPopup;