
import {
  SET_AUTH_TOKEN,
  AUTH_LOADER,
  ROUTE_DATA_VERIFICATION,
  ROUTE_DATA_RESET_PWD,
  SET_HEADER_TAB,
  DRAWER_STATE,
  GET_STATE_DATA
} from './authType'

const initialState = {
  reduxSetup: true,
  loader:false,
  token:false,
  data:{},
  dataReset:{},
  headerTab:"Delivery",
  drawerState:false,
  stateAndCites:[],
  cities:[]
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN: return {
      ...state,
      token: action.payload
    }
    case AUTH_LOADER: return {
      ...state,
      loader: action.payload
    }
    case ROUTE_DATA_VERIFICATION: return {
      ...state,
      data: action.payload
    }
    case ROUTE_DATA_RESET_PWD: return {
      ...state,
      dataReset: action.payload
    }
    case SET_HEADER_TAB: return {
      ...state,
      headerTab: action.payload
    }
    case DRAWER_STATE: return {
      ...state,
      drawerState: action.payload
    }
    case GET_STATE_DATA: return {
      ...state,
      stateAndCites:action.payload.stateCites,
      cities:action.payload.cities,
    }
    default: return state
  }
}

export default authReducer 