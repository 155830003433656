export const OrderStatuses = {
  PENDING: {
    id: 1,
    text: "Pending",
    isItDelivery: true,
    order: 1,
  },
  ASSIGN: {
    id: 3,
    text: "Pending",
    isItDelivery: true,
    order: 1,
  },
  ACCEPTED: {
    id: 5,
    text: "Accepted",
    isItDelivery: true,
    order: 2,
  },
  PICKEDUP: {
    id: 8,
    text: "Picked Up",
    isItDelivery: true,
    order: 3,
  },
  DELIVERED: {
    id: 10,
    text: "Delivered",
    isItDelivery: true,
    order: 4,
  },
  PICKUP_PENDING: {
    id: 1,
    text: "Pending",
    isItDelivery: false,
    order: 1,
  },
  PICKUP_ACCEPTED: {
    id: 2,
    text: "Accepted",
    isItDelivery: false,
    order: 2,
  },
  PICKUP_READY: {
    id: 4,
    text: "Ready",
    isItDelivery: false,
    order: 3,
  },
  OWN_PICKEDUP: {
    id: 13,
    text: "Picked Up",
    isItDelivery: false,
    order: 4,
  },
  FUTURE: {
    id: 9,
    text: "Scheduled",
    isItDelivery: false,
    order: 1,
  },
  CANCELLED: {
    id: 6,
    text: "Canceled",
    isItDelivery: false,
    order: 0,
  },
  GIFT_CARD_PENDING: {
    id: 14,
    text: "Pending",
    isItDelivery: true,
    order: 1,
  },
};
