import axios from "./axiosInstance";

function get(path, config = {}) {
  return axios.get(path, config);
}

/**
 * Post request
 *
 * @param path
 * @param data
 * @param config
 * @returns {axios.Promise}
 */
function post(path, data, config = {}) {
  return axios.post(path, data, config);
}

/**
 * Put request
 *
 * @param path
 * @param data
 * @param config
 * @returns {axios.Promise}
 */
function put(path, data, config = {}) {
  return axios.put(path, data, config);
}

/**
 * Patch request
 *
 * @param path
 * @param data
 * @param config
 * @returns {axios.Promise}
 */
function patch(path, data, config = {}) {
  return axios.patch(path, data, config);
}

/**
 * Delete request
 *
 * @param path
 * @param data
 * @param config
 * @returns {axios.Promise}
 */
function del(path, config = {}) {
  return axios.delete(path, config);
}

export const APIUtils = {
  get,
  put,
  post,
  patch,
  del,
};
