import {
  AUTH_LOADER,
  REGISTER_REQUEST,
  SEND_OTP,
  SET_AUTH_TOKEN,
  RESET,
  ROUTE_DATA_VERIFICATION,
  ROUTE_DATA_RESET_PWD,
  SET_HEADER_TAB,
  DRAWER_STATE,
  GET_STATE_DATA,
} from "./authType";
import { APIUtils, ApiNames } from "../../service";
import { toast } from "react-toastify";
import axios from "axios";
import { setDefaultAddress } from "../index";

export const setLoader = (payload) => {
  return {
    type: AUTH_LOADER,
    payload: payload,
  };
};

export const setToken = (payload) => {
  //
  return {
    type: SET_AUTH_TOKEN,
    payload: payload,
  };
};
export const resetAll = () => ({
  type: RESET,
});
export const setRouteDataVerificationPage = (payload) => {
  return {
    type: ROUTE_DATA_VERIFICATION,
    payload: payload,
  };
};
export const setRouteDataResetPwdPage = (payload) => {
  return {
    type: ROUTE_DATA_RESET_PWD,
    payload: payload,
  };
};
export const headerTabSelected = (payload) => {
  return {
    type: SET_HEADER_TAB,
    payload: payload,
  };
};
export const setDraweState = (payload) => {
  return {
    type: DRAWER_STATE,
    payload: payload,
  };
};

export const setStateAndCities = (payload) => {
  return {
    type: GET_STATE_DATA,
    payload: payload,
  };
};

export const registerUser = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.REGISTER, data)
    .then((response) => {
      toast.success(
        "Congratulations! You have successfully signed up for Food Dudes"
      );
      dispatch(setToken(response.data.token));
      cb();
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const socialLogin = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));

  APIUtils.post(ApiNames.SOCIAL_LOGIN, {
    ...data,
    // customers_telephone: 1234567890,
    // customers_email_address: "zsdsd@yopmail.com",
    // customers_newsletter: "1",
  })
    .then((response) => {
      // toast.success("Congratulations! You have successfully signed up for Food Dudes")
      dispatch(setLoader(false));
      if (response.data.other_info) {
        if (response.data.other_info.address_book_id != null) {
          dispatch(setDefaultAddress(response.data.other_info));
        }
      }
      dispatch(setToken(response.data.token));
      cb();
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const sendOtp = (data, cb, isSignup) => (dispatch) => {
  console.log("data of generate otp ==>", data);
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.GENERATE_OTP, {
    customers_username: data.customers_telephone
      ? data.customers_telephone
      : data.customers_username,
    expiry_interval: "+30 minutes",
  })
    .then((response) => {
      console.log("response of otp", response);
      dispatch(setLoader(false));
      cb();
      // let isCheck = data.customers_telephone || data.customers_username
      // if (isSignup) {
        // toast.success("OTP sent to your phone and email");
        toast.success((data.customers_telephone?data.customers_telephone:data.customers_username).includes("@")?"OTP sent to your email":"OTP sent to your phone");
      // } else {
      //   toast.success(response.data.message);
      // }
    })
    .catch((error) => {
      console.log("data of generate otp error==>", error.response);
      toast.error(error.response.data.message);
      dispatch(setLoader(false));
    });
};
export const reSendOtp = (data, cb, isSignup) => (dispatch) => {
  console.log("data of generate otp ==>", data);
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.GENERATE_OTP, {
    customers_username: data.customers_telephone
      ? data.customers_telephone
      : data.customers_username,
    expiry_interval: "+30 minutes",
  })
    .then((response) => {
      cb();
      toast.success((data.customers_telephone?data.customers_telephone:data.customers_username).includes("@")?"OTP sent to your email":"OTP sent to your phone");
      dispatch(setLoader(false));
    })
    .catch((error) => {
      console.log("data of generate otp error==>", error.response);
      toast.error(error.response.data.message);
      dispatch(setLoader(false));
    });
};

export const verifyOtp = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.VERIFY_OTP, data)
    .then((response) => {
      console.log("response verify otp ", response);
      dispatch(setLoader(false));
      cb();
      toast.success(response.data.message);
    })
    .catch((error) => {
      console.log("response verify otp error", error.response);
      if (Array.isArray(error.response.data.error)) {
        toast.error(error.response.data.error[0]);
      } else {
        toast.error(error.response.data.error);
      }
      dispatch(setLoader(false));
    });
};

export const forgotPassword = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.FORGOT_PASSWORD, data)
    .then((response) => {
      dispatch(setLoader(false));
      cb();
      toast.success(response.data.message);
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const resetPassword = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.RESET_PASSWORD, data)
    .then((response) => {
      dispatch(setLoader(false));
      cb();
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(setLoader(false));
    });
};

export const logIn = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.LOGIN, data)
    .then((response) => {
      if (response.data.other_info) {
        if (response.data.other_info.address_book_id != null) {
          dispatch(setDefaultAddress(response.data.other_info));
        }
      }
      dispatch(setToken(response.data.token));

      dispatch(setLoader(false));
      cb();
      toast.success(response.data.message);
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const requestSubscription = (data, cb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.REQUEST_SUBSCRIPTION, data)
    .then((response) => {
      dispatch(setLoader(false));
      // cb()
      toast.success(response.data.message);
    })
    .catch((error) => {
      dispatch(setLoader(false));
      toast.error(error.response.data.message);
    });
};

export const checkIfUserExists = (data, scb, ecb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.USER_EXISTS, data)
    .then((response) => {
      dispatch(setLoader(false));
      if (scb) {
        // console.log("response ==>usexit 1", response.data.token)
        // dispatch(setToken(response.data.token))
        scb();
      }
      // toast.success(response.data.message)
      // cb()
    })
    .catch((error) => {
      if (ecb) {
        // console.log("response ==>usexit 1", response.data.token)
        // dispatch(setToken(response.data.token))
        ecb();
      }

      toast.error(error.response.data.message);
      dispatch(setLoader(false));
    });
};
export const checkIfProviderExists = (data, scb, ecb) => (dispatch) => {
  dispatch(setLoader(true));
  APIUtils.post(ApiNames.PROVIDER_EXISTS, data)
    .then((response) => {
      if (response.data.other_info) {
        if (response.data.other_info.address_book_id != null) {
          dispatch(setDefaultAddress(response.data.other_info));
        }
      }
      dispatch(setToken(response.data.token));
      if (ecb) {
        ecb();
      }
      dispatch(setLoader(false));
      // cb()
    })
    .catch((error) => {
      scb();
      // toast.error(error.response.data.message)
      dispatch(setLoader(false));
    });
};
export const getStateAndCities = () => (dispatch) => {
  // dispatch(setLoader(true))
  // dispatch(setLoader(false))
  APIUtils.get(ApiNames.STATE_CITIES, {})
    .then((res) => {
      const cities = [];
      res.data.data.map((item, i) => {
        if (item.cities) {
          item.cities.map((c, index) => {
            cities.push({
              // id: item.id.toString() + index.toString() + name,
              id: item.id.toString() + index.toString(),
              orignalId:item.id,
              name: c.city_name,
              position: { lat: Number(c.lat), lng: Number(c.lng) },
            });
          });
        }
      });
      // dispatch(setLoader(false))
      dispatch(setStateAndCities({ stateCites: res.data.data, cities }));
    })
    .catch((err) => {
      // dispatch(setLoader(false))
      console.log(err.response);
    });
};

// export const generateAccessToken = (data) => {
//   axios.post("https://cors-anywhere.herokuapp.com/https://appleid.apple.com/auth/token", data)
//   .then((response)=>{
//     console.log("response from apple =>",response )
//   }).catch((error)=>{
//     console.log("response from apple error=>",error.response )
//   })
// }
export const validateMasterLogin =
  (token, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.VALIDATE_MASTER_TOKEN + token)
      .then((response) => {
        if (response.data.other_info) {
          if (response.data.other_info.address_book_id != null) {
            dispatch(setDefaultAddress(response.data.other_info));
          }
        }
        dispatch(setToken(response.data.token));
        toast.success("Logged in successfully");
        dispatch(setLoader(false));
        cb();
      })
      .catch((error) => {
        toast.error("Invalid login token");
        dispatch(setLoader(false));
      });
  };

export const driverSignUpApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    let model = {
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      email: data.email,
      age_check: data.ageCheck,
      city: data.city,
      comments: data.comments,
    };
    dispatch(setLoader(true));
    APIUtils.post(`${ApiNames.DRIVER_SIGN_UP}`, model)
      .then((response) => {
        let data = response?.data;
        cb(data);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        if (Array.isArray(error.response.data?.error) && error.response.data?.error.length) {
          toast.error(error.response.data?.error[0]);
        } else {
          toast.error(error.response.data?.message);
        }
        ecb(error.response.data?.message);
        dispatch(setLoader(false));
      });
  };

export const restaurantSignupApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    let model = {
      restaurant_name: data.rest_name,
      restaurant_address: data.address,
      contact_name: data.contact_name,
      email: data.email,
      phone: data.phone,
      comments: data.comments,
    };
    dispatch(setLoader(true));
    APIUtils.post(`${ApiNames.RESTAURANT_SIGN_UP}`, model)
      .then((response) => {
        let data = response?.data;
        cb(data);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        if (error.response.data?.error.length) {
          toast.error(error.response.data?.error[0]);
        } else {
          toast.error(error.response.data?.message);
        }
        ecb(error.response.data?.message);
        dispatch(setLoader(false));
      });
  };

export const contentPagesApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.get(`${ApiNames.CONTENT_PAGES}${data}`)
      .then((response) => {
        console.log("response of CONTENT_PAGES =>", response);
        cb(response.data.data.content);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("response of CONTENT_PAGES error =>", error.response);
        dispatch(setLoader(false));
      });
  };

export const contactUsApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    let model = {
      // restaurant_name: data.rest_name,
      // restaurant_address: data.address,
      fullname: data.firstname + data.lastname,
      email: data.email,
      phone: data.phone,
      comments: data.comments,
    };
    dispatch(setLoader(true));
    APIUtils.post(`${ApiNames.CONTACT_US}`, model)
      .then((response) => {
        let data = response?.data;
        cb(data);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        if (error.response.data?.error.length) {
          toast.error(error.response.data?.error[0]);
        } else {
          toast.error(error.response.data?.message);
        }
        ecb(error.response.data?.message);
        dispatch(setLoader(false));
      });
  };

export const faqListingApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.FAQ_LISTING)
      .then((response) => {
        console.log("response of FAQ =>", response);
        cb(response.data.data);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("response of FAQ error =>", error.response);
        dispatch(setLoader(false));
      });
  };
export const jobListingApi =
  (data, cb = () => null, ecb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.JOB_LIST)
    	.then((response) => {
    		// console.log("response of JOB_LIST =>", response);
        const val = JSON.parse(response.data.data)
    		// console.log("response of JOB_LIST =>", val.jobs);

    		cb(val.jobs);
    		dispatch(setLoader(false));
    	})
    	.catch((error) => {
    		console.log("response of JOB_LIST error =>", error.response);
    		dispatch(setLoader(false));
    	});
  //   var config = {
  //     method: "get",
  //     url: "https://food-dudes-delivery.workable.com/spi/v3/jobs",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:
  //         "Bearer fdd461e16499600c5f45b30de89520db29cbb26be8f7a46e055055530ddac736",
  //     },
  //     data: data,
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       console.log("Job list api success ", JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log("Job list api error", error, error.response);
  //     });
  };

  export const citiesApiDriver =
  (cb = () => null, ecb = () => null) =>
  (dispatch) => {
    dispatch(setLoader(true));
    APIUtils.get(ApiNames.DRIVER_CITIES)
    	.then((response) => {
    		console.log("response of citiesApiDriver =>", response);
    		cb(response.data.data);
    		dispatch(setLoader(false));
    	})
    	.catch((error) => {
    		console.log("response of JcitiesApiDriver =>", error.response);
        toast.error("")
    		dispatch(setLoader(false));
    	});
    }
