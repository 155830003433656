/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotificationAtFirst, getNotifyList } from "redux-store";
import Pusher from "pusher-js";
import { SocketConfig } from "constants/socketConfig";

const NotificationCounter = () => {
  const { token } = useSelector((store) => store.auth);
  const { customers_id } = useSelector((store) => store.profile);

  const dispatch = useDispatch();
  let pusher;
  let userNotificationChannel;
  const channelName = "new-notification";

  useEffect(() => {
    return () => {
      if (userNotificationChannel && pusher) {
        userNotificationChannel.unbind_all();
        pusher.unsubscribe(channelName);
        pusher.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (token) {
      startingConnection();
      getListing();
    }
  }, [token]);

  const startingConnection = () => {
    pusher = new Pusher(SocketConfig.PUSHER_KEY, SocketConfig.config);
    Pusher.log = (msg) => {
      console.log(msg);
    };

    pusher.connection.bind("error", function (err) {
      if (err.error.data.code === 4004) {
        console.log("Over limit!");
        pusher.disconnect();
      }
    });

    userNotificationChannel = pusher.subscribe(channelName);
    userNotificationChannel.bind("pusher:subscription_succeeded", function () {
      notificationUpdateListener();
    });
  };

  const notificationUpdateListener = () => {
    userNotificationChannel.bind(channelName, (pusherData) => {
      if (pusherData) {
        console.log("notification-update : ", pusherData);
        let data = pusherData.data;
        if (+data.customer_id === +customers_id) {
          addNotificationAtTop(data);
        }
      }
    });

    const addNotificationAtTop = (data) => {
      dispatch(addNotificationAtFirst(data));
    };
  };

  const getListing = () => {
    dispatch(getNotifyList());
  };

  return <></>;
};

export default NotificationCounter;
