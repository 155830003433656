import React, { useState, useEffect, useRef } from "react";
import { useLocation, Redirect } from "react-router-dom";
import {
	sendOtp,
	verifyOtp,
	registerUser,
	socialLogin,
	setRouteDataResetPwdPage,
	reSendOtp,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Loader from "../components/Loader";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

import backArrowImg from "assets/images/back-arrow-mobile.svg";
import verifyCodeImg from "assets/images/verify-code.svg";
import { useWindowResize } from "./../components/useWindowResize";

export const Verification = (props) => {
	const isMobileOnly = useWindowResize();

	let location = useLocation();
	const { loader, data } = useSelector((store) => store.auth);
	const history = useHistory();
	const dispatch = useDispatch();
	const [resetButton, setResetButton] = useState(false);
	const [isEmail, setIsEmail] = useState(undefined)
	const [duration, setDuration] = useState(30);
	const [otpValues, setOtpValues] = useState({
		a: "",
		b: "",
		c: "",
		d: "",
		e: "",
		f: "",
	});
	const inputRefA = useRef(null);
	const inputRefB = useRef(null);
	const inputRefC = useRef(null);
	const inputRefD = useRef(null);
	const inputRefE = useRef(null);
	const inputRefF = useRef(null);
	useEffect(() => {
		inputRefA.current.focus();
	}, []);
	useEffect(() => {
		if (document.activeElement === inputRefA.current && otpValues.a) {
			inputRefB.current.focus();
		}
		if (document.activeElement === inputRefB.current && otpValues.b) {
			inputRefC.current.focus();
		}
		if (document.activeElement === inputRefC.current && otpValues.c) {
			inputRefD.current.focus();
		}
		if (document.activeElement === inputRefD.current && otpValues.d) {
			inputRefE.current.focus();
		}
		if (document.activeElement === inputRefE.current && otpValues.e) {
			inputRefF.current.focus();
		}
	}, [otpValues]);
	const onKeyDown = (e, field, z) => {
		setTimeout(() => {
			if (e.keyCode === 8) {
				field.current.focus();
			}
		}, 1);
	};
	// const { data } = useLocation();
	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(
			verifyOtp(
				{
					customers_username: isEmail?data.customers_email_address:data.reset
						? data.customers_username
						: data.fromSignUp
						? data.customers_email_address
						: data.customers_telephone,
					otp: [otpValues.a]
						.concat(
							otpValues.b,
							otpValues.c,
							otpValues.d,
							otpValues.e,
							otpValues.f,
						)
						.join(""),
				},
				successCallBack,
			),
		);
	};
	useEffect(() => {
		const interval = setInterval(() => {
			setDuration((prevState) => {
				if (prevState < 31 && prevState > 0) {
					return prevState - 1;
				} else {
					clearInterval(interval);
					return 30;
				}
			});
		}, 1000);
		return () => clearInterval(interval);
	}, [resetButton]);

	const [initial, setInitial] = useState(true);
	useEffect(() => {
		setTimeout(() => setInitial(false), 1000);
	}, []);

	const resendCode = (isEmail) => {
		setIsEmail(isEmail)
		dispatch(
			reSendOtp(
				{
					customers_telephone: isEmail?data.customers_email_address: data.reset
						? data.customers_username
						: data.fromSignUp
						? data.customers_email_address
						: data.customers_telephone,
					expiry_interval: "+30 minutes",
				},
				() => {
					setResetButton((res) => !res);
					// history.push({
					// 	pathname: `${url}/verification`,
					// 	// search: '?query=abc',
					// 	data:{...data, customers_newsletter:data.customers_newsletter?"1":"0"	}
					// });
				},
			),
		);
		setOtpValues({
			a: "",
			b: "",
			c: "",
			d: "",
			e: "",
			f: "",
		});
	};

	const successCallBack = () => {
		if (data.reset) {
			dispatch(
				setRouteDataResetPwdPage({
					...data,
					otp: [otpValues.a]
						.concat(
							otpValues.b,
							otpValues.c,
							otpValues.d,
							otpValues.e,
							otpValues.f,
						)
						.join(""),
				}),
			);
			history.push({
				pathname: `/reset-password`,
				// search: '?query=abc',
				data: {
					...data,
					otp: [otpValues.a]
						.concat(
							otpValues.b,
							otpValues.c,
							otpValues.d,
							otpValues.e,
							otpValues.f,
						)
						.join(""),
				},
			});
		} else if (data.type == "social") {
			dispatch(
				socialLogin(
					{
						customers_email_address: data.customers_email_address,
						customers_newsletter: data.customers_newsletter,
						customers_telephone: data.customers_telephone,
						provider: data.provider,
						provider_access_token: data.provider_access_token,
						provider_uid: data.provider_uid,
						provider_user_name: data.provider_user_name,
					},
					() => {
						history.push("/");
					},
				),
			);
		} else {
			dispatch(
				registerUser(data, () => {
					history.push("/");
				}),
			);
		}
	};

	return (
		<>
			{location.data ? null : <Redirect to="/" />}
			{!isMobileOnly ? <Header auth /> : null}
			<main className="mobile-screen-bg">
				<div className="container">
					<div className="vertically-centered vertically-centered-mobilescreen initial-screens mobile-screens">
						<div className="wrapper">
							<div className="row mobile-logodiv backbtn-div d-md-none">
								<div className="col-12">
									<div>
										<img
											src={backArrowImg}
											alt="go back"
											onClick={() => history.goBack()}
										/>
									</div>
								</div>
							</div>
							<div className="row despktop-logodiv mb-4 mb-md-5">
								<div className="col-12">
									<div className="top-logo-wrapper">
										<a>
											<img src={verifyCodeImg} alt="verify code" />
										</a>
									</div>
								</div>
							</div>
							<div className="row centered-heading">
								<div className="col-12 mb-2 mb-md-4">
									<h1>Enter Code</h1>
								</div>
								<div className="col-12 mb-3 mb-md-4 text-center">
									{console.log("data check =>", data)}
									<p>
										We have sent you a 6-digit Code on your{" "}
										{`${
											data.customers_username &&
											data.customers_username.length == 10
												? "phone number":data.reset?data.customers_username.length == 10?"phone number":"email"
												: isEmail?"email":"phone number"
										}`}
										. <br /> Please enter the Code here.
									</p>
								</div>
							</div>
							<div className="otp-validation mb-4">
								<form
									className="needs-validation otp-validation-form"
									onSubmit={onSubmit}
								>
									<div className="form-row mb-5">
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit1"
												placeholder="0"
												required
												maxLength="1"
												onChange={(e) =>
													setOtpValues({ ...otpValues, a: e.target.value })
												}
												ref={inputRefA}
												onKeyDown={(e) => onKeyDown(e, inputRefA, "a")}
												value={otpValues.a}
											/>
										</div>
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit2"
												placeholder="0"
												required
												maxLength="1"
												onChange={(e) =>
													setOtpValues({ ...otpValues, b: e.target.value })
												}
												ref={inputRefB}
												onKeyDown={(e) => onKeyDown(e, inputRefA, "b")}
												value={otpValues.b}
											/>
										</div>
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit3"
												placeholder="0"
												required
												maxLength="1"
												onChange={(e) =>
													setOtpValues({ ...otpValues, c: e.target.value })
												}
												ref={inputRefC}
												onKeyDown={(e) => onKeyDown(e, inputRefB, "c")}
												value={otpValues.c}
											/>
										</div>
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit4"
												placeholder="0"
												required
												maxLength="1"
												onChange={(e) =>
													setOtpValues({ ...otpValues, d: e.target.value })
												}
												ref={inputRefD}
												onKeyDown={(e) => onKeyDown(e, inputRefC, "d")}
												value={otpValues.d}
											/>
										</div>
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit5"
												placeholder="0"
												required
												maxLength="1"
												onChange={(event) =>
													setOtpValues({ ...otpValues, e: event.target.value })
												}
												ref={inputRefE}
												onKeyDown={(e) => onKeyDown(e, inputRefD, "e")}
												value={otpValues.e}
											/>
										</div>
										<div className="col-2">
											<input
												type="tel"
												className="form-control input-field-layout otp-inputs"
												id="digit6"
												placeholder="0"
												required
												maxLength="1"
												onChange={(event) =>
													setOtpValues({ ...otpValues, f: event.target.value })
												}
												ref={inputRefF}
												onKeyDown={(e) => onKeyDown(e, inputRefE, "f")}
												value={otpValues.f}
											/>
										</div>
									</div>
									<button
										className="btn initial-screen-lg-btn font-20"
										id="verify-top-btn"
										type="submit"
									>
										Confirm Code
									</button>

									<div className="row mt-3">
										<div className="col-12">
											<div className="regenerate-code">
												{duration == 30 ? (
													<>
														{!initial ? (
															// <button
															// 	className="btn initial-screen-lg-btn font-20"
															// 	id="verify-top-btn"
															// 	type="button"
															// 	onClick={resendCode}
															// >
															// 	Resend Code
															// </button>
															<>
															{data.customers_password?<span className="resend-otp-text">Didn’t get a code? Resend on <span><a className="otp-links" role="button" onClick={()=>resendCode()}>phone</a></span> or <span><a className="otp-links" role="button" onClick={()=>resendCode("isEmail")}>email</a></span>?</span>:
														
															<span className="resend-otp-text">Didn’t get a code?<span><a className="otp-links" role="button" onClick={()=>resendCode()}>{" "}Resend Code</a></span> </span>}</>
															
														) : null}
													</>
												) : (
													<p>
														Please wait for {duration} seconds to re-generate
														the code
													</p>
												)}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			{!isMobileOnly ? <Footer /> : null}
		</>
	);
};

export default Verification;
