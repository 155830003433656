import React, { useState, useEffect } from "react";
import MenuItemContent from "./MenuItemContent";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import crossWhiteBgImg from "assets/images/modal-cross-icon-white-bg.svg";
import { BASE_URL_IMAGE } from "../../service/ApiNames";
import { useParams } from "react-router-dom";

const DesktopView = (props) => {
  const { groupToken } = useParams();
  const { store, dispatch, data, limitExceedMessage, isItemNotAvailable } =
    props;
  const { status } = store.tab.modalIsOpen;
  console.log("data?.isDisabled", data?.isDisabled)
  const [isDisabled, setIsDisabled] = useState(
    data?.isDisabled || isItemNotAvailable
  );

  useEffect(() => {
    setIsDisabled(data?.isDisabled || isItemNotAvailable);
  }, [props]);

  useEffect(() => {
    return () => {
      closeItemModal();
    };
  }, []);

  const closeItemModal = () => {
    dispatch({ type: "ITEM_MODAL", payload: { status: false, type: "" } });
  };

  return (
    <Modal
      className="modal fade menu-item-detailsModal"
      id="menu-item-detailsModal"
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      show={status}
      onHide={closeItemModal}
      aria-labelledby="menu-item-detailsModalLabel"
      centered
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        {props.data ? (
          <div className="modal-content">
            <div className="modal-header">
              {props.data && props.data.products_image ? (
                <div className="menu-item-banner-image">
                  <img
                    src={`${BASE_URL_IMAGE}${props.data.products_image}`}
                    // src={menuItemBgImg}
                    alt="menu-item"
                    className="img-fluid"
                  />
                </div>
              ) : null}
              <button
                style={{ zIndex: 9 }}
                type="button"
                className="close"
                onClick={closeItemModal}
              >
                <span aria-hidden="true">
                  <img src={crossWhiteBgImg} alt="cross icon" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="menu-item-modal-wrapper">
                <div className="menu-item-modal-wrapper-inner">
                  {limitExceedMessage ? (
                    <div className="row">
                      <div class="col-12 mb-2">
                        <div class="redeem-points bg-beige text-dark d-inline-block font-weight-bold">
                          {limitExceedMessage}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <MenuItemContent {...props}>
                    <div className="col-12">
                      <div
                        className={`modal-bottom-actions ${
                          isDisabled ? "disabled-state" : ""
                        }`}
                      >
                        <div className="quantity-actions">
                          <span
                            className="quantity-changes"
                            onClick={
                              props.quantityLoader
                                ? () => null
                                : props.customers_basket_quantity == 1
                                ? () => null
                                : () =>
                                    isDisabled
                                      ? null
                                      : props.handleQuantity(false)
                            }
                          >
                            -
                          </span>
                          {/* <span>{props.customers_basket_quantity}</span> */}
                          <span className="quantity-input-box">
                            <input
                              value={props.customers_basket_quantity}
                              // onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                              onChange={
                                isDisabled ? () => null : props.onChangeInput
                              }
                              style={{ border: "none" }}
                            />
                          </span>

                          <span
                            className="quantity-changes"
                            onClick={
                              props.quantityLoader
                                ? () => null
                                : () =>
                                    isDisabled
                                      ? null
                                      : props.handleQuantity(true)
                            }
                          >
                            +
                          </span>
                        </div>
                        <div className="update-order-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-filled"
                            disabled={isDisabled || limitExceedMessage}
                            onClick={
                              props.customers_basket_quantity
                                ? isDisabled || limitExceedMessage
                                  ? null
                                  : props.handleAddToCart
                                : () => toast.error("Please add quantity")
                            }
                          >
                            {groupToken ? (
                              <>{props.isEdit ? "Update Item" : "Add Item"}</>
                            ) : (
                              <>
                                {props.isEdit ? "Update Order" : "Add To Order"}
                              </>
                            )}
                            <span className="total-order-value">
                              {/* {props.total?`${props.total}`:`${props.data.products_price}`} */}
                              ${parseFloat(props.total).toFixed(2)}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </MenuItemContent>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="loading">
              <div className="sk-spinner-pulse"></div>
            </section>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DesktopView;
