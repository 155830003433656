import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getContactList, getGroupMembers } from "redux-store";
import moment from "moment";

const ImportContact = (props) => {
  const { getMembersList, toggleContacts } = props;
  const dispatch = useDispatch();

  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    dispatch(getContactList(getList));
  }, []);

  const getList = (data) => {
    setGroupList(data);
  };

  const getMembers = (id) => {
    dispatch(
      getGroupMembers(id, (data) => {
        getMembersList(data);
        toggleContacts(false);
      })
    );
  };

  return (
    <>
      <div className="devilery-address-modal-wrapper">
        <div className="devilery-address-modal-wrapper-inner">
          <div className="row">
            <div className="col-12 mb-4 payment__page__wrapper">
              <div className="order-listing-wrapper">
                <table className="table order-listing-table contact-listing">
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={{ "min-width": "120px" }}
                        className="font-weight-bold"
                      >
                        Label & Restaurant Names
                      </th>
                      <th style={{ "min-width": "100px" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupList && groupList.length > 0 ? (
                      groupList.map((group, idx) => (
                        <tr key={idx} onClick={() => getMembers(group?.id)}>
                          <td>
                            <span className="font-weight-bold">
                              {group?.title ? group?.title + " - " : ""}
                              {group?.restaurant_name}
                            </span>
                          </td>
                          <td>
                            {moment(group?.date_deliver).format(
                              "ddd MM/DD/YYYY h:mm A"
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center pt-5" colSpan={2}>
                          <span className="mt-4 d-inline-block no-contact-text">There are no previous group order to import contacts</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportContact;
