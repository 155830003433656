import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetAll } from "redux-store";
import profileImg from "assets/images/profile-icon.svg";
import locationMarkerImg from "assets/images/location-marker-icon.svg";
import signinImg from "assets/images/signin-icon.svg";
import signupImg from "assets/images/signup-icon.jpg";
import myProfileImg from "assets/images/myProfile-icon.svg";
import addressImg from "assets/images/address-icon.svg";
import walletImg from "assets/images/wallet-icon.svg";
import paymentMethods from "assets/images/payment_methods.svg";
import giftCard from "assets/images/giftCard.svg";
import workWithUsImg from "assets/images/work-with-us.svg";
import listRestaurantImg from "assets/images/lisyoutrestutant-icon.svg";
import becomeDriverImg from "assets/images/become-driver-icon.svg";
import contactImg from "assets/images/contact.svg";
import logoutImg from "assets/images/logout.svg";
import pgc_img from "assets/images/pgc_img.svg";
import caarIcon from "assets/images/carr-icon.svg";
import chatSquare from "assets/images/chat-square.svg"
import notification_side from "assets/images/notification_side.svg";
import { useWindowResize } from "../useWindowResize";

const SideNav = (props) => {
  const isMobileOnly = useWindowResize();
  const dispatch = useDispatch();
  const [rdirect, setRdirect] = useState(false);
  const store = useSelector((store) => store);
  const {unreadNotify} = store.notification
  const {
    customers_email_address,
    customers_firstname,
    customers_lastname,
    customers_telephone,
    provider,
  } = store.profile || {};
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (!isMobileOnly) {
      if(store.auth.drawerState && location.pathname!="/"){
        props.setDraweState(false);
      }
    }
  }, [location.pathname]);
  const handleLogout = () => {
    //setRdirect(true)
    dispatch(resetAll());
    // history.push('/')
  };
  const renderRedirect = () => {
    if (rdirect) {
      dispatch(resetAll());
      return <Redirect to="/" />;
    }
  };
  const isLoggedIn = store.auth.token;

  return (
    <div className="container">
      <div className="signedIn-user-profile d-none">
        <Link
          to={
            isLoggedIn
              ? {
                  pathname: "/profile",
                  state: { background: isMobileOnly ? null : location },
                  data: { isProfile: true },
                }
              : { pathname: "/signin" }
          }
          className="my-profile"
          data-toggle="modal"
          // data-target="#profileModal"
          onClick={isMobileOnly ? null : () => props.setDraweState(false)}
        >
          <>
            {isLoggedIn ? (
              <div className="persons-account">
                <div className="row d-none">
                  <div className="col-3">
                    <img src={profileImg} alt="person" className="img-fluid" />
                  </div>
                  <div className="col-9 pl-0">
                    <div className="person-details">
                      <h6>{`${customers_firstname} ${customers_lastname}`}</h6>
                      <div className="contactno">
                        <p>{customers_telephone}</p>
                      </div>
                      <div className="emailadd">
                        <p>{customers_email_address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="guest-user-profile pt-4 pt-md-0">
                <h3>Hello there!</h3>
                <p>Sign In or Sign up to view your profile</p>
              </div>
            )}
          </>
        </Link>

        {/* <!--end:: Add Address Modal -->
                         <!--begin:: edit Address Modal --> */}
        <div
          className="modal fade"
          id="editAddressModal"
          tabindex="-1"
          aria-labelledby="editAddressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editdAddressModalLabel">
                  Edit Address
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <section className="modal-editAddress-wrapper pickaddress">
                  <form className="form-wrapper">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="deliveryAddress"
                        placeholder="Enter Your Delivery Address"
                      />
                    </div>
                    <ul className="addressLists">
                      Saved Addresses
                      <li>
                        <div className="icon">
                          <img src={locationMarkerImg} alt="icon" />
                        </div>
                        <div className="addressType">
                          <p>Rapid City </p>
                          <p className="addressDetails">SD, USA</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img src={locationMarkerImg} alt="icon" />
                        </div>
                        <div className="addressType">
                          <p>Rapid City Regional Airport</p>
                          <p className="addressDetails">Terminal Road</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img src={locationMarkerImg} alt="icon" />
                        </div>
                        <div className="addressType">
                          <p>Rapid City</p>
                          <p className="addressDetails">MI, USA</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img src={locationMarkerImg} alt="icon" />
                        </div>
                        <div className="addressType">
                          <p>Rapid City Regional Airport</p>
                          <p className="addressDetails">Terminal Road</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img src={locationMarkerImg} alt="icon" />
                        </div>
                        <div className="addressType">
                          <p>Rapid City / Black Hills</p>
                          <p className="addressDetails">South Dakota 44</p>
                        </div>
                      </li>
                    </ul>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* <!--end:: edit Address Modal --> */}
      </div>

      <div className="persons-account-menu">
        <ul
          className="nav flex-column"
          style={isMobileOnly ? { paddingBottom: "100px" } : {}}
        >
          {!isLoggedIn ? (
            <>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/signin"
                  onClick={
                    isMobileOnly ? null : () => props.setDraweState(false)
                  }
                >
                  <span className="non-active menu-icons">
                    <img src={signinImg} alt="icon" />
                  </span>
                  Sign In
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/signup"
                  onClick={
                    isMobileOnly ? null : () => props.setDraweState(false)
                  }
                >
                  <span className="non-active menu-icons">
                    <img className="img-fluid" src={signupImg} alt="icon" />
                  </span>
                  Sign Up
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link
                  data-toggle="modal"
                  // data-target="#profileModal"
                  className="nav-link active"
                  to={
                    isLoggedIn
                      ? {
                          pathname: "/profile",
                          state: { background: isMobileOnly ? null : location },
                          data: { isProfile: true, step: 0 },
                        }
                      : { pathname: "/signin" }
                  }
                  onClick={
                    isMobileOnly ? null : () => props.setDraweState(false)
                  }
                >
                  <span className="non-active menu-icons">
                    <img src={myProfileImg} alt="icon" />
                  </span>
                  My Account
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/orderlist"
                  onClick={
                    isMobileOnly ? null : () => props.setDraweState(false)
                  }
                >
                  <span className="non-active menu-icons">
                    <img src={signinImg} alt="icon" />
                  </span>
                  Orders
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  data-toggle="modal"
                  // data-target="#profileModal"
                  className="nav-link active"
                  to={
                    isLoggedIn
                      ? {
                          pathname: "/profile",
                          state: { background: isMobileOnly ? null : location },
                          data: { isProfile: false },
                        }
                      : { pathname: "/signin" }
                  }
                  onClick={
                    isMobileOnly ? null : () => props.setDraweState(false)
                  }
                >
                  <span className="non-active menu-icons">
                    <img src={addressImg} alt="icon" />
                  </span>
                  Addresses
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  onClick={() => history.push("/buy-gift-card")}
                >
                  <span className="non-active menu-icons">
                    <img className="img-fluid" src={pgc_img} alt="icon" />
                  </span>
                  Purchase Gift Cards
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  onClick={() => history.push("/giftcardtab/rewards")}
                >
                  <span className="non-active menu-icons">
                    <img className="img-fluid" src={walletImg} alt="icon" />
                  </span>
                  Rewards
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/notifications">
                  <span className="non-active menu-icons">
                    <img
                      className="img-fluid"
                      src={notification_side}
                      alt="icon"
                    />
                      {unreadNotify ? <span className="sidenav-notification-icon"></span> : null}
                  </span>
                  Notifications
                </Link>
              </li>
            </>
          )}

          <li className="nav-item">
            <Link
              className="nav-link active"
              onClick={() =>
                history.push(
                  store.auth.token
                    ? "/giftcardtab"
                    : "/buy-gift-card?guest=true"
                )
              }
            >
              <span className="non-active menu-icons">
                <img className="img-fluid" src={giftCard} alt="icon" />
              </span>
              Gift Cards
            </Link>
          </li>
          {isLoggedIn ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                onClick={() => history.push("/payment-methods")}
              >
                <span className="non-active menu-icons">
                  <img className="img-fluid" src={paymentMethods} alt="icon" />
                </span>
                Payment Methods
              </Link>
            </li>
          ) : null}

          {/* <li className="nav-item">
            <Link
              className="nav-link active"
              // to="/jobs"
              onClick={isMobileOnly ? null : () => props.setDraweState(false)}
            >
              <span className="non-active menu-icons">
                <img className="img-fluid" src={workWithUsImg} alt="icon" />
              </span>
              Work with us
            </Link>
          </li> */}
          
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/restaurant-signup"
              replace={location.pathname.includes("/restaurant-signup")?true:false}
              onClick={isMobileOnly ? null : () => props.setDraweState(false)}
            >
              <span className="non-active menu-icons">
                <img className="img-fluid" src={listRestaurantImg} alt="icon" />
              </span>
              List a Resturant
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={isMobileOnly ? null : () => props.setDraweState(false)}
          >
            <Link className="nav-link active" to="/driver-signup" replace={location.pathname.includes("/driver-signup")?true:false}>
              <span className="non-active menu-icons">
                <img className="img-fluid" src={becomeDriverImg} alt="icon" />
              </span>
              Become a Driver
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={isMobileOnly ? null : () => props.setDraweState(false)}
          >
            <Link className="nav-link active" to="/contact-us" replace={location.pathname.includes("/contact-us")?true:false}>
              <span className="non-active menu-icons">
                <img className="img-fluid" src={contactImg} alt="icon" />
              </span>
              Contact Us
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={isMobileOnly ? null : () => props.setDraweState(false)}
          >
            <a className="nav-link active" href="javascript:void(Tawk_API.toggle())">
              <span className="non-active menu-icons">
                <img className="img-fluid" src={chatSquare} alt="icon" />
              </span>
              Chat with Us
            </a>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/jobs"
              onClick={isMobileOnly ? null : () => props.setDraweState(false)}
            >
              <span className="non-active menu-icons">
                <img className="img-fluid" src={caarIcon} alt="icon" />
              </span>
              Career
            </Link>
          </li>

          {isLoggedIn && (
            <li className="nav-item">
              <Link onClick={handleLogout} to="/" className="nav-link active">
                <span className="non-active menu-icons">
                  <img className="img-fluid" src={logoutImg} alt="icon" />
                </span>
                Logout
              </Link>
            </li>
          )}
          {renderRedirect()}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
